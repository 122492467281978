import styled from 'styled-components';

export const EnvContainer = styled.div`
  display: flex;
  block-size: 3.5rem;
  gap: 0.8rem;
  border-radius: 0.4rem;
`;

const getButtonCss = (type: string) => {
  switch (type) {
    case 'draft':
      return `
        background-color: var(--color-earlyDawn);
        color: var(--color-tangerine);
        border-right: 1px solid var(--color-tangerine);
      `;

    case 'in-review':
      return `
          background-color: var(--color-aliceColor);
          color: var(--color-inReviewBg);
          border-right: 1px solid var(--color-inReviewBg);
        `;
    case 'publish':
      return `
              background-color: var(--color-lightCyan);
              color: var(--color-waterCourse);
              border-right: 1px solid var(--color-waterCourse);
            `;
  }
};

const getButtonNumberCss = (type: string) => {
  switch (type) {
    case 'draft':
      return `
        background-color: var(--color-earlyDawn);
        color: var(--color-tangerine);
      `;

    case 'in-review':
      return `
          background-color: var(--color-aliceColor);
          color: var(--color-inReviewBg);
        `;
    case 'publish':
      return `
              background-color: var(--color-lightCyan);
              color: var(--color-waterCourse);
            `;
  }
};

const getButtonByType = (type: string) => {
  switch (type) {
    case 'draft':
      return `
        border: 1px solid var(--color-tangerine);
      `;
    case 'in-review':
      return `
          border: 1px solid var(--color-inReviewBg);
        `;

    case 'publish':
      return `
            border: 1px solid var(--color-waterCourse);
          `;
  }
};

export const EnvButtonLayer = styled.div`
  block-size: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.4rem 0.8rem;
  border-radius: 0.4rem;
  gap: 0.4rem;
  color: var(--color-black);
  background-color: var(--color-white);
`;

export const StatusDisplay = styled.div<{ $type?: string }>`
  display: flex;
  block-size: 100%;
  cursor: pointer;
  background: var(--color-primary1);
  color: var(--color-white);
  border: none;
  font-size: 1.4rem;
  align-items: center;
  justify-content: space-between;
  min-inline-size: 3rem;
  padding: 0.3rem 1rem;
  ${({ $type = 'draft' }) => getButtonCss($type)}
`;

export const StatusNumberDisplay = styled.div<{ $type?: string }>`
  display: flex;
  block-size: 100%;
  inline-size: 3rem;
  cursor: pointer;
  color: var(--color-primary1);
  background-color: var(--color-white);
  border: none;
  font-size: 1.4rem;
  align-items: center;
  justify-content: center;
  padding: 0.3rem 1rem;
  ${({ $type = 'draft' }) => getButtonNumberCss($type)}
  font-weight: 700;
`;

export const EnvButtonContainer = styled.button<{ $type?: string }>`
  display: flex;
  justify-content: center;
  padding: 0;
  block-size: 100%;
  border: 1px solid var(--color-primary1);
  border-radius: 0.4rem;
  overflow: hidden;
  ${({ $type = 'draft' }) => getButtonByType($type)}
`;
