import { PadBox } from '@bedrock-layout/padbox';
import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _reduce from 'lodash/reduce';
import { useEffect, useState } from 'react';
import {
  Control,
  UseFormSetValue,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { Button, Sheet, Spinner, Typography } from 'ui';

import { createRuleSheetAtom, simpleRuleNodeIdAtom } from '../../..';
import { customAttributesAtom } from '../../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import { useTestRule } from '../../../hooks/restApi/useTestRule';
import type { CronCAObjectModel } from '../../../models';
import {
  convertDataToTestableDataCron,
  convertRuleSetDataToTestableDataCron,
  getDataTypeByKeyV2,
} from '../../../utils/common';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { decisionTableNodeIdAtom } from '../../DecisionTable/DecisionTable';
import { ruleSetNodeId } from '../../RuleSet/RuleSet';
import { RuleCronScheduleModel } from '../../SimpleRule/models';
import { TestNodeField } from '../../TestNodeComponents/TestNodeField';
import { TestNodeFooter } from '../../TestNodeComponents/TestNodeFooter';
import {
  EmptyInputText,
  Form,
  HeaderItemStyled,
  HeaderStyled,
  RowItemStyled,
  RowStyled,
  SaveNodesContainerStyled,
} from './SchedulerCASheet.styled';
import { SchedulerResult, SuccessErrorResponse } from './SchedulerResult';

type TestNodesHeaderModel = {
  label: string;
  dataType: string;
};

export type CronCADataModel = {
  cronAttributes: Array<Record<string, CronCAObjectModel>>;
};

type ScheduleTestSheetProps = {
  cronIndex: number;
  parentControl?: Control<any>;
  setValue?: UseFormSetValue<any>;
};

export function ScheduleTestSheet({
  cronIndex = 0,
  parentControl,
  setValue,
}: ScheduleTestSheetProps) {
  const [dataset] = useAtom(dataSetParamsAtom);
  const { attributes } = dataset.customInput;
  const [customAttributes] = useAtom(customAttributesAtom);
  const [ruleType] = useAtom(createRuleSheetAtom);
  const [decisionTableId] = useAtom(decisionTableNodeIdAtom);
  const [simpleRuleId] = useAtom(simpleRuleNodeIdAtom);
  const [ruleSetId] = useAtom(ruleSetNodeId);
  const dataTypeByKey = getDataTypeByKeyV2(customAttributes);

  const [response, setResponse] = useState<SuccessErrorResponse | null>(null);
  const [isValid, setIsValid] = useState(false);

  const oldValue: RuleCronScheduleModel | undefined = useWatch({
    name: `productionConfig.schedule.${cronIndex}`,
    control: parentControl,
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue: setLocalValue,
  } = useForm<CronCADataModel>({
    defaultValues: {
      cronAttributes: [
        _reduce(
          customAttributes,
          (result: Record<string, CronCAObjectModel>, value, key) => {
            if (key !== 'CI0') {
              return {
                ...result,
                [key]: {
                  value:
                    customAttributes[key].sourceType === 'dataSet'
                      ? 'Mapped to DB Field'
                      : null,
                  isNullable: false,
                  isOptional: false,
                  isDataset: customAttributes[key].sourceType === 'dataSet',
                  schemaId: customAttributes[key].schemaId,
                },
              };
            }

            return result;
          },
          {}
        ),
      ],
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'cronAttributes',
  });

  const [headerList, setHeaderList] = useState<TestNodesHeaderModel[]>([]);

  useEffect(() => {
    if (!_isEmpty(attributes)) {
      setHeaderList(
        _reduce(
          attributes,
          (result: TestNodesHeaderModel[], value, key) => {
            if (key !== 'CI0') {
              result.push({ label: key, dataType: attributes[key].dataType });
            }

            return result;
          },
          []
        )
      );
    }

    return () => {
      setHeaderList([]);
    };
  }, [attributes]);

  useEffect(() => {
    if (!_isNil(customAttributes) && ruleType === 'ruleSet') {
      const headers: TestNodesHeaderModel[] = [];

      _forEach(Object.keys(customAttributes), (value) => {
        headers.push({
          dataType: customAttributes[value].dataType?.value ?? 'string',
          label: value,
        });
      });

      setHeaderList(headers.filter((header) => header.label !== 'CI0'));
    }
  }, [customAttributes]);

  const ruleName = useWatch({ name: 'ruleName', control: parentControl });

  const { testRule, data, isLoading } = useTestRule({ ruleName, ruleType });

  const onSubmit = async (data: CronCADataModel) => {
    const ruleId =
      ruleType === 'decisionTable'
        ? decisionTableId
        : ruleType === 'ruleSet'
        ? ruleSetId
        : simpleRuleId;

    const values =
      ruleType === 'decisionTable' || ruleType === 'simpleRule'
        ? convertDataToTestableDataCron(data.cronAttributes[0], attributes)
        : convertRuleSetDataToTestableDataCron(
            data.cronAttributes[0],
            dataTypeByKey
          );

    if (!_isNil(ruleId)) {
      try {
        await testRule({
          id: ruleId,
          params: values,
          type: 'cron',
        });
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (!_isNil(oldValue)) {
      reset({
        cronAttributes: [
          _reduce(
            customAttributes,
            (result: Record<string, CronCAObjectModel>, value, key) => {
              if (key !== 'CI0') {
                return {
                  ...result,
                  [key]: {
                    value:
                      customAttributes[key].sourceType === 'dataSet'
                        ? 'Mapped to DB Field'
                        : !_isNil(oldValue?.inputParam?.[key])
                        ? oldValue?.inputParam?.[key].value
                        : null,
                    isNullable: oldValue?.inputParam?.[key]?.sendNull ?? false,
                    isOptional: oldValue?.inputParam?.[key]?.notSend ?? false,
                    isDataset: customAttributes[key].sourceType === 'dataSet',
                    schemaId: customAttributes[key].schemaId,
                  },
                };
              }

              return result;
            },
            {}
          ),
        ],
      });
    }
  }, [oldValue, ruleType]);

  useEffect(() => {
    if (!_isNil(data)) {
      if (data.data.data?.success.count > 0) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }

      setResponse({
        success: {
          count: data.data.data.success.count,
        },
        failure: {
          count: data.data.data.failure.count,
          message: data.data.data.failure.count > 0 && data.data.data,
        },
        limitExceeded: {
          count: data.data.data.limitExceed?.count,
          message: data.data.data.limitExceed?.count > 0 && data.data.data,
        },
      });
    }
  }, [data]);

  return (
    <Sheet size="large">
      <div>
        <PadBox padding="2rem">
          <Typography name="heading2">Test Schedule</Typography>
        </PadBox>

        <Stack gutter="1.6rem" as={Form} onSubmit={handleSubmit(onSubmit)}>
          {headerList.length > 0 ? (
            <>
              <Typography fontWeight={700}>Input Atrributes</Typography>
              <SaveNodesContainerStyled as="ul">
                <HeaderStyled gutter={0}>
                  {headerList.map((header, index) => (
                    <HeaderItemStyled key={`attributeHeader_${index}`}>
                      <PadBox padding={[5, 10]}>
                        <Inline stretch="start" align="end">
                          <Typography>{header.label}</Typography>
                          <Typography name="secondaryXs">
                            {header.dataType}
                          </Typography>
                        </Inline>
                      </PadBox>
                    </HeaderItemStyled>
                  ))}
                </HeaderStyled>

                {fields.map((field, index) => (
                  <RowStyled
                    gutter={0}
                    key={`testRowItem_${field.id}`}
                    align="stretch"
                  >
                    {Object.keys(field)
                      .filter((key) => key !== 'id')
                      .map((key, fieldIndex) => (
                        <RowItemStyled
                          key={`testRowItem_${index}_${fieldIndex}`}
                        >
                          <PadBox padding={[5, 10]} as={Inline} align="center">
                            <TestNodeField
                              nodeKey={key}
                              index={index}
                              dataType={
                                // eslint-disable-next-line
                                field[key].isDataset
                                  ? 'string'
                                  : ruleType === 'ruleSet'
                                  ? dataTypeByKey[key]
                                  : customAttributes[key]?.sourceType ===
                                    'dataSet'
                                  ? 'string'
                                  : attributes[key].dataType
                              }
                              control={control}
                              isNullable={customAttributes[key]?.isNullable}
                              isOptional={customAttributes[key]?.isOptional}
                              isDisabled={field[key].isDataset}
                              nodeType="cronAttributes"
                              setValue={setLocalValue}
                            />
                          </PadBox>
                        </RowItemStyled>
                      ))}
                  </RowStyled>
                ))}
              </SaveNodesContainerStyled>{' '}
            </>
          ) : (
            <EmptyInputText name="secondarySmall">
              You do not have any input attributes configured. Please go ahead
              and click {'Test Schedule'} to test the scheduler. Please note
              test will be run on only 10 entries.
            </EmptyInputText>
          )}

          <TestNodeFooter>
            <Button type="submit" disabled={isLoading}>
              {isLoading ? <Spinner size="extraSmall" /> : 'Test'}
            </Button>
          </TestNodeFooter>

          {!_isNil(response) && (
            <SchedulerResult value={response} isValid={isValid} />
          )}
        </Stack>
      </div>
    </Sheet>
  );
}
