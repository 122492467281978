import { gql, useLazyQuery } from '@apollo/client';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useMemo, useState } from 'react';
import type { UseFormSetValue } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { handleSetCheckSumByEntityName } from '../../../utils/common';
import type { DataSetModel } from '../models';
import type { DurationUnitValue, UpdateDataSetStatusFunction } from '../types';
import { getDurationUnitByValue } from '../utils';
import { useGetDatasetByIdOnPublish } from './graphql/useGetDatasetByIdOnPublish';
import { timezoneOptions } from '../../Workspace/component/WorkspaceSettings/constant';

const getDataSetsQuery = gql`
  query getDataSet(
    $dataSetId: String!
    $live: Boolean
    $filters: QueryOperators
  ) {
    getDataSet(id: $dataSetId, live: $live, filters: $filters) {
      data {
        id
        name
        description
        query
        params
        status
        isLive
        checksum
        createdAt
        publishedAt
        settings {
          cacheEnabled
          durationUnit
          durationValue
          rowLimit
          timezone
          dateFormat
        }
        connector {
          id
          name
          plugin {
            imageUrl
            name
          }
        }
        approvalInfo {
          title
          description
          approvers
        }
        versionInfo {
          currentVersion
          currentLiveVersion
          lastVersion
        }
      }
    }
  }
`;

export type GetDataSetQueryResult = {
  getDataSet: {
    data: DataSetModel[];
  };
};

export function useGetDataSet(
  dataSetId: string,
  setValue: UseFormSetValue<any>,
  updateDataSetStatus: UpdateDataSetStatusFunction,
  isLive = false,
  commitId?: string
) {
  const [searchParams] = useSearchParams();
  const version = searchParams.get('version') ?? '';

  const [datasetObj, setDatasetObj] = useState<DataSetModel>();
  // eslint-disable-next-line
  const [selectedConnector, setSelectedConnector] = useState<any>();

  const queryFilters = useMemo(() => {
    const filters: Record<string, any> = {};

    if (!_isNil(commitId) && !_isEmpty(commitId)) {
      filters.eq = { commitId };
    }

    if (!_isNil(version) && !_isEmpty(version)) {
      filters.eq = { version };
    }

    return filters;
  }, [version, commitId]);

  const [getDataSet, { loading, error }] = useLazyQuery<
    GetDataSetQueryResult,
    {
      dataSetId: string;
      live: boolean;
      filters: Record<string, any>;
    }
  >(getDataSetsQuery, {
    variables: {
      dataSetId,
      live: isLive,
      filters: queryFilters,
    },
    fetchPolicy: 'no-cache',
  });

  const [getDataSetByIdOnPublish, { data: datasetAfterPublish }] =
    useGetDatasetByIdOnPublish();

  const setFormValues = (data?: GetDataSetQueryResult) => {
    const dataSet = data?.getDataSet.data[0];

    if (!_isNil(dataSet)) {
      handleSetCheckSumByEntityName('datasets', dataSet.checksum);
    }

    if (!_isNil(dataSet)) {
      setDatasetObj(dataSet);

      setValue('query', dataSet.query);
      setValue('integration', {
        label: dataSet.connector.name,
        value: dataSet.connector.id,
      });
      setValue('name', dataSet.name);
      setValue('description', dataSet.description);
      setValue('params', dataSet.params);
      setValue('status', dataSet.status);
      setValue('isLive', dataSet.isLive);
      setValue('approvalInfo', dataSet.approvalInfo);
      setValue('versionInfo', dataSet.versionInfo);
      setValue('publishedAt', dataSet.publishedAt);
      setValue('createdAt', dataSet.createdAt);

      const productionSettings = dataSet.settings;

      if (!_isNil(productionSettings)) {
        setValue('productionSettings', {
          ...productionSettings,
          durationUnit: getDurationUnitByValue(
            productionSettings.durationUnit as DurationUnitValue
          ),
        });

        setValue(
          'productionSettings.durationUnit',
          getDurationUnitByValue(
            productionSettings.durationUnit as DurationUnitValue
          )
        );

        setValue(
          'productionSettings.timezone',
          timezoneOptions.find((i) => i.value === productionSettings.timezone)
        )

        setValue(
          'productionSettings.dateFormat',
          productionSettings.dateFormat === '' ? 'in' : productionSettings.dateFormat
        )
      }
    }
  };

  useEffect(() => {
    if (!_isEmpty(dataSetId)) {
      const getDataSetById = async () => {
        try {
          const { data } = await getDataSet();
          setFormValues(data);
        } catch (error) {}
      };

      void getDataSetById();
    }
  }, []);

  useEffect(() => {
    if (!_isNil(datasetAfterPublish)) {
      const data = datasetAfterPublish?.getDataSet.data[0];

      if (!_isNil(data)) {
        setValue('status', data.status);
        setValue('isLive', data.isLive);
        setValue('versionInfo', data.versionInfo);
        setValue('publishedAt', data.publishedAt);

        handleSetCheckSumByEntityName('datasets', data.checksum);
      }
    }
  }, [datasetAfterPublish]);

  const handleGetWorkflowAfterStateTransition = async () => {
    try {
      const { data } = await getDataSet();
      setFormValues(data);
    } catch (error) {}
  };

  return {
    loading,
    error,
    datasetObj,
    selectedConnector,
    getDataSetByIdOnPublish,
    handleGetWorkflowAfterStateTransition,
  };
}
