import { Inline, PadBox } from '@bedrock-layout/primitives';
import styled from 'styled-components';
import { Typography } from 'ui';

export const ContainerStyled = styled(PadBox)`
  min-block-size: 2.5rem;
  background-color: var(--color-solitudeSecondary);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
`;

export const PropertyContainer = styled(Inline)`
  inline-size: 11rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RuleLauncherLabel = styled(Typography)`
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const PopoverContainerStyled = styled.div`
  overflow-y: auto;
  max-block-size: 30rem;
  inline-size: 32rem;
`;

export const RhsInputContainer = styled.div`
  max-block-size: 30rem;
  inline-size: 46rem;
  overflow-y: auto;
`;
