import { Inline, PadBox } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const RuleEditorContainer = styled(Inline)`
  border-block-start: 1px solid var(--color-darkGray);
  block-size: calc(100vh - 23.2rem);
`;

export const RulesContainerStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SourceContainerStyled = styled(PadBox)`
  background-color: var(--color-solitude);
`;

export const RuleNodesContainer = styled(PadBox)`
  display: inline-block;
  margin-top: 5rem;
  z-index: 0;
`;

export const DatasetButtonStyled = styled.button.attrs((props) => ({
  type: 'button',
}))`
  min-inline-size: 10rem;
  border-radius: 4px;
  background-color: var(--color-white);
  border: 1px solid var(--color-lightGray);
  padding: 0.6rem 0.5rem;
  cursor: pointer;

  &.btn-disabled-interceptor {
    background: var(--color-white) !important;
    color: var(--color-lightGray) !important;
    cursor: progress !important;
  }
`;

export const TestButtonContainer = styled(Inline)`
  position: absolute;
  right: 1rem;
  bottom: 1rem;
`;

export const RuleInputHeder = styled(PadBox)`
  position: fixed;
  z-index: 100;
  height: 5rem;
  padding: 0.6rem 1.2rem;
  background-color: var(--color-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
