import * as Sentry from '@sentry/react';
import { useAtom } from 'jotai';
import Cookies from 'js-cookie';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _reduce from 'lodash/reduce';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Spinner, setLocalStorageItem, toasts, useLayer } from 'ui';
import { createUUID } from 'ui/src/Layer/utils';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

import { axiosPrivate } from './api/axios';
import {
  allWorkspaceAtom,
  checksumWarningAtom,
  enableGetAllWorkspaceApiAtom,
  enablePermissionApiAtom,
  isFetchedUserStateAtom,
  siteConstantsAtom,
  subscriptionPlanAtom,
  userProfileAtom,
} from './atom';
import { ChecksumWarning } from './components/ChecksumWarning';
import {
  AuthenticatedAlready,
  AuthenticationRequired,
  RememberMe,
  useAuth,
} from './components/authentication';
import { Authentication } from './components/layouts/Authentication';
import { CoveredLogo } from './components/layouts/CoveredLogo';
import { Dashboard } from './components/layouts/Dashboard';
import { WLAuthentication } from './components/layouts/WlAuthentication';
import { getUserState } from './hooks/getUserState';
import { useAllWorkspaceData } from './hooks/useAllWorkspaceData';
import { useFeatureFlag } from './hooks/useFeatureFlag';
import { useLogEvent } from './hooks/useLogEvent';
import { usePremiumFeature } from './hooks/usePremiumFeatures';
import { useProfile } from './hooks/useProfile';
import { useSendEventToGTM } from './hooks/useSendEventToGTM';
import { FallBackLayer } from './main.styled';
import { EmailNotVerified } from './pages/EmailNotVerified/EmailNotVerified';
import { isUerAllowedToAccessProtectedRoutes } from './pages/EmailNotVerified/utils';
import { Error404 } from './pages/Errors/Error404';
import { ForgotPassword } from './pages/ForgotPassword';
import { Home } from './pages/Home/components/Home';
import { OAuth2Redirect } from './pages/OAuth2Redirect';
import { Questionnaire } from './pages/Questionnaire';
import { PageModel } from './pages/Questionnaire/models';
import { ResetPassword } from './pages/ResetPassword';
import { RedirectViaSignedURL } from './pages/SignedURLRedirect';
import { Signin } from './pages/Signin';
import { Signup } from './pages/Signup';
import { SwitchToDesktop } from './pages/SwitchToDesktop';
import { VerifyEmail } from './pages/VerifyEmail/components/VerifyEmail';
import { WLForgotPassword } from './pages/WLForgotPassword';
import { WLSignin } from './pages/WLSignin';
import { currentWorkspaceDetailAtom } from './pages/Workspace/atom';
import { VerifyWorkspaceInvite } from './pages/Workspace/component/verifyWsInvite';
import { ComponentRoleWrapper } from './routes';
import { ALL_ROUTE } from './routes/constant';
import type { SubscriptionPlanType, WorkSpaceDetailsModel } from './types';
import {
  CookieConsentConfig,
  constructEventPayload,
  generateCaptchaToken,
  handleGetConstantData,
  installClarity,
  installGA,
  installGTM,
  setDateAndTZinContext,
  useGetQuestionnaire,
} from './utils/common';
import {
  WORKSPACE_BASIC_THEME,
  envMap,
  workspaceBasicThemeFormat,
} from './utils/constant';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
// todo : will shift to env after signoff from product - @subhanshu
const GA_MID = envMap.VITE_GA_MID;
const CLARITY_ID = envMap.VITE_CLARITY_ID;
const GTM_ID = envMap.VITE_GTM_ID;
const isCookieConsentEnabled = envMap.VITE_COOKIE_CONSENT_ENABLED === 'true';

function App() {
  const prodEnvironment = envMap.VITE_ENVIRONMENT === 'production';
  localStorage.setItem(
    'aB_Params_onlyWorkEmailAllowed',
    prodEnvironment ? 'true' : 'false'
  );

  localStorage.setItem(
    'aB_Params_emailVerifyRequired',
    prodEnvironment ? 'true' : 'false'
  );
  const location = useLocation();
  const currentRoutePath = location.pathname;
  const urlParams = new URLSearchParams(window.location.search);
  const paramsWsid = urlParams.get('wsid');
  const navigate = useNavigate();

  if (!_isNil(paramsWsid) && !_isEmpty(paramsWsid)) {
    window.sessionStorage.setItem('workspaceUUID', paramsWsid);
  }
  const { logEvent } = useLogEvent();
  const [siteConstants, setSiteConstants] = useAtom(siteConstantsAtom);
  const [, setSubscriptionPlan] = useAtom(subscriptionPlanAtom);
  const [userProfile, setUserProfile] = useAtom(userProfileAtom);
  const [allWorkspaceList, setAllWorkspaceAtom] = useAtom(allWorkspaceAtom);
  const [checksumWarningData] = useAtom(checksumWarningAtom);
  const [enablePermissionApi, setEnablePermissionApi] = useAtom(
    enablePermissionApiAtom
  );
  const { openWithProps: openChecksumWarning } = useLayer(
    <ChecksumWarning redirectTo="" />
  );
  const [currWorkspaceDetails, setCurrWorkspace] = useAtom(
    currentWorkspaceDetailAtom
  );

  const [, setIsFetchedUserState] = useAtom(isFetchedUserStateAtom);
  const [enableAllWorkspaceApi, setEnableAllWorkspaceApi] = useAtom(
    enableGetAllWorkspaceApiAtom
  );

  const { sendEventToGTM } = useSendEventToGTM();

  usePremiumFeature({
    onLoadGetData: true,
  });

  const email = !_isEmpty(userProfile) ? userProfile?.email : '';

  const { auth, isLoggedIn } = useAuth();

  const { data, isFetching } = useProfile(
    (_isEmpty(userProfile) || _isNil(userProfile)) &&
      !_isEmpty(auth.accessToken)
  );

  const {
    data: allWorkspaceData,
    isFetching: isWorkspaceDataFetching,
    refetch: fetchWorkspace,
  } = useAllWorkspaceData(enableAllWorkspaceApi);
  const isEmailVerified = isUerAllowedToAccessProtectedRoutes(userProfile);

  useFeatureFlag({ email, enablePermissionApi });

  useEffect(() => {
    /* eslint-disable */
    if (_isNil(window.localStorage.getItem('nec_fid'))) {
      window.localStorage.setItem('nec_fid', createUUID());
    }

    if (envMap.VITE_ENVIRONMENT === 'production') {
      if (!_isNil(CLARITY_ID) && !_isEmpty(CLARITY_ID)) {
        installClarity(CLARITY_ID);
      }
      if (!_isNil(GTM_ID) && !_isEmpty(GTM_ID)) {
        installGTM(GTM_ID);
      }
      sendEventToGTM({
        event: 'site_access',
      });
      if (!_isNil(GA_MID) && !_isEmpty(GA_MID)) {
        installGA(GA_MID);
      }
    }
    if (isCookieConsentEnabled) {
      void CookieConsentConfig();
    }
  }, []);

  useEffect(() => {
    const role = currWorkspaceDetails?.role;
    if (!_isNil(role)) {
      window.sessionStorage.setItem('userRole', role);
    }
  }, [currWorkspaceDetails]);

  const getAccountUsageDetails = async () => {
    const response = await axiosPrivate.get<{
      data: SubscriptionPlanType;
    }>(`/plan/usage`);
    if (!_isNil(response.data.data)) {
      setSubscriptionPlan({
        ...response.data.data,
        planName: response.data.data.plan.name,
      });
    }
  };

  const logAppVisitor = () => {
    const eventName = 'app_visitor';
    const entity = 'views';
    const entityId = '';
    const appVisitorCookie = Cookies.get(eventName);
    const payload = {
      value: 'visited',
    };
    const apiPayload = constructEventPayload(
      eventName,
      'internal',
      entity,
      entityId,
      payload
    );

    if (
      _isNil(appVisitorCookie) &&
      _isEmpty(appVisitorCookie) &&
      appVisitorCookie !== 'visited'
    ) {
      generateCaptchaToken(logEvent, apiPayload);
    }
  };

  const fetchQuestionareData = async () => {
    const data = await useGetQuestionnaire(axiosPrivate);
    if (!_isNil(userProfile)) {
      if (
        localStorage.getItem('aB_Params_emailVerifyRequired') === 'true' &&
        !userProfile?.emailVerified
      ) {
        try {
          if (
            // if user has filled all the required fields and is not in questionnaire
            validateRequiredFields(data.data) &&
            !location.pathname.includes('questionnaire')
          ) {
            navigate('/email-not-verified');
          } else {
            navigate('/questionnaire'); // just to make sure we collect all the required info even if required email is true
          }
        } catch (error) {
          // fallback
          navigate('/home');
        }
      }
    }
  };

  // this will check if user has filled all the required fields in first step
  // returns a boolean | Generic function
  const validateRequiredFields = (formData: PageModel[]): boolean => {
    if (!formData?.[0]?.questions?.length) return false;
    const { questions } = formData[0];
    const validateByType: Record<string, any> = {
      text: (value: string): boolean => {
        if (typeof value !== 'string') return false;
        return value.trim().length > 0;
      },
      phone: (value: string): boolean => {
        if (typeof value !== 'string') return false;
        return value.trim().length > 0;
      },
      radio: (value: string): boolean => {
        if (typeof value !== 'string') return false;
        return value !== '';
      },
      'select-single': (value: string): boolean => {
        if (typeof value !== 'string') return false;
        return value !== '';
      },
      multi: (value: string): boolean => {
        if (!Array.isArray(value)) return false;
        return value.length > 0;
      },
    };
    return questions.every((question) => {
      if (!question.required) return true;
      const validateValue = validateByType[question.questionType];
      if (!validateValue) return false;
      return validateValue(question.value);
    });
  };
  useEffect(() => {
    const envEmailConfig: string[] = envMap.VITE_ADMIN_EMAIL_CONFIG.split(',');

    if (!_isEmpty(email)) {
      void getAccountUsageDetails();
      void fetchQuestionareData();
      if (
        envEmailConfig.some((currEmail) => currEmail === email.toLowerCase())
      ) {
        setEnablePermissionApi(false);
      } else {
        setEnablePermissionApi(true);
      }

      void (async () => {
        const result = await getUserState();
        setIsFetchedUserState(result);
      })();

      sendEventToGTM({
        event: 'config',
        trackingId: GA_MID,
        user_id: window.localStorage.getItem('userUUID'),
        email,
        createdAt: userProfile?.createdAt ?? '',
      });

      sendEventToGTM({
        event: 'userdata',
        userid: window.localStorage.getItem('userUUID'),
        user_id: window.localStorage.getItem('userUUID'),
        email,
        createdAt: userProfile?.createdAt ?? '',
      });

      setTimeout(() => {
        if (!_isNil(window.clarity) && !_isEmpty(window.clarity)) {
          window.clarity('set', 'userId', email);
        }
      }, 2000);

      logAppVisitor();
    }
  }, [email]);

  useEffect(() => {
    if (_isNil(allWorkspaceList) && isLoggedIn) {
      setEnableAllWorkspaceApi(true);
      void fetchWorkspace();
    } else {
      setEnableAllWorkspaceApi(false);
    }
  }, [allWorkspaceList, isLoggedIn]);

  useEffect(() => {
    if (!isFetching && !_isNil(data)) {
      setUserProfile(data);
    }
  }, [isFetching, data]);

  const id =
    window.sessionStorage.getItem('workspaceUUID') ??
    window.localStorage.getItem('workspaceUUID');

  useEffect(() => {
    if (
      !isWorkspaceDataFetching &&
      !_isNil(allWorkspaceData) &&
      !_isEmpty(allWorkspaceData)
    ) {
      const allWorkspaceList = _reduce(
        allWorkspaceData,
        (accum: WorkSpaceDetailsModel[], currWorkspace) => {
          return [...accum, currWorkspace];
        },
        []
      );

      setAllWorkspaceAtom(allWorkspaceList);

      const currWorkspace = allWorkspaceList.find((workspace) =>
        !_isEmpty(id) ? workspace.uuid === id : workspace.isDefault
      );

      const defaultWorkspace = allWorkspaceList.find(
        (workspace) => workspace.isDefault
      );

      const isInIframe = window !== window.parent;

      if (
        isInIframe ||
        (!_isNil(currWorkspace) && currWorkspace?.type === 'EUS')
      ) {
        setLocalStorageItem('x-nec-signed-url', 'true');
      } else if (!_isNil(currWorkspace) && currWorkspace?.type !== 'EUS') {
        setLocalStorageItem('x-nec-signed-url', 'false');
      }

      setCurrWorkspace(currWorkspace ?? null);

      if (!_isNil(currWorkspace?.theme)) {
        setLocalStorageItem(
          'local-theme',
          JSON.stringify(currWorkspace?.theme)
        );
      } else {
        setLocalStorageItem(
          'local-theme',
          JSON.stringify(workspaceBasicThemeFormat(WORKSPACE_BASIC_THEME))
        );
      }

      if (!_isNil(paramsWsid) && !_isEmpty(paramsWsid)) {
        window.sessionStorage.setItem('workspaceUUID', paramsWsid);
      } else {
        window.sessionStorage.setItem(
          'workspaceUUID',
          currWorkspace?.uuid ?? ''
        );
      }

      const payload = {
        timezone : currWorkspace?.timezone,
        dateFormat : currWorkspace?.dateFormat
      }

      setDateAndTZinContext(payload);

      if (
        _isNil(defaultWorkspace) &&
        _isNil(currWorkspace) &&
        allWorkspaceList.length > 0
      ) {
        toasts.error(
          'You have been removed from the current workspace by owner, switching back to available workspace',
          'workspace-change-msg'
        );
        window.localStorage.setItem('workspaceUUID', allWorkspaceList[0].uuid);
        setCurrWorkspace(allWorkspaceList[0]);
      } else {
        window.localStorage.setItem(
          'workspaceUUID',
          defaultWorkspace?.uuid ??
            currWorkspace?.uuid ??
            '00000000-0000-0000-0000-000000000000'
        );
      }

      window.localStorage.setItem(
        'userType',
        defaultWorkspace?.type ?? currWorkspace?.type ?? ''
      );
    }

    if (!isLoggedIn) {
      setLocalStorageItem(
        'local-theme',
        JSON.stringify(workspaceBasicThemeFormat(WORKSPACE_BASIC_THEME))
      );
    }
  }, [isWorkspaceDataFetching, JSON.stringify(allWorkspaceData)]);

  useEffect(() => {
    if (!_isNil(location.pathname)) {
      handleGetConstantData(
        location.pathname.split('/')[1],
        setSiteConstants,
        siteConstants
      );
      if (!_isEmpty(email) && !_isNil(email)) {
        void fetchQuestionareData();
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (checksumWarningData.showPopup) {
      openChecksumWarning({
        redirectTo: location.pathname.split('/')[1],
      });
    }
  }, [checksumWarningData]);

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      {_isNil(currWorkspaceDetails) &&
        id !== '00000000-0000-0000-0000-000000000000' &&
        ![
          'signin',
          'signup',
          'onboarding',
          'wl-signup',
          'wl-signin',
          'forget-password',
          'forgot-password',
          'wl-forget-password',
          'wl-forgot-password',
          'reset-password',
          'questionnaire',
        ].includes(location.pathname.split('/')[1]) && (
          <FallBackLayer>
            <Spinner size="small" />
          </FallBackLayer>
        )}
      <SentryRoutes>
        <Route element={<Authentication />}>
          <Route path="/verify/signup/email" element={<VerifyEmail />} />
        </Route>
        <Route
          path="/verify/workspace/invitation/:inviteToken"
          element={<VerifyWorkspaceInvite />}
        />
        <Route
          path="/redirect/signed-url/:token"
          element={<RedirectViaSignedURL />}
        />
        <Route element={<AuthenticatedAlready />}>
          <Route element={<Authentication />}>
            <Route path="/signin" element={<Signin />} />
            <Route path="/signup" element={<Signup />} />
          </Route>

          <Route element={<WLAuthentication />}>
            <Route path="/wl-signin" element={<WLSignin />} />
          </Route>
          <Route element={<CoveredLogo />}>
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="*" element={<Error404 />} />
          </Route>

          <Route path="/wl-forgot-password" element={<WLForgotPassword />} />
        </Route>

        <Route element={<RememberMe />}>
          <Route element={<AuthenticationRequired />}>
            <Route element={<Authentication />}>
              <Route path="/switch-to-desktop" element={<SwitchToDesktop />} />
            </Route>

            <Route element={<CoveredLogo />}>
              <Route path="/questionnaire" element={<Questionnaire />} />
            </Route>

            <Route path="/:type/redirect" element={<OAuth2Redirect />} />

            <Route element={<Dashboard />}>
              <Route
                index
                element={ComponentRoleWrapper(
                  Home,
                  'home',
                  isEmailVerified,
                  currentRoutePath
                )}
              />

              <Route
                path="/email-not-verified"
                element={<EmailNotVerified />}
              />

              {ALL_ROUTE.map((route) => (
                <Route
                  key={route.id}
                  path={route.path}
                  element={ComponentRoleWrapper(
                    route.element,
                    route.id,
                    isEmailVerified,
                    currentRoutePath
                  )}
                />
              ))}
            </Route>
          </Route>
        </Route>
      </SentryRoutes>
    </>
  );
}

export default Sentry.withProfiler(App);
