import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';
import { Typography } from 'ui';

export const PillStyled = styled(PadBox)<{ $hasError: boolean }>`
  border-radius: 2.5rem;
  background-color: var(--color-lightGray);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  outline: 1px solid
    ${({ $hasError }) =>
      $hasError ? 'var(--color-fireEngineRed)' : 'var(--color-lavender)'};
`;

export const FieldPillStyled = styled(PadBox)<{ $hasError: boolean }>`
  border-radius: 0.4rem;
  background-color: var(--color-gainsboro);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  outline: 1px solid
    ${({ $hasError }) =>
      $hasError ? 'var(--color-fireEngineRed)' : 'var(--color-lavender)'};
`;

export const AlignedText = styled(Typography)`
  text-align: center;
`;
