import { useEffect, useState } from 'react';
import { customParseDate, DateTimePickerInput } from 'ui';
import { removeOffsetFromDateString } from 'ui/src/Form/DateTimePickerInput/helpers';


type CalenderIconPickerProps = {
  onPick?: (val: any) => void;
  value: any;
  dataType: string;
  disabled?: boolean;
};

export function CalenderIconPicker({
  value,
  onPick,
  dataType,
  disabled = false,
}: CalenderIconPickerProps) {
  const [date, setDate] = useState(new Date(Date.now()));

  useEffect(() => {
    try {
      if (value !== '') {
        const formattedDate = customParseDate(removeOffsetFromDateString(value));

        setDate(formattedDate);
      }
    } catch {}
  }, [value]);

  return (
    <DateTimePickerInput
      appendText=""
      value={date}
      showIconInput
      showTimeInput={dataType === 'dateTime'}
      disabled={disabled}
      onChange={(v) => {
        if (typeof onPick === 'function') {
          onPick(v);
        }
      }}
      showIcon={false}
    />
  );
}
