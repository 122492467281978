import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useRef } from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';
import {
  Dataset,
  IconButton,
  Menu,
  MenuItem,
  PopoverMethods,
  TooltipReact,
  Typography,
  useLayer,
} from 'ui';

import { siteConstantsAtom } from '../../../atom';
import { currentWorkspaceDetailAtom } from '../../../pages/Workspace/atom';
import { getTooltipText } from '../../../utils/common';
import { AddIcon } from '../../icons/AddIcon';
import { PredefineTokenModal } from '../PredefineTokenModal/PredefineTokenModal';
import { onClickTokenSelectionArgs } from '../PredefineTokenPopover/PredefineTokenPopover';

type TokenMenuProps = {
  entity: string;
  disabled?: boolean;
  launcher?: JSX.Element;
  onLauncherClick?: () => void;
  tokenDataset: Record<string, Dataset>;
  handleAddCustomToken: (value: any) => void;
  handlePredefineTokenSelection: (data: onClickTokenSelectionArgs) => {
    canCloseModal: boolean;
  };
  showMultiSelect?: boolean;
  handlePredefineMultiTokenSelection?: (data: onClickTokenSelectionArgs[]) => {
    canCloseModal: boolean;
  };
  notAllowedCustomMenuItems?: string[];
};

export const TokenMenu = ({
  entity = 'rules',
  disabled = false,
  launcher,
  onLauncherClick,
  handleAddCustomToken,
  tokenDataset,
  handlePredefineTokenSelection,
  showMultiSelect = false,
  handlePredefineMultiTokenSelection,
  notAllowedCustomMenuItems = [],
}: TokenMenuProps) => {
  const menuRef = useRef<PopoverMethods>(null);
  const customTokenMenuref = useRef<PopoverMethods>(null);

  const [currentWorkspace] = useAtom(currentWorkspaceDetailAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);

  const { openWithProps: openTokenModal } = useLayer(
    <PredefineTokenModal tokenDataset={tokenDataset} />
  );

  const tokenLauncher = (
    <IconButton disabled={disabled} onClick={onLauncherClick}>
      <AddIcon color="var(--color-saddleBrown)" />
    </IconButton>
  );

  const isEnforceModuleSchema = currentWorkspace?.enforceModuleSchema ?? false;

  const customTokenLauncher = (
    <Inline align="center" gutter={8}>
      {!isEnforceModuleSchema ? (
        <>
          <Typography>Custom Attributes</Typography>
          <RiArrowRightSLine />
        </>
      ) : null}
    </Inline>
  );

  let menuItems = [
    { value: 'boolean', label: 'Boolean' },
    { value: 'numeric', label: 'Numeric' },
    { value: 'string', label: 'String' },
    { value: 'date', label: 'Date' },
    { value: 'dateTime', label: 'DateTime' },
    { value: 'list', label: 'List' },
    {
      value: 'json',
      label: 'JSON',
      tooltip: getTooltipText(siteConstants, entity, 'customJsonRule'),
    },
    {
      value: 'jsFormula',
      label: 'JS Code',
      tooltip: getTooltipText(siteConstants, entity, 'customJSRule'),
    },
    {
      value: 'excelFormula',
      label: 'Formula',
      tooltip: getTooltipText(siteConstants, entity, 'customExcelRule'),
    },
  ];

  menuItems = menuItems.filter(
    (item) => !notAllowedCustomMenuItems.includes(item.value)
  );

  const handleGroupSelection = (value: string) => {
    if (value === 'predefinedTokens') {
      openTokenModal({
        tokenDataset,
        onTokenSelection: handlePredefineTokenSelection,
        showMultiSelect,
        onMultiTokenSelection: handlePredefineMultiTokenSelection,
        typesToAllow: menuItems.map((obj) => obj.value),
      });
    }
  };

  const renderTooltip = (tooltip: string | undefined) => {
    if (_isNil(tooltip)) {
      return null;
    }

    return (
      <TooltipReact id={`dataType-${tooltip}`}>
        <Typography>{tooltip}</Typography>
      </TooltipReact>
    );
  };

  const menuChildren = [];

  if (!isEnforceModuleSchema) {
    menuChildren.push(
      <MenuItem value={'customTokens'} key="customToken">
        <Menu
          ref={customTokenMenuref}
          launcher={customTokenLauncher}
          onMenuItemClick={(val) => {
            handleAddCustomToken(val);
            menuRef?.current?.hide();
          }}
          placement="right-end"
        >
          {menuItems.map((menuItem) => (
            <MenuItem value={menuItem.value} key={menuItem.value}>
              <Inline align="center" gutter={8}>
                <Typography>{menuItem.label}</Typography>
                {renderTooltip(menuItem.tooltip)}
              </Inline>
            </MenuItem>
          ))}
        </Menu>
      </MenuItem>
    );
  }

  menuChildren.push(
    <MenuItem value={'predefinedTokens'} key="predefinedTokens">
      <Inline align="center" gutter={8}>
        <Typography>From Attribute Library</Typography> <RiArrowRightSLine />
      </Inline>
    </MenuItem>
  );

  return (
    <Menu
      ref={menuRef}
      launcher={launcher ?? tokenLauncher}
      onMenuItemClick={handleGroupSelection}
    >
      {menuChildren}
    </Menu>
  );
};
