import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import type { UseFormWatch } from 'react-hook-form';
import { CheckboxInput, Typography, toasts } from 'ui';

import {
  convertCaSampleValues,
  getPropertyIfExists,
  getReplacedItem,
} from '../../../../../../utils/common';
import { StatusCode } from '../../../../../../utils/response/statusCode';
import { RuleExecutionTest } from '../../../../../Rules/components/RuleExecutionTest/RuleExecutionTest';
import { useUseGetRuleTestActionResults } from '../../../../../Rules/hooks/restApi/useGetRuleTestActionResult';
import { usePolling } from '../../../../../Rules/hooks/usePolling';
import { useServerResponse } from '../../../../../Rules/hooks/useServerResponse';
import {
  ActionInfoObject,
  RuleTestActionObject,
  RuleTestActionPollingPayload,
} from '../../../../../Rules/types';
import {
  getActionInfoForRuleTestActions,
  getAllInProgressActions,
  getAllRuleTestActions,
  getFormattedJsonForRuleTestActions,
} from '../../../../../Rules/utils/common';
import { workflowNodesAtom } from '../../../../atoms/atoms';
import { WorkflowNodeType } from '../../../../hooks/useOpenWorkflow';
import type { WorkflowAttribute } from '../../../../models/models';
import { getNodesByType } from '../../../../utils/common';
import { TestMappedValues } from './TestMappedValues/TestMappedValues';
import { TestValue } from './TestMappedValues/TestValue/TestValue';
import { OutputContainer } from './WorkflowTest.styled';

type WorkflowTestProps = {
  mappedValues: WorkflowAttribute[];
  outputValue?: Record<string, any>;
  watch: UseFormWatch<any>;
  error?: Record<string, any>;
  parentNodes: WorkflowNodeType[];
  ruleData?: Record<string, any>;
  isTesting: boolean;
  dataSet?: Record<string, any>;
};

export function WorkflowTest({
  mappedValues,
  outputValue = { value: 'Please test the same' },
  watch,
  parentNodes,
  error,
  ruleData,
  isTesting,
  dataSet = {},
}: WorkflowTestProps) {
  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [actionResponse, setActionResponse] = useState<RuleTestActionObject[]>(
    []
  );
  const [actionInfo, setActionInfo] = useState<ActionInfoObject>({
    status: StatusCode.RUNNING,
    count: 0,
  });

  const [isNoCode, setIsNoCode] = useState(true);
  const [params, setParams] = useState<Record<string, any>>({});
  const [pollingPayload, setPollingPayload] = useState<
    RuleTestActionPollingPayload[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    getActionResults,
    actionData,
    error: actionError,
  } = useUseGetRuleTestActionResults();
  const { serverResponse, setServerResponse } = useServerResponse();

  const handlePollingRequest = async (
    pollingPayload: RuleTestActionPollingPayload[]
  ) => {
    try {
      if (pollingPayload.length > 0) {
        await getActionResults({
          params: pollingPayload,
        });
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        toasts.error(err.message, 'error');
      }
    }
  };

  useEffect(() => {
    const actionInfoData = !_isNil(actionData) ? actionData.data : {};

    if (!_isNil(actionInfoData.data)) {
      const data = actionInfoData.data;
      const currActionResponse = structuredClone(actionResponse);

      currActionResponse.forEach((action: RuleTestActionObject) => {
        const { executionId } = action.actionData;

        if (_has(data, executionId)) {
          const { executionTime, output, status } = data[executionId];
          const hasError = !_isNil(output?.error) && !_isEmpty(output?.error);

          action.actionData = {
            isCollapsed: true,
            executionTime,
            status,
            executionId,
            hasError,
            json: getFormattedJsonForRuleTestActions(
              hasError ? output?.error : output?.Result
            ),
          };
        }
      });

      let currPollingPayload = structuredClone(pollingPayload);
      currPollingPayload = currPollingPayload.filter(
        (actionPayload: RuleTestActionPollingPayload) =>
          _has(data, actionPayload.executionId) &&
          data[actionPayload.executionId].status === StatusCode.RUNNING
      );

      setActionResponse(currActionResponse);
      setPollingPayload(currPollingPayload);
    }
  }, [JSON.stringify(actionData)]);

  const { setIsPolling } = usePolling({
    functionToExecute: handlePollingRequest,
    args: pollingPayload,
    timeInterval: 2000,
  });

  const attributes = watch('attributes');
  const runInLoop = watch('runInLoop');

  let mappedData = getPropertyIfExists(
    JSON.parse(
      JSON.stringify(
        parentNodes.find((p) => p.data.name === runInLoop?.source)?.data
          ?.executedValue ?? {}
      )
    ),
    runInLoop?.attribute ?? ''
  );

  if (runInLoop?.source === 'globalVar') {
    mappedData = getPropertyIfExists(
      JSON.parse(
        JSON.stringify(
          Object.keys(dataSet.globalVar.attributes).reduce((acc, curr) => {
            return {
              ...acc,
              [curr]: dataSet.globalVar.attributes[`${curr}`].executedValue,
            };
          }, {})
        )
      ) ?? {},
      runInLoop?.attribute ?? ''
    );
  }

  const handleTestRule = async () => {
    const params: Record<string, any> = {};

    try {
      const triggerNodes = getNodesByType(workflowNodes, 'trigger');

      attributes.forEach((attr: any) => {
        if (attr.sendNull === true || attr.isNullable === true) {
          params[attr.keyName] = null;
        } else if (attr.notSend === true || attr.isOptional === true) {
          // eslint-disable-next-line
          return;
        } else if (
          !_isNil(attr.attribute) &&
          !_isEmpty(attr.attribute) &&
          !_isNil(attr.source) &&
          !_isEmpty(attr.source)
        ) {
          if (attr.source === 'loop') {
            params[attr.keyName] = `Each item in ${getReplacedItem(
              `${attr.source as string}.${attr.attribute as string}`,
              `loop.currentItem`,
              `${runInLoop.source as string}.${runInLoop.attribute as string}`
            )}`;

            return;
          }

          if (
            attr.source === 'globalVar' ||
            attr.source === 'systemVar' ||
            attr.source === 'LoopBlock'
          ) {
            const mappedData = getPropertyIfExists(
              JSON.parse(
                JSON.stringify(
                  Object.keys(dataSet[attr.source].attributes).reduce(
                    (acc, curr) => {
                      return {
                        ...acc,
                        [curr]:
                          dataSet[attr.source].attributes[`${curr}`]
                            .executedValue,
                      };
                    },
                    {}
                  )
                )
              ) ?? {},
              attr.attribute
            );

            params[attr.keyName] =
              attr.source === 'systemVar' && attr.attribute === 'NULL'
                ? null
                : mappedData;

            return;
          }

          const triggerNode = triggerNodes.find(
            (trigger) => trigger.data?.name === attr.source
          );

          const parentData = getPropertyIfExists(
            JSON.parse(
              JSON.stringify(
                parentNodes.find((p) => p.data.name === attr.source)?.data
                  ?.executedValue ?? {}
              )
            ),
            attr.attribute
          );

          const parentDataTr = getPropertyIfExists(
            JSON.parse(
              JSON.stringify(
                triggerNodes.find(
                  (trigger) => trigger.data?.name === attr.source
                )?.data?.executedValue ?? {}
              )
            ) ?? {},
            attr.attribute
          );

          if (!_isNil(parentData)) {
            params[attr.keyName] = convertCaSampleValues(
              attr.selectedType?.dataType ?? 'string',
              parentData
            );
          } else if (!_isNil(parentDataTr)) {
            params[attr.keyName] = convertCaSampleValues(
              attr.selectedType?.dataType ?? 'string',
              parentDataTr
            );
          } else if (
            !_isNil(triggerNode) &&
            !_isNil(triggerNode?.data?.executedValue[attr.attribute])
          ) {
            params[attr.keyName] = convertCaSampleValues(
              attr.selectedType?.dataType ?? 'string',
              triggerNode?.data?.executedValue[attr.attribute]
            );
          }
        } else {
          params[attr.keyName] = convertCaSampleValues(
            attr.dataType ?? 'string',
            attr.value
          );
        }
      });
    } catch (error) {}

    setParams(params);

    return params;
  };

  const handleExpandIconClick = (actionName: string) => {
    const updatedActionResponse = structuredClone(actionResponse);
    updatedActionResponse.forEach((action) => {
      action.actionData.isCollapsed =
        actionName !== action.actionName
          ? true
          : !action.actionData.isCollapsed;
    });

    setActionResponse(updatedActionResponse);
  };

  useEffect(() => {
    void handleTestRule();
  }, []);

  useEffect(() => {
    const currActionInfo: ActionInfoObject =
      getActionInfoForRuleTestActions(actionResponse);
    setActionInfo(currActionInfo);

    const isPollingRequired = actionResponse.some(
      (action: RuleTestActionObject) =>
        action.actionData.status === StatusCode.RUNNING
    );
    setIsPolling(isPollingRequired);
  }, [JSON.stringify(actionResponse)]);

  useEffect(() => {
    if (!_isEmpty(actionError) && !_isEmpty(actionError.response)) {
      const { status, data } = actionError.response;
      const message = !_isEmpty(data) ? (data as any).message : '';

      if (
        !_isNil(status) &&
        (status === 400 || status === 401 || status >= 500)
      ) {
        setIsPolling(false);

        const updatedActionResponse = actionResponse.map(
          (action: RuleTestActionObject) => {
            if (action.actionData.status === StatusCode.RUNNING) {
              action.actionData.status = StatusCode.FAILED;
              action.actionData.json = message;
              action.actionData.hasError = true;
            }

            return action;
          }
        );

        setActionResponse(updatedActionResponse);
      }
    }
  }, [actionError]);

  useEffect(() => {
    const json = !_isNil(ruleData) ? ruleData.data : {};
    const errorJson = error?.response?.data;

    if (!_isNil(json.data)) {
      setServerResponse({
        json: JSON.stringify(outputValue, null, 2),
        executionTime: json.data?.executionTime,
      });

      const actionList = getAllRuleTestActions(outputValue?.action);

      setActionResponse(actionList);
      setPollingPayload(getAllInProgressActions(actionList));
    } else if (!_isNil(errorJson)) {
      setServerResponse({
        json: JSON.stringify(errorJson, null, 2),
        executionTime: '',
      });
    }
  }, [ruleData, error]);

  useEffect(() => {
    setIsLoading(true);
    const timeout = setTimeout(() => {
      if (!isTesting) {
        setIsLoading(false);
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [isTesting]);

  return (
    <Stack gutter="2rem">
      <Inline gutter="1rem" align="center" stretch="start">
        <Typography name="heading2" fontWeight={700}>
          Input
        </Typography>
        <Inline gutter="1rem" align="center">
          <CheckboxInput
            appearance="switch"
            onChange={() => setIsNoCode((code) => !code)}
            checked={isNoCode}
          />
          <Typography fontWeight={700}>Low code mode</Typography>
        </Inline>
      </Inline>
      <OutputContainer padding="1rem">
        <Stack gutter={isNoCode ? '0rem' : '1rem'}>
          {!_isNil(mappedData) && (
            <Inline gutter={4}>
              <Typography fontWeight={700} name="heading3">
                Loop List:
              </Typography>
              <TestValue value={JSON.stringify(mappedData)} />
            </Inline>
          )}
          {mappedValues
            .filter((val) => val.dataType !== 'restAPI')
            .map((val, index) => {
              return (
                <TestMappedValues
                  index={index}
                  isNoCode={isNoCode}
                  value={val}
                  key={`mappedList_${index}`}
                  params={params}
                />
              );
            })}
        </Stack>
      </OutputContainer>

      <OutputContainer padding="1rem">
        <Typography name="heading2" fontWeight={700}>
          Output
        </Typography>

        <br />
        {!isTesting && !isLoading && (
          <RuleExecutionTest
            serverResponse={serverResponse}
            isTesting={isTesting}
            actionResponse={actionResponse}
            actionInfo={actionInfo}
            handleExpandIconClick={handleExpandIconClick}
            overrideOutput={outputValue}
            disableAction
          />
        )}
      </OutputContainer>
    </Stack>
  );
}
