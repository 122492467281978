import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useMemo, useState } from 'react';
import { useController, useWatch } from 'react-hook-form';
import type { Control, UseFormSetValue } from 'react-hook-form';
import { MdEdit } from 'react-icons/md';
import { RiErrorWarningLine } from 'react-icons/ri';
import {
  CheckboxField,
  Dataset,
  Typography,
  formatNectedDate,
  useLayer,
} from 'ui';

import { predefineTokenDatasetAtom } from '../../../../../../atom';
import { CalenderIconPicker } from '../../../../../../components/CalenderIconPicker/CalenderIconPicker';
import { ExcelModal } from '../../../../../../components/ExcelLikeFormula/ExcelModal/ExcelModal';
import { ExcelPill } from '../../../../../../components/ExcelLikeFormula/ExcelPill/ExcelPill';
import { FieldByDataType } from '../../../../../../components/FieldByDataType/FieldByDataType';
import { getUsedSchemaAttributes } from '../../../../../../components/TokenComponents/utils/helperFunction';
import { isArrayNotPresent } from '../../../../../../utils/common';
import { ResultHeader } from '../../../../../Rules/components/DecisionTable/components/ResultHeader';
import { ResultRhs } from '../../../../../Rules/components/RestltRhs/ResultRhs';
import { ErrorPopoverPositioned } from '../../../../../Rules/components/SimpleRule/Error/ErrorPopoverPositioned';
import { JsNodeSheet } from '../../../../../Rules/components/SimpleRule/Results/JsNodeSheet';
import type { AttributeModel } from '../../../../../Rules/models';
import { getDatasetWithoutReactCode } from '../../../../../Rules/utils/common';
import { isWorkflowReadOnlyAtom } from '../../../../atoms/atoms';
import {
  InputContainer,
  JsonPillStyled,
  UntestedFooter,
} from './Selector.styled';

type SelectorProps = {
  control?: Control<any>;
  setValue: UseFormSetValue<any>;
  getValues?: UseFormSetValue<any>;
  keyName?: string;
  nodeName: string;
  dataType: string;
  dataSet: Record<string, Dataset>;
  index: number;
  customInputs?: Record<string, AttributeModel>;
  showKeyName?: boolean;
  showPillOnly?: boolean;
  previousCustomInputs?: Record<string, any>;
  idsToExpand?: string[];
  idsToNotExpand?: string[];
  useTokenSelectionPopover?: boolean;
  schemaId?: string;
  placeholder?: string;
  componentType?: string;
  popoverTabsToShow?: string[];
  otherKeys?: any;
};

export function Selector({
  componentType="default",
  setValue,
  getValues,
  control,
  keyName = '',
  nodeName,
  dataType,
  dataSet,
  index = 0,
  customInputs = {},
  showKeyName = true,
  showPillOnly = false,
  previousCustomInputs = {},
  idsToExpand = [],
  idsToNotExpand = [],
  useTokenSelectionPopover = false,
  schemaId,
  placeholder = "",
  popoverTabsToShow = ['token', 'predefined', 'customFunction'],
  otherKeys,
}: SelectorProps) {
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [predefineTokenDataset] = useAtom(predefineTokenDatasetAtom);
  const [isSchemaMandatory, setIsSchemaMandatory] = useState(false);

  const { openWithProps: openJsNode } = useLayer(
    <JsNodeSheet
      index={index}
      name={`${nodeName}.value`}
      control={control}
      section="thenDataParams"
      setOriginalValue={setValue}
    />
  );

  const { openWithProps: openExcelModal } = useLayer(
    <ExcelModal name={`${nodeName}.value`} />
  );

  const isDataOptional: boolean = useWatch({
    control,
    name: `${nodeName}.isOptional`,
  });

  const isDataNull: boolean = useWatch({
    control,
    name: `${nodeName}.isNullable`,
  });

  const { fieldState } = useController({
    name: `${nodeName}.value`,
    control,
  });

  const value = useWatch({
    name: `${nodeName}.value`,
    control,
  });

  const error = fieldState.error?.message;

  const isDataNullInSavedData: boolean =
    !_isNil(previousCustomInputs) && !_isNil(previousCustomInputs[keyName])
      ? previousCustomInputs[keyName].sendNull
      : false;

  const updatedDataSet = useMemo(() => {
    return Object.keys(dataSet).reduce((acc, curr) => {
      if (Object.keys(dataSet[curr].attributes).length === 0) {
        return {
          ...acc,
          [curr]: {
            ...dataSet[curr],
            footer: (
              <UntestedFooter gutter="0.6rem" align="center">
                <RiErrorWarningLine />
                <Typography name="paragraphSmall">Node not tested</Typography>
              </UntestedFooter>
            ),
          },
        };
      }

      return {
        ...acc,
        [curr]: dataSet[curr],
      };
    }, {});
  }, [dataSet]);

  useEffect(() => {
    const usedSchemaObj = getUsedSchemaAttributes(
      predefineTokenDataset,
      schemaId
    );

    if (!_isNil(usedSchemaObj) && !_isNil(usedSchemaObj?.usageType)) {
      setIsSchemaMandatory(usedSchemaObj.usageType === 'mandatory');
    }
  }, [JSON.stringify(predefineTokenDataset), schemaId]);

  const rightIcon = (hide: any) => {
    if (['dateTime', 'date'].includes(dataType)) {
      return (
        <CalenderIconPicker
          value={value}
          dataType={dataType}
          disabled={
            isDataNull ||
            isDataOptional ||
            isWorkflowReadOnly ||
            isSchemaMandatory
          }
          onPick={(val) => {
            if (typeof setValue === 'function') {
              setValue(`${nodeName}.value`, formatNectedDate(val, dataType));
              setValue(`${nodeName}.attribute`, null);
              setValue(`${nodeName}.source`, null);
              setValue(`${nodeName}.sourceType`, undefined);
            }
          }}
        />
      );
    }

    if (dataType === 'jsFormula') {
      return (
        <JsonPillStyled
          align="center"
          gutter={8}
          onClick={() =>
            openJsNode({
              dataSet: updatedDataSet,
              disabled: isWorkflowReadOnly,
              onClose: () => {
                setValue(`${nodeName}.attribute`, null);
                setValue(`${nodeName}.source`, null);
              },
              executedValueName: `${nodeName}.executedValue`,
              returnTypeName: `${nodeName}.returnType`,
            })
          }
        >
          <Typography>JS Code</Typography>
          <MdEdit />
          {!_isNil(error) && showPillOnly && (
            <ErrorPopoverPositioned error={error} />
          )}
        </JsonPillStyled>
      );
    }

    if (dataType === 'excelFormula') {
      return (
        <ExcelPill
          control={control}
          setValue={setValue}
          dataSet={getDatasetWithoutReactCode(updatedDataSet)}
          name={`${nodeName}.value`}
          isReadOnly={isWorkflowReadOnly}
          executedValueName={`${nodeName}.executedValue`}
          returnTypeName={`${nodeName}.returnType`}
          usingFrom="fieldComponent"
          showError={false}
          hidePopover={hide}
        />
      );
    }

    if (['json', 'list'].includes(dataType)) {
      return (
        <FieldByDataType
          name={`${nodeName}.value`}
          returnTypeName={`${nodeName}.returnType`}
          executedValueName={`${nodeName}.executedValue`}
          dataType={dataType}
          control={control}
          showError
          index={index}
          // suggestions={suggestions}
          disabled={isWorkflowReadOnly}
          // section={type}
          // hideOptionalCustomAttributes={hideOptionalCustomAttributes}
          setValue={setValue}
          // suggestionObjs={suggestionsObj}
          dataSet={getDatasetWithoutReactCode(updatedDataSet)}
          hidePopover={hide}
        />
      );
    }

    return null;
  };

  const handleOpenEditorSheet = (dataType: string) => {
    if (dataType === 'jsFormula') {
      openJsNode({
        dataSet: updatedDataSet,
        disabled: isWorkflowReadOnly,
        onClose: () => {
          setValue(`${nodeName}.attribute`, null);
          setValue(`${nodeName}.source`, null);
        },
        executedValueName: `${nodeName}.executedValue`,
        returnTypeName: `${nodeName}.returnType`,
      });
    } else if (dataType === 'excelFormula') {
      openExcelModal({
        name: `${nodeName}.value`,
        control,
        dataSet,
        setOriginalValue: setValue,
        executedValueName: `${nodeName}.executedValue`,
        returnTypeName: `${nodeName}.returnType`,
        isReadOnly: isWorkflowReadOnly,
      });
    }
  };

  const getComponentByType = (type: string) => {
    switch (type) {
      case 'restAPI':
        return <Typography>Values will be mapped via API</Typography>;

      case 'unknown':
        return (
          <Typography name="error">
            The ruleSet has rules with conflicting datatypes for this field
            please fix that first.
          </Typography>
        );
      default:
        return (
          <ResultRhs
            otherKeys={otherKeys}
            sourceKey={componentType === "resetVariableKey" ? 'keySource' : 'source'}
            attributeKey={componentType === "resetVariableKey" ? 'keyAttribute' : 'attribute'}
            componentType={componentType}
            placeholder={placeholder}
            control={control}
            setValue={setValue}
            getValues={getValues}
            updatedDataSet={getDatasetWithoutReactCode(updatedDataSet)}
            size="40.8rem"
            keyName={keyName}
            nodeName={nodeName}
            dataType={dataType}
            isFull
            typesToAllow={[dataType, 'generic']}
            allowList
            onlyJson={dataType === 'json'}
            type="workflowDT"
            disabled={isDataNull || isDataOptional || isWorkflowReadOnly}
            useTokenSelectionPopover={useTokenSelectionPopover}
            schemaId={schemaId}
            isSchemaMandatory={isSchemaMandatory}
            showTooltip={true}
            popoverTabsToShow={popoverTabsToShow}
            readOnly={[
              'list',
              'json',
              'date',
              'dateTime',
              'jsFormula',
              'excelFormula',
            ].includes(dataType)}
            idsToExpand={idsToExpand}
            idsToNotExpand={idsToNotExpand}
            rightIcon={rightIcon}
            handleOpenEditorSheet={handleOpenEditorSheet}
            options={{
              updateDataType: false,
            }}
            header={
              ['list', 'json'].includes(dataType) ? (
                <ResultHeader
                  dataSet={dataSet}
                  // eslint-disable-next-line
                  isAdd={isArrayNotPresent(value)}
                  nodeName={`${nodeName}`}
                  resIndex={index}
                  control={control}
                  setOriginalValue={setValue}
                  disabled={isWorkflowReadOnly}
                  returnTypeName={`${nodeName}.returnType`}
                  executedValueName={`${nodeName}.executedValue`}
                  editorType={dataType as unknown as 'list' | 'json'}
                />
              ) : undefined
            }
          />
        );
    }
  };

  return (
    <InputContainer>
      {showKeyName && (
        <Typography fontWeight={700}>
          {index + 1}. {keyName}
        </Typography>
      )}

      {getComponentByType(dataType)}

      {(!!customInputs[keyName]?.isNullable ||
        !!customInputs[keyName]?.isOptional) && (
        <Inline gutter={10} align="center">
          {!!customInputs[keyName]?.isNullable && isDataNullInSavedData && (
            <Inline>
              <CheckboxField
                control={control}
                name={`${nodeName}.isNullable`}
                label={
                  <Typography name={'paragraphSmall'}>
                    Send null value
                  </Typography>
                }
                useId={`${nodeName}.isNullable`}
                disabled={isDataOptional || isWorkflowReadOnly}
              />
            </Inline>
          )}

          {!!customInputs[keyName]?.isOptional && (
            <Inline>
              <CheckboxField
                control={control}
                name={`${nodeName}.isOptional`}
                label={
                  <Typography name="paragraphSmall">
                    Don&apos;t send attribute
                  </Typography>
                }
                useId={`${nodeName}.isOptional`}
                disabled={isWorkflowReadOnly}
              />
            </Inline>
          )}
        </Inline>
      )}
    </InputContainer>
  );
}
