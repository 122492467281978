/* eslint-disable */
import * as Comlink from 'comlink';


export const _isNil = (obj: any) => obj === null || obj === undefined;

export const _isEmpty = (obj: any) =>
  (typeof obj === 'object' && Object.keys(obj).length === 0) || obj === '';

const handleTransformDecisionTableRow = (
  timezoneOptions: Record<string, any>,
  decisionTableData: Record<string, any>,
  properties: Record<string, any>[]
) => {
  const tableRows: Array<Record<string, any>> = [];
  let currentRow = decisionTableData.decisionTable.firstRow;

  while (!_isEmpty(currentRow) && !_isNil(currentRow)) {
    const rowData = decisionTableData.decisionTable.rows[currentRow];
    const row = {
      [currentRow]: {
        isEnabled: rowData.isEnabled,
        condition: handleGetConditionsForRow(
          decisionTableData,
          currentRow,
          properties,
          timezoneOptions
        ),
        ruleResult: handleGetRowResults(
          decisionTableData,
          currentRow,
          timezoneOptions
        ),
      },
    };

    tableRows.push(row);
    currentRow = rowData.nextId;
  }

  return tableRows;
};

const handleGetRowResults = (
  decisionTableData: Record<string, any>,
  currentRow: string,
  timezoneOptions: Record<string, any>
) => {
  const results: Record<string, any>[] = [];
  let firstOutput =
    decisionTableData.decisionTable.rows[currentRow].firstOutputData;

  while (!_isNil(firstOutput) && !_isEmpty(firstOutput)) {
    const firstOutputNode =
      decisionTableData.action.then.outputData[firstOutput];
    if (_isNil(firstOutputNode)) break;

    results.push({
      [firstOutput]: {
        value: getOutputValueParsed(
          firstOutputNode.dataType ?? 'string',
          firstOutputNode.value,
          timezoneOptions
        ),
        dataType: firstOutputNode.dataType ?? 'string',
        returnType: firstOutputNode.returnType ?? '',
        executedValue: firstOutputNode.executedValue ?? '',
        source:
          !_isNil(firstOutputNode.source) && !_isEmpty(firstOutputNode.source)
            ? firstOutputNode.source
            : null,
        attribute:
          !_isNil(firstOutputNode.attribute) &&
          !_isEmpty(firstOutputNode.source)
            ? firstOutputNode.attribute
            : null,
      },
    });

    firstOutput = firstOutputNode.next;
  }

  return results;
};

const handleGetConditionsForRow = (
  decisionTableData: Record<string, any>,
  currentRow: string,
  properties: Record<string, any>[],
  timezoneOptions: Record<string, any>
) => {
  const conditions: any[] = [];
  const currentGroupNode = decisionTableData.conditions.nodes[currentRow];

  if (!_isNil(currentGroupNode) && !_isNil(currentGroupNode.children)) {
    sortArrayBasedOnLHS(
      decisionTableData.conditions.nodes,
      properties.map((obj) => Object.keys(obj)[0]),
      currentGroupNode.children
    );

    for (const child of currentGroupNode.children) {
      if (decisionTableData.conditions.nodes[child]?.nodeType !== 'group') {
        const condition = decisionTableData.conditions.nodes[child];
        const rhs = getRHSForCondition(decisionTableData, condition);

        if (!_isNil(condition)) {
          const cond = {
            value:
              condition.nodeType === 'jsCondition'
                ? condition.query ?? ''
                : condition.operator ?? '',
            type: condition.dataType ?? '',
            leftOperands: condition.leftNode?.length ?? 1,
            rightOperands: condition.rightNode?.length ?? 1,
          };

          if (child !== 'rhs') {
            conditions.push({ [child]: cond, rhs });
          }
        }
      } else {
        handleGetRowsByGroup(conditions, decisionTableData, child, properties);
      }
    }
  }

  return conditions;
};

const getRHSForCondition = (
  decisionTableData: Record<string, any>,
  condition: any
) => {
  return (
    condition?.rightNode?.map((rightNodeId: string) => {
      const rightNode = decisionTableData.conditions.nodes[rightNodeId];
      const value =
        rightNode?.nodeType === 'params' ? rightNode.attribute : rightNode.value;

      return {
        [rightNodeId]: {
          key: rightNode.sourceType ?? '',
          dataType: rightNode.dataType ?? '',
          value: value ?? '',
        },
      };
    }) ?? []
  );
};

const handleGetRowsByGroup = (
  conditions: any[],
  decisionTableData: Record<string, any>,
  currentChild: string,
  properties: Record<string, any>[]
) => {
  const currentGroupNode = decisionTableData.conditions.nodes[currentChild];

  if (!_isNil(currentGroupNode) && !_isNil(currentGroupNode.children)) {
    sortArrayBasedOnLHS(
      decisionTableData.conditions.nodes,
      properties.map((obj) => Object.keys(obj)[0]),
      currentGroupNode.children
    );

    for (const child of currentGroupNode.children) {
      if (decisionTableData.conditions.nodes[child]?.nodeType !== 'group') {
        const condition = decisionTableData.conditions.nodes[child];
        const rhs = getRHSForCondition(decisionTableData, condition);

        if (!_isNil(condition)) {
          const cond = {
            value:
              condition.nodeType === 'jsCondition'
                ? condition.query ?? ''
                : condition.operator ?? '',
            type: condition.dataType ?? '',
            leftOperands: condition.leftNode?.length ?? 1,
            rightOperands: condition.rightNode?.length ?? 1,
          };

          if (child !== 'rhs') {
            conditions.push({ [child]: cond, rhs });
          }
        }
      } else {
        handleGetRowsByGroup(conditions, decisionTableData, child, properties);
      }
    }
  }
};

const sortArrayBasedOnLHS = (
  originalObject: Record<string, any>,
  sortedArray: string[],
  arrToSort: string[]
): string[] => {
  const sortedIndexMap = new Map<string, number>();
  sortedArray.forEach((id, index) => sortedIndexMap.set(id, index));

  return arrToSort.sort((idA, idB) => {
    const lhsA = getLHSForSorting(originalObject, idA, sortedArray);
    const lhsB = getLHSForSorting(originalObject, idB, sortedArray);

    const indexA = sortedIndexMap.get(lhsA) ?? Infinity;
    const indexB = sortedIndexMap.get(lhsB) ?? Infinity;

    return indexA - indexB;
  });
};

// Get LHS for sorting
const getLHSForSorting = (
  originalObject: Record<string, any>,
  id: string,
  sortedArray: string[]
): string => {
  const node = originalObject[id];
  if (
    node.nodeType === 'group' &&
    !_isNil(node.children) &&
    !_isEmpty(node.children)
  ) {
    const sortedGroupChildren = [...node.children];
    sortArrayBasedOnLHS(originalObject, sortedArray, sortedGroupChildren);
    return originalObject[sortedGroupChildren[0]]?.leftNode?.[0] ?? '';
  }
  return node?.leftNode?.[0] ?? '';
};

const indianDateFormat = {
  date: 'yyyy-mm-dd',
  dateTime: "yyyy-mm-dd'T'HH:MM:ssp",
};

const usDateFormat = {
  date: 'mm-dd-yyyy',
  dateTime: "mm-dd-yyyy'T'HH:MM:ssp",
};

const DateFormatMap: Record<string, any> = {
  in: indianDateFormat,
  us: indianDateFormat,
};


export const getOutputValueParsed = (
  dataType: string,
  value: string | null,
  timezoneOptions: Record<string, any>
) => {
  if (value === null || value === '') return null;

  if (dataType === 'date') {
    return isValidDate(value , timezoneOptions)
      ? value
      : formatNectedDate(new Date(value), 'date' , timezoneOptions);
  }

  return `${value}`;
};

export const isValidDate = (date: string , timezoneOptions: Record<string, any>): boolean =>
  isValidDateorDateTime(date , ''  , timezoneOptions).isValid;
export const isValidDateTime = (dateTime: string , timezoneOptions: Record<string, any>): boolean =>
  isValidDateorDateTime(dateTime , '' , timezoneOptions).type === 'dateTime';

interface DateValidationResult {
  isValid: boolean;
  type: 'date' | 'dateTime' | 'string';
}

export const isValidDateorDateTime = (
  inputString: string,
  from = '',
  timezoneOptions: Record<string, any>
): DateValidationResult => {
  // Get user preference for date format
  const globalDateSettings = timezoneOptions.dateFormat;
  const isUSFormat = globalDateSettings === 'us';

  // Regular expressions for different date formats
  // Standard Date Formats
  const dateFormat1 = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/; // mm/dd/yyyy or dd/mm/yyyy
  const dateFormat2 = /^(\d{4})\/(\d{1,2})\/(\d{1,2})$/; // yyyy/mm/dd
  const dateFormat3 = /^(\d{1,2})-(\d{1,2})-(\d{4})$/; // mm-dd-yyyy or dd-mm-yyyy
  const dateFormat4 = /^(\d{4})-(\d{1,2})-(\d{1,2})$/; // yyyy-mm-dd

  // Standard DateTime Formats
  const dateTimeFormat1 =
    /^(\d{1,2})\/(\d{1,2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})$/; // mm/dd/yyyy HH:mm:ss or dd/mm/yyyy HH:mm:ss
  const dateTimeFormat2 =
    /^(\d{4})\/(\d{1,2})\/(\d{1,2}) (\d{2}):(\d{2}):(\d{2})$/; // yyyy/mm/dd HH:mm:ss
  const dateTimeFormat3 =
    /^(\d{4})-(\d{1,2})-(\d{1,2}) (\d{2}):(\d{2}):(\d{2})$/; // yyyy-mm-dd HH:mm:ss
  const dateTimeFormat4 =
    /^(\d{1,2})-(\d{1,2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})$/; // mm-dd-yyyy HH:mm:ss or dd-mm-yyyy HH:mm:ss

  // ISO and Timezone Formats with nanosecond support
  const dateTimeTZFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/; // yyyy-MM-ddTHH:mm:ssZ
  const dateTimeTZMilliSecFormat =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,9})?Z$/; // yyyy-MM-ddTHH:mm:ss.aaaaaaaaaZ (updated for nanoseconds)
  const rfc3339Format =
    /^(\d{4})-(\d{2})-(\d{2})(?:T|\s)(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,9}))?(?:(Z)|(?:([+-])(\d{2}):(\d{2})))$/; // RFC 3339 with optional nanoseconds
  
  // New format specifically for nanosecond precision without timezone
  const nanoSecFormat = 
    /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{1,9})$/; // yyyy-MM-ddTHH:mm:ss.nnnnnnnnn

  // New formats with timezone offset and nanosecond support
  const formattedDashTimeWithTZOffset =
    /^(\d{1,2})-(\d{1,2})-(\d{4})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,9}))?([+-]\d{2}:\d{2})$/; // mm-dd-yyyyTHH:mm:ss.nnnnnnnnn+TZ or dd-mm-yyyyTHH:mm:ss.nnnnnnnnn+TZ
  const formattedSlashTimeWithTZOffset =
    /^(\d{1,2})\/(\d{1,2})\/(\d{4})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,9}))?([+-]\d{2}:\d{2})$/; // mm/dd/yyyyTHH:mm:ss.nnnnnnnnn+TZ or dd/mm/yyyyTHH:mm:ss.nnnnnnnnn+TZ

  // Helper function to check if a date object is valid
  const isValidDate = (date: Date): boolean => !isNaN(date.getTime());

  // Helper function to determine if a date is ambiguous (valid in both US and Indian formats)
  const isAmbiguousDate = (
    first: number,
    second: number,
    year: number
  ): boolean => {
    // Both components must be valid days (1-31) and valid months (1-12)
    if (first >= 1 && first <= 12 && second >= 1 && second <= 31) {
      // Check if second is a valid day for first as a month (US format)
      const lastDayOfMonthUS = new Date(year, first, 0).getDate();
      const validInUS = second <= lastDayOfMonthUS;

      // Check if first is a valid day for second as a month (Indian format)
      const lastDayOfMonthIN = new Date(year, second, 0).getDate();
      const validInIndian = first <= lastDayOfMonthIN;

      // If valid in both formats, it's ambiguous
      return validInUS && validInIndian;
    }
    return false;
  };

  // Helper to test standard date formats and validate the date
  const testAndValidate = (
    regex: RegExp,
    type: 'date' | 'dateTime'
  ): DateValidationResult | null => {
    if (typeof inputString !== 'string') {
      return { isValid: false, type: 'string' };
    }
    const match = (inputString || '').match(regex);

    if (match) {
      // For formats that could be either mm/dd or dd/mm based on user preference
      if (
        (regex === dateFormat1 ||
          regex === dateFormat3 ||
          regex === dateTimeFormat1 ||
          regex === dateTimeFormat4) &&
        match[1].length <= 2 &&
        match[2].length <= 2
      ) {
        const first = parseInt(match[1], 10);
        const second = parseInt(match[2], 10);
        const year = parseInt(match[3], 10);

        // Check if the date is ambiguous (valid in both US and Indian formats)
        const ambiguous = isAmbiguousDate(first, second, year);

        // Strict validation for dates during validation mode
        if (from === 'validation') {
          // If we're in validation mode, we need to be more strict about format

          // For ambiguous dates with both components between 1-12
          if (ambiguous && first <= 12 && second <= 12) {
            // In validation mode, we should enforce that dates must be entered in the expected format
            // For ambiguous dates, this means rejecting dates that appear to be in the wrong format

            // If the date components are similar or identical, allow it (like 1-1-2025)
            if (first === second) {
              // This is fine in either format
            }
            // For US format, enforce that dates should be entered as MM-DD-YYYY
            else if (isUSFormat) {
              // For US format, if it looks like DD-MM-YYYY (day typically > month), reject it
              if (first > second && first <= 12) {
                return { isValid: false, type: 'string' }; // Likely DD-MM format but we expect MM-DD
              }
            }
            // For Indian format, enforce that dates should be entered as DD-MM-YYYY
            else {
              // For Indian format, if it looks like MM-DD-YYYY (month typically < day), reject it
              if (second > first && second <= 12) {
                return { isValid: false, type: 'string' }; // Likely MM-DD format but we expect DD-MM
              }
            }
          }
        }

        // Additional validation for US/Indian format
        // For US format (mm/dd), first should be 1-12 (month), second should be 1-31 (day)
        // For Indian format (dd/mm), first should be 1-31 (day), second should be 1-12 (month)
        if (isUSFormat) {
          // Validate US format: first should be month (1-12), second should be day (1-31)
          if (first < 1 || first > 12) {
            return { isValid: false, type: 'string' }; // Invalid month
          }

          // Check if day is valid for the given month
          const lastDayOfMonth = new Date(year, first, 0).getDate();
          if (second < 1 || second > lastDayOfMonth) {
            return { isValid: false, type: 'string' }; // Invalid day for this month
          }
        } else {
          // Validate Indian format: first should be day (1-31), second should be month (1-12)
          if (second < 1 || second > 12) {
            return { isValid: false, type: 'string' }; // Invalid month
          }

          // Check if day is valid for the given month
          const lastDayOfMonth = new Date(year, second, 0).getDate();
          if (first < 1 || first > lastDayOfMonth) {
            return { isValid: false, type: 'string' }; // Invalid day for this month
          }
        }

        // Validate based on user's date format preference
        let date: Date;
        if (type === 'date') {
          if (isUSFormat) {
            // US format: mm/dd/yyyy
            date = new Date(year, first - 1, second);
          } else {
            // Indian format: dd/mm/yyyy
            date = new Date(year, second - 1, first);
          }
        } else {
          // dateTime
          const hours = parseInt(match[4], 10);
          const minutes = parseInt(match[5], 10);
          const seconds = parseInt(match[6], 10);

          // Validate time components
          if (
            hours < 0 ||
            hours > 23 ||
            minutes < 0 ||
            minutes > 59 ||
            seconds < 0 ||
            seconds > 59
          ) {
            return { isValid: false, type: 'string' }; // Invalid time components
          }

          if (isUSFormat) {
            // US format: mm/dd/yyyy HH:mm:ss
            date = new Date(year, first - 1, second, hours, minutes, seconds);
          } else {
            // Indian format: dd/mm/yyyy HH:mm:ss
            date = new Date(year, second - 1, first, hours, minutes, seconds);
          }
        }

        return isValidDate(date)
          ? { isValid: true, type }
          : { isValid: false, type: 'string' };
      }
      // For timezone offset formats with nanoseconds
      else if (
        regex === formattedDashTimeWithTZOffset ||
        regex === formattedSlashTimeWithTZOffset
      ) {
        const first = parseInt(match[1], 10);
        const second = parseInt(match[2], 10);
        const year = parseInt(match[3], 10);
        const hours = parseInt(match[4], 10);
        const minutes = parseInt(match[5], 10);
        const seconds = parseInt(match[6], 10);
        
        // Handle nanoseconds
        let milliseconds = 0;
        if (match[7]) {
          // Get first 3 digits for milliseconds (JS Date limit)
          const nanoStr = match[7].padEnd(9, '0');
          milliseconds = parseInt(nanoStr.substring(0, 3), 10);
        }
        
        // Get timezone offset
        const tzOffset = match[8] || '';

        // Additional validation for US/Indian format
        if (isUSFormat) {
          // Validate US format: first should be month (1-12), second should be day (1-31)
          if (first < 1 || first > 12) {
            return { isValid: false, type: 'string' }; // Invalid month
          }

          // Check if day is valid for the given month
          const lastDayOfMonth = new Date(year, first, 0).getDate();
          if (second < 1 || second > lastDayOfMonth) {
            return { isValid: false, type: 'string' }; // Invalid day for this month
          }
        } else {
          // Validate Indian format: first should be day (1-31), second should be month (1-12)
          if (second < 1 || second > 12) {
            return { isValid: false, type: 'string' }; // Invalid month
          }

          // Check if day is valid for the given month
          const lastDayOfMonth = new Date(year, second, 0).getDate();
          if (first < 1 || first > lastDayOfMonth) {
            return { isValid: false, type: 'string' }; // Invalid day for this month
          }
        }

        // Validate time components
        if (
          hours < 0 ||
          hours > 23 ||
          minutes < 0 ||
          minutes > 59 ||
          seconds < 0 ||
          seconds > 59
        ) {
          return { isValid: false, type: 'string' }; // Invalid time components
        }

        // Create a proper ISO string based on user preference
        let isoString;
        if (isUSFormat) {
          // US format: MM-DD-YYYY to YYYY-MM-DD
          isoString = `${year}-${first.toString().padStart(2, '0')}-${second
            .toString()
            .padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')}:${seconds
            .toString()
            .padStart(2, '0')}`;
        } else {
          // Indian format: DD-MM-YYYY to YYYY-MM-DD
          isoString = `${year}-${second.toString().padStart(2, '0')}-${first
            .toString()
            .padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')}:${seconds
            .toString()
            .padStart(2, '0')}`;
        }
        
        // Add milliseconds if present
        if (match[7]) {
          isoString += `.${milliseconds.toString().padStart(3, '0')}`;
        }
        
        // Add timezone offset if present
        if (tzOffset) {
          isoString += tzOffset;
        }

        const date = new Date(isoString);
        return isValidDate(date)
          ? { isValid: true, type: 'dateTime' }
          : { isValid: false, type: 'string' };
      }
      // For the nanosecond format without timezone
      else if (regex === nanoSecFormat) {
        const year = parseInt(match[1], 10);
        const month = parseInt(match[2], 10) - 1;
        const day = parseInt(match[3], 10);
        const hours = parseInt(match[4], 10);
        const minutes = parseInt(match[5], 10);
        const seconds = parseInt(match[6], 10);
        
        // Handle nanoseconds - convert to milliseconds
        let milliseconds = 0;
        if (match[7]) {
          const nanoStr = match[7].padEnd(9, '0');
          milliseconds = parseInt(nanoStr.substring(0, 3), 10);
        }
        
        // Create date with millisecond precision
        const date = new Date(year, month, day, hours, minutes, seconds, milliseconds);
        
        return isValidDate(date)
          ? { isValid: true, type: 'dateTime' }
          : { isValid: false, type: 'string' };
      }
      // Handle RFC3339 format with improved nanoseconds support
      else if (regex === rfc3339Format) {
        const year = parseInt(match[1], 10);
        const month = parseInt(match[2], 10) - 1;
        const day = parseInt(match[3], 10);
        const hours = parseInt(match[4], 10);
        const minutes = parseInt(match[5], 10);
        const seconds = parseInt(match[6], 10);
        
        // Handle nanoseconds - convert to milliseconds
        let milliseconds = 0;
        if (match[7]) {
          const nanoStr = match[7].padEnd(9, '0');
          milliseconds = parseInt(nanoStr.substring(0, 3), 10);
        }
        
        let date: Date;
        if (match[8] === 'Z') {
          // UTC time
          date = new Date(Date.UTC(year, month, day, hours, minutes, seconds, milliseconds));
        } else if (match[9]) {
          // Has timezone offset
          const offsetSign = match[9] === '+' ? 1 : -1;
          const offsetHours = parseInt(match[10], 10);
          const offsetMinutes = parseInt(match[11], 10);
          
          // Create date in UTC, then apply offset
          const utcDate = new Date(Date.UTC(year, month, day, hours, minutes, seconds, milliseconds));
          const offsetMilliseconds = offsetSign * ((offsetHours * 60 + offsetMinutes) * 60 * 1000);
          date = new Date(utcDate.getTime() - offsetMilliseconds);
        } else {
          // Local time
          date = new Date(year, month, day, hours, minutes, seconds, milliseconds);
        }
        
        return isValidDate(date)
          ? { isValid: true, type: 'dateTime' }
          : { isValid: false, type: 'string' };
      }
      // Special handling for dateTimeTZMilliSecFormat with nanoseconds
      else if (regex === dateTimeTZMilliSecFormat) {
        // Extract the date components from the string to properly handle nanoseconds
        const match = inputString.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,9}))?Z$/);
        
        if (match) {
          const year = parseInt(match[1], 10);
          const month = parseInt(match[2], 10) - 1;
          const day = parseInt(match[3], 10);
          const hours = parseInt(match[4], 10);
          const minutes = parseInt(match[5], 10);
          const seconds = parseInt(match[6], 10);
          
          // Handle nanoseconds
          let milliseconds = 0;
          if (match[7]) {
            const nanoStr = match[7].padEnd(9, '0');
            milliseconds = parseInt(nanoStr.substring(0, 3), 10);
          }
          
          // Create date in UTC
          const date = new Date(Date.UTC(year, month, day, hours, minutes, seconds, milliseconds));
          
          return isValidDate(date)
            ? { isValid: true, type: 'dateTime' }
            : { isValid: false, type: 'string' };
        }
      }
      // For other formats (yyyy-mm-dd, etc.) use standard parsing
      else {
        const date = new Date(inputString);
        return isValidDate(date)
          ? { isValid: true, type }
          : { isValid: true, type: 'string' };
      }
    }

    return null;
  };

  // Define the array of formats and iterate over them
  const formats: Array<{ regex: RegExp; type: 'date' | 'dateTime' }> = [
    { regex: dateFormat1, type: 'date' },
    { regex: dateFormat2, type: 'date' },
    { regex: dateFormat3, type: 'date' },
    { regex: dateFormat4, type: 'date' },
    { regex: dateTimeFormat1, type: 'dateTime' },
    { regex: dateTimeFormat2, type: 'dateTime' },
    { regex: dateTimeFormat3, type: 'dateTime' },
    { regex: dateTimeFormat4, type: 'dateTime' },
    { regex: dateTimeTZFormat, type: 'dateTime' },
    { regex: dateTimeTZMilliSecFormat, type: 'dateTime' },
    { regex: rfc3339Format, type: 'dateTime' },
    { regex: nanoSecFormat, type: 'dateTime' }, // New format for nanoseconds
    { regex: formattedDashTimeWithTZOffset, type: 'dateTime' },
    { regex: formattedSlashTimeWithTZOffset, type: 'dateTime' },
  ];

  // Iterate over all formats and validate
  for (const { regex, type } of formats) {
    const result = testAndValidate(regex, type);

    if (result != null) {
      return result;
    }
  }

  // If no valid date format matches, return based on the 'from' parameter
  if (from === 'validation') {
    return { isValid: false, type: 'string' };
  }

  return { isValid: true, type: 'string' };
};


export const isInvalidDate = (dateString: string | null , timezoneOptions  : Record<string , any>) => {
  if (_isNil(dateString)) {
    return false;
  }
  const date = customParseDate(dateString as unknown as string , timezoneOptions);
  return (date instanceof Date && !isNaN(date as unknown as number));
};

export type DecisionTableModel = {
  firstRow: string;
  firstProperty: string;
  firstResult: string;
  firstAggOutputData: string;
  rows: DecisionTableRows;
  properties: DecisionTableProperties;
  results: DecisionTableResults;
};

export type DecisionTableRows = Record<string, DecisionTableGroups>;
export type DecisionTableProperties = Record<string, DecisionTableProperty>;
export type DecisionTableResults = Record<string, DecisionTableResult>;

export type DecisionTableGroups = {
  firstOutputData: string;
  isEnabled: boolean;
  nextId: string;
  prevId: string;
};
export type DecisionTableProperty = {
  nextId: string;
  prevId: string;
  type: string;
  multiParent?: string[] | null;
};

export type DecisionTableResult = {
  nextId: string;
  prevId: string;
  dataType: string;
  keyName: string;
  children?: string[] | null;
  schemaId?: string;
};

export const handleTransformResults = (
  decisionTableData: DecisionTableModel
) => {
  const array = [];
  let current = decisionTableData.firstResult;
  const { results } = decisionTableData;

  while (current && results[current]) {
    const currentResult = results[current];
    array.push({
      [current]: {
        keyName: currentResult.keyName,
        dataType: currentResult.dataType,
        schemaId: currentResult.schemaId,
      },
    });

    current = currentResult.nextId;
  }

  return array;
};

export type DecisionTableNodesModel = {
  nodeType: string;
  parent: string;
  siblingIndex?: number;
  operator?: string;
  dataType?: string;
  children?: string[];
  leftNode?: string[];
  rightNode?: string[];
  multiParent?: string[];
  sourceType?: string;
  attribute?: string;
  query?: string;
  name?: string;
  value?: string | number | any[] | Record<string, any>;
};

export const deleteDecisionTableNodes = (
  ruleId: string,
  rules: Record<string, DecisionTableNodesModel>
): Record<string, DecisionTableNodesModel> => {
  if (!rules[ruleId]) return rules;

  const scheduledDeletedElementsIds = new Set<string>();
  getScheduledDeletedElementsIds(
    rules[ruleId],
    ruleId,
    scheduledDeletedElementsIds,
    rules
  );

  const parentRule = rules[rules[ruleId]?.parent || ''];
  if (parentRule?.children) {
    // Update parent's children
    parentRule.children = parentRule.children.filter(
      (child) => child !== ruleId
    );

    // Update sibling indices
    updateSiblingIndices(rules, ruleId, rules[ruleId]?.children || []);
  }

  // Create new rules object excluding deleted elements
  return Object.fromEntries(
    Object.entries(rules).filter(([id]) => !scheduledDeletedElementsIds.has(id))
  );
};

const updateSiblingIndices = (
  rules: Record<string, DecisionTableNodesModel>,
  ruleId: string,
  children: string[]
) => {
  const siblingIndices = new Map(
    children
      .filter((child) => rules[child]?.siblingIndex != null)
      .map((child) => [child, rules[child].siblingIndex!])
  );

  if (siblingIndices.size === 0) return;

  let decrementNext = false;
  [...siblingIndices.entries()]
    .sort(([, a], [, b]) => a - b)
    .forEach(([key]) => {
      if (decrementNext && rules[key]?.siblingIndex != null) {
        rules[key].siblingIndex!--;
      }
      if (key === ruleId) decrementNext = true;
    });
};

const getScheduledDeletedElementsIds = (
  rule: DecisionTableNodesModel | undefined,
  ruleId: string,
  idSet: Set<string>,
  rules: Record<string, DecisionTableNodesModel>
): void => {
  if (!rule || idSet.has(ruleId)) return;

  idSet.add(ruleId);
  rule.rightNode?.forEach((id) => idSet.add(id));

  if (rule.nodeType === 'group' && rule.children?.length) {
    rule.children.forEach((child) =>
      getScheduledDeletedElementsIds(rules[child], child, idSet, rules)
    );
  }
};

export const DTTableTransform = {
  handleTransformDecisionTableRow,
  handleTransformResults,
  deleteDecisionTableNodes,
};

Comlink.expose(DTTableTransform);

export function dateFormat(date: Date | string, format: string): string {
  const d = new Date(date);

  if (isNaN(d.getTime())) {
    throw new Error('Invalid date provided');
  }

  const tokens: { [key: string]: () => string } = {
    yyyy: () => d.getFullYear().toString(),
    mm: () => padZero(d.getMonth() + 1),
    dd: () => padZero(d.getDate()),
    HH: () => {
      const hours = d.getHours();
      return format.includes('p') ? padZero(hours % 12 || 12) : padZero(hours);
    },
    MM: () => padZero(d.getMinutes()),
    p: () => (d.getHours() >= 12 ? 'PM' : 'AM'),
  };

  let result = format;
  for (const [token, getter] of Object.entries(tokens)) {
    result = result.replace(token, getter());
  }

  return result;
}

function padZero(num: number): string {
  return num.toString().padStart(2, '0');
}

export const formatNectedDate = (value: string | Date, type: string , timezoneOptions  : Record<string , any>) => {
  if (value === '') return value;
  const updatedValue = value === 'NOW' ? new Date() : value;
  const formattedDate =
    typeof updatedValue === 'string'
      ? customParseDate(updatedValue , timezoneOptions)
      : updatedValue;
  const formatString = type === 'date' ? DateFormatMap[timezoneOptions.dateFormat].date : DateFormatMap[timezoneOptions.dateFormat].dateTime;

  try {
    return dateFormat(formattedDate, formatString);
  } catch (err) {
    return updatedValue;
  }
};

export const customParseDate = (input: string | Date, timezoneOptions: Record<string, any>): Date => {
  // For all date formats, use the regular parsing logic
  let parsedDate: Date;

  // Get user preference for date format (1 = US format with mm/dd, 0 = Indian format with dd/mm)
  const globalDateSettings = timezoneOptions.dateFormat;
  const isUSFormat = globalDateSettings === '1';

  if (typeof input === 'string' && input.length > 0) {
    // Regular expressions for different date formats
    const slashFormat = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/; // mm/dd/yyyy or dd/mm/yyyy
    const dashFormat = /^(\d{1,2})-(\d{1,2})-(\d{4})$/; // mm-dd-yyyy or dd-mm-yyyy
    const yearFirstSlashFormat = /^(\d{4})\/(\d{1,2})\/(\d{1,2})$/; // yyyy/mm/dd
    const yearFirstDashFormat = /^(\d{4})-(\d{1,2})-(\d{1,2})$/; // yyyy-mm-dd

    // Date and time formats
    const dateTimeSlashFormat =
      /^(\d{1,2})\/(\d{1,2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})$/;
    const dateTimeDashFormat =
      /^(\d{1,2})-(\d{1,2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})$/;
    const yearFirstDateTimeSlashFormat =
      /^(\d{4})\/(\d{1,2})\/(\d{1,2}) (\d{2}):(\d{2}):(\d{2})$/;
    const yearFirstDateTimeDashFormat =
      /^(\d{4})-(\d{1,2})-(\d{1,2}) (\d{2}):(\d{2}):(\d{2})$/;

    // ISO formats with improved nanosecond support
    const isoFormat =
      /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,9}))?(?:Z|[+-]\d{2}:\d{2})$/;
    const dateTimeTZFormat =
      /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/; // yyyy-MM-ddTHH:mm:ssZ
    const rfc3339Format =
      /^(\d{4})-(\d{2})-(\d{2})(?:T|\s)(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,9}))?(?:Z)$/; // RFC 3339 with Z timezone and nanoseconds

    let match;

    // Parse date with format: 12/31/2023 or 31/12/2023
    if ((match = input.match(slashFormat)) !== null) {
      const first = parseInt(match[1], 10);
      const second = parseInt(match[2], 10);
      const year = parseInt(match[3], 10);

      if (isUSFormat) {
        // US format: mm/dd/yyyy
        parsedDate = new Date(year, first - 1, second);
      } else {
        // Indian format: dd/mm/yyyy
        parsedDate = new Date(year, second - 1, first);
      }
    }
    // Parse date with format: 12-31-2023 or 31-12-2023
    else if ((match = input.match(dashFormat)) !== null) {
      const first = parseInt(match[1], 10);
      const second = parseInt(match[2], 10);
      const year = parseInt(match[3], 10);

      if (isUSFormat) {
        // US format: mm-dd-yyyy
        parsedDate = new Date(year, first - 1, second);
      } else {
        // Indian format: dd-mm-yyyy
        parsedDate = new Date(year, second - 1, first);
      }
    }
    // Parse date with format: 2023/12/31
    else if ((match = input.match(yearFirstSlashFormat)) !== null) {
      parsedDate = new Date(
        parseInt(match[1], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[3], 10)
      );
    }
    // Parse date with format: 2023-12-31
    else if ((match = input.match(yearFirstDashFormat)) !== null) {
      parsedDate = new Date(
        parseInt(match[1], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[3], 10)
      );
    }
    // Parse datetime with format: 12/31/2023 14:30:00 or 31/12/2023 14:30:00
    else if ((match = input.match(dateTimeSlashFormat)) !== null) {
      const first = parseInt(match[1], 10);
      const second = parseInt(match[2], 10);
      const year = parseInt(match[3], 10);
      const hours = parseInt(match[4], 10);
      const minutes = parseInt(match[5], 10);
      const seconds = parseInt(match[6], 10);

      if (isUSFormat) {
        // US format: mm/dd/yyyy HH:mm:ss
        parsedDate = new Date(year, first - 1, second, hours, minutes, seconds);
      } else {
        // Indian format: dd/mm/yyyy HH:mm:ss
        parsedDate = new Date(year, second - 1, first, hours, minutes, seconds);
      }
    }
    // Parse datetime with format: 12-31-2023 14:30:00 or 31-12-2023 14:30:00
    else if ((match = input.match(dateTimeDashFormat)) !== null) {
      const first = parseInt(match[1], 10);
      const second = parseInt(match[2], 10);
      const year = parseInt(match[3], 10);
      const hours = parseInt(match[4], 10);
      const minutes = parseInt(match[5], 10);
      const seconds = parseInt(match[6], 10);

      if (isUSFormat) {
        // US format: mm-dd-yyyy HH:mm:ss
        parsedDate = new Date(year, first - 1, second, hours, minutes, seconds);
      } else {
        // Indian format: dd-mm-yyyy HH:mm:ss
        parsedDate = new Date(year, second - 1, first, hours, minutes, seconds);
      }
    }
    // Parse datetime with format: 2023/12/31 14:30:00
    else if ((match = input.match(yearFirstDateTimeSlashFormat)) !== null) {
      parsedDate = new Date(
        parseInt(match[1], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[3], 10),
        parseInt(match[4], 10),
        parseInt(match[5], 10),
        parseInt(match[6], 10)
      );
    }
    // Parse datetime with format: 2023-12-31 14:30:00
    else if ((match = input.match(yearFirstDateTimeDashFormat)) !== null) {
      parsedDate = new Date(
        parseInt(match[1], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[3], 10),
        parseInt(match[4], 10),
        parseInt(match[5], 10),
        parseInt(match[6], 10)
      );
    }
    // Parse ISO datetime format: 2023-12-31T14:30:00Z or 2023-12-31T14:30:00+05:30 with nanoseconds
    else if ((match = input.match(isoFormat)) !== null) {
      const year = parseInt(match[1], 10);
      const month = parseInt(match[2], 10) - 1;
      const day = parseInt(match[3], 10);
      const hours = parseInt(match[4], 10);
      const minutes = parseInt(match[5], 10);
      const seconds = parseInt(match[6], 10);
      
      // Handle nanoseconds - convert to milliseconds (JavaScript Date only supports milliseconds)
      let milliseconds = 0;
      if (match[7]) {
        // Pad to 3 digits to convert to milliseconds (JavaScript limit)
        const nanoStr = match[7].padEnd(9, '0');
        milliseconds = parseInt(nanoStr.substring(0, 3), 10);
      }
      
      // For direct ISO format, we can let the Date constructor handle timezone offsets
      if (input.includes('Z') || input.includes('+') || input.includes('-')) {
        // Create a proper ISO string with milliseconds
        let isoString = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}T`;
        isoString += `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        
        if (match[7]) {
          isoString += `.${match[7].substring(0, 3).padEnd(3, '0')}`;
        }
        
        if (input.includes('Z')) {
          isoString += 'Z';
        } else {
          // Extract timezone
          const tzMatch = input.match(/([+-]\d{2}:\d{2})$/);
          if (tzMatch) {
            isoString += tzMatch[1];
          }
        }
        
        parsedDate = new Date(isoString);
      } else {
        parsedDate = new Date(year, month, day, hours, minutes, seconds, milliseconds);
      }
    }
    // Parse yyyy-MM-ddTHH:mm:ssZ format (UTC)
    else if ((match = input.match(dateTimeTZFormat)) !== null) {
      parsedDate = new Date(input);
    }
    // Parse RFC 3339 format with Z timezone and nanosecond precision
    else if ((match = input.match(rfc3339Format)) !== null) {
      const year = parseInt(match[1], 10);
      const month = parseInt(match[2], 10) - 1;
      const day = parseInt(match[3], 10);
      const hours = parseInt(match[4], 10);
      const minutes = parseInt(match[5], 10);
      const seconds = parseInt(match[6], 10);
      
      // Handle nanoseconds - convert to milliseconds (JavaScript Date only supports milliseconds)
      let milliseconds = 0;
      if (match[7]) {
        // Pad to 3 digits to convert to milliseconds (JavaScript limit)
        const nanoStr = match[7].padEnd(9, '0');
        milliseconds = parseInt(nanoStr.substring(0, 3), 10);
      }
      
      if (input.includes('Z')) {
        // UTC time
        parsedDate = new Date(Date.UTC(year, month, day, hours, minutes, seconds, milliseconds));
      } else {
        // Local time
        parsedDate = new Date(year, month, day, hours, minutes, seconds, milliseconds);
      }
    } else {
      // Try direct parsing for nanosecond formats first
      const nanoFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{1,9})$/;
      const nanoMatch = input.match(nanoFormat);
      
      if (nanoMatch) {
        const year = parseInt(nanoMatch[1], 10);
        const month = parseInt(nanoMatch[2], 10) - 1;
        const day = parseInt(nanoMatch[3], 10);
        const hours = parseInt(nanoMatch[4], 10);
        const minutes = parseInt(nanoMatch[5], 10);
        const seconds = parseInt(nanoMatch[6], 10);
        
        // Handle nanoseconds
        let milliseconds = 0;
        if (nanoMatch[7]) {
          const nanoStr = nanoMatch[7].padEnd(9, '0');
          milliseconds = parseInt(nanoStr.substring(0, 3), 10);
        }
        
        parsedDate = new Date(year, month, day, hours, minutes, seconds, milliseconds);
      } else {
        // Fallback for other formats
        parsedDate = new Date(input);
      }
    }
  } else if (input instanceof Date) {
    parsedDate = new Date(input); // Create a copy of the Date object
  } else {
    parsedDate = new Date(); // Return current date for invalid input
  }

  // Get the user's timezone from localStorage and adjust if needed
  try {
    const userTimezone = timezoneOptions.timezone;

    if (userTimezone) {
      // For dates without timezone info, adjust to the user's timezone
      const options: Intl.DateTimeFormatOptions = {
        timeZone: userTimezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      };

      const formatter = new Intl.DateTimeFormat('en-US', options);
      const parts = formatter.formatToParts(parsedDate);

      // Extract date/time components from the formatted parts
      const dateParts = parts.reduce((acc, part) => {
        if (part.type !== 'literal') {
          acc[part.type] = parseInt(part.value, 10);
        }
        return acc;
      }, {} as Record<string, number>);

      // Create a new date in the user's timezone
      const adjustedDate = new Date(
        dateParts.year,
        dateParts.month - 1,
        dateParts.day,
        dateParts.hour,
        dateParts.minute,
        dateParts.second
      );

      return adjustedDate;
    }
  } catch (error) {
    console.error('Error applying timezone:', error);
  }

  // Return the original parsed date if no timezone adjustment was made
  return parsedDate;
};