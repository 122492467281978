import dateFormat from 'dateformat';
import { Ref, forwardRef, useMemo } from 'react';
import DatePicker, { DatePickerProps } from 'react-datepicker';
import { BiCalendar } from 'react-icons/bi';

import { _isNil } from '../../../../../apps/web/src/pages/Rules/worker/DTTableTransform';
import { TextInput } from '../TextInput';
import { DateFormatMap } from './constant';
import {
  customParseDate,
  formatNectedDate,
  isInvalidDate,
  removeOffsetFromDateString,
} from './helpers';
import './styles.css';

export type DateTimePickerInputProps = Pick<
  DatePickerProps,
  'minDate' | 'maxDate' | 'popperPlacement' | 'showYearDropdown'
> & {
  showIcon?: boolean;
  timeInputLabel?: string;
  value?: Date | null;
  placeholderText?: string;
  dateFormat?: string | string[];
  name?: string;
  onChange?: (e: any) => void;
  showTimeInput?: boolean;
  showCustomInput?: boolean;
  showIconInput?: boolean;
  appendText?: string;
  disabled?: boolean;
  withPortal?: boolean;
};

export const DateTimePickerInput = forwardRef(
  (
    {
      showIcon = true,
      timeInputLabel = 'Time:',
      showTimeInput = true,
      value,
      name,
      onChange,
      placeholderText,
      minDate,
      maxDate,
      showCustomInput = false,
      appendText = '',
      disabled = false,
      showIconInput = false,
      popperPlacement = 'right-start',
      showYearDropdown = true,
      withPortal = true,
    }: DateTimePickerInputProps,
    ref: Ref<HTMLElement>
  ) => {
    const customInput = useMemo(() => {
      if (showCustomInput) {
        return (
          <TextWithIcon
            appendText={appendText}
            showTimeInput={showTimeInput}
            placeholderText={placeholderText}
            inputValue={value}
          />
        );
      } else if (showIconInput) {
        return <OnlyIconInput />;
      }
    }, [value]);

    const otherProps: Record<string, any> = {};

    if (withPortal) {
      otherProps.portalId = 'datepicker-portal';
    }

    let selectedDate = null;

    if (isInvalidDate(String(value)) && value != null) {
      try {
        if (typeof value === 'object') {
          const dateString = formatNectedDate(value as Date, 'dateTime');
          selectedDate = customParseDate(removeOffsetFromDateString(dateString as unknown as string));
        } else {
          selectedDate = customParseDate(removeOffsetFromDateString(String(value)));
        }
      } catch (err) {
        selectedDate = value;
      }
    }
    return (
      <DatePicker
        todayButton="Today"
        showIcon={showIcon}
        timeInputLabel={timeInputLabel}
        showTimeInput={showTimeInput}
        selected={selectedDate}
        placeholderText={placeholderText}
        name={name}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        showYearDropdown={showYearDropdown}
        onYearChange={(e) => {
          if (typeof onChange === 'function') {
            onChange(e);
          }
        }}
        popperPlacement={popperPlacement}
        onChange={(e) => {
          if (typeof onChange === 'function') {
            const format =
              DateFormatMap[
                window.sessionStorage.getItem('nected-df') as string
              ].dateTime;

            const dateObj = dateFormat(e?.toISOString(), format);
            

            const dateObjWithTZ = formatNectedDate(
              dateObj,
              showTimeInput ? 'dateTime' : 'date',
              true
            );
            onChange(dateObjWithTZ);
          }
        }}
        customInput={customInput}
        withPortal={withPortal}
        {...otherProps}
      />
    );
  }
);

DateTimePickerInput.displayName = 'DateTimePickerInput';

const TextWithIcon = forwardRef(
  (
    {
      value,
      onClick,
      appendText = '',
      showTimeInput = false,
      placeholderText,
      inputValue,
    }: any,
    ref
  ) => {
    let displayValue = '';

    try {
      displayValue = !_isNil(inputValue) ? inputValue : '';
    } catch (err) {}

    return (
      <div onClick={onClick}>
        <TextInput
          value={
            displayValue +
            `${(appendText as string) !== '' ? ` ${appendText as string}` : ''}`
          }
          startIcon={<BiCalendar color="#bbb" size={20} />}
          readOnly
          placeholder={placeholderText}
        />
      </div>
    );
  }
);

TextWithIcon.displayName = 'TextWithIcon';

const OnlyIconInput = forwardRef(({ value, onClick }: any, ref) => (
  <>
    <BiCalendar color="#8f8e8e" size={20} onClick={onClick} />
  </>
));

OnlyIconInput.displayName = 'OnlyIconInput';
