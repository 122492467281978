import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { MdEdit } from 'react-icons/md';
import { Typography, useLayer } from 'ui';

import { decisionTableNodesAtom } from '../DecisionTable';
import { ExcelFormulaSheet } from './ExcelFormulaSheet';
import { JsFormulaSheet } from './JsFormulaSheet';
import { JsonNodeSheet } from './JsonNodeSheet';
import { PillStyled } from './RhsFormulaField.styled';
import { _isNil } from '../../../worker/DTTableTransform';

type RhsFormulaFieldProps = Omit<UseControllerProps<any>, 'name'> & {
  nodeId: string;
  nodeName: string;
  setValue: UseFormSetValue<any>;
  hidePopover?: any;
};

export const RhsFormulaField = ({
  nodeId,
  nodeName,
  control,
  setValue,
  hidePopover
}: RhsFormulaFieldProps) => {
  const [nodes] = useAtom(decisionTableNodesAtom);

  const currentNode = nodes?.[nodeId];

  const nodeType = currentNode?.nodeType;
  const dataType = currentNode?.dataType;

  const { openWithProps: openJsonEditor } = useLayer(
    <JsonNodeSheet
      nodeId={nodeId}
      name={`${nodeName}.query`}
      control={control}
      type={dataType ?? ''}
    />
  );

  const { openWithProps: openJsFormulaEditor } = useLayer(
    <JsFormulaSheet
      nodeId={nodeId}
      name={`${nodeName}.query`}
      control={control}
      type={'js'}
    />
  );

  const { openWithProps: openExcelFormulaEditor } = useLayer(
    <ExcelFormulaSheet
      nodeId={nodeId}
      name={`${nodeName}.query`}
      control={control}
      type={'js'}
    />
  );

  const getPillName = () => {
    if (nodeType === 'jsFormula') {
      return 'JS Code';
    } else if (nodeType === 'excelFormula') {
      return 'Formula';
    } else {
      if (dataType === 'list') {
        return 'List';
      } else if (dataType === 'json') {
        return 'Json';
      }
    }

    return '-';
  };

  const handleClick = () => {
    if(!_isNil(hidePopover)){
      hidePopover();
    }
    if (nodeType === 'jsFormula') {
      openJsFormulaEditor({
        nodeId,
        executedValueName: `${nodeName}.value`,
        returnTypeName: `${nodeName}.returnType`,
        setValue,
        type: 'js',
        onClose: () => {
          if (typeof setValue === 'function') {
            setValue(`${nodeName}.attribute`, null);
            setValue(`${nodeName}.sourceType`, null);
          }
        },
      });
    } else if (nodeType === 'excelFormula') {
      openExcelFormulaEditor({
        nodeId,
        executedValueName: `${nodeName}.value`,
        returnTypeName: `${nodeName}.returnType`,
        setValue,
        type: 'js',
        onClose: () => {
          if (typeof setValue === 'function') {
            setValue(`${nodeName}.attribute`, null);
            setValue(`${nodeName}.sourceType`, null);
          }
        },
      });
    } else {
      openJsonEditor({
        nodeId,
        executedValueName: `${nodeName}.value`,
        returnTypeName: `${nodeName}.returnType`,
        setValue,
        type: dataType,
        onClose: () => {
          if (typeof setValue === 'function') {
            setValue(`${nodeName}.attribute`, null);
            setValue(`${nodeName}.sourceType`, null);
          }
        },
      });
    }
  };

  return (
    <Inline
      align="center"
      gutter={8}
      as={PillStyled}
      onClick={handleClick}
      padding={[0, '0.6rem']}
    >
      <Typography>{getPillName()}</Typography>
      <MdEdit />
    </Inline>
  );
};
