import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _reduce from 'lodash/reduce';
import { createRef, useEffect, useState } from 'react';
import { Link, PopoverMethods, ProgressBar, Typography, useLayer } from 'ui';

import { useAxiosPrivate } from '../../hooks';
import { useSendEventToGTM } from '../../hooks/useSendEventToGTM';
import { VideoPopup } from '../../pages/Guides/videoPopup';
import { OnboardingSteps } from '../../pages/Home/components/OnboardingSteps';
import { initialOnboardingData } from '../../pages/Home/constant';
import {
  useGetFirstCreatedRule,
  useGetFirstCreatedWorkflow,
} from '../../pages/Home/hooks/useGetHomePageData';
import {
  CompletedTaskProps,
  OnboardingDataProps,
} from '../../pages/Home/models';
import { PageModel } from '../../pages/Questionnaire/models';
import { updateSubTaskCompletion, useGetQuestionnaire } from '../../utils/common';
import { envMap } from '../../utils/constant';
import {
  CenterdText,
  ResourceBlock,
  ResourceItem,
  ResourceTitle,
  StepperContainer,
  StepperLeftContainer,
  StepperRightContainer,
  StepperStepsHeaderBlock,
  StepsCompletionContainer,
  VideoThumbnail,
} from './StepperSteps.styled';
import { HomePageVideoBlock, NectedResources } from './data';

const deploymentType = envMap.VITE_DEPLOYMENT_TYPE;
export function StepperSteps() {
  const popoverMethods = createRef<PopoverMethods>();
  const { axiosPrivate } = useAxiosPrivate();
  const [onboardingData, setOnboardingData] = useState<OnboardingDataProps>({
    ...initialOnboardingData,
  });
  const { openWithProps: OpenVideoPopup } = useLayer(<VideoPopup />);
  const { sendEventToGTM } = useSendEventToGTM();
  const [stepsCount, setStepsCount] = useState<number>(0);
  const [firstEntityData, setFirstEntityData] = useState<any>(null);
  const [isQuestionnaireInProgress, setIsQuestionnaireInProgress] =
    useState<boolean>(false);
  const [questionnaireData, setQuestionnaireData] = useState<PageModel[]>([]);
  const [isApiTriggered, setIsApiTriggered] = useState<null | boolean>(null);
  const [
    getFirstCreatedRule,
    { data: firstCreateRuleData, loading: isRuleDataInProgress },
  ] = useGetFirstCreatedRule();

  const [
    getFirstCreatedWorkflow,
    { data: firstCreatedWorkflowData, loading: isWorkflowDataInProgress },
  ] = useGetFirstCreatedWorkflow();

  const obstate = window.atob(window.localStorage.getItem('obstate') ?? '');

  const isApiTriggeredCall = async () => {
    const response = await axiosPrivate.get(
      `/audit/audit/search?pageNo=1&pageSize=1&event=api&environment=staging,production`
    );

    return !_isNil(response.data.data);
  };

  const fetchQuestionareData = async () => {
    const data = await useGetQuestionnaire(axiosPrivate);
    const isAPITriggered = await isApiTriggeredCall();
    sendEventToGTM({
      event: 'rule_executed',
      status: 'success',
      action: 'api_called',
    });
    setIsApiTriggered(isAPITriggered);
    setQuestionnaireData(data.data);
    setIsQuestionnaireInProgress(data.isLoading);
  };

  useEffect(() => {
    if (deploymentType !== 'onpremise') {
      void getFirstCreatedRule();
      void getFirstCreatedWorkflow();
    }

    void fetchQuestionareData();
  }, []);

  useEffect(() => {
    if (!_isNil(firstCreateRuleData) && !_isNil(firstCreatedWorkflowData)) {
      const homepageData = {
        rule: firstCreateRuleData,
        workflow: firstCreatedWorkflowData,
      };
      window.sessionStorage.setItem(
        'homepageData',
        JSON.stringify(homepageData)
      );
      setFirstEntityData(homepageData);
    } else if (
      !isRuleDataInProgress &&
      !isWorkflowDataInProgress &&
      !_isNil(window.sessionStorage?.homepageData)
    ) {
      setFirstEntityData(
        JSON.parse(
          window.sessionStorage.homepageData === 'undefined'
            ? '{}'
            : window.sessionStorage.homepageData
        )
      );
    }
  }, [
    JSON.stringify(firstCreateRuleData),
    JSON.stringify(firstCreatedWorkflowData),
  ]);

  useEffect(() => {
    if (
      !_isNil(questionnaireData) &&
      !_isNil(firstEntityData) &&
      !_isNil(isApiTriggered)
    ) {
      const updatedOnboardingData: OnboardingDataProps =
        structuredClone(onboardingData);

      const isQuestionnaireStepIncomplete = questionnaireData.some(
        ({ questions }) => {
          return questions.some(({ value }) => _isEmpty(value));
        }
      );

      const updatedObstate =
        obstate !== 'undefined' && !_isEmpty(obstate)
          ? JSON.parse(obstate)
          : {};
      updatedObstate.onboarding_completed = !isQuestionnaireStepIncomplete;
      updatedObstate.configure_api_scheduler = isApiTriggered;

      const obstateArray: CompletedTaskProps[] = Object.keys(
        updatedObstate
      ).map((key: string) => ({
        value: updatedObstate[
          key as keyof typeof obstate
        ] as unknown as boolean,
        id: key,
      }));

      setOnboardingData(
        updateSubTaskCompletion(
          updatedOnboardingData,
          obstateArray,
          firstEntityData
        )
      );
    }
  }, [
    JSON.stringify(firstEntityData),
    JSON.stringify(questionnaireData),
    isApiTriggered,
  ]);

  useEffect(() => {
    // if all onboardingData.subTasks are isCompleted true then update the isCompleted of onboardingData to true then update the onboardingData
    // also count the number of steps completed and update the stepsCount

    const count = _reduce(
      onboardingData,
      (acc, { subTasks }) => {
        const subTaskCount = _reduce(
          subTasks,
          (subAcc, { isCompleted }) => {
            return isCompleted ? subAcc + 1 : subAcc;
          },
          0
        );

        return subTaskCount === subTasks.length ? acc + 1 : acc;
      },
      0
    );
    setStepsCount(count);
  }, [JSON.stringify(onboardingData)]);

  const handleCloseButton = () => {
    window.localStorage.setItem('ssteps', 'hide');
    popoverMethods.current?.hide();
  };

  return (
    <Inline gutter={2}>
      <StepperLeftContainer>
        <Inline as={StepperStepsHeaderBlock}>
          <Typography name="paragraph">
            Here are the steps that would help you get started
          </Typography>
          <Stack as={StepperContainer} padding="1rem" gutter="1rem">
            <CenterdText name="success">
              {stepsCount}/{Object.keys(onboardingData).length} Steps Complete
            </CenterdText>
            <StepsCompletionContainer>
              <ProgressBar
                appearance="seaGreen"
                fill={(stepsCount / Object.keys(onboardingData).length) * 100}
              />
            </StepsCompletionContainer>
          </Stack>
        </Inline>

        <Stack gutter="1.5rem">
          <OnboardingSteps
            isQuestionnaireInProgress={isQuestionnaireInProgress}
            isRuleDataInProgress={isRuleDataInProgress}
            onboardingData={onboardingData}
            closeMenu={handleCloseButton}
          />
        </Stack>
      </StepperLeftContainer>

      <StepperRightContainer padding="1rem">
        <Stack gutter={'2rem'}>
          <ResourceBlock>
            <ResourceTitle fontWeight={700}>
              {HomePageVideoBlock.name}
            </ResourceTitle>
            <Stack
              gutter={5}
              onClick={() => OpenVideoPopup({ videoObj: HomePageVideoBlock })}
            >
              <VideoThumbnail
                alt="video-thumbnail"
                src={HomePageVideoBlock.thumbnail}
              />
            </Stack>
          </ResourceBlock>
          <ResourceBlock>
            <ResourceTitle fontWeight={700}>Resources</ResourceTitle>
            <Stack gutter={5}>
              {NectedResources.map((i, index) => {
                return (
                  <ResourceItem key={index}>
                    <img src={`${envMap.VITE_ASSETS_URL}${i.icon}`} />
                    <Typography name="paragraph" fontWeight={400}>
                      <Link
                        id={i.id}
                        key={index}
                        to={i.link}
                        target={i.target}
                        bold={false}
                      >
                        {i.title}
                      </Link>
                    </Typography>
                  </ResourceItem>
                );
              })}
            </Stack>
          </ResourceBlock>
        </Stack>
      </StepperRightContainer>
    </Inline>
  );
}
