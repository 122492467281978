import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { useWatch } from 'react-hook-form';

import { sendEventToGTMType } from '../../../types';
import { decisionTableNodesAtom } from '../DecisionTable';
import { ExcelNodePill } from '../TableNodes/ExcelNodePill';
import { JsNodePill } from '../TableNodes/JsNodePill';
import { OperatorSelection } from '../TableNodes/OperatorSelection';
import { RhsParamPopover } from '../TableNodes/RhsNode';
import { DecisionTableConditionStructure } from '../models';
import { ConditionBlockContainer } from './RowsFieldArray.styled';

type ConditionFieldArrayProps = Omit<UseControllerProps, 'name'> & {
  setValue: UseFormSetValue<any>;
  index: number;
  rowKey: string;
  isError: boolean;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
};

export function ConditionFieldArray({
  control,
  setValue,
  index,
  rowKey,
  isError,
  handleSendEventToGTM,
}: ConditionFieldArrayProps) {
  const [nodes, setNodes] = useAtom(decisionTableNodesAtom);

  const currentCondition: DecisionTableConditionStructure[] = useWatch({
    name: `rows.${index}.${rowKey}.condition`,
    control,
  });

  return (
    <Inline
      style={{ backgroundColor: isError ? 'var(--color-snow)' : '' }}
      align="stretch"
      gutter={0}
    >
      {currentCondition?.map((field, conditionIndex: number) => {
        const currentConditionKey = Object.keys(field).filter(
          (key) => key !== 'id' && key !== 'rhs'
        )[0];

        const padding = '0.8rem 1.5rem';
        const currentProperty = nodes[currentConditionKey]?.leftNode?.[0];

        const hasSwitchField = ['date', 'dateTime'].includes(
          field[currentConditionKey].type
        );

        let hasRelativeField = false;

        field.rhs?.forEach((rhs) => {
          const rhsKey = Object.keys(rhs).filter((key) => key !== 'id')[0];
          const rhsValue = nodes[rhsKey]?.value;

          if (
            typeof rhsValue === 'object' &&
            !Array.isArray(rhsValue) &&
            !_isNil(rhsValue)
          ) {
            hasRelativeField = true;
          }
        });

        return (
          <ConditionBlockContainer
            key={`condition_${conditionIndex}`}
            hasSwitchField={hasSwitchField}
            hasRelativeField={hasRelativeField}
            padding={padding}
          >
            <Inline gutter={8} align={'center'}>
              {!['jsCondition', 'excelCondition'].includes(
                field[currentConditionKey].type
              ) && (
                <>
                  <OperatorSelection
                    nodeCondition={nodes[currentConditionKey]}
                    nodeCurrentProperty={nodes[currentProperty ?? '']}
                    setNodes={setNodes}
                    conditionIndex={conditionIndex}
                    conditionKey={currentConditionKey}
                    index={index}
                    rowKey={rowKey}
                    setValue={setValue}
                    control={control}
                    handleSendEventToGTM={handleSendEventToGTM}
                  />

                  <Stack>
                    {field.rhs?.map((rhs, rhsIndex: number) => {
                      const rhsKey = Object.keys(rhs).filter(
                        (key) => key !== 'id'
                      )[0];

                      return (
                        <RhsParamPopover
                          key={rhsKey}
                          conditionKey={currentConditionKey}
                          groupKey={rowKey}
                          nodeKey={rhsKey}
                          setValue={setValue}
                          control={control}
                          conditionIndex={conditionIndex}
                          groupIndex={index}
                          rhsIndex={rhsIndex}
                          handleSendEventToGTM={handleSendEventToGTM}
                          leftNodeId={currentProperty}
                        />
                      );
                    })}
                  </Stack>
                </>
              )}

              {field[currentConditionKey]?.type === 'jsCondition' && (
                <JsNodePill
                  ruleId={currentConditionKey}
                  setValue={setValue}
                  control={control}
                  index={conditionIndex}
                  rowIndex={index}
                  rowKey={rowKey}
                  handleSendEventToGTM={handleSendEventToGTM}
                />
              )}

              {field[currentConditionKey]?.type === 'excelCondition' && (
                <ExcelNodePill
                  ruleId={currentConditionKey}
                  setValue={setValue}
                  control={control}
                  index={conditionIndex}
                  rowIndex={index}
                  rowKey={rowKey}
                  handleSendEventToGTM={handleSendEventToGTM}
                />
              )}
            </Inline>
          </ConditionBlockContainer>
        );
      })}
    </Inline>
  );
}
