import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useRef, useState } from 'react';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import { BiExpandAlt } from 'react-icons/bi';
import {
  CheckboxField,
  formatNectedDate,
  OnClickRuleArgs,
  PopoverMethods,
  PopoverPanel,
  RoundButton,
  TextAreaField,
  TextField,
  TextInput,
  Typography,
  useLayer,
} from 'ui';

import { predefineTokenDatasetAtom } from '../../../../atom';
import {
  dataSetFieldsByIdAtom,
  selectedDataSetAtom,
} from '../../../../pages/Rules';
import { JsonNodePill } from '../../../../pages/Rules/components/SimpleRule/Results/JsonNodePill';
import type { SelectedType } from '../../../../pages/Rules/types';
import {
  convertArrayToString,
  getFieldValueAsArray,
  isFieldReadOnly,
} from '../../../../utils/common';
import { CalenderIconPicker } from '../../../CalenderIconPicker/CalenderIconPicker';
import { TextInputModal } from '../../../Modals/TextInputModal/TextInputModal';
import { TokenSelectionPopover } from '../../../TokenComponents/TokenSelectionPopover/TokenSelectionPopover';
import { isValidSchemaId } from '../../../TokenComponents/utils/helperFunction';
import type { TokensSetProps } from './CustomAttributeSheet';
import { DateFormatMap } from 'ui/src/Form/DateTimePickerInput/constant';

type SampleValueFieldProps = UseControllerProps<any> & {
  index: number;
  setValue: UseFormSetValue<any>;
  isDisabled: boolean;
  tokensSet?: TokensSetProps[];
  from?: 'workflow' | 'rule';
};

export function SampleValueField({
  index,
  control,
  setValue,
  isDisabled,
  name,
  tokensSet,
  from = 'rule',
}: SampleValueFieldProps) {
  const ref = useRef<PopoverMethods>(null);

  const [DSSelectedPropery, setDSSelectedPropery] = useState<
    string | boolean | number | Record<string, any>
  >('string');
  const [isFocus, setIsFocus] = useState(false);
  const [dataSetFieldsById] = useAtom(dataSetFieldsByIdAtom);
  const [selectedDataSets] = useAtom(selectedDataSetAtom);
  const [predefineTokenDataset] = useAtom(predefineTokenDatasetAtom);

  const propertyName = `attributes.${index}.attribute`;
  const sourceTypeName = `attributes.${index}.sourceType`;
  const datasetProperty = useWatch({ control, name: propertyName });
  const sourceType = useWatch({ control, name: sourceTypeName });
  const selectedType: SelectedType | null = useWatch({
    name: `attributes.${index}.selectedType`,
    control,
  });

  const sampleValue = useWatch({
    control,
    name,
  });

  const schemaId = useWatch({
    control,
    name: `attributes.${index}.schemaId`,
  });

  const isModuleSchemaField =
    !_isNil(schemaId) &&
    !_isEmpty(schemaId) &&
    isValidSchemaId(predefineTokenDataset, schemaId);

  const { openWithProps: openTextInputModal } = useLayer(<TextInputModal />);

  const dataSetOptions =
    !_isNil(selectedDataSets[0]) &&
    !_isNil(dataSetFieldsById[selectedDataSets[0]])
      ? dataSetFieldsById[selectedDataSets[0]].fields.filter(
          (field) => field.type !== 'json'
        )
      : [];

  useEffect(() => {
    if (
      !_isNil(datasetProperty) &&
      !_isEmpty(datasetProperty) &&
      sourceType === 'dataSet'
    ) {
      const filteredObj = dataSetOptions.find(
        (option) => option.name === datasetProperty
      );

      if (!_isNil(filteredObj)) {
        setDSSelectedPropery(filteredObj.type);
      }
    }
  }, [datasetProperty, sourceType]);

  useEffect(() => {
    if (from === 'rule') {
      setValue(`attributes.${index}.executedValue`, sampleValue);
    }
  }, [sampleValue, from]);

  const getPlaceholder = (dataType: string) => {
    const placeholderString = 'Enter Value';

    if (dataType === 'date') {
      return DateFormatMap[window.sessionStorage.getItem('nected-df') ?? 'in'].date;
    }

    if (dataType === 'dateTime') {
      return DateFormatMap[window.sessionStorage.getItem('nected-df') ?? 'in'].dateTime;
    }

    return placeholderString;
  };

  const getComponent = () => {
    if (_isNil(selectedType)) {
      return <TextInput placeholder="NA" disabled />;
    }

    if (selectedType.value === 'boolean' || DSSelectedPropery === 'boolean') {
      return (
        <CheckboxField
          name={name}
          useId={name}
          control={control}
          disabled={isDisabled}
          appearance="switch"
        />
      );
    }

    if (selectedType.value === 'numeric' || DSSelectedPropery === 'numeric') {
      return (
        <TextField
          name={name}
          control={control}
          placeholder="Enter Value"
          readOnly={isDisabled}
          type="number"
          showErrorIcon={false}
        />
      );
    }

    if (selectedType.value === 'json' || selectedType.value === 'list') {
      let value = sampleValue;

      if (!_isNil(sampleValue)) {
        if (selectedType.value === 'list') {
          value = convertArrayToString(getFieldValueAsArray(sampleValue));
        } else if (selectedType.value === 'json') {
          if (typeof sampleValue === 'string') {
            value = JSON.parse(sampleValue);
          }

          value = JSON.stringify(value);
        }
      }

      return (
        <Inline
          align="center"
          justify="center"
          gutter={8}
          style={{
            backgroundColor: 'var(--color-white)',
            borderRadius: '4px',
            border: '1px solid var(--color-gainsboro)',
            padding: '0.8rem 1.6rem',
          }}
        >
          <div
            style={{
              width: '10rem',
              height: '2rem',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {!_isNil(value) ? (
              value
            ) : (
              <Typography name="secondarySmall">Enter Value</Typography>
            )}
          </div>
          <JsonNodePill
            control={control}
            name={name}
            setOriginalValue={setValue}
            hideSuggestions
            size={'medium'}
            isJsonInCustomInput={true}
            type={selectedType.value}
            disabled={isDisabled}
          />
        </Inline>
      );
    }

    if (selectedType.key === 'restAPI') {
      return null;
    }

    if (
      (selectedType.value === 'string' ||
        (selectedType.key === 'dataSet' && DSSelectedPropery === 'string')) &&
      from === 'rule'
    ) {
      return (
        <Inline align="center" gutter={8}>
          <TextAreaField
            name={name}
            control={control}
            placeholder="Enter Value"
            rows={isFocus ? 4 : 2}
            size="xs"
            disabled={isDisabled}
            onFocus={() => {
              setIsFocus(true);
            }}
            onBlur={() => {
              setTimeout(() => {
                setIsFocus(false);
              }, 200);
            }}
          />
          <div>
            <RoundButton
              onClick={() => {
                openTextInputModal({
                  onSubmit: (val: Record<string, any>) => {
                    setValue(name, val.value);
                  },
                  value: sampleValue,
                });
              }}
              type="button"
            >
              <BiExpandAlt />
            </RoundButton>
          </div>
        </Inline>
      );
    }

    return (
      <TextField
        name={name}
        control={control}
        placeholder={getPlaceholder(selectedType.value ?? '')}
        readOnly={isDisabled || isFieldReadOnly(selectedType.value ?? '')}
        showExpandIcon={false}
        icon={
          ['dateTime', 'date'].includes(selectedType.value ?? '') ? (
            <CalenderIconPicker
              value={sampleValue}
              disabled={isDisabled}
              dataType={selectedType.value ?? 'date'}
              onPick={(val) => {
                if (typeof setValue === 'function') {
                  setValue(
                    `attributes.${index}.sampleValue`,
                    formatNectedDate(val, selectedType.value ?? 'date')
                  );

                  setValue(
                    `attributes.${index}.executedValue`,
                    formatNectedDate(val, selectedType.value ?? 'date')
                  );
                }
              }}
            />
          ) : undefined
        }
      />
    );
  };

  const handlePredefineValueSelection = (data: OnClickRuleArgs) => {
    if (data.dataType === 'boolean') {
      setValue(name, data.executedValue === 'true');
    } else {
      setValue(name, data.executedValue);
    }

    ref.current?.hide();
  };

  return (
    <div>
      {isModuleSchemaField ? (
        <PopoverPanel
          trigger="click"
          placement="bottom-start"
          launcher={
            <div style={{ minBlockSize: '3rem' }}>{getComponent()}</div>
          }
          ref={ref}
          padding="8px"
          disabled={false}
        >
          <div>
            <TokenSelectionPopover
              selectedSchemaId={schemaId}
              tokenDataset={predefineTokenDataset}
              handleValueSelection={handlePredefineValueSelection}
              tabsToShow={['predefined']}
              typesToAllow={[selectedType?.value ?? 'string']}
              showTooltip={['json', 'list'].includes(
                selectedType?.value ?? 'string'
              )}
            />
          </div>
        </PopoverPanel>
      ) : (
        <>{getComponent()}</>
      )}
    </div>
  );
}
