import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import AceEditor from 'react-ace';
import { JsonHeroTreeViewer } from 'ui';

import { JsonInTableView } from '../../../../../components/JsonInTableView/JsonInTableView';
import { ExecutionView } from '../../../../../components/jsonOutputField/components/ExecutionView';
import { JsonTreeContainer, OutputContainer } from './SwitchNodeSheet.styled';

type SwitchNodeTestProps = {
  outputValue?: Record<string, any>;
};

export function SwitchNodeTest({
  outputValue = {
    _comment: 'Please test the node first',
  },
}: SwitchNodeTestProps) {
  return (
    <PadBox padding={8}>
      <OutputContainer>
        <Stack gutter="1rem">
          <ExecutionView>
            <AceEditor
              className="json-result-readonly"
              mode="json"
              theme="chrome"
              width="100%"
              fontSize={12}
              showPrintMargin={false}
              highlightActiveLine={true}
              showGutter={true}
              setOptions={{
                useWorker: false,
                showLineNumbers: true,
                tabSize: 2,
              }}
              readOnly
              value={JSON.stringify(outputValue, null, 2)}
            />
            <JsonTreeContainer>
              <JsonHeroTreeViewer json={outputValue} />
            </JsonTreeContainer>

            <JsonTreeContainer>
              <JsonInTableView json={{ output: outputValue?.output ?? null }} />
            </JsonTreeContainer>
          </ExecutionView>
        </Stack>
      </OutputContainer>
    </PadBox>
  );
}
