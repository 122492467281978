import { Inline } from '@bedrock-layout/primitives';
import styled, { css } from 'styled-components';

type InputStyledProps = {
  /** Error to change background-color and border */
  hasError: boolean;
  hasIcon: boolean;
  hasStartIcon: boolean;
  isSmallInput: boolean;
  showOutline: boolean;
  showExpandIcon: boolean;
};

const outlineNoneCssRule = css`
  border: none;
  outline: none;
`;

const disabledCssRules = css`
  cursor: default;
  background-color: var(--color-solitude);
`;

export const InputStyled = styled.input<InputStyledProps>`
  border-radius: 4px;
  border: 1px solid var(--color-gainsboro);
  font-size: 1.4rem;
  max-width: 100%;
  width: 100%;

  padding: ${({ hasIcon, isSmallInput, hasStartIcon, showExpandIcon }) =>
    !isSmallInput
      ? hasIcon && hasStartIcon
        ? '0.8rem 2.8rem 0.8rem 2.8rem'
        : hasIcon
        ? '0.8rem 2.8rem 0.8rem 1.6rem'
        : hasStartIcon
        ? '0.8rem 1.6rem 0.8rem 2.8rem'
        : showExpandIcon
        ? '0.8rem 3.6rem 0.8rem 0.8rem'
        : '0.8rem 1.6rem'
      : hasIcon
      ? '0.4rem 2.8rem 0.4rem 0.8rem'
      : hasStartIcon
      ? '0.4rem 0.8rem 0.4rem 2.8rem'
      : showExpandIcon
      ? '0.4rem 3.6rem 0.4rem 0.8rem'
      : '0.4rem 0.8rem'};

  outline: ${({ hasError }) =>
    `1px solid ${
      hasError ? 'var(--color-fireEngineRed)' : 'var(--color-solitude)'
    }`};
  background-color: ${({ hasError }) =>
    hasError ? 'var( --color-snow)' : 'var(--color-white)'};

  &:focus {
    outline: 1px solid
      ${({ hasError }) =>
        hasError ? 'var(--color-fireEngineRed)' : 'var(--color-darkGray)'};
  }

  :disabled {
    ${disabledCssRules}
  }
  ${({ showOutline }) =>
    !showOutline &&
    css`
      ${outlineNoneCssRule}
      &:focus {
        ${outlineNoneCssRule}
      }
    `}
`;

export const InputAndIconStyled = styled(Inline)`
  position: relative;
  min-width: 100%;
`;

export const InputIconContainerStyled = styled.span`
  position: absolute;
  inset-inline-end: 0.4rem;
  cursor: pointer;
  line-height: 1;
  top: 50%;
  transform: translateY(-50%);
`;

export const ExpandIconContainer = styled.span`
  inline-size: 2rem;
  block-size: 2rem;
  background-color: var(--color-whiteSmoke);
  border-radius: 0.2rem;
  position: absolute;
  inset-inline-end: 0.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputStartIconContainerStyled = styled.span`
  position: absolute;
  inset-inline-start: 0.4rem;
  cursor: pointer;
`;
