import { Stack } from '@bedrock-layout/stack';
import { useExpandedNodes } from 'hooks';
import _isNil from 'lodash/isNil';
import {
  ChangeEvent,
  ReactNode,
  Ref,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import { CiSearch } from 'react-icons/ci';

import { TextInput } from '../../Form/TextInput';
import { Dataset } from '../../TreeViewer/TreeViewer';
import { RuleNodes } from './RuleNodes';
import { RuleSearchContainer } from './RulePopover.styled';

export type DataTypes =
  | 'date'
  | 'datetime'
  | 'numeric'
  | 'string'
  | 'boolean'
  | 'popup'
  | 'dateTime'
  | 'jsCondition'
  | 'excelCondition'
  | 'dataSet'
  | 'json'
  | 'list'
  | 'restAPI'
  | 'bigint'
  | 'unknown'
  | 'excelFormula'
  | 'jsFormula'
  | 'nected'
  | '';

export type Attributes = {
  id?: string;
  name: string;
  dataType: DataTypes;
  sampleValue?: any;
  executedValue?: any;
  options?: any[];
  usageType?: string;
};

export type OnClickRuleArgs = {
  value: string;
  key: string;
  dataType: string;
  executedValue?: any;
};

export type RulePopoverProps = {
  dataset: Record<string, Dataset>;
  onClick?: ({ value, key, dataType, executedValue }: OnClickRuleArgs) => void;
  onChange?: ({ value, key, dataType, executedValue }: OnClickRuleArgs) => void;
  disabled?: boolean;
  footer?: ReactNode;
  allowList?: boolean;
  typesToAllow?: string[];
  onlyJson?: boolean;
  showTooltip?: boolean;
  version?: 'v1' | 'v2';
  header?: ReactNode;
  isExpanded?: boolean;
  idsToExpand?: string[];
  idsToNotExpand?: string[];
  showFooter?: boolean;
  placeholder?: string;
  infoComponent?: ReactNode;
  showMultiSelect?: boolean;
  selectedCheckboxData?: Record<string, OnClickRuleArgs>;
  setSelectedCheckboxData?: (data: Record<string, OnClickRuleArgs>) => void;
  restrictNestedNodeSelection?: boolean;
};

export const RulePopover = forwardRef(
  (
    {
      dataset,
      onClick,
      onChange,
      disabled = false,
      footer,
      allowList = false,
      typesToAllow,
      onlyJson = false,
      showTooltip = false,
      version = 'v1',
      header,
      isExpanded = true,
      idsToExpand = [],
      idsToNotExpand = [],
      showFooter = true,
      placeholder = 'Search Attributes',
      infoComponent,
      showMultiSelect,
      selectedCheckboxData,
      setSelectedCheckboxData,
      restrictNestedNodeSelection = false,
    }: RulePopoverProps,
    ref: Ref<HTMLElement>
  ) => {
    const [expandedNodes, toggleNode] = useExpandedNodes({
      isExpanded,
      idsToExpand,
      idsToNotExpand,
    });
    const [searchInput, setSearchInput] = useState<string>('');

    const element = document.getElementById('rule-popover-container');

    const leafNodesElement = element?.querySelectorAll('[id=nodeLeaf]');
    const leafNodesTextElement = element?.querySelectorAll('[id=nodeLeafText]');

    useEffect(() => {
      if (!_isNil(leafNodesElement) && !_isNil(leafNodesTextElement)) {
        for (let x = 0; x < leafNodesElement.length; x++) {
          const text = (leafNodesTextElement[x] as HTMLElement).innerText;

          if (text.toLowerCase().includes(searchInput.toLowerCase())) {
            (leafNodesElement[x] as HTMLElement).style.display = 'flex';
          } else {
            (leafNodesElement[x] as HTMLElement).style.display = 'none';
          }
        }
      }
    }, [searchInput]);

    return (
      <Stack gutter={10} id="rule-popover-container">
        {!_isNil(header) && header}
        {Object.keys(dataset).length !== 0 && (
          <RuleSearchContainer>
            <TextInput
              type="text"
              placeholder={placeholder}
              icon={<CiSearch size={18} color="var(--color-suvaGray)" />}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setSearchInput(e.target.value);
              }}
              value={searchInput}
              disabled={disabled}
            />
          </RuleSearchContainer>
        )}
        {!_isNil(infoComponent) && infoComponent}
        <RuleNodes
          dataset={dataset}
          parentNodeClickHandler={toggleNode}
          allowList={allowList}
          typesToAllow={typesToAllow}
          version={version}
          onClick={(value, key, dataType, executedValue) => {
            // This check is used when this component is used via Form Manager
            if (typeof onChange === 'function') {
              onChange({ value, key, dataType, executedValue });
            }

            // This check is used when this component is directly used.
            // ( Without form Manager)
            if (typeof onClick === 'function') {
              onClick({ value, key, dataType, executedValue });
            }
          }}
          expandedNodes={expandedNodes}
          searchInput={searchInput}
          searchedLeaves={[]}
          onlyJson={onlyJson}
          showTooltip={showTooltip}
          restrictNestedNodeSelection={restrictNestedNodeSelection}
          showMultiSelect={showMultiSelect}
          selectedCheckboxData={selectedCheckboxData}
          onCheckboxClick={(e, value, key, dataType, executedValue) => {
            if (typeof setSelectedCheckboxData === 'function') {
              if (e.target.checked) {
                setSelectedCheckboxData({
                  ...selectedCheckboxData,
                  [`${key}_${value}`]: {
                    value,
                    key,
                    dataType,
                    executedValue,
                  },
                });
              } else {
                const updatedList = structuredClone(selectedCheckboxData ?? {});
                // eslint-disable-next-line
                delete updatedList[`${key}_${value}`];

                setSelectedCheckboxData(updatedList);
              }
            }
          }}
        />
        {!_isNil(footer) && showFooter && footer}
      </Stack>
    );
  }
);

RulePopover.displayName = 'RulePopover';
