import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  UseControllerProps,
  UseFormSetValue,
  useController,
} from 'react-hook-form';
import {
  Dataset,
  OnClickRuleArgs,
  PopoverMethods,
  PopoverPanel,
  RuleField,
  Typography,
  useLayer,
} from 'ui';

import { predefineTokenDatasetAtom } from '../../../../../atom';
import { DateSwitcher } from '../../../../../components/RelativeDateComponent/DateSwitcher';
import { RelativeDateFields } from '../../../../../components/RelativeDateComponent/RelativeDateFields';
import {
  TokenSelectionPopover,
  TokenSelectionType,
  defaultTokenTabsToShow,
} from '../../../../../components/TokenComponents/TokenSelectionPopover/TokenSelectionPopover';
import { getUsedSchemaAttributes } from '../../../../../components/TokenComponents/utils/helperFunction';
import { customAttributesAtom } from '../../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import {
  extractSourceAndAttributeFromValue,
  getInitalValueifRelativeFieldActive,
  isArrayNotPresent,
} from '../../../../../utils/common';
import { EXTRACT_TOKEN_REGEX } from '../../../../../utils/regex';
import { isRuleReadOnlyAtom, selectedDataSetAtom } from '../../../index';
import { sendEventToGTMType } from '../../../types';
import {
  getDataSetByType,
  getRhsNodeTitle,
  getSchemaIdForNode,
  getTypesToAllowForConditionNodes,
  removeCustomFunction,
  updateDataSetOnChange,
} from '../../../utils/common';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { decisionTableNodesAtom } from '../DecisionTable';
import { ExcelFormulaSheet } from '../RhsFormulaField/ExcelFormulaSheet';
import { JsFormulaSheet } from '../RhsFormulaField/JsFormulaSheet';
import { RhsFormulaField } from '../RhsFormulaField/RhsFormulaField';
import { ResultHeader } from '../components/ResultHeader';
import { RhsLauncher } from './RhsLauncher';
import { RhsInputContainer, RhsStackContainer } from './RhsNode.styled';

type RhsParamsPopoverProps = Omit<UseControllerProps<any>, 'name'> & {
  groupIndex: number;
  conditionIndex: number;
  rhsIndex: number;
  nodeKey: string;
  groupKey: string;
  conditionKey: string;
  setValue: UseFormSetValue<any>;
  onFocus?: () => void;
  onBlur?: () => void;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
  leftNodeId?: string;
};

export const RhsParamPopover = ({
  groupIndex,
  conditionIndex,
  rhsIndex,
  nodeKey,
  groupKey,
  conditionKey,
  control,
  setValue,
  onFocus,
  onBlur,
  handleSendEventToGTM,
  leftNodeId = '',
}: RhsParamsPopoverProps) => {
  const [nodes, setNodes] = useAtom(decisionTableNodesAtom);
  const [dataset] = useAtom(dataSetParamsAtom);
  const [dataSetSelected] = useAtom(selectedDataSetAtom);
  const [customAttributes] = useAtom(customAttributesAtom);

  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [predefineTokenDataset] = useAtom(predefineTokenDatasetAtom);

  const [tokenSelectionTabsToShow, setTokenSelectionTabsToShow] = useState(
    defaultTokenTabsToShow
  );
  const [isSchemaMandatory, setIsSchemaMandatory] = useState(false);

  const [panelVisible, setPanelVisible] = useState(false);
  // eslint-disable-next-line
  const [isActiveDate, setIsActiveDate] = useState(true);
  const [, setFilteredDataSet] = useState(dataset);

  const nodeName = `rows.${groupIndex}.${groupKey}.condition.${conditionIndex}.rhs.${rhsIndex}.${nodeKey}`;

  const dataType = !_isUndefined(nodes[conditionKey]?.dataType)
    ? nodes[conditionKey].dataType
    : '';

  const value =
    !_isUndefined(nodes[nodeKey]) && !_isUndefined(nodes[nodeKey].value)
      ? nodes[nodeKey].value
      : {};

  const selectedOperator = !_isUndefined(nodes[conditionKey]?.operator)
    ? nodes[conditionKey].operator
    : '';

  const nodeType =
    !_isUndefined(nodes[nodeKey]) && !_isUndefined(nodes[nodeKey].nodeType)
      ? nodes[nodeKey].nodeType
      : 'constant';

  const currentNodeDataType =
    !_isUndefined(nodes[nodeKey]) && !_isUndefined(nodes[nodeKey].dataType)
      ? nodes[nodeKey].dataType
      : '';

  const schemaId = getSchemaIdForNode(
    nodes[leftNodeId],
    dataset,
    predefineTokenDataset
  );

  const { fieldState } = useController({
    name: nodeName,
    control,
  });

  const { fieldState: durationFieldState } = useController({
    name: `${nodeName}.otherFields.duration`,
    control,
  });

  const { openWithProps: openJsFormulaEditor } = useLayer(
    <JsFormulaSheet
      nodeId={nodeKey}
      name={`${nodeName}.query`}
      control={control}
      type={'js'}
    />
  );

  const { openWithProps: openExcelFormulaEditor } = useLayer(
    <ExcelFormulaSheet
      nodeId={nodeKey}
      name={`${nodeName}.query`}
      control={control}
      type={'js'}
    />
  );

  useEffect(() => {
    if (!_isUndefined(dataType) && !_isEmpty(dataType)) {
      const dataSetWithSameDataType = getDataSetByType(
        dataset,
        dataType,
        dataSetSelected,
        selectedOperator
      );

      setFilteredDataSet(
        updateDataSetOnChange(
          customAttributes,
          dataSetWithSameDataType,
          dataSetSelected,
          true
        )
      );
    }
  }, [nodes[conditionKey], dataset, dataSetSelected]);

  useEffect(() => {
    if (_isNil(schemaId) || _isEmpty(schemaId)) {
      setTokenSelectionTabsToShow(
        defaultTokenTabsToShow.filter((val) => val !== 'predefined')
      );
    } else {
      setTokenSelectionTabsToShow(defaultTokenTabsToShow);
    }
  }, [schemaId]);

  useEffect(() => {
    const usedSchemaObj = getUsedSchemaAttributes(
      predefineTokenDataset,
      schemaId
    );

    if (!_isNil(usedSchemaObj) && !_isNil(usedSchemaObj?.usageType)) {
      setIsSchemaMandatory(usedSchemaObj.usageType === 'mandatory');
    } else {
      setIsSchemaMandatory(false);
    }
  }, [JSON.stringify(predefineTokenDataset), schemaId]);

  const title = (getRhsNodeTitle(nodes, nodeKey, dataset) ?? '') as string;
  const ref = useRef<PopoverMethods>(null);

  const typesToAllow = useMemo(
    () => getTypesToAllowForConditionNodes(dataType, selectedOperator),
    [dataType, selectedOperator]
  );

  const onChangeSpecial = (val: any) => {
    const localNodeType = 'constant';
    let newVal = val;
    try {
      const tokens = (typeof val === 'string' ? val : '').match(
        EXTRACT_TOKEN_REGEX
      );

      if (_isNil(tokens) || _isEmpty(tokens)) {
        newVal = JSON.parse(val);
      }
    } catch {}

    setValue(`${nodeName}.value`, newVal);

    setValue(`${nodeName}.key`, '');
    setValue(`${nodeName}.dataType`, 'list');

    setNodes((prev) => ({
      ...prev,
      [nodeKey]: {
        ...prev[nodeKey],
        nodeType: localNodeType,
        value: newVal,
        dataType: 'list',
        sourceType: '',
        attribute: '',
      },
    }));
  };

  const updateDateConfiguration = (isDateActiveNow: boolean) => {
    if (isDateActiveNow) {
      const isValueOfObject =
        !_isNil(value) && typeof value === 'object' && !Array.isArray(value);

      const { source, attribute } = isValueOfObject
        ? extractSourceAndAttributeFromValue(
            (value.value ?? '') as string,
            dataset
          )
        : {
            source: undefined,
            attribute: undefined,
          };

      setNodes((prev) => ({
        ...prev,
        [nodeKey]: {
          ...prev[nodeKey],
          sourceType: source ?? '',
          attribute: attribute ?? '',
          value:
            _isNil(source) && _isNil(attribute) && isValueOfObject
              ? value.value
              : undefined,
          nodeType:
            !_isNil(source) && !_isNil(attribute) ? 'params' : 'constant',
        },
      }));

      setValue(`${nodeName}.value`, isValueOfObject ? value.value : undefined);
    } else {
      const sourceType = nodes[nodeKey]?.sourceType;
      const attribute = nodes[nodeKey]?.attribute;

      let value = nodes[nodeKey]?.value;

      if (
        !_isNil(sourceType) &&
        !_isEmpty(sourceType) &&
        !_isNil(attribute) &&
        !_isEmpty(attribute)
      ) {
        value = `{{.${sourceType}.${attribute}}}`;
      }

      setNodes((prev) => ({
        ...prev,
        [nodeKey]: {
          ...prev[nodeKey],
          source: undefined,
          attribute: undefined,
          nodeType: 'noCodeFunc',
          value: {
            unit: 'd',
            duration: undefined,
            value,
            subOp: 'next',
            FUNC_NAME: 'relativeDate',
          },
        },
      }));

      setValue(`${nodeName}.value`, {
        unit: 'd',
        duration: '',
        value,
        subOp: 'current',
      });
    }

    setIsActiveDate(isDateActiveNow);
  };

  const handleRelativeFieldClick = (key: string, value: any) => {
    const currNodeValue = nodes[nodeKey].value;
    const relativeDateParams =
      typeof currNodeValue === 'object' && !Array.isArray(currNodeValue)
        ? currNodeValue
        : {};

    if (
      key === 'duration' &&
      typeof value === 'object' &&
      !_isNil(value) &&
      !Array.isArray(value)
    ) {
      const { sourceType, attribute } = value;

      setNodes((prev) => ({
        ...prev,
        [nodeKey]: {
          ...prev[nodeKey],
          source: '',
          attribute: '',
          value: {
            ...relativeDateParams,
            [key]: `{{.${sourceType as string}.${attribute as string}}}`,
          },
        },
      }));
    } else {
      if (key === 'subOp' && value === 'current') {
        setNodes((prev) => ({
          ...prev,
          [nodeKey]: {
            ...prev[nodeKey],
            source: '',
            attribute: '',
            value: {
              ...relativeDateParams,
              duration: undefined,
              [key]: value,
            },
          },
        }));
      } else {
        setNodes((prev) => ({
          ...prev,
          [nodeKey]: {
            ...prev[nodeKey],
            source: '',
            attribute: '',
            value: {
              ...relativeDateParams,
              [key]: value,
            },
          },
        }));
      }
    }
  };

  const isRelativeFieldEnable =
    ['date', 'dateTime'].includes(dataType ?? '') && !isActiveDate;

  const isValueObjectType =
    typeof value === 'object' && !Array.isArray(value) && !_isNil(value);
  const funcName = isValueObjectType ? value.FUNC_NAME : '';

  useEffect(() => {
    setIsActiveDate(!getInitalValueifRelativeFieldActive(nodes[nodeKey]));
  }, [JSON.stringify(nodes[nodeKey])]);

  const showDateSwitchField =
    ['date', 'dateTime'].includes(dataType ?? '') &&
    !['in', 'nin'].includes(selectedOperator ?? '') &&
    !['jsFormula', 'excelFormula'].includes(nodeType);

  const handlePredefineValueSelection = (
    { value, dataType, executedValue }: OnClickRuleArgs,
    type?: TokenSelectionType
  ) => {
    if (type === 'predefined') {
      let fieldsToUpdate: Record<string, any> = {};

      if (isActiveDate) {
        fieldsToUpdate = {
          ...fieldsToUpdate,
          value: executedValue,
          sourceType: undefined,
          attribute: undefined,
          query: undefined,
        };
      } else {
        const currNodeValue = nodes[nodeKey].value;
        const relativeDateParams =
          typeof currNodeValue === 'object' && !Array.isArray(currNodeValue)
            ? currNodeValue
            : {};

        fieldsToUpdate = {
          value: {
            ...relativeDateParams,
            value: executedValue,
          },
          sourceType: undefined,
          attribute: undefined,
          query: undefined,
        };
      }

      setNodes((prev) => ({
        ...prev,
        [nodeKey]: {
          ...prev[nodeKey],
          dataType,
          attribute: value,
          nodeType: isActiveDate ? 'constant' : 'noCodeFunc',
          value: fieldsToUpdate?.value ?? undefined,
          ...fieldsToUpdate,
        },
      }));

      setValue(`${nodeName}.value`, fieldsToUpdate?.value ?? undefined);
    } else if (type === 'customFunction') {
      setNodes((prev) => ({
        ...prev,
        [nodeKey]: {
          ...prev[nodeKey],
          sourceType: undefined,
          attribute: undefined,
          returnType: dataType,
          query: '',
          nodeType: dataType,
          value: undefined,
        },
      }));

      if (dataType === 'jsFormula') {
        openJsFormulaEditor({
          nodeId: nodeKey,
          executedValueName: `${nodeName}.value`,
          returnTypeName: `${nodeName}.returnType`,
          setValue,
          type: 'js',
          onClose: () => {
            if (typeof setValue === 'function') {
              setValue(`${nodeName}.attribute`, null);
              setValue(`${nodeName}.sourceType`, null);
            }
          },
        });
      } else if (dataType === 'excelFormula') {
        openExcelFormulaEditor({
          nodeId: nodeKey,
          executedValueName: `${nodeName}.value`,
          returnTypeName: `${nodeName}.returnType`,
          setValue,
          type: 'js',
          onClose: () => {
            if (typeof setValue === 'function') {
              setValue(`${nodeName}.attribute`, null);
              setValue(`${nodeName}.sourceType`, null);
            }
          },
        });
      }
    }

    ref?.current?.hide();
  };

  const popoverComponent = (
    <RuleField
      name={nodeName}
      control={control}
      dataset={removeCustomFunction(dataset)}
      disabled={isRuleReadOnly}
      allowList
      version="v2"
      typesToAllow={typesToAllow}
      onClick={({ value, key, dataType }) => {
        ref.current?.hide();

        if (typeof handleSendEventToGTM === 'function') {
          handleSendEventToGTM({
            action: 'edit',
            element: 'rhs_value',
            actionName: dataType,
          });
        }

        let fieldsToUpdate: Record<string, any> = {};

        if (isActiveDate) {
          fieldsToUpdate = {
            ...fieldsToUpdate,
            sourceType: key,
            attribute: value,
          };
        } else {
          const currNodeValue = nodes[nodeKey].value;
          const relativeDateParams =
            typeof currNodeValue === 'object' && !Array.isArray(currNodeValue)
              ? currNodeValue
              : {};

          fieldsToUpdate = {
            value: {
              ...relativeDateParams,
              value: `{{.${key}.${value}}}`,
            },
          };
        }

        setNodes((prev) => ({
          ...prev,
          [nodeKey]: {
            ...prev[nodeKey],
            dataType,
            attribute: value,
            nodeType: isActiveDate ? 'params' : 'noCodeFunc',
            sourceType: key,
            value: fieldsToUpdate?.value ?? undefined,
            ...fieldsToUpdate,
          },
        }));

        setValue(`${nodeName}.value`, fieldsToUpdate?.value ?? undefined);
      }}
      header={
        typesToAllow.includes('list') ? (
          <ResultHeader
            dataSet={removeCustomFunction(dataset)}
            isAdd={
              // eslint-disable-next-line
              !!nodes[nodeKey].attribute ||
              isArrayNotPresent(nodes[nodeKey]?.value)
            }
            nodeName={`${nodeName}`}
            resIndex={0}
            control={control}
            onChangeSpecial={onChangeSpecial}
            disabled={isRuleReadOnly}
            dataType={'list'}
            overrideValue={nodes[nodeKey]?.value}
            popoverRef={ref}
          />
        ) : undefined
      }
    />
  );

  const rightIcon = (hide: any) => {
    if (['jsFormula', 'excelFormula'].includes(nodeType ?? '')) {
      return (
        <RhsFormulaField
          nodeId={nodeKey}
          nodeName={nodeName}
          control={control}
          setValue={setValue}
          hidePopover={hide}
        />
      );
    } else {
      if (['json', 'list'].includes(currentNodeDataType ?? '')) {
        return (
          <RhsFormulaField
            nodeId={nodeKey}
            nodeName={nodeName}
            control={control}
            setValue={setValue}
            hidePopover={hide}
          />
        );
      }
    }

    return null;
  };

  return (
    <RhsStackContainer gutter={8} style={{ alignItems: 'end' }}>
      {showDateSwitchField && (
        <DateSwitcher
          isActiveDate={isActiveDate}
          position={''}
          setIsActiveDate={updateDateConfiguration}
          disabled={isRuleReadOnly}
        />
      )}
      <Stack gutter="1.2rem" style={{ alignItems: 'end' }}>
        {isRelativeFieldEnable &&
          isValueObjectType &&
          funcName === 'relativeDate' && (
            <RelativeDateFields
              dataset={dataset}
              value={value ?? {}}
              isDisabled={isRuleReadOnly}
              onClick={handleRelativeFieldClick}
              durationFieldError={durationFieldState?.error?.message}
            />
          )}

        <Inline style={{ alignItems: 'center' }}>
          {isRelativeFieldEnable && (
            <Typography name="secondarySm">Relative to</Typography>
          )}

          <PopoverPanel
            trigger="click"
            placement="auto"
            appendTo={document.body}
            launcher={
              <RhsLauncher
                from="dt"
                panelVisible={panelVisible}
                text={
                  dataType === 'list'
                    ? title ?? ''
                    : (title as unknown as any)?.value?.toString() ?? ''
                }
                nodeId={nodeKey}
                nodeName={nodeName}
                control={control}
                setValue={setValue}
                conditionKey={conditionKey}
                error={fieldState.error?.message}
                onFocus={onFocus}
                onBlur={onBlur}
                disabled={isRuleReadOnly}
                dataType={dataType}
                handleSendEventToGTM={handleSendEventToGTM}
                selectedOperator={selectedOperator}
                isActiveDate={isActiveDate}
                handleHidePanel={ref.current?.hide}
                rightIcon={rightIcon}
                isSchemaMandatory={isSchemaMandatory}
              />
            }
            ref={ref}
            padding="8px"
            disabled={isRuleReadOnly}
          >
            <RhsInputContainer
              onMouseEnter={() => setPanelVisible(true)}
              onMouseLeave={() => setPanelVisible(false)}
            >
              <TokenSelectionPopover
                selectedSchemaId={schemaId}
                tokenDataset={predefineTokenDataset}
                customTokenComponent={popoverComponent}
                handleValueSelection={handlePredefineValueSelection}
                tabsToShow={tokenSelectionTabsToShow}
                typesToAllow={typesToAllow}
                size={'46rem'}
                showTooltip={['json', 'list'].includes(dataType ?? '')}
              />
            </RhsInputContainer>
          </PopoverPanel>
        </Inline>
      </Stack>
    </RhsStackContainer>
  );
};

export const getAllIdsExceptFirst = (data: Record<string, Dataset>) => {
  const ids = Object.keys(data)
    .filter((key) => data[key].id)
    .map((key) => data[key].id)
    .slice(1);

  return ids;
};
