import { Inline, Stack } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const RhsStackContainer = styled(Stack)`
  position: relative;
`;

export const InputContainer = styled(Inline)`
  position: relative;
`;

export const InputStyled = styled.textarea<{
  $hasError: boolean;
  $showExpandIcon?: boolean;
  $paddingTop?: string;
}>`
  padding: 0.2rem 1.8rem;
  max-inline-size: 18rem;
  font-size: 1.2rem;
  outline: none;
  border: none;
  border-radius: 4px;
  text-overflow: ellipsis;
  resize: none;
  overflow: hidden;
  ${({ $paddingTop = '0.2rem' }) => `padding-top: ${$paddingTop};`}

  ${({ $showExpandIcon = false }) =>
    $showExpandIcon && 'padding-right: 3.8rem;'}

  &::placeholder {
    text-overflow: clip;
  }
  outline: ${({ $hasError }) =>
    `1px solid ${
      $hasError ? 'var(--color-fireEngineRed)' : 'var(--color-white)'
    }`};
  background-color: ${({ $hasError }) =>
    $hasError ? 'var( --color-snow)' : 'var(--color-solitudeSecondary)'};
`;

export const InputFieldStyled = styled.input<{
  $hasError: boolean;
  $showExpandIcon?: boolean;
  $paddingTop?: string;
  isActiveDate: boolean;
  isFormulaNode: boolean;
  isRelativeDate?: boolean;
}>`
  padding: 0.2rem 1.8rem;
  max-inline-size: 20rem;
  font-size: 1.2rem;
  outline: none;
  border: none;
  border-radius: 4px;
  text-overflow: ellipsis;

  ${({ isRelativeDate = true }) =>
    isRelativeDate && `max-inline-size: 10rem; inline-size: 10rem;`}

  ${({ isActiveDate = true }) =>
    isActiveDate && `max-inline-size: 16.8rem; inline-size: 16.8rem;`}

  ${({ isFormulaNode = true }) =>
    isFormulaNode && `max-inline-size: 18rem; inline-size: 18rem;`}

  ${({ $paddingTop = '0.2rem' }) => `padding-top: ${$paddingTop};`}

  ${({ $showExpandIcon = false }) =>
    $showExpandIcon && 'padding-right: 3.8rem;'}

  &::placeholder {
    text-overflow: clip;
  }
  outline: ${({ $hasError }) =>
    `1px solid ${
      $hasError ? 'var(--color-fireEngineRed)' : 'var(--color-white)'
    }`};
  background-color: ${({ $hasError }) =>
    $hasError ? 'var( --color-snow)' : 'var(--color-solitudeSecondary)'};
`;

export const InputBrackets = styled.div<{
  direction: 'left' | 'right';
  $showExpandIcon?: boolean;
}>`
  position: absolute;
  ${({ direction = 'left', $showExpandIcon = false }) =>
    direction === 'left'
      ? 'left: 0.5rem;'
      : `right: ${$showExpandIcon ? '3rem' : '0.5rem'}`};
  color: var(--color-lightGray);

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const RhsInputContainer = styled.div`
  max-block-size: 30rem;
  inline-size: 46rem;
  overflow-y: auto;
`;

export const ExpandIconContainer = styled.div`
  inline-size: 1.8rem;
  block-size: 1.8rem;
  background-color: var(--color-gainsboro);
  border-radius: 0.4rem;
  position: absolute;
  inset-inline-end: 0.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RoundIcon = styled.button`
  border-radius: 0.4rem;
  background-color: var(--color-lightGray2);
  border: none;
  cursor: pointer;
  line-height: 2;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
