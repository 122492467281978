import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import _isNil from 'lodash/isNil';
import { useRef } from 'react';
import AceEditor from 'react-ace';
import { UseFormWatch } from 'react-hook-form';
import { JsonHeroTreeViewer, Typography } from 'ui';

import { JsonInTableView } from '../../../../../../components/JsonInTableView/JsonInTableView';
import { ExecutionView } from '../../../../../../components/jsonOutputField/components/ExecutionView';
import { DataSetOutput } from '../../../../../DataSets/components/DataSetOutput';
import {
  JsonContainer,
  JsonTreeContainer,
  OutputContainer,
} from './GSheetNodeTest.styled';

type GSheetNodeTestProps = {
  output?: Record<string, any>;
  error?: any;
  watch: UseFormWatch<any>;
};

export function GSheetNodeTest({
  output = {
    _comment: 'Please Test the node first',
  },
  error,
  watch,
}: GSheetNodeTestProps) {
  const ref = useRef<HTMLDivElement>(null);
  const isLookup = watch('actionMethod')?.value === 'lookup';

  const tableOutput = {
    fields: output?.output?.fields ?? [],
    rows: output?.output?.rows ?? [],
    executionTime: output?.executionTime ?? '',
  };

  return (
    <PadBox padding="2rem">
      <OutputContainer padding="1rem">
        <Stack gutter="1rem">
          {
            // eslint-disable-next-line
            !isLookup || !_isNil(error) || !!output?.error ? (
              <ExecutionView>
                <JsonContainer>
                  <AceEditor
                    className="json-result-readonly"
                    mode="json"
                    theme="chrome"
                    width="100%"
                    fontSize={12}
                    showPrintMargin={false}
                    highlightActiveLine={true}
                    showGutter={true}
                    setOptions={{
                      useWorker: false,
                      showLineNumbers: true,
                      tabSize: 2,
                    }}
                    readOnly
                    value={JSON.stringify(
                      !_isNil(error) ? error : output,
                      null,
                      2
                    )}
                  />
                </JsonContainer>
                <JsonTreeContainer>
                  <JsonHeroTreeViewer json={output} />
                </JsonTreeContainer>

                <JsonTreeContainer>
                  <JsonInTableView json={{ output: output?.output ?? null }} />
                </JsonTreeContainer>
              </ExecutionView>
            ) : tableOutput.rows.length > 0 ? (
              <DataSetOutput
                isLoading={false}
                data={tableOutput}
                // error={error as AxiosError<CustomAxiosError, any>}
                outputRef={ref}
              />
            ) : (
              <Typography>No records found</Typography>
            )
          }
        </Stack>
      </OutputContainer>
    </PadBox>
  );
}
