import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import {
  Control,
  UseFormSetValue,
  UseFormWatch,
  useController,
  useWatch,
} from 'react-hook-form';
import { Dataset, formatNectedDate, Typography } from 'ui';

import { CalenderIconPicker } from '../../../../../../../components/CalenderIconPicker/CalenderIconPicker';
import { DateSwitcher } from '../../../../../../../components/RelativeDateComponent/DateSwitcher';
import { RelativeDateFields } from '../../../../../../../components/RelativeDateComponent/RelativeDateFields';
import {
  extractSourceAndAttributeFromValue,
  isArrayNotPresent,
} from '../../../../../../../utils/common';
import { ResultHeader } from '../../../../../../Rules/components/DecisionTable/components/ResultHeader';
import { ResultRhs } from '../../../../../../Rules/components/RestltRhs/ResultRhs';
import { RoundIcon } from '../../../../../../Rules/components/SimpleRule/RulePopovers/RhsParamPopover.styled';
import { isWorkflowReadOnlyAtom } from '../../../../../atoms/atoms';
import { PathRhsContainer } from './PathBlock.styled';

type PathRhsProps = {
  dataType: string;
  groupIndex: number;
  paramId: string;
  control?: Control<any>;
  setValue: UseFormSetValue<any>;
  updatedDataSet?: Record<string, Dataset>;
  typesToAllow?: string[];
  conditionId: string;
  val: any;
  pathKey: string;
  onChangeSpecial: (value: any) => void;
  watch: UseFormWatch<any>;
  selectedOperator?: string;
};

export function PathRhs({
  dataType,
  groupIndex,
  paramId,
  control,
  setValue,
  updatedDataSet = {},
  typesToAllow = [],
  conditionId,
  val,
  pathKey,
  onChangeSpecial,
  watch,
  selectedOperator = '',
}: PathRhsProps) {
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [isActiveDate, setIsActiveDate] = useState(true);

  const { fieldState: durationFieldState } = useController({
    name: `${pathKey}.otherFields.duration`,
    control,
  });

  const node = useWatch({
    name: pathKey,
    control,
  });

  const updateDateConfiguration = (isDateActiveNow: boolean) => {
    if (isDateActiveNow) {
      const isValueOfObject =
        !_isNil(val) && typeof val === 'object' && !Array.isArray(val);

      const { source, attribute } = isValueOfObject
        ? extractSourceAndAttributeFromValue(
            (val.value ?? '') as string,
            updatedDataSet
          )
        : {
            source: undefined,
            attribute: undefined,
          };

      const condition = watch(`paths.${groupIndex}.${paramId}`);

      condition.sourceType = source ?? '';
      condition.attribute = attribute ?? '';
      condition.value =
        _isNil(source) && _isNil(attribute) && isValueOfObject
          ? val.value
          : undefined;
      condition.nodeType =
        !_isNil(source) && !_isNil(attribute) ? 'params' : 'constant';

      setValue(`paths.${groupIndex}.${paramId}`, condition);
    } else {
      const condition = watch(`paths.${groupIndex}.${paramId}`);

      const sourceType = condition?.sourceType;
      const attribute = condition?.attribute;

      let value = condition?.value ?? '';

      if (
        !_isNil(sourceType) &&
        !_isEmpty(sourceType) &&
        !_isNil(attribute) &&
        !_isEmpty(attribute)
      ) {
        value = `{{.${sourceType as unknown as string}.${
          attribute as unknown as string
        }}}`;
      }

      setValue(`paths.${groupIndex}.${paramId}.value`, {
        unit: 'd',
        duration: undefined,
        value,
        subOp: 'next',
        FUNC_NAME: 'relativeDate',
      });

      setValue(`paths.${groupIndex}.${paramId}.sourceType`, undefined);
      setValue(`paths.${groupIndex}.${paramId}.attribute`, undefined);

      setValue(`paths.${groupIndex}.${paramId}.nodeType`, 'noCodeFunc');
    }

    setIsActiveDate(isDateActiveNow);
  };

  const updateOnChangeDurationFieldError = (cValues: any) => {};

  const isValueObjectType =
    typeof val === 'object' && !Array.isArray(val) && !_isNil(val);

  const funcName = isValueObjectType ? val.FUNC_NAME : '';

  const handleRelativeFieldClick = (key: string, value: any) => {
    const currNodeValue = watch(`paths.${groupIndex}.${paramId}.value`);
    const relativeDateParams =
      typeof currNodeValue === 'object' && !Array.isArray(currNodeValue)
        ? currNodeValue
        : {};

    if (
      key === 'duration' &&
      typeof value === 'object' &&
      !_isNil(value) &&
      !Array.isArray(value)
    ) {
      const { sourceType, attribute } = value;
      setValue(`paths.${groupIndex}.${paramId}.value`, {
        ...relativeDateParams,
        [key]: `{{.${sourceType as string}.${attribute as string}}}`,
      });
    } else {
      if (key === 'subOp' && value === 'current') {
        setValue(`paths.${groupIndex}.${paramId}.value`, {
          ...relativeDateParams,
          duration: undefined,
          [key]: value,
        });
      } else {
        setValue(`paths.${groupIndex}.${paramId}.value`, {
          ...relativeDateParams,

          [key]: value,
        });
      }
    }
  };

  useEffect(() => {
    if (
      ['date', 'dateTime'].includes(dataType ?? '') &&
      !_isNil(val) &&
      typeof val === 'object' &&
      !Array.isArray(val)
    ) {
      setIsActiveDate(false);
    }
  }, [val, dataType]);

  const isRelativeFieldEnable =
    ['date', 'dateTime'].includes(dataType ?? '') && !isActiveDate;

  const showDateSwitchField =
    ['date', 'dateTime'].includes(dataType ?? '') &&
    !['in', 'nin'].includes(selectedOperator ?? '');

  return (
    <PathRhsContainer>
      {showDateSwitchField && (
        <DateSwitcher
          isActiveDate={isActiveDate}
          setIsActiveDate={updateDateConfiguration}
          disabled={isWorkflowReadOnly}
        />
      )}

      <Stack gutter="1.2rem">
        {isRelativeFieldEnable &&
          isValueObjectType &&
          funcName === 'relativeDate' && (
            <RelativeDateFields
              dataset={updatedDataSet}
              value={val ?? {}}
              isDisabled={isWorkflowReadOnly}
              onClick={handleRelativeFieldClick}
              durationFieldError={durationFieldState?.error?.message}
              updateOnChangeDurationFieldError={
                updateOnChangeDurationFieldError
              }
            />
          )}

        <Inline align="center">
          {isRelativeFieldEnable &&
            isValueObjectType &&
            funcName === 'relativeDate' && <Typography>Relative to</Typography>}
          <ResultRhs
            dataType={dataType}
            nodeName={`paths.${groupIndex}.${paramId}`}
            allowList
            isRelativeDate={isRelativeFieldEnable && isValueObjectType}
            control={control}
            setValue={setValue}
            isSmall
            updatedDataSet={updatedDataSet}
            showTooltip
            sourceKey="sourceType"
            typesToAllow={
              isRelativeFieldEnable && isValueObjectType
                ? [dataType]
                : typesToAllow
            }
            nodeTypeName={`paths.${groupIndex}.${paramId}.nodeType`}
            otherKeys={{
              [`paths.${groupIndex}.${paramId}.parent`]: conditionId,
            }}
            disabled={isWorkflowReadOnly}
            header={
              typesToAllow.includes('list') ? (
                <ResultHeader
                  dataSet={updatedDataSet}
                  // eslint-disable-next-line
                  isAdd={isArrayNotPresent(val)}
                  nodeName={`paths.${groupIndex}.${paramId}`}
                  resIndex={0}
                  control={control}
                  onChangeSpecial={onChangeSpecial}
                  disabled={isWorkflowReadOnly}
                  dataType={'list'}
                  overrideValue={val}
                />
              ) : undefined
            }
          />

          {['dateTime', 'date'].includes(dataType) ? (
            <RoundIcon type="button">
              <CalenderIconPicker
                value={isActiveDate ? val : val?.value}
                dataType={dataType}
                disabled={isWorkflowReadOnly}
                onPick={(val) => {
                  if (typeof setValue === 'function') {
                    const currNodeValue = node.value;
                    const relativeDateParams =
                      typeof currNodeValue === 'object' &&
                      !Array.isArray(currNodeValue)
                        ? currNodeValue
                        : {};

                    setValue(
                      `${pathKey}.value`,
                      isActiveDate
                        ? (formatNectedDate(val, dataType) as string)
                        : {
                            ...relativeDateParams,
                            value: formatNectedDate(val, dataType) as string,
                          }
                    );
                    setValue(`${pathKey}.attribute`, null);
                    setValue(`${pathKey}.sourceType`, null);
                    setValue(`${pathKey}.dataType`, dataType);
                    setValue(
                      `${pathKey}.nodeType`,
                      isActiveDate ? 'constant' : 'noCodeFunc'
                    );
                  }
                }}
              />
            </RoundIcon>
          ) : null}
        </Inline>
      </Stack>
    </PathRhsContainer>
  );
}
