import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { UseControllerProps, UseFormSetValue, useWatch } from 'react-hook-form';
import {
  ExecutedValueTooltip,
  formatNectedDate,
  Image,
  TextButton,
  TextField,
  Typography,
} from 'ui';
import { IconByDataType } from 'ui/src/ConditionPopovers/RulePopover/IconByDataType';

import { predefineTokenDatasetAtom } from '../../../../../atom';
import { CalenderIconPicker } from '../../../../../components/CalenderIconPicker/CalenderIconPicker';
import { FieldByDataType } from '../../../../../components/FieldByDataType/FieldByDataType';
import {
  getTokenModuleName,
  getUsedSchemaAttributes,
  isValidSchemaId,
} from '../../../../../components/TokenComponents/utils/helperFunction';
import {
  isArrayNotPresent,
} from '../../../../../utils/common';
import {
  envMap,
  hideOptionalCustomAttributes,
} from '../../../../../utils/constant';
import { maxFiftyCharactersRule } from '../../../../../utils/validation';
import { isRuleReadOnlyAtom } from '../../../index';
import type { sendEventToGTMType } from '../../../types';
import { getRequiredKey } from '../../../utils/common';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { ResultRhs } from '../../RestltRhs/ResultRhs';
import { DecisionTableResultRow } from '../types';
import { DataContainer, StyledContainer } from './AdditionalData.styled';
import { ModuleSchemaFieldContainer } from './DecisionTableBlock.styled';
import { ResultHeader } from './ResultHeader';

type AddAdditionalDataProps = Omit<UseControllerProps, 'name'> & {
  index: number;
  handleDeleteData: (index: number) => void;
  handleSendEventToGTM: (obj: sendEventToGTMType) => void;
  setValue: UseFormSetValue<any>;
  dataType: string;
};

export function AdditionalData({
  control,
  index,
  handleDeleteData,
  handleSendEventToGTM,
  setValue,
}: AddAdditionalDataProps) {
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [isSchemaMandatory, setIsSchemaMandatory] = useState(false);

  const [dataset] = useAtom(dataSetParamsAtom);
  const [predefineTokenDataset] = useAtom(predefineTokenDatasetAtom);

  const decisionTableResults: DecisionTableResultRow[] = useWatch({
    control,
    name: 'results',
  });

  const nodeName = `additionalData.${index}`;

  const currentData = useWatch({
    name: nodeName,
    control,
  });
  const additionalDataKeyName = useWatch({
    name: `additionalData.${index}.name`,
    control,
  });

  const dataType = currentData?.dataType ?? '';

  const generateSuggestionsByData = (results: DecisionTableResultRow[]) => {
    const generatedSuggestions: string[] = [];
    results.forEach((result: DecisionTableResultRow) => {
      const key = getRequiredKey(result, ['id']);

      if (![''].includes(result[key].dataType)) {
        generatedSuggestions.push(`{{.outputDataList.${result[key].keyName}}}`);
      }
    });

    return generatedSuggestions;
  };

  useEffect(() => {
    if (decisionTableResults.length > 0) {
      setSuggestions(generateSuggestionsByData(decisionTableResults));
    }
  }, [decisionTableResults]);

  const handleClick = () => {
    if (typeof handleSendEventToGTM === 'function') {
      handleSendEventToGTM({
        action: 'edit',
        element: 'additional_data',
        actionName: '',
      });
    }
  };

  const handleDeleteClick = () => {
    handleDeleteData(index);

    if (typeof handleSendEventToGTM === 'function') {
      handleSendEventToGTM({
        action: 'delete',
        element: 'additional_data',
        actionName: '',
      });
    }
  };

  const schemaId: string = currentData?.schemaId;

  const isModuleSchemaField =
    !_isNil(schemaId) &&
    !_isEmpty(schemaId) &&
    isValidSchemaId(predefineTokenDataset, schemaId);

  useEffect(() => {
    const usedSchemaObj = getUsedSchemaAttributes(
      predefineTokenDataset,
      schemaId
    );

    if (!_isNil(usedSchemaObj) && !_isNil(usedSchemaObj?.usageType)) {
      setIsSchemaMandatory(usedSchemaObj.usageType === 'mandatory');
    }
  }, [JSON.stringify(predefineTokenDataset), schemaId]);

  const rightIcon = (hide : any) => {
    const dataType = currentData?.dataType ?? '';

    if (['dateTime', 'date'].includes(dataType)) {
      return (
        <CalenderIconPicker
          value={currentData.value}
          dataType={dataType}
          disabled={['list', 'json'].includes(dataType) || isSchemaMandatory}
          onPick={(val) => {
            if (typeof setValue === 'function') {
              setValue(`${nodeName}.value`, formatNectedDate(val, dataType));
              setValue(`${nodeName}.attribute`, null);
              setValue(`${nodeName}.source`, null);
            }
          }}
        />
      );
    }

    if (['jsFormula', 'excelFormula', 'list', 'json'].includes(dataType)) {
      return (
        <FieldByDataType
          name={`${nodeName}.value`}
          returnTypeName={`${nodeName}.returnType`}
          executedValueName={`${nodeName}.executedValue`}
          setValue={setValue}
          dataType={dataType}
          control={control}
          showError
          index={(decisionTableResults ?? []).length}
          section="thenDataParams"
          disabled={isRuleReadOnly}
          suggestions={suggestions}
          isSmallInput
          hideOptionalCustomAttributes={hideOptionalCustomAttributes}
          dataSet={dataset}
          additionalDataIndex={index}
          isAdditionalData
          hidePopover={hide}
        />
      );
    }

    return null;
  };

  return (
    <StyledContainer align="start" gutter="1rem" onClick={handleClick}>
      {isModuleSchemaField ? (
        <ExecutedValueTooltip
          id={nodeName}
          showExecutedValue={false}
          value={currentData.name}
          attribute={currentData?.name}
          dataType={dataType}
          source={getTokenModuleName(predefineTokenDataset, schemaId)}
          placement="top"
        >
          <ModuleSchemaFieldContainer>
            <IconByDataType
              dataType={dataType ?? 'string'}
              color="var(--color-paleDodgerBlue)"
            />
            <Typography>{currentData.name}</Typography>
          </ModuleSchemaFieldContainer>
        </ExecutedValueTooltip>
      ) : (
        <TextField
          control={control}
          rules={{
            required: 'Required',
            maxLength: maxFiftyCharactersRule,
          }}
          name={`additionalData.${index}.name`}
          placeholder="Key"
          isSmallInput
          size="small"
          showErrorIcon={false}
          disabled={isRuleReadOnly}
          startIcon={
            <span>
              <IconByDataType
                dataType={dataType ?? 'string'}
                color="var(--color-paleDodgerBlue)"
              />
            </span>
          }
        />
      )}

      <ResultRhs
        control={control}
        nodeName={nodeName}
        dataType={dataType}
        setValue={setValue}
        keyName={additionalDataKeyName}
        isSmall
        typesToAllow={[dataType, 'generic']}
        allowList
        showTooltip={true}
        useTokenSelectionPopover
        schemaId={schemaId}
        isSchemaMandatory={isSchemaMandatory}
        showExpandIconIfList={false}
        updatedDataSet={dataset}
        readOnly={[
          'list',
          'json',
          'date',
          'dateTime',
          'jsFormula',
          'excelFormula',
        ].includes(dataType)}
        options={{
          updateDataType: ['jsFormula', 'excelFormula'].includes(dataType),
        }}
        inputType={
          [dataType, 'generic'].includes('string') ? 'textarea' : 'input'
        }
        rightIcon={rightIcon}
        header={
          ['list', 'json'].includes(dataType) ? (
            <ResultHeader
              dataSet={dataset}
              // eslint-disable-next-line
              isAdd={isArrayNotPresent(currentData?.value)}
              nodeName={nodeName}
              resIndex={index}
              control={control}
              setOriginalValue={setValue}
              disabled={isRuleReadOnly}
              returnTypeName={`${nodeName}.returnType`}
              executedValueName={`${nodeName}.executedValue`}
              dataType={dataType}
              editorType={dataType as unknown as 'list' | 'json'}
            />
          ) : undefined
        }
      />

      {!isRuleReadOnly && (
        <DataContainer>
          <TextButton onClick={handleDeleteClick}>
            <Image
              src={`${envMap.VITE_ASSETS_URL}website/icons/trash.svg`}
              alt="minus"
              size="small"
            />
          </TextButton>
        </DataContainer>
      )}
    </StyledContainer>
  );
}
