import { Stack } from '@bedrock-layout/stack';
import _isEmpty from 'lodash/isEmpty';
import { Control, UseFormSetValue, useController } from 'react-hook-form';
import { MdEdit } from 'react-icons/md';
import { Dataset, Typography, useLayer } from 'ui';

import { ExcelModal } from '../ExcelModal/ExcelModal';
import { AlignedText, FieldPillStyled, PillStyled } from './ExcelPill.styled';

type ExcelPillProps = {
  control?: Control<any>;
  setValue?: UseFormSetValue<any>;
  name: string;
  dataSet?: Record<string, Dataset>;
  returnTypeName?: string;
  executedValueName?: string;
  isReadOnly?: boolean;
  isAdditionalData?: boolean;
  index?: number;
  usingFrom?: string;
  showError?: boolean;
  hidePopover?: () => void;
};

export function ExcelPill({
  control,
  setValue,
  name,
  dataSet,
  executedValueName,
  returnTypeName,
  isReadOnly = false,
  isAdditionalData = false,
  index = 0,
  usingFrom,
  showError = false,
  hidePopover,
}: ExcelPillProps) {
  const { openWithProps } = useLayer(<ExcelModal name={name} />);

  const { fieldState } = useController({
    name,
    control,
  });

  const errorMessage = fieldState.error?.message;

  const getPadding = () => {
    if (
      name.includes('ruleResult') ||
      name.includes('additionalData') ||
      name.includes('attributes')
    ) {
      return { size: true, pill: ['0rem', '0.6rem'] };
    }

    return { size: false, pill: ['0.4rem', '1rem'] };
  };

  return (
    <Stack gutter={2}>
      {usingFrom === 'fieldComponent' ? (
        <FieldPillStyled
          padding={getPadding().pill}
          onClick={() => {
            if(typeof hidePopover === "function"){
              hidePopover();
            }
            openWithProps({
              name,
              control,
              dataSet,
              setOriginalValue: setValue,
              executedValueName,
              returnTypeName,
              isReadOnly,
              isAdditionalData,
              index,
            });
          }}
          $hasError={!_isEmpty(errorMessage) && showError}
        >
          <AlignedText>Formula</AlignedText>
          <MdEdit />
        </FieldPillStyled>
      ) : (
        <PillStyled
          padding={['0.4rem', '1rem']}
          onClick={() => {
            if(typeof hidePopover === "function"){
              hidePopover();
            }
            openWithProps({
              name,
              control,
              dataSet,
              setOriginalValue: setValue,
              executedValueName,
              returnTypeName,
              isReadOnly,
              isAdditionalData,
              index,
            });
          }}
          $hasError={!_isEmpty(errorMessage) && showError}
        >
          <AlignedText fontWeight={700}>Formula</AlignedText>
          <MdEdit />
        </PillStyled>
      )}

      {!_isEmpty(errorMessage) && showError && (
        <Typography name="error">{errorMessage}</Typography>
      )}
    </Stack>
  );
}
