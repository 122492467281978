import { Inline } from '@bedrock-layout/primitives';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { UseControllerProps, UseFormSetValue, useWatch } from 'react-hook-form';
import {
  CheckboxField,
  IconButton,
  TextAreaField,
  TextField,
  TextInput,
  Typography,
  useLayer,
} from 'ui';

import { TextInputModal } from '../../../../components/Modals/TextInputModal/TextInputModal';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  convertArrayToString,
  getFieldValueAsArray,
  isArrayAsInputValid,
} from '../../../../utils/common';
import {
  FLOATING_NUMBER_REGEX,
  VALID_STRING_REGEX,
} from '../../../../utils/regex';
import {
  isValidDate,
  isValidDateTime,
  isValidDateTimeSecondary,
} from '../../../../utils/validation';
import { JsonNodePill } from '../SimpleRule/Results/JsonNodePill';

type TestNodeComponentProps = Omit<UseControllerProps<any>, 'name'> & {
  index: number;
  nodeKey: string;
  isNullable: boolean;
  disabled?: boolean;
  nodeType?: 'test' | 'cronAttributes';
  setValue?: UseFormSetValue<any>;
  dataType: string;
  from?: string;
};

function TextComponent({
  index,
  nodeKey,
  control,
  isNullable,
  disabled = false,
  nodeType = 'test',
  dataType,
  setValue,
}: TestNodeComponentProps) {
  const { openWithProps } = useLayer(<TextInputModal />);

  const val = useWatch({
    control,
    name: `${nodeType}.${index}.${nodeKey}`,
  });

  return (
    <Inline align="center" gutter={4}>
      <TextAreaField
        control={control}
        name={`${nodeType}.${index}.${nodeKey}.value`}
        rules={{
          pattern: {
            value: VALID_STRING_REGEX,
            message: 'Invalid string',
          },
        }}
        size="xxs"
        showError
        disabled={disabled}
        rows={2}
        columns={17}
      />
      <IconButton
        onClick={() => {
          openWithProps({
            onSubmit: (val: Record<string, any>) => {
              if (setValue != null) {
                setValue(`${nodeType}.${index}.${nodeKey}.value`, val.value);
              }
            },
            value: val.value,
          });
        }}
      >
        <>⤢</>
      </IconButton>
    </Inline>
  );
}

// eslint-disable-next-line
function ListComponent({
  index,
  nodeKey,
  control,
  isNullable,
  disabled = false,
  nodeType = 'test',
  dataType,
}: TestNodeComponentProps) {
  return (
    <TextField
      control={control}
      name={`${nodeType}.${index}.${nodeKey}.value`}
      rules={{
        pattern: {
          value: VALID_STRING_REGEX,
          message: 'Invalid string',
        },
        validate: {
          require: (value: string) => {
            if (!_isEmpty(value) && !isArrayAsInputValid(value)) {
              return 'Error while parsing input value';
            }

            return undefined;
          },
        },
      }}
      showError
      showErrorIcon={false}
      disabled={disabled}
    />
  );
}

function NumericComponent({
  index,
  nodeKey,
  control,
  isNullable,
  disabled = false,
  nodeType = 'test',
  dataType,
}: TestNodeComponentProps) {
  return (
    <TextField
      control={control}
      name={`${nodeType}.${index}.${nodeKey}.value`}
      rules={{
        required: {
          value: !disabled,
          message: 'Required Field',
        },
        pattern: {
          value: FLOATING_NUMBER_REGEX,
          message: 'Invalid number',
        },
      }}
      showError
      showErrorIcon={false}
      disabled={disabled}
    />
  );
}

function BooleanComponent({
  index,
  nodeKey,
  control,
  isNullable,
  disabled = false,
  nodeType = 'test',
  dataType,
}: TestNodeComponentProps) {
  return (
    <CheckboxField
      control={control}
      name={`${nodeType}.${index}.${nodeKey}.value`}
      showError
      appearance="switch"
      useId={`${nodeType}.${index}.${nodeKey}.value`}
      disabled={disabled}
    />
  );
}

function DateComponent({
  index,
  nodeKey,
  control,
  isNullable,
  disabled = false,
  nodeType = 'test',
  dataType,
}: TestNodeComponentProps) {
  return (
    <TextField
      control={control}
      name={`${nodeType}.${index}.${nodeKey}.value`}
      rules={{
        required: {
          value: !disabled,
          message: 'Required Field',
        },
        validate: {
          require: (value) => {
            if (typeof value === 'string' && !disabled) {
              return isValidDate(value) ||
                isValidDateTime(value) ||
                isValidDateTimeSecondary(value)
                ? undefined
                : 'Invalid Date Format';
            }

            return undefined;
          },
        },
      }}
      placeholder={DATE_FORMAT}
      showError
      showErrorIcon={false}
      disabled={disabled}
    />
  );
}

function DateTimeComponent({
  index,
  nodeKey,
  control,
  isNullable,
  disabled = false,
  nodeType = 'test',
  dataType,
}: TestNodeComponentProps) {
  return (
    <TextField
      control={control}
      name={`${nodeType}.${index}.${nodeKey}.value`}
      rules={{
        required: {
          value: !disabled,
          message: 'Required Field',
        },
        validate: {
          require: (value) => {
            if (typeof value === 'string' && !disabled) {
              return isValidDateTime(value) || isValidDateTimeSecondary(value)
                ? undefined
                : 'Invalid Date Format';
            }

            return undefined;
          },
        },
      }}
      placeholder={DATE_TIME_FORMAT}
      showError
      showErrorIcon={false}
      disabled={disabled}
    />
  );
}

function JsonComponent({
  index,
  nodeKey,
  control,
  isNullable,
  disabled = false,
  nodeType = 'test',
  setValue,
  dataType,
  from = '',
}: TestNodeComponentProps) {
  const testValue = useWatch({
    name: `${nodeType}.${index}.${nodeKey}.value`,
    control,
  });

  let value = testValue;

  if (!_isNil(testValue)) {
    if (dataType === 'list') {
      value = convertArrayToString(getFieldValueAsArray(testValue));
    } else if (dataType === 'json') {
      if (typeof testValue === 'string') {
        value = JSON.parse(testValue);
      }

      value = JSON.stringify(value);
    }
  }

  return (
    <Inline
      align="center"
      justify="center"
      gutter={8}
      style={{
        backgroundColor: 'var(--color-white)',
        borderRadius: '4px',
        border: '1px solid var(--color-gainsboro)',
        padding: '0.4rem 1.6rem',
      }}
    >
      <div
        style={{
          width: '7.5rem',
          height: '2rem',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {!_isNil(value) ? (
          value
        ) : (
          <Typography name="secondarySmall">Enter Value</Typography>
        )}
      </div>
      <JsonNodePill
        type={dataType}
        control={control}
        name={`${nodeType}.${index}.${nodeKey}.value`}
        setOriginalValue={setValue}
        from={from}
      />
    </Inline>
  );
}

function RestApiComponent({
  index,
  nodeKey,
  control,
  isNullable,
  disabled = false,
  nodeType = 'test',
  setValue,
}: TestNodeComponentProps) {
  return <TextInput placeholder="Fetched via API" disabled />;
}

export const testNodeComponents = {
  string: TextComponent,
  numeric: NumericComponent,
  boolean: BooleanComponent,
  date: DateComponent,
  dateTime: DateTimeComponent,
  json: JsonComponent,
  restAPI: RestApiComponent,
  list: JsonComponent,
};
