import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { MdEdit } from 'react-icons/md';
import { Typography, useLayer } from 'ui';

import { simpleRuleNodesAtom } from '../../SimpleRule';
import { ExcelFormulaNodeSheet } from './ExcelFormulaSheet';
import { JsFormulaNodeSheet } from './JsFormulaSheet';
import { JsonNodeSheet } from './JsonNodeSheet';
import { PillStyled } from './RhsFormulaField.styled';
import { _isNil } from '../../../../worker/DTTableTransform';

type RhsFormulaFieldProps = {
  nodeId: string;
  hidePopover: any;
};

export const RhsFormulaField = ({ nodeId , hidePopover }: RhsFormulaFieldProps) => {
  const { openWithProps: openJsSheet } = useLayer(
    <JsFormulaNodeSheet nodeId={nodeId} />
  );

  const { openWithProps: openExcelSheet } = useLayer(
    <ExcelFormulaNodeSheet nodeId={nodeId} />
  );

  const { openWithProps: openJsonSheet } = useLayer(
    <JsonNodeSheet nodeId={nodeId} />
  );

  const [rules] = useAtom(simpleRuleNodesAtom);

  const currentNode = rules?.[nodeId];

  const nodeType = currentNode?.nodeType;

  const dataType = currentNode?.dataType;

  const getPillName = () => {
    if (nodeType === 'jsFormula') {
      return 'JS Code';
    } else if (nodeType === 'excelFormula') {
      return 'Formula';
    } else {
      if (dataType === 'list') {
        return 'List';
      } else if (dataType === 'json') {
        return 'Json';
      }
    }

    return '-';
  };

  const handleClick = () => {
    if(!_isNil(hidePopover)){
      hidePopover();
    }
    if (nodeType === 'jsFormula') {
      openJsSheet({});
    } else if (nodeType === 'excelFormula') {
      openExcelSheet({});
    } else {
      openJsonSheet({ mode: dataType });
    }
  };

  return (
    <Inline
      align="center"
      gutter={8}
      as={PillStyled}
      onClick={handleClick}
      padding={[0, '0.6rem']}
    >
      <Typography>{getPillName()}</Typography>
      <MdEdit />
    </Inline>
  );
};
