import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import {
  type Control,
  type UseFormSetValue,
  type UseFormWatch,
  useWatch,
} from 'react-hook-form';
import { FiPlusCircle } from 'react-icons/fi';
import { Dataset, Image, TextButton, TextField, Typography, toasts } from 'ui';

import { predefineTokenDatasetAtom } from '../../../../../../atom';
import { AddButton } from '../../../../../../components/AddButton';
import { publishedConnectorsAtom } from '../../../../../../components/CreateAction/CreateAction';
import { onClickTokenSelectionArgs } from '../../../../../../components/TokenComponents/PredefineTokenPopover/PredefineTokenPopover';
import { TokenMenu } from '../../../../../../components/TokenComponents/TokenMenu/TokenMenu';
import { isValidSchemaId } from '../../../../../../components/TokenComponents/utils/helperFunction';
import { convertCaSampleValues } from '../../../../../../utils/common';
import { envMap } from '../../../../../../utils/constant';
import { useGetPublishedConnectors } from '../../../../../DataSets/hooks/useGetPublishedConnectors';
import { PublishedConnectors } from '../../../../../Rules/components/DecisionTable/types';
import { AttributeModel } from '../../../../../Rules/models';
import { getDefaultValueByDataTypeForRhsOutput } from '../../../../../Rules/utils/common';
import { isWorkflowReadOnlyAtom } from '../../../../atoms/atoms';
import { WorkflowAttribute } from '../../../../models/models';
import { convertCurrentNodeAttributesToDataset } from '../../../../utils/common';
import { Selector } from '../../RuleSheet/Selector/Selector';
import { AddFieldStyled, StyledTextContainer } from './ResponseMapping.styled';

type ResponseMappingProps = {
  control?: Control<any>;
  setValue: UseFormSetValue<any>;
  dataSet?: Record<string, Dataset>;
  watch: UseFormWatch<any>;
  customInputs?: Record<string, AttributeModel>;
  idsToNotExpand?: string[];
};

export function ResponseMapping({
  control,
  setValue,
  dataSet = {},
  watch,
  customInputs = {},
  idsToNotExpand = [],
}: ResponseMappingProps) {
  const [predefineTokenDataset] = useAtom(predefineTokenDatasetAtom);

  const attributes: WorkflowAttribute[] = useWatch({
    name: 'attributes',
    control,
  });

  const { data } = useGetPublishedConnectors(false);
  const [, setPublishConnectors] = useAtom(publishedConnectorsAtom);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  useEffect(() => {
    if (!_isNil(data)) {
      const publishedConnectors = data.getConnector.data;

      const publishConnectorIdAndPlugin: PublishedConnectors = {};

      if (publishedConnectors.length > 0) {
        publishedConnectors.forEach((connector) => {
          publishConnectorIdAndPlugin[connector.id] = connector;
        });
      }
      setPublishConnectors(publishConnectorIdAndPlugin);
    }
  }, [data]);

  const checkIsKeyNameUnique = (name?: string) => {
    let exists = false;

    attributes.forEach((currAttribute: any) => {
      if (currAttribute.keyName === name) {
        exists = true;
      }
    });

    return !exists;
  };

  const handleAddDataParam = (
    dataType: string,
    predefineTokenData?: onClickTokenSelectionArgs
  ) => {
    if (!_isNil(predefineTokenData)) {
      const isKeyNameUnique = checkIsKeyNameUnique(
        predefineTokenData.schemaName
      );

      if (!isKeyNameUnique) {
        toasts.infoBlue(
          'Cannot add attribute with same key name',
          'keyname-unique'
        );

        return {
          isKeyNameUnique,
        };
      }
    }

    if (attributes.length < 10) {
      setValue('attributes', [
        ...attributes,
        {
          keyName: predefineTokenData?.schemaName ?? `key_${attributes.length}`,
          schemaId: predefineTokenData?.schemaId,
          dataType,
          returnType: '',
          executedValue: convertCaSampleValues(
            dataType,
            getDefaultValueByDataTypeForRhsOutput(dataType)
          ),
          value: getDefaultValueByDataTypeForRhsOutput(dataType),
        },
      ]);
    } else {
      toasts.info(
        'You have reached the limit to add response',
        'max-set-variables'
      );
    }

    return {
      isKeyNameUnique: true,
    };
  };

  const handleDeleteElement = (index: number) => {
    const updatedAttributes = attributes.filter((attr, i) => i !== index);

    setValue('attributes', updatedAttributes);
  };

  const addFieldLauncher = (
    <AddFieldStyled $disabled={isWorkflowReadOnly} align="center" gutter={5}>
      {isWorkflowReadOnly ? (
        <FiPlusCircle color="var(--color-darkGray)" />
      ) : (
        <AddButton />
      )}
      <Typography>Add Field</Typography>
    </AddFieldStyled>
  );

  const handlePredefineTokenSelection = (data: onClickTokenSelectionArgs) => {
    const { isKeyNameUnique } = handleAddDataParam(data.dataType, data);

    return {
      canCloseModal: isKeyNameUnique,
    };
  };

  return (
    <Stack gutter="1.5rem">
      <TokenMenu
        entity="workflow"
        disabled={isWorkflowReadOnly}
        tokenDataset={predefineTokenDataset}
        launcher={addFieldLauncher}
        handleAddCustomToken={handleAddDataParam}
        handlePredefineTokenSelection={handlePredefineTokenSelection}
      />

      {attributes.length > 0 && (
        <Inline align="center">
          <StyledTextContainer>
            <Typography>Key Name</Typography>
          </StyledTextContainer>

          <StyledTextContainer>
            <Typography>Value</Typography>
          </StyledTextContainer>
        </Inline>
      )}

      {attributes?.map((currAttribute, index: number) => {
        const currentNodeDataset = convertCurrentNodeAttributesToDataset(
          attributes.slice(0, index),
          dataSet
        );

        const schemaId = currAttribute?.schemaId;

        const isModuleSchemaField =
          !_isNil(schemaId) &&
          !_isEmpty(schemaId) &&
          isValidSchemaId(predefineTokenDataset, schemaId);

        return (
          <Inline key={index} align="center">
            <StyledTextContainer>
              <TextField
                name={`attributes.${index}.keyName`}
                control={control}
                disabled={isWorkflowReadOnly || isModuleSchemaField}
                showErrorIcon={false}
              />
            </StyledTextContainer>

            <StyledTextContainer>
              <Selector
                control={control}
                setValue={setValue}
                keyName={currAttribute.keyName}
                nodeName={`attributes.${index}`}
                dataType={currAttribute.dataType ?? 'string'}
                dataSet={{ ...dataSet, ...currentNodeDataset }}
                index={index}
                key={`attributes.${index}`}
                customInputs={customInputs}
                showKeyName={false}
                showPillOnly={false}
                idsToNotExpand={idsToNotExpand}
                useTokenSelectionPopover={true}
                schemaId={schemaId}
              />
            </StyledTextContainer>

            <TextButton
              disabled={isWorkflowReadOnly}
              onClick={() => handleDeleteElement(index)}
            >
              <Image
                src={`${envMap.VITE_ASSETS_URL}website/icons/trash.svg`}
                alt="minus"
                size="small"
              />
            </TextButton>
          </Inline>
        );
      })}
    </Stack>
  );
}
