import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import { useEffect, useState } from 'react';
import { Control, UseFormSetValue, useForm, useWatch } from 'react-hook-form';
import {
  Button,
  DataTypes,
  Dataset,
  Modal,
  ModalContent,
  ModalFooter,
  NectedEditorField,
  NectedSuggestionModel,
  toasts,
  useCurrentLayer,
  useLayer,
} from 'ui';
import { Attributes } from 'ui/src/TreeViewer/TreeViewer';

import { createRuleSheetAtom } from '../../../pages/Rules';
import { DecisionTableResultRow } from '../../../pages/Rules/components/DecisionTable/types';
import { suggestionsAtom } from '../../../pages/Rules/components/SimpleRule/Results/JsNodePill';
import { OnCloseNodeModal } from '../../../pages/Rules/components/SimpleRule/Results/OnCloseNodeModal';
import { SaveNodeModal } from '../../../pages/Rules/components/SimpleRule/Results/SaveNodeModal';
import { ResultAddDataModel } from '../../../pages/Rules/components/SimpleRule/models';
import { useUpdateExecutedValueRules } from '../../../pages/Rules/hooks/useUpdateExecutedValueRules';
import {
  getDatasetWithoutReactCode,
  getEvaluatedExValueForResult,
  getRequiredKey,
} from '../../../pages/Rules/utils/common';
import { getDtOutputRows } from '../../../pages/Rules/utils/decisionTable';
import { useGenerateDataset } from '../../../pages/Workflow/hooks/useGenerateDataset';
import {
  convertCaSampleValues,
  getDataTypeByReturnType,
  getOutputDataByExecutedValue,
  getPropertyIfExists,
} from '../../../utils/common';
import {
  TokenDataTypeByDataType,
  TokenDataTypeByDataTypeSingle,
  TokenScores,
  editorDomain,
} from '../../../utils/constant';
import { ExcelEditor } from './ExcelModal.styled';

type ExcelModalProps = {
  name: string;
  control?: Control<any>;
  setOriginalValue?: UseFormSetValue<any>;
  returnTypeName?: string;
  executedValueName?: string;
  dataSet?: Record<string, Dataset>;
  suggestionObjs?: NectedSuggestionModel[];
  isReadOnly?: boolean;
  isAdditionalData?: boolean;
  index?: number;
};

type ExcelNodeSheetResult = {
  nodeQuery: string;
};

export function ExcelModal({
  name,
  control,
  setOriginalValue,
  returnTypeName,
  executedValueName,
  dataSet,
  suggestionObjs = [],
  isReadOnly = false,
  isAdditionalData = false,
  index = 0,
}: ExcelModalProps) {
  const [isQueryValidNected, setIsQueryValidNected] = useState(true);
  const [hasChanged, setHasChanged] = useState(false);
  const [returnType, setReturnType] = useState<string | null>(null);
  const [isReturnTypeRequired, setIsReturnTypeRequired] = useState(false);
  const [executedValue, setExecutedValue] = useState<any | null>(null);
  const [isMounted, setIsMounted] = useState(false);
  const [filteredDataset, setFilteredDataset] = useState<
    Record<string, Dataset>
  >({});

  const [ruleType] = useAtom(createRuleSheetAtom);
  const [, setSuggestions] = useAtom(suggestionsAtom);

  const { close } = useCurrentLayer();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const results: DecisionTableResultRow[] = useWatch({
    name: 'results',
    control,
  });

  const rows = useWatch({ control, name: 'rows' });

  const { openWithProps: openSaveNodeModal } = useLayer(<SaveNodeModal />);
  const { open: onCloseModal } = useLayer(
    <OnCloseNodeModal onClose={() => close()} />
  );

  const nodeQuery = useWatch({ control, name });

  const onSetReturnType = (type: any) => {
    if (type === 'undefined' || type === null) {
      setReturnType('undefined');
    }

    setReturnType(type);
    setIsReturnTypeRequired(false);
  };

  const formatAttributes = (
    attributes: Record<string, Attributes>
  ): Record<string, Attributes> =>
    Object.fromEntries(
      Object.entries(attributes).map(([key, attribute]) => [
        key,
        {
          ...attribute,
          executedValue: getEvaluatedExValueForResult(
            attribute.executedValue,
            attribute.dataType,
            false,
            true
          ),
        },
      ])
    );

  const formatExecutedValues = (
    obj: Record<string, Dataset>
  ): Record<string, Dataset> =>
    Object.fromEntries(
      Object.entries(obj).map(([sectionKey, section]) => {
        if (typeof section === 'object' && !_isNil(section.attributes)) {
          const updatedAttributes = formatAttributes(section.attributes);

          return [sectionKey, { ...section, attributes: updatedAttributes }];
        }

        return [sectionKey, section];
      })
    );

  useEffect(() => {
    if (!_isNil(dataSet)) {
      setFilteredDataset(formatExecutedValues({ ...dataSet }));
    }
  }, [JSON.stringify(getDatasetWithoutReactCode(dataSet))]);

  useEffect(() => {
    const suggestionList: string[] = [];
    const suggestionListObj: NectedSuggestionModel[] = [];

    const resultAddDataModels: ResultAddDataModel[] = [];

    const outputDataAttributes: Record<string, Attributes> = {};

    results?.forEach((field, currIndex) => {
      const fieldKey = getRequiredKey(field, ['id']);
      const currentResult = field[fieldKey];

      const rowKey = getRequiredKey(rows[0], ['id']);
      const firstRow = rows[0][rowKey];

      const ruleResultKey = getRequiredKey(firstRow.ruleResult[currIndex], [
        'id',
      ]);

      const firstOutput = firstRow.ruleResult[currIndex]?.[ruleResultKey] ?? {};

      const { source, attribute } = firstOutput;

      const mappedValue =
        !_isNil(source) && !_isNil(attribute) && !_isNil(dataSet?.[source])
          ? getPropertyIfExists(
              JSON.parse(
                JSON.stringify(
                  Object.keys(dataSet?.[source].attributes ?? {}).reduce(
                    (acc, curr) => {
                      return {
                        ...acc,
                        [curr]:
                          dataSet?.[source].attributes[`${curr}`].executedValue,
                      };
                    },
                    {}
                  )
                )
              ) ?? {},
              attribute
            )
          : firstOutput.value;

      if (
        currentResult.dataType !== 'json' &&
        currentResult.dataType !== 'jsFormula' &&
        currentResult.dataType !== 'excelFormula'
      ) {
        if (isAdditionalData) {
          suggestionList.push(`<<outputDataList.${currentResult.keyName}>>`);
          suggestionListObj.push({
            name: `<<outputDataList.${currentResult.keyName}>>`,
            value: `<<outputDataList.${currentResult.keyName}>>`,
            meta: `array[${TokenDataTypeByDataType[currentResult.dataType]}]`,
            score: TokenScores.outputDataList,
            executedValue: [
              convertCaSampleValues(currentResult.dataType, firstOutput.value),
            ],
          });

          if (currIndex < index) {
            // TODO: Might need to change dataType and add all executed values depending on editor support
            outputDataAttributes[currentResult.keyName] = {
              name: currentResult.keyName,
              dataType: `array[${currentResult.dataType}]` as DataTypes,
              executedValue: [
                convertCaSampleValues(currentResult.dataType, mappedValue),
              ],
            };
          }
        } else {
          if (currIndex < index) {
            outputDataAttributes[currentResult.keyName] = {
              name: currentResult.keyName,
              dataType: currentResult.dataType as DataTypes,
              executedValue: convertCaSampleValues(
                currentResult.dataType,
                mappedValue
              ),
            };
          }
        }
      } else {
        suggestionList.push(
          `<<outputData${isAdditionalData ? 'List' : ''}.${
            currentResult.keyName
          }>>`
        );

        suggestionListObj.push({
          name: `<<outputData${isAdditionalData ? 'List' : ''}.${
            currentResult.keyName
          }>>`,
          value: `<<outputData${isAdditionalData ? 'List' : ''}.${
            currentResult.keyName
          }>>`,
          meta: isAdditionalData
            ? `array[${
                ['jsFormula', 'excelFormula'].includes(currentResult.dataType)
                  ? (getDataTypeByReturnType(firstOutput) as string)
                  : TokenDataTypeByDataType[currentResult.dataType]
              }]`
            : TokenDataTypeByDataTypeSingle[currentResult.dataType],
          score: TokenScores[`outputData${isAdditionalData ? 'List' : ''}`],
          executedValue: getOutputDataByExecutedValue(
            currentResult,
            isAdditionalData,
            firstOutput
          ),
        });

        if (currIndex < index) {
          const calculatedDataType = (
            isAdditionalData
              ? `array[${
                  ['jsFormula', 'excelFormula'].includes(currentResult.dataType)
                    ? (getDataTypeByReturnType(firstOutput) as string)
                    : TokenDataTypeByDataType[currentResult.dataType]
                }]`
              : ['jsFormula', 'excelFormula'].includes(currentResult.dataType)
              ? (getDataTypeByReturnType(firstOutput) as string)
              : TokenDataTypeByDataType[currentResult.dataType]
          ) as DataTypes;

          // TODO: Might need to change dataType and add all executed values depending on editor support
          outputDataAttributes[currentResult.keyName] = {
            name: currentResult.keyName,
            dataType: calculatedDataType,
            executedValue: getOutputDataByExecutedValue(
              currentResult,
              isAdditionalData,
              firstOutput
            ),
          };
        }
      }

      resultAddDataModels.push({
        keyName: currentResult.keyName,
        dataType: currentResult.dataType,
        value: '',
      });
    });

    if (ruleType === 'decisionTable') {
      setSuggestions(suggestionList);

      if (isAdditionalData) {
        const finalRows = getDtOutputRows(results, rows, dataSet ?? {});
        setFilteredDataset((prev) => ({
          ...prev,
          outputData: {
            attributes: {
              output: {
                dataType: 'list',
                name: 'output',
                executedValue: finalRows,
              },
            },
            name: 'Output Data Attributes',
            id: 'output_data_attributes',
          },
        }));
      } else {
        setFilteredDataset((prev) => ({
          ...prev,
          [isAdditionalData ? 'outputDataList' : 'outputData']: {
            attributes: outputDataAttributes,
            name: 'Output Data Attributes',
            id: 'output_data_attributes',
          },
        }));
      }
    }
  }, [
    results,
    rows,
    ruleType,
    JSON.stringify(getDatasetWithoutReactCode(dataSet)),
  ]);

  const onExecutionValueSave = (execValue: any) => {
    if (!_isUndefined(execValue)) {
      setExecutedValue(execValue);
    } else {
      setExecutedValue(undefined);
      openSaveNodeModal({
        onSave: () => {
          if (!_isNil(setOriginalValue)) {
            if (!_isNil(returnTypeName) && !_isEmpty(returnTypeName)) {
              setOriginalValue(returnTypeName, undefined);
            }

            if (!_isNil(executedValueName) && !_isEmpty(executedValueName)) {
              setOriginalValue(executedValueName, undefined);
            }

            setOriginalValue(name, watch('nodeQuery'));
          }

          close();
        },
      });
    }
  };

  const { executedValue: execValues, handleGetExecutionValues } =
    useUpdateExecutedValueRules({
      updatedDataset: filteredDataset ?? {},
    });

  const { tokens } = useGenerateDataset({
    updatedDataset: filteredDataset ?? {},
  });

  const {
    control: localControl,
    handleSubmit,
    watch,
  } = useForm<ExcelNodeSheetResult>({
    defaultValues: {
      nodeQuery: _isEmpty(nodeQuery) || _isNil(nodeQuery) ? '' : nodeQuery,
    },
    mode: 'onSubmit',
  });
  const localQuery = watch('nodeQuery');

  const onSubmit = (data: any) => {
    if (!isQueryValidNected || _isEmpty(localQuery)) {
      toasts.error('Formula is invalid or empty', 'formula-invalid-empty');

      return;
    }

    setIsReturnTypeRequired(true);
  };

  useEffect(() => {
    if (
      !_isEmpty(localQuery) &&
      isMounted &&
      typeof setOriginalValue === 'function'
    ) {
      if (!_isNil(executedValueName) && !_isEmpty(executedValueName)) {
        setOriginalValue(executedValueName, executedValue);
      }
    }

    if (
      !_isEmpty(localQuery) &&
      !_isNil(returnType) &&
      returnType !== 'undefined' &&
      typeof setOriginalValue === 'function'
    ) {
      if (!_isNil(returnTypeName) && !_isEmpty(returnTypeName)) {
        setOriginalValue(returnTypeName, returnType);
      }

      setOriginalValue(name, watch('nodeQuery'));
      close();
    } else if (isMounted && returnType !== 'undefined') {
      toasts.error(
        'The type of the last statement is not supported',
        'type-last-statement-not-supported'
      );
    }
  }, [returnType, executedValue]);

  return (
    <Modal
      onClose={() => {
        if (hasChanged) {
          onCloseModal();
        } else {
          close();
        }
      }}
      size="extraLargeXS"
      hideHeader
      padding={'1rem'}
    >
      <ModalContent>
        <Stack as="form" gutter={8} onSubmit={handleSubmit(onSubmit)}>
          <ExcelEditor>
            <NectedEditorField
              name="nodeQuery"
              control={localControl}
              showError={false}
              readOnly={isReadOnly}
              customSuggestions={tokens}
              setHasEditorChanged={setHasChanged}
              onSetEditorValidity={setIsQueryValidNected}
              mode="formula"
              setReturnType={onSetReturnType}
              setExecutedValue={onExecutionValueSave}
              sendReturnType={isReturnTypeRequired}
              domain={editorDomain}
              execValues={execValues}
              handleGetExecData={handleGetExecutionValues}
              defaultValue=""
            />
          </ExcelEditor>

          <ModalFooter>
            <Button
              type="button"
              appearance="neutral"
              onClick={() => {
                if (hasChanged) {
                  onCloseModal();
                } else {
                  close();
                }
              }}
            >
              Close
            </Button>
            <Button disabled={isReadOnly} type="submit">
              Save & Close
            </Button>
          </ModalFooter>
        </Stack>
      </ModalContent>
    </Modal>
  );
}
