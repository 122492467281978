import { PadBox } from '@bedrock-layout/padbox';
import { Inline, Stack } from '@bedrock-layout/primitives';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  CheckboxField,
  ColorPickerField,
  DropdownField,
  Toast,
  TooltipReact,
  Typography,
  setLocalStorageItem,
  toasts,
} from 'ui';

import { allWorkspaceAtom, siteConstantsAtom } from '../../../../atom';
import { getValueFromObject } from '../../../../components/Listing/utils';
import { PermissionWrapper } from '../../../../components/PermissionComponent';
import { permissionObj } from '../../../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../../../components/PermissionComponent/hooks/useCheckPermissions';
import { useAxiosPrivate } from '../../../../hooks';
import {
  formatWorkflowAttribute,
  getTooltipText,
  transformNectedWorkspaceThemeData,
  validateWorkspaceSettings,
} from '../../../../utils/common';
import {
  ENTITY_ID,
  TOOLTIP_COLORS_BY_KEY,
  WORKSPACE_BASIC_THEME,
  WORKSPACE_THEME_FONT_FAMILY,
  envMap,
} from '../../../../utils/constant';
import { handleKeyDown } from '../../../../utils/form';
import { currentWorkspaceDetailAtom } from '../../atom';
import { useUpdateCurrentWorkspace } from '../../hooks/restApi/useUpdateCurrentWorkspace';
import { WorkspaceContainer } from '../common/Workspace.styled';
import type { WorkSpaceDetailsType } from '../types';
import { DateTimeSettingPopover } from './DateTimeSettingPopover';
import {
  ButtonContainer,
  WSColorWrapper,
  WSTextField,
  WorkSpaceFormFieldContainer,
} from './WorkspaceSettings.styled';
import { WorkspaceUploader } from './WorkspaceUploader';
import { timezoneOptions, workspaceSettingsObject } from './constant';
import { settingsFormSchema } from './schema';
import { InfoBlock } from '../../../Rules/components/Triggers/Scheduler/SchedulerResult.styled';

export function WorkspaceSettings() {
  const {
    WORKSPACE_ID,
    WORKSPACE_NAME,
    WORKSPACE_DOMAIN,
    APPROVAL_FLOW_ENABLED,
    WORKSPACE_THEME,
    WORKSPACE_THEME_ENABLED,
    ENFORCE_MODULE_SCHEMA,
    TIMEZONE,
    DATE_FORMAT,
  } = workspaceSettingsObject;
  const { axiosPrivate } = useAxiosPrivate();

  const [currentWorkspace, setCurrentWorkspace] = useAtom(
    currentWorkspaceDetailAtom
  );
  const [allWorkspaceList, setAllWorkspaceAtom] = useAtom(allWorkspaceAtom);
  const [siteConstant] = useAtom(siteConstantsAtom);

  const {
    uuid,
    name,
    subDomain,
    approvalFlowEnabled,
    theme,
    enforceModuleSchema,
    whiteLabelEnabled = false,
    wsWhiteLabelAllowed = false,
    timezone,
    dateFormat,
  } = !_isNil(currentWorkspace)
    ? { ...currentWorkspace }
    : {
        uuid: '',
        name: '',
        subDomain: '',
        approvalFlowEnabled: false,
        whiteLabelEnabled: false,
        theme: WORKSPACE_BASIC_THEME,
        wsWhiteLabelAllowed: true,
        enforceModuleSchema: false,
        timezone: '',
        dateFormat: 'in',
      };

  const allowThemeChange =
    wsWhiteLabelAllowed || envMap.VITE_FULL_WHITELABELLED === 'true';

  const plan = JSON.parse(window.sessionStorage.getItem('userPlan') ?? '{}');

  const { control, setValue, handleSubmit, setError, watch } = useForm<any>({
    resolver: zodResolver(settingsFormSchema),
    defaultValues: {
      [WORKSPACE_ID]: uuid,
      [WORKSPACE_NAME]: name,
      [WORKSPACE_DOMAIN]: subDomain,
      [APPROVAL_FLOW_ENABLED]: approvalFlowEnabled,
      [ENFORCE_MODULE_SCHEMA]: enforceModuleSchema,
      [WORKSPACE_THEME]: transformNectedWorkspaceThemeData(
        theme ?? WORKSPACE_BASIC_THEME
      ),
      [WORKSPACE_THEME_ENABLED]: whiteLabelEnabled,
      [TIMEZONE]: timezone,
      [DATE_FORMAT]: dateFormat,
    },
    mode: 'onSubmit',
  });


  const entityList = [ENTITY_ID.workspace];
  const { isHide: disableNameField } = useCheckPermissions({
    allowedPermission: [permissionObj.create, permissionObj.edit],
    entityList,
  });

  // eslint-disable-next-line
  const { workspaceData, updateCurrentWorkspace, isLoading } =
    useUpdateCurrentWorkspace();

  useEffect(() => {
    if (!isLoading && workspaceData?.status === 200) {
      void fetchUpdatedWorspaceDetails();
    }
  }, [isLoading]);

  useEffect(() => {
    if (!_isNil(currentWorkspace) && !_isEmpty(currentWorkspace)) {
      setValue(WORKSPACE_ID, uuid);
      setValue(WORKSPACE_NAME, name);
      setValue(WORKSPACE_DOMAIN, subDomain);
      setValue(APPROVAL_FLOW_ENABLED, approvalFlowEnabled);
      setValue(ENFORCE_MODULE_SCHEMA, enforceModuleSchema);
      setValue(
        WORKSPACE_THEME,
        transformNectedWorkspaceThemeData(theme ?? WORKSPACE_BASIC_THEME)
      );
      setValue(
        TIMEZONE,
        timezoneOptions.find(
          (option: { value: string }) => option.value === timezone
        ) ??
          timezoneOptions.find(
            (option: { value: string }) => option.value === ''
          )
      );
      setValue(DATE_FORMAT, dateFormat === "" ? 'in' : dateFormat);
    }
  }, [JSON.stringify(currentWorkspace)]);

  const fetchUpdatedWorspaceDetails = async () => {
    const response = await axiosPrivate.get(`/workspace?workspace=${uuid}`);

    const updatedWsDetails =
      response?.data?.data?.filter(
        (i: { uuid: string }) => i.uuid === uuid
      )[0] ?? response?.data?.data?.[0];

    if (!_isNil(updatedWsDetails)) {
      setAllWorkspaceAtom(
        (allWorkspaceList ?? [])?.map((obj) => {
          if (obj.uuid === uuid) {
            return updatedWsDetails;
          }

          return obj;
        })
      );

      setCurrentWorkspace(updatedWsDetails);
    }
  };

  const onSubmit = async (data: WorkSpaceDetailsType) => {
    const fData = formatWorkflowAttribute(
      data,
      // eslint-disable-next-line
      !allowThemeChange || !data.whiteLabelEnabled
    );

    const isValid = validateWorkspaceSettings(fData, setError);

    if (!isValid) {
      return;
    }

    if (
      fData?.theme?.logoUrl === '/assets/konark/images/logo.svg' ||
      fData?.theme?.logoUrl === envMap.VITE_COMPANY_LOGO_URL
    ) {
      fData.theme.logoUrl = '';
    }

    if (
      fData?.theme?.favicon === '/favicon.ico' ||
      fData?.theme?.favicon === envMap.VITE_THEME_FAV_ICON
    ) {
      fData.theme.favicon = '';
    }

    if (!_isNil(fData.timezone)) {
      fData.timezone = fData.timezone.value;
    }
    updateCurrentWorkspace({
      params: fData,
    });

    if (allowThemeChange) {
      setLocalStorageItem('local-theme', JSON.stringify(fData.theme));
    }

    if (!_isNil(fData?.timezone)) {
      window.sessionStorage.setItem('nected-tz', fData?.timezone);
    }

    if (!_isNil(fData?.dateFormat)) {
      window.sessionStorage.setItem(
        'nected-df',
        fData?.dateFormat as unknown as string
      );
    }
    toasts.success('Workspace updated successfully', 'updated-success');

  };

  const whiteLabelEnabledForm = watch('whiteLabelEnabled');

  return (
    <form onKeyDown={handleKeyDown} onSubmit={handleSubmit(async (data) => {
      await onSubmit(data);
    })}>
      <PadBox padding={'2.4rem'} gutter={'2.4rem'} as={WorkspaceContainer}>
        <WorkSpaceFormFieldContainer>
          <WSTextField
            control={control}
            name={WORKSPACE_NAME}
            label="Workspace Name"
            placeholder="Enter Workspace Name"
            size="medium"
            disabled={disableNameField}
            labelFontWeight={700}
            heightFull
          />
        </WorkSpaceFormFieldContainer>
        <WorkSpaceFormFieldContainer>
          <WSTextField
            control={control}
            name={WORKSPACE_DOMAIN}
            label="Workspace Domain"
            placeholder="Enter Workspace Domain"
            size="medium"
            disabled
            labelFontWeight={700}
            heightFull
          />
        </WorkSpaceFormFieldContainer>

        <WorkSpaceFormFieldContainer>
          <Inline
            id={getValueFromObject(plan, 'plan.approvalFlow.componentId')}
            data-premium-component-id={getValueFromObject(
              plan,
              'plan.approvalFlow.componentId'
            )}
            data-premium-component-trigger={getValueFromObject(
              plan,
              'plan.approvalFlow.trigger'
            )}
            align="center"
          >
            <CheckboxField
              control={control}
              name={APPROVAL_FLOW_ENABLED}
              showError
              appearance="switch"
              useId={APPROVAL_FLOW_ENABLED}
              disabled={disableNameField}
            />
            <Typography fontWeight={700}>
              Make Approval Flow Mandatory
            </Typography>
          </Inline>
        </WorkSpaceFormFieldContainer>

        <WorkSpaceFormFieldContainer>
          <Inline
            id={getValueFromObject(plan, 'plan.module.componentId')}
            data-premium-component-id={getValueFromObject(
              plan,
              'plan.module.componentId'
            )}
            data-premium-component-trigger={getValueFromObject(
              plan,
              'plan.module.trigger'
            )}
            align="center"
          >
            <CheckboxField
              control={control}
              name={ENFORCE_MODULE_SCHEMA}
              showError
              appearance="switch"
              useId={ENFORCE_MODULE_SCHEMA}
              disabled={disableNameField}
            />
            <Typography fontWeight={700}>
              Make Attribute Library Mandatory
            </Typography>
          </Inline>
        </WorkSpaceFormFieldContainer>

        {wsWhiteLabelAllowed && (
          <WorkSpaceFormFieldContainer>
            <Inline
              id={getValueFromObject(plan, 'plan.approvalFlow.componentId')}
              align="center"
            >
              <CheckboxField
                control={control}
                name={WORKSPACE_THEME_ENABLED}
                showError
                appearance="switch"
                useId={WORKSPACE_THEME_ENABLED}
                disabled={disableNameField}
              />
              <Typography fontWeight={700}>Enable White Labelling</Typography>
            </Inline>
          </WorkSpaceFormFieldContainer>
        )}

        {
          // eslint-disable-next-line
          wsWhiteLabelAllowed && whiteLabelEnabledForm && (
            <>
              <WorkSpaceFormFieldContainer>
                <WSTextField
                  control={control}
                  name={'theme.title'}
                  label="Company Name"
                  placeholder="Enter Custom Company Name"
                  size="medium"
                  labelFontWeight={700}
                  heightFull
                />

                <Stack gutter="0.5rem">
                  <Typography fontWeight={700}>Font Family</Typography>

                  <DropdownField
                    name="theme.baseFontFamily"
                    control={control}
                    options={WORKSPACE_THEME_FONT_FAMILY}
                  />
                </Stack>

                <WSTextField
                  control={control}
                  name={'theme.baseFontSize'}
                  label="Base font size (in px)"
                  type="number"
                  placeholder="Enter Base font size (in px)"
                  size="medium"
                  labelFontWeight={700}
                  heightFull
                />
                <Stack gutter="0.5rem">
                  <Typography fontWeight={700}>Favicon</Typography>

                  <WorkspaceUploader
                    setValue={setValue}
                    control={control}
                    name="theme.favicon"
                  />
                </Stack>
                <Stack gutter="0.5rem">
                  <Typography fontWeight={700}>Company Logo</Typography>

                  <WorkspaceUploader
                    setValue={setValue}
                    control={control}
                    name="theme.logoUrl"
                  />
                </Stack>
              </WorkSpaceFormFieldContainer>
              <Stack gutter={'1rem'}>
                <Typography fontWeight={700}>Colors:</Typography>

                <WSColorWrapper>
                  {(theme ?? WORKSPACE_BASIC_THEME)?.colors.primary.map(
                    (color: any, i: number) => {
                      return (
                        <Stack
                          gutter="0.5rem"
                          // eslint-disable-next-line
                          key={`${color.key}__${i}`}
                          style={{
                            width: '30%',
                          }}
                        >
                          <Inline gutter={10} align="center">
                            <Typography fontWeight={700}>
                              {color.name}
                            </Typography>
                            {!_isNil(TOOLTIP_COLORS_BY_KEY[color.key]) ? (
                              <TooltipReact
                                id={`tooltip_icon_${
                                  color.key as unknown as string
                                }`}
                              >
                                <Typography>
                                  {TOOLTIP_COLORS_BY_KEY[color.key]}
                                </Typography>
                              </TooltipReact>
                            ) : null}
                          </Inline>
                          <ColorPickerField
                            name={`theme.colors.primary.${
                              i as unknown as string
                            }.value`}
                            control={control}
                          />
                        </Stack>
                      );
                    }
                  )}
                </WSColorWrapper>
              </Stack>
            </>
          )
        }

        <WorkSpaceFormFieldContainer gutter={'1rem'}>
          <Inline align="center">
            <Typography fontWeight={700}>Dateformat</Typography>
          </Inline>

          <DateTimeSettingPopover control={control} setValue={setValue} />

          

        </WorkSpaceFormFieldContainer>

        <WorkSpaceFormFieldContainer gutter={'1rem'}>
          <Typography fontWeight={700}>Time Zone</Typography>
          <DropdownField
            control={control}
            placeholder="Select Timezone"
            name={TIMEZONE}
            isMulti={false}
            showCustomMenuV2
            options={timezoneOptions}
          />
          <Toast
              message="Updating dateformat and timezone settings in the workspace won't affect existing Rules and Workflows"
              type="infoBlue"
            />
        </WorkSpaceFormFieldContainer>
        <PermissionWrapper
          allowedPermission={[permissionObj.create, permissionObj.edit]}
          entityList={entityList}
        >
          <ButtonContainer>
            <Button type="submit">Save</Button>
          </ButtonContainer>
        </PermissionWrapper>
      </PadBox>
    </form>
  );
}
