import { useAtom } from 'jotai';
import { useEffect } from 'react';

import { ruleWarningsAtom } from '..';
import { AttributeModel } from '../models';
import { isTokenMappedInCI } from '../utils/common';
import { currentWorkspaceDetailAtom } from '../../Workspace/atom';

const PRIMARY_KEY_WARNING = 'You have not mapped any primary keys in dataset. Without primary key mapping, this rule will fetch the whole data as per your dataset query.';
const TIMEZONE_MISMATCH_WARNING = 'Your current timezone settings is different from the workspace timezone settings.';


export function useCIWarning() {
  const [ruleWarnings, setRuleWarnings] = useAtom(ruleWarningsAtom);
  const [currWorkspaceDetails] = useAtom(currentWorkspaceDetailAtom);

  useEffect(() => {
    const sessionTimezone = window.sessionStorage.getItem('nected-tz');
    const workspaceTimezone = currWorkspaceDetails?.timezone;
    
    if (workspaceTimezone && sessionTimezone && workspaceTimezone !== sessionTimezone) {
      addWarning(TIMEZONE_MISMATCH_WARNING);
    } else {
      removeWarning(TIMEZONE_MISMATCH_WARNING);
    }
  }, [currWorkspaceDetails?.timezone]);

  
  const addWarning = (warning: string) => {
    setRuleWarnings((prev = []) => {
      const prevWarnings = Array.isArray(prev) ? prev : [];
      return prevWarnings.includes(warning) ? prevWarnings : [...prevWarnings, warning];
    });
  };

  
  const removeWarning = (warning: string) => {
    setRuleWarnings((prev = []) => {
      const prevWarnings = Array.isArray(prev) ? prev : [];
      return prevWarnings.filter(w => w !== warning);
    });
  };

  
  const handleSetWarning = (
    customInput: Record<string, AttributeModel>,
    dataSetId?: string,
    selectedDataSets?: Record<string, any>
  ) => {
    if (!customInput) {
      return;
    }

    const isTokenMapped = isTokenMappedInCI(customInput, dataSetId);
    
    if (!isTokenMapped) {
      addWarning(PRIMARY_KEY_WARNING);
    } else {
      removeWarning(PRIMARY_KEY_WARNING);
    }
  };

  return {
    handleSetWarning,
    warnings: ruleWarnings || [],
  };
}