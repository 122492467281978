/* eslint-disable no-console */
import _reduce from 'lodash/reduce';
import { useEffect, useState } from 'react';
import { Dataset, NectedSuggestionModel } from 'ui';

import { getDatasetWithoutReactCode } from '../../Rules/utils/common';
import { useWorker } from '../workers/useWorker';

type UseGenerateDatasetArgs = {
  updatedDataset: Record<string, Dataset>;
  id?: string;
};

export function useGenerateDataset({
  updatedDataset,
  id,
}: UseGenerateDatasetArgs) {
  const [tokens, setTokens] = useState<NectedSuggestionModel[]>([]);
  const { workerApi } = useWorker();

  useEffect(() => {
    // In some cases Tooltip and footer is present which are not cloned by the worker
    // internally and are not used for token generation so ignoring them here
    const dataset = _reduce(
      updatedDataset,
      (finalDataset, currDataset, key) => {
        return {
          ...finalDataset,
          [key]: {
            id: currDataset.id,
            name: currDataset.name,
            attributes: currDataset.attributes,
          },
        };
      },
      {}
    );
    const timezoneOptions = {
      timezone : window.sessionStorage.getItem('nected-tz'),
      dateFormat : window.sessionStorage.getItem('nected-df')
    }
    workerApi
      .getDataSetSuggestionsObjV2Worker(timezoneOptions , dataset)
      .then((res: NectedSuggestionModel[]) => {
        setTokens(res);
      });
  }, [JSON.stringify(getDatasetWithoutReactCode(updatedDataset))]);

  return {
    tokens,
  };
}
