import { useEffect } from 'react';
import { ErrorPopover } from './ErrorPopover';
import { PositionedErrorContainer } from './ErrorPopoverPositioned.styled';

type ErrorPopoverPositionedProps = {
  error: string;
};

export function ErrorPopoverPositioned({ error }: ErrorPopoverPositionedProps) {

  useEffect(() => {
    if (error && typeof window !== 'undefined' && typeof window.reportToSentry === 'function') {
      window.reportToSentry(error, {
        context: 'rule-field-errors',
        level: 'error',
        extra: {
          componentName: 'ErrorPopoverPositioned',
          timestamp: new Date().toISOString()
        }
      });
    }
  }, [error]);

  return (
    <PositionedErrorContainer>
      <ErrorPopover error={error} />
    </PositionedErrorContainer>
  );
}
