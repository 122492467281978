import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const JsPillStyled = styled(PadBox)<{ $hasError: boolean }>`
  min-inline-size: 4rem;
  border-radius: 0.8rem;
  cursor: pointer;
  background: var(--color-lavender);
  outline: 1px solid
    ${({ $hasError }) =>
      $hasError ? 'var(--color-fireEngineRed)' : 'var(--color-lavender)'};
`;

export const FieldJsPillStyled = styled(PadBox)<{ $hasError: boolean }>`
  border-radius: 0.4rem;
  cursor: pointer;
  background: var(--color-gainsboro);
  outline: 1px solid
    ${({ $hasError }) =>
      $hasError ? 'var(--color-fireEngineRed)' : 'var(--color-lavender)'};
`;
