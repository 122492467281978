import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { RxDragHandleDots2 } from 'react-icons/rx';

import { useHandleRuleMenuActions } from '../../../hooks/useHandleRuleMenuActions';
import { isRuleReadOnlyAtom } from '../../../index';
import { isFirstChild, isUnGroupAble } from '../../../utils/common';
import { OperatorParamPopover } from '../RulePopovers/OperatorParamPopover';
import { RhsParamPopover } from '../RulePopovers/RhsParamPopover';
import { RuleParamPopover } from '../RulePopovers/RuleParamPopover';
import { simpleRuleNodesAtom } from '../SimpleRule';
import { SimpleRuleNodesModel } from '../models';
import { GroupOperatorSelection } from './GroupOperatorSelection';
import { sendEventToGTMType } from './RuleBlock';
import { ConditionStyled, RuleConditionStyled } from './RuleBlock.styled';
import { RuleMenu } from './RuleMenu';
import { DragContainer } from './RuleSqlCondition.styled';

export type RuleConditionProps = {
  ruleId: string;
  rule: SimpleRuleNodesModel;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
};

export const RuleCondition = ({
  rule,
  ruleId,
  handleSendEventToGTM,
}: RuleConditionProps) => {
  const [rules] = useAtom(simpleRuleNodesAtom);
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const operator = rules[rule.parent]?.operator;

  const siblings = rules[rule.parent]?.children;

  const numberOfSiblings = !_isNil(siblings) ? siblings.length : 0;
  const { convertConditionToGroup, handleDelete, unGroupAnElement } =
    useHandleRuleMenuActions({
      rule,
      ruleId,
    });

  return (
    <RuleConditionStyled $isReadOnly={isRuleReadOnly}>
      {!isFirstChild(rules, ruleId) &&
        !_isNil(operator) &&
        !_isEmpty(operator) && (
          <PadBox padding={[5, 30]}>
            <GroupOperatorSelection id={rule.parent} operator={operator} />
          </PadBox>
        )}
      <Inline gutter={0} align="center">
        <ConditionStyled padding={[5, 5]}>
          <Inline gutter={10}>
            <DragContainer>
              <RxDragHandleDots2 size={25} color={'var(--color-lightGray)'} />
            </DragContainer>

            {rule?.leftNode?.map((left, index) => (
              <RuleParamPopover
                ruleId={ruleId}
                nodeId={left}
                key={`leftNode_${index}`}
                handleSendEventToGTM={handleSendEventToGTM}
              />
            ))}

            <OperatorParamPopover
              operator={rule.operator}
              ruleId={ruleId}
              handleSendEventToGTM={handleSendEventToGTM}
            />

            <Stack id="rhs-node">
              {rule.rightNode?.map((right, index) => (
                <RhsParamPopover
                  ruleId={ruleId}
                  nodeId={right}
                  leftNodeId={rule?.leftNode?.[0]}
                  key={`rightNode_${index}`}
                  handleSendEventToGTM={handleSendEventToGTM}
                />
              ))}
            </Stack>
          </Inline>
        </ConditionStyled>

        <RuleMenu
          showDelete={numberOfSiblings > 1}
          convertIntoGroup
          handleConvertIntoGroup={() => convertConditionToGroup(rule, ruleId)}
          handleDelete={handleDelete}
          unGroupItem={isUnGroupAble(rules, ruleId)}
          handleUnConvert={unGroupAnElement}
        />
      </Inline>
    </RuleConditionStyled>
  );
};
