import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import { Dataset } from '../../../TreeViewer';
import type { Attributes } from '../RulePopover';

// Used to check if searchKey is present in nth level nested object (obj),
// if present then update filteredFlatRecords
function checkKeyIsPresentInNestedObject(
  obj: any,
  searchKey: string,
  parentKey: string,
  filteredFlatRecords: Record<string, any>
) {
  if (_isNil(obj)) {
    return;
  }

  if (Array.isArray(obj)) {
    obj.forEach((item, index) => {
      checkKeyIsPresentInNestedObject(
        item,
        searchKey,
        `${parentKey}[${index}]`,
        filteredFlatRecords
      );
    });
  } else if (typeof obj === 'object') {
    Object.keys(obj).forEach((key) => {
      if (key.toLowerCase().includes(searchKey.toLowerCase())) {
        filteredFlatRecords[parentKey] = obj;
        filteredFlatRecords[`${parentKey}.${key}`] = obj[key];
      }

      if (typeof obj[key] === 'object') {
        checkKeyIsPresentInNestedObject(
          obj[key],
          searchKey,
          `${parentKey}.${key}`,
          filteredFlatRecords
        );
      }
    });
  }
}

function search(
  data: Record<string, any>,
  value: any,
  path: string[],
  filteredFlatRecords: Record<string, any>
) {
  if (typeof data !== 'object') {
    return {};
  }

  if (!_isNil(data?.attributes) && !_isEmpty(data.attributes)) {
    for (const [attrKey, attrValue] of Object.entries<Attributes>(
      data.attributes
    )) {
      const sampleValue = attrValue?.executedValue;

      const key = path.concat(attrKey).join('.');

      if (attrValue.name.toLowerCase().includes(value.toLowerCase())) {
        filteredFlatRecords[key] = attrValue;
      } else if (typeof sampleValue === 'object' && !_isNil(sampleValue)) {
        if (attrValue.dataType === 'json' || attrValue.dataType === 'restAPI') {
          Object.keys(sampleValue).forEach((k) => {
            if (k.toLowerCase().includes(value.toLowerCase())) {
              filteredFlatRecords[key] = attrValue;
              filteredFlatRecords[`${key}.${k}`] = sampleValue[k];
            }

            if (typeof sampleValue[k] === 'object') {
              checkKeyIsPresentInNestedObject(
                sampleValue[k],
                value,
                `${key}.${k}`,
                filteredFlatRecords
              );
            }
          });
        } else if (attrValue.dataType === 'list') {
          sampleValue.forEach((item: any, index: number) => {
            if (
              `${key}[${index}]`.toLowerCase().includes(value.toLowerCase())
            ) {
              filteredFlatRecords[key] = attrValue;
              filteredFlatRecords[`${key}[${index}]`] = item;
            }

            if (typeof item === 'object') {
              checkKeyIsPresentInNestedObject(
                item,
                value,
                `${key}[${index}]`,
                filteredFlatRecords
              );
            }
          });
        }
      }
    }
  }

  for (const [k, v] of Object.entries(data)) {
    if (!['attributes', 'footer', 'tooltip'].includes(k)) {
      search(v, value, path.concat(k), filteredFlatRecords);
    }
  }

  return filteredFlatRecords;
}

export function searchAttributes(
  dataset: Record<string, Dataset>,
  value: string
) {
  const filteredFlatRecords: Record<string, any> = search(
    dataset,
    value,
    [],
    {}
  );

  return Object.keys(filteredFlatRecords).length > 0
    ? Array.from(
        new Set(
          Object.keys(filteredFlatRecords).reduce<string[]>(
            (acc, curr: string) => {
              return [...acc, ...curr.split('.')];
            },
            []
          )
        )
      )
    : [];
}

type KeyAndType = {
  key: string;
  type: string;
  value: any;
};

export function flattenKeysAndTypes(
  obj: Record<string, any>,
  depth = 0,
  parentKey = '',
  maxDepth = 6,
  allowList = false,
  allowJson = false
): KeyAndType[] {
  const isArray = allowList ? false : Array.isArray(obj);

  if (
    depth > maxDepth ||
    (typeof obj !== 'object' && !isArray) ||
    obj === null
  ) {
    return [];
  }

  const result: KeyAndType[] = [];
  for (const key in obj) {
    if (!_isNil(obj[key])) {
      const value = obj[key];
      const dataType = getDataTypeNected(value);
      // eslint-disable-next-line

      let currentKey = !_isEmpty(parentKey) ? parentKey + '.' + key : key;

      if (isArray) {
        const indexKey = Number.isInteger(parseInt(key)) ? `[${key}]` : key;
        // eslint-disable-next-line
        currentKey = parentKey ? parentKey + indexKey : indexKey;
      }

      if (typeof value === 'object' && !Array.isArray(value)) {
        if (allowJson && depth === 0) {
          result.push({
            key: currentKey,
            type: 'json',
            value,
          });
        }

        result.push(
          ...flattenKeysAndTypes(
            value,
            depth + 1,
            currentKey,
            maxDepth,
            allowList,
            allowJson
          )
        );
      } else {
        result.push({
          key: currentKey,
          type:
            (dataType as unknown as any) === 'object'
              ? 'list'
              : KEY_NAME_BY_DATATYPE[dataType] ?? dataType,
          value,
        });
      }
    }
  }

  return result;
}

export const KEY_NAME_BY_DATATYPE: Record<string, string> = {
  number: 'numeric',
  object: 'json',
};

export const isValidDateorDateTime = (
  inputString: string,
  from = ''
): DateValidationResult => {
  // Get user preference for date format
  const globalDateSettings = window.sessionStorage.getItem('nected-df') ?? 'in';
  const isUSFormat = globalDateSettings === 'us';

  // Regular expressions for different date formats
  // Standard Date Formats
  const dateFormat1 = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/; // mm/dd/yyyy or dd/mm/yyyy
  const dateFormat2 = /^(\d{4})\/(\d{1,2})\/(\d{1,2})$/; // yyyy/mm/dd
  const dateFormat3 = /^(\d{1,2})-(\d{1,2})-(\d{4})$/; // mm-dd-yyyy or dd-mm-yyyy
  const dateFormat4 = /^(\d{4})-(\d{1,2})-(\d{1,2})$/; // yyyy-mm-dd

  // Standard DateTime Formats
  const dateTimeFormat1 =
    /^(\d{1,2})\/(\d{1,2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})$/; // mm/dd/yyyy HH:mm:ss or dd/mm/yyyy HH:mm:ss
  const dateTimeFormat2 =
    /^(\d{4})\/(\d{1,2})\/(\d{1,2}) (\d{2}):(\d{2}):(\d{2})$/; // yyyy/mm/dd HH:mm:ss
  const dateTimeFormat3 =
    /^(\d{4})-(\d{1,2})-(\d{1,2}) (\d{2}):(\d{2}):(\d{2})$/; // yyyy-mm-dd HH:mm:ss
  const dateTimeFormat4 =
    /^(\d{1,2})-(\d{1,2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})$/; // mm-dd-yyyy HH:mm:ss or dd-mm-yyyy HH:mm:ss

  // ISO and Timezone Formats with nanosecond support
  const dateTimeTZFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/; // yyyy-MM-ddTHH:mm:ssZ
  const dateTimeTZMilliSecFormat =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,9})?Z$/; // yyyy-MM-ddTHH:mm:ss.aaaaaaaaaZ (supports nanoseconds)
  const rfc3339Format =
    /^(\d{4})-(\d{2})-(\d{2})(?:T|\s)(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,9}))?(?:(Z)|(?:([+-])(\d{2}):(\d{2})))$/; // RFC 3339 with optional nanoseconds
    
  // New format for nanosecond precision without timezone
  const nanoSecFormat = 
    /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{1,9})$/; // yyyy-MM-ddTHH:mm:ss.nnnnnnnnn

  // New formats with timezone offset and nanosecond support
  const formattedDashTimeWithTZOffset =
    /^(\d{1,2})-(\d{1,2})-(\d{4})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,9}))?([+-]\d{2}:\d{2})$/; // mm-dd-yyyyTHH:mm:ss.nnnnnnnnn+TZ or dd-mm-yyyyTHH:mm:ss.nnnnnnnnn+TZ
  const formattedSlashTimeWithTZOffset =
    /^(\d{1,2})\/(\d{1,2})\/(\d{4})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,9}))?([+-]\d{2}:\d{2})$/; // mm/dd/yyyyTHH:mm:ss.nnnnnnnnn+TZ or dd/mm/yyyyTHH:mm:ss.nnnnnnnnn+TZ

  // Helper function to check if a date object is valid
  const isValidDate = (date: Date): boolean => !isNaN(date.getTime());

  // Helper function to determine if a date is ambiguous (valid in both US and Indian formats)
  const isAmbiguousDate = (
    first: number,
    second: number,
    year: number
  ): boolean => {
    // Both components must be valid days (1-31) and valid months (1-12)
    if (first >= 1 && first <= 12 && second >= 1 && second <= 31) {
      // Check if second is a valid day for first as a month (US format)
      const lastDayOfMonthUS = new Date(year, first, 0).getDate();
      const validInUS = second <= lastDayOfMonthUS;

      // Check if first is a valid day for second as a month (Indian format)
      const lastDayOfMonthIN = new Date(year, second, 0).getDate();
      const validInIndian = first <= lastDayOfMonthIN;

      // If valid in both formats, it's ambiguous
      return validInUS && validInIndian;
    }

    return false;
  };

  // Helper to test standard date formats and validate the date
  const testAndValidate = (
    regex: RegExp,
    type: 'date' | 'dateTime'
  ): DateValidationResult | null => {
    if (typeof inputString !== 'string') {
      return { isValid: false, type: 'string' };
    }
    const match = (inputString || '').match(regex);

    if (match != null) {
      // For formats that could be either mm/dd or dd/mm based on user preference
      if (
        (regex === dateFormat1 ||
          regex === dateFormat3 ||
          regex === dateTimeFormat1 ||
          regex === dateTimeFormat4) &&
        match[1].length <= 2 &&
        match[2].length <= 2
      ) {
        const first = parseInt(match[1], 10);
        const second = parseInt(match[2], 10);
        const year = parseInt(match[3], 10);

        // Check if the date is ambiguous (valid in both US and Indian formats)
        const ambiguous = isAmbiguousDate(first, second, year);

        // Strict validation for dates during validation mode
        if (from === 'validation') {
          // If we're in validation mode, we need to be more strict about format

          // For ambiguous dates with both components between 1-12
          if (ambiguous && first <= 12 && second <= 12) {
            // In validation mode, we should enforce that dates must be entered in the expected format
            // For ambiguous dates, this means rejecting dates that appear to be in the wrong format

            // If the date components are similar or identical, allow it (like 1-1-2025)
            if (first === second) {
              // This is fine in either format
            }
            // For US format, enforce that dates should be entered as MM-DD-YYYY
            else if (isUSFormat) {
              // For US format, if it looks like DD-MM-YYYY (day typically > month), reject it
              if (first > second && first <= 12) {
                return { isValid: false, type: 'string' }; // Likely DD-MM format but we expect MM-DD
              }
            }
            // For Indian format, enforce that dates should be entered as DD-MM-YYYY
            else {
              // For Indian format, if it looks like MM-DD-YYYY (month typically < day), reject it
              if (second > first && second <= 12) {
                return { isValid: false, type: 'string' }; // Likely MM-DD format but we expect DD-MM
              }
            }
          }
        }

        // Additional validation for US/Indian format
        // For US format (mm/dd), first should be 1-12 (month), second should be 1-31 (day)
        // For Indian format (dd/mm), first should be 1-31 (day), second should be 1-12 (month)
        if (isUSFormat) {
          // Validate US format: first should be month (1-12), second should be day (1-31)
          if (first < 1 || first > 12) {
            return { isValid: false, type: 'string' }; // Invalid month
          }

          // Check if day is valid for the given month
          const lastDayOfMonth = new Date(year, first, 0).getDate();

          if (second < 1 || second > lastDayOfMonth) {
            return { isValid: false, type: 'string' }; // Invalid day for this month
          }
        } else {
          // Validate Indian format: first should be day (1-31), second should be month (1-12)
          if (second < 1 || second > 12) {
            return { isValid: false, type: 'string' }; // Invalid month
          }

          // Check if day is valid for the given month
          const lastDayOfMonth = new Date(year, second, 0).getDate();

          if (first < 1 || first > lastDayOfMonth) {
            return { isValid: false, type: 'string' }; // Invalid day for this month
          }
        }

        // Validate based on user's date format preference
        let date: Date;

        if (type === 'date') {
          if (isUSFormat) {
            // US format: mm/dd/yyyy
            date = new Date(year, first - 1, second);
          } else {
            // Indian format: dd/mm/yyyy
            date = new Date(year, second - 1, first);
          }
        } else {
          // dateTime
          const hours = parseInt(match[4], 10);
          const minutes = parseInt(match[5], 10);
          const seconds = parseInt(match[6], 10);

          // Validate time components
          if (
            hours < 0 ||
            hours > 23 ||
            minutes < 0 ||
            minutes > 59 ||
            seconds < 0 ||
            seconds > 59
          ) {
            return { isValid: false, type: 'string' }; // Invalid time components
          }

          if (isUSFormat) {
            // US format: mm/dd/yyyy HH:mm:ss
            date = new Date(year, first - 1, second, hours, minutes, seconds);
          } else {
            // Indian format: dd/mm/yyyy HH:mm:ss
            date = new Date(year, second - 1, first, hours, minutes, seconds);
          }
        }

        return isValidDate(date)
          ? { isValid: true, type }
          : { isValid: false, type: 'string' };
      }
      // For timezone offset formats with nanoseconds
      else if (
        regex === formattedDashTimeWithTZOffset ||
        regex === formattedSlashTimeWithTZOffset
      ) {
        const first = parseInt(match[1], 10);
        const second = parseInt(match[2], 10);
        const year = parseInt(match[3], 10);
        const hours = parseInt(match[4], 10);
        const minutes = parseInt(match[5], 10);
        const seconds = parseInt(match[6], 10);
        
        // Handle nanoseconds
        let milliseconds = 0;
        if (match[7]) {
          // Get first 3 digits for milliseconds (JS Date limit)
          const nanoStr = match[7].padEnd(9, '0');
          milliseconds = parseInt(nanoStr.substring(0, 3), 10);
        }
        
        // Get timezone offset
        const tzOffset = match[8] || "";

        // Additional validation for US/Indian format
        if (isUSFormat) {
          // Validate US format: first should be month (1-12), second should be day (1-31)
          if (first < 1 || first > 12) {
            return { isValid: false, type: 'string' }; // Invalid month
          }

          // Check if day is valid for the given month
          const lastDayOfMonth = new Date(year, first, 0).getDate();

          if (second < 1 || second > lastDayOfMonth) {
            return { isValid: false, type: 'string' }; // Invalid day for this month
          }
        } else {
          // Validate Indian format: first should be day (1-31), second should be month (1-12)
          if (second < 1 || second > 12) {
            return { isValid: false, type: 'string' }; // Invalid month
          }

          // Check if day is valid for the given month
          const lastDayOfMonth = new Date(year, second, 0).getDate();

          if (first < 1 || first > lastDayOfMonth) {
            return { isValid: false, type: 'string' }; // Invalid day for this month
          }
        }

        // Validate time components
        if (
          hours < 0 ||
          hours > 23 ||
          minutes < 0 ||
          minutes > 59 ||
          seconds < 0 ||
          seconds > 59
        ) {
          return { isValid: false, type: 'string' }; // Invalid time components
        }

        // Create a proper ISO string based on user preference
        let isoString;

        if (isUSFormat) {
          // US format: MM-DD-YYYY to YYYY-MM-DD
          isoString = `${year}-${first.toString().padStart(2, '0')}-${second
            .toString()
            .padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')}:${seconds
            .toString()
            .padStart(2, '0')}`;
        } else {
          // Indian format: DD-MM-YYYY to YYYY-MM-DD
          isoString = `${year}-${second.toString().padStart(2, '0')}-${first
            .toString()
            .padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')}:${seconds
            .toString()
            .padStart(2, '0')}`;
        }
        
        // Add milliseconds if present
        if (match[7]) {
          isoString += `.${milliseconds.toString().padStart(3, '0')}`;
        }
        
        // Add timezone offset if present
        if (tzOffset) {
          isoString += tzOffset;
        }

        const date = new Date(isoString);

        return isValidDate(date)
          ? { isValid: true, type: 'dateTime' }
          : { isValid: false, type: 'string' };
      }
      // For the nanosecond format without timezone
      else if (regex === nanoSecFormat) {
        const year = parseInt(match[1], 10);
        const month = parseInt(match[2], 10) - 1;
        const day = parseInt(match[3], 10);
        const hours = parseInt(match[4], 10);
        const minutes = parseInt(match[5], 10);
        const seconds = parseInt(match[6], 10);
        
        // Handle nanoseconds - convert to milliseconds
        let milliseconds = 0;
        if (match[7]) {
          const nanoStr = match[7].padEnd(9, '0');
          milliseconds = parseInt(nanoStr.substring(0, 3), 10);
        }
        
        // Create date with millisecond precision
        const date = new Date(year, month, day, hours, minutes, seconds, milliseconds);
        
        return isValidDate(date)
          ? { isValid: true, type: 'dateTime' }
          : { isValid: false, type: 'string' };
      }
      // Handle RFC3339 format with improved nanoseconds support
      else if (regex === rfc3339Format) {
        const year = parseInt(match[1], 10);
        const month = parseInt(match[2], 10) - 1;
        const day = parseInt(match[3], 10);
        const hours = parseInt(match[4], 10);
        const minutes = parseInt(match[5], 10);
        const seconds = parseInt(match[6], 10);
        
        // Handle nanoseconds - convert to milliseconds
        let milliseconds = 0;
        if (match[7]) {
          const nanoStr = match[7].padEnd(9, '0');
          milliseconds = parseInt(nanoStr.substring(0, 3), 10);
        }
        
        let date: Date;
        if (match[8] === 'Z') {
          // UTC time
          date = new Date(Date.UTC(year, month, day, hours, minutes, seconds, milliseconds));
        } else if (match[9]) {
          // Has timezone offset
          const offsetSign = match[9] === '+' ? 1 : -1;
          const offsetHours = parseInt(match[10], 10);
          const offsetMinutes = parseInt(match[11], 10);
          
          // Create date in UTC, then apply offset
          const utcDate = new Date(Date.UTC(year, month, day, hours, minutes, seconds, milliseconds));
          const offsetMilliseconds = offsetSign * ((offsetHours * 60 + offsetMinutes) * 60 * 1000);
          date = new Date(utcDate.getTime() - offsetMilliseconds);
        } else {
          // Local time
          date = new Date(year, month, day, hours, minutes, seconds, milliseconds);
        }
        
        return isValidDate(date)
          ? { isValid: true, type: 'dateTime' }
          : { isValid: false, type: 'string' };
      }
      // Special handling for dateTimeTZMilliSecFormat with nanoseconds
      else if (regex === dateTimeTZMilliSecFormat) {
        // Extract the date components from the string to properly handle nanoseconds
        const dtMatch = inputString.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,9}))?Z$/);
        
        if (dtMatch) {
          const year = parseInt(dtMatch[1], 10);
          const month = parseInt(dtMatch[2], 10) - 1;
          const day = parseInt(dtMatch[3], 10);
          const hours = parseInt(dtMatch[4], 10);
          const minutes = parseInt(dtMatch[5], 10);
          const seconds = parseInt(dtMatch[6], 10);
          
          // Handle nanoseconds
          let milliseconds = 0;
          if (dtMatch[7]) {
            const nanoStr = dtMatch[7].padEnd(9, '0');
            milliseconds = parseInt(nanoStr.substring(0, 3), 10);
          }
          
          // Create date in UTC
          const date = new Date(Date.UTC(year, month, day, hours, minutes, seconds, milliseconds));
          
          return isValidDate(date)
            ? { isValid: true, type: 'dateTime' }
            : { isValid: false, type: 'string' };
        }
      }
      // For other formats (yyyy-mm-dd, etc.) use standard parsing
      else {
        const date = new Date(inputString);

        return isValidDate(date)
          ? { isValid: true, type }
          : { isValid: false, type: 'string' };
      }
    }

    return null;
  };

  // Define the array of formats and iterate over them
  const formats: Array<{ regex: RegExp; type: 'date' | 'dateTime' }> = [
    { regex: dateFormat1, type: 'date' },
    { regex: dateFormat2, type: 'date' },
    { regex: dateFormat3, type: 'date' },
    { regex: dateFormat4, type: 'date' },
    { regex: dateTimeFormat1, type: 'dateTime' },
    { regex: dateTimeFormat2, type: 'dateTime' },
    { regex: dateTimeFormat3, type: 'dateTime' },
    { regex: dateTimeFormat4, type: 'dateTime' },
    { regex: dateTimeTZFormat, type: 'dateTime' },
    { regex: dateTimeTZMilliSecFormat, type: 'dateTime' },
    { regex: rfc3339Format, type: 'dateTime' },
    { regex: nanoSecFormat, type: 'dateTime' }, // New format for nanoseconds
    { regex: formattedDashTimeWithTZOffset, type: 'dateTime' },
    { regex: formattedSlashTimeWithTZOffset, type: 'dateTime' },
  ];

  // Iterate through all formats and use testAndValidate
  for (const { regex, type } of formats) {
    const result = testAndValidate(regex, type);

    if (result !== null) {
      return result;
    }
  }

  // If no valid date format matches, return based on the 'from' parameter
  if (from === 'validation') {
    return { isValid: false, type: 'string' };
  }

  return { isValid: true, type: 'string' };
};

// TypeScript interface for return type
interface DateValidationResult {
  isValid: boolean;
  type: 'date' | 'dateTime' | 'string';
}

export const getDataTypeNected = (value: any) => {
  const type = typeof value;
  const isDateObj = isValidDateorDateTime(value);

  if (type === 'string' && isDateObj.isValid) {
    return isDateObj.type;
  }

  if (value === null) {
    return 'generic';
  }

  switch (type) {
    case 'number':
    case 'bigint':
      return 'numeric';
    case 'object':
      return Array.isArray(value) ? 'list' : 'json';
    case 'symbol':
    case 'undefined':
    case 'function':
      return '';

    default:
      return type;
  }
};

export const getDataTypeNectedForReturnType = (value: string) => {
  switch (value) {
    case 'number':
    case 'bigint':
      return 'numeric';
    case 'object':
      return Array.isArray(value) ? 'list' : 'json';
    case 'array':
      return 'list';
    case 'date':
      return 'date';
    case 'datetime':
      return 'dateTime';
    case 'dateTime':
      return 'dateTime';
    case 'datetime-local':
      return 'dateTime';
    case 'boolean':
      return 'boolean';
    case 'string':
      return getDataTypeNected(value).toLocaleLowerCase();
    case 'symbol':
    case 'undefined':
    case 'function':
      return '';
    default:
      return '';
  }
};

type KeyPermutation = {
  key: string;
  value: any;
  depth: number;
  dataType: string;
};

type FlattenKeysAndTypesV2Args = {
  obj: any;
  depth?: number;
  currentKey?: string;
  result?: KeyPermutation[];
  seenKeys?: Set<string>;
};

export function flattenKeysAndTypesV2({
  obj,
  depth = 0,
  currentKey = '',
  result = [],
  seenKeys = new Set(),
}: FlattenKeysAndTypesV2Args): KeyPermutation[] {
  if (Array.isArray(obj)) {
    if (currentKey !== '' && !seenKeys.has(currentKey)) {
      result.push({ key: currentKey, value: obj, depth, dataType: 'list' });
      seenKeys.add(currentKey);
    }
    obj.forEach((item, index) => {
      const newKey = `${currentKey}[${index}]`;

      if (typeof item === 'object') {
        result.push(
          ...flattenKeysAndTypesV2({
            obj: item,
            depth: depth + 1,
            currentKey: newKey,
            result,
            seenKeys,
          })
        );
      } else {
        if (!seenKeys.has(newKey)) {
          result.push({
            key: newKey,
            value: item,
            depth: depth + 1,
            dataType: getDataTypeNected(item),
          });
          seenKeys.add(newKey);
        }
      }
    });
  } else if (typeof obj === 'object' && obj !== null) {
    if (currentKey !== '' && !seenKeys.has(currentKey)) {
      result.push({
        key: currentKey,
        value: obj,
        depth,
        dataType: getDataTypeNected(obj),
      });
      seenKeys.add(currentKey);
    }
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        // eslint-disable-next-line
        const newKey = currentKey ? `${currentKey}.${key}` : key;

        if (!seenKeys.has(newKey)) {
          result.push({
            key: newKey,
            value,
            depth: depth + 1,
            dataType: getDataTypeNected(value),
          });
          seenKeys.add(newKey);

          if (typeof value === 'object') {
            result.push(
              ...flattenKeysAndTypesV2({
                obj: value,
                depth: depth + 1,
                currentKey: newKey,
                result,
                seenKeys,
              })
            );
          }
        }
      }
    }
  } else {
    if (currentKey !== '' && !seenKeys.has(currentKey)) {
      result.push({
        key: currentKey,
        value: obj,
        depth,
        dataType: getDataTypeNected(obj),
      });
      seenKeys.add(currentKey);
    }
  }

  const visited: string[] = [];

  return result.filter((x) => {
    if (!visited.includes(x.key)) {
      visited.push(x.key);

      return true;
    }

    return false;
  });
}

export function getObjectUnion(objects: any[]) {
  const nonObjects = (Array.isArray(objects) ? objects : []).filter(
    (obj) => typeof obj !== 'object' || obj === null || Array.isArray(obj)
  );

  if (nonObjects.length === objects.length) {
    // If all elements are non-objects, return the last non-object element
    return nonObjects.slice(-1)[0];
  }

  const union: Record<string, any> = {};

  for (const obj of Array.isArray(objects) ? objects : []) {
    if (typeof obj === 'object' && obj !== null && !Array.isArray(obj)) {
      for (const [key, value] of Object.entries(obj)) {
        if (typeof value !== 'undefined' && value !== null && !(key in union)) {
          union[key] = value;
        } else if (
          typeof value !== 'undefined' &&
          value !== null &&
          Array.isArray(value)
        ) {
          union[key] = value;
        } else if (
          typeof value !== 'undefined' &&
          value !== null &&
          typeof union[key] === 'object' &&
          typeof value === 'object'
        ) {
          union[key] = getObjectUnion([union[key], value]);
        }
      }
    }
  }

  return union;
}
