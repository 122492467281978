import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useRef } from 'react';
import {
  type UseControllerProps,
  type UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { BsThreeDots } from 'react-icons/bs';
import { MdOutlineGroups } from 'react-icons/md';
import { TbDownload, TbUpload } from 'react-icons/tb';
import { useSearchParams } from 'react-router-dom';
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  PopoverMethods,
  Typography,
  WorkflowNodeSheetHeader,
  useLayer,
} from 'ui';

import { ButtonWithOptions } from '../../../../components/ButtonWithOptions/ButtonWithOptions';
import { EntityHeader } from '../../../../components/EntityHeader/EntityHeader';
import { getValueFromObject } from '../../../../components/Listing/utils';
import { EntityLevelAccessModal } from '../../../../components/Modals/EntityLevelAccessModal/EntityLevelAccessModal';
import { ExportEntityModal } from '../../../../components/Modals/ExportEntityModal/ExportEntityModal';
import { ImportEntityModal } from '../../../../components/Modals/ImportEntityModal/ImportEntityModal';
import { permissionObj } from '../../../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../../../components/PermissionComponent/hooks/useCheckPermissions';
import { AutoSaveLoader } from '../../../../components/autoSaveLoader/AutoSaveLoader';
import { useSendEventToGTM } from '../../../../hooks/useSendEventToGTM';
import { useUpdateModalStateOnButtonOptionClick } from '../../../../hooks/useUpdateModalStateOnButtonOptionClick';
import {
  ENTITY_ID,
  editEntityAccessDisableRoles,
} from '../../../../utils/constant';
import {
  activePanelWorkflowAtom,
  approvalInfoWorkflowAtom,
  isWorkflowLiveAtom,
  isWorkflowReadOnlyAtom,
  isWorkflowTestOnlyAtom,
  vcListTabIndexWorkflowAtom,
  versionInfoWorkflowAtom,
  workflowAccessRoleAtom,
  workflowIdAtom,
  workflowStatusAtom,
} from '../../atoms/atoms';
import {
  MenuItemInlineStyled,
  StyledHeaderContainer,
  WSHeaderContainer,
} from './WorkflowSheetHeader.styled';

type WorkflowSheetHeaderProps = Omit<UseControllerProps, 'name'> & {
  onPublish: () => void;
  isLoading?: boolean;
  onTest: () => void;
  onEdit: () => void;
  setValue: UseFormSetValue<any>;
  handleEntityUpdate: (data: Record<string, any>) => void;
  id: string;
  handleFetchWorkflow?: () => void;
};

export function WorkflowSheetHeader({
  control,
  onPublish,
  setValue,
  isLoading = false,
  onTest,
  onEdit,
  handleEntityUpdate,
  id,
  handleFetchWorkflow,
}: WorkflowSheetHeaderProps) {
  const menuRef = useRef<PopoverMethods>(null);

  const [searchParams] = useSearchParams();

  const [workflowStatus] = useAtom(workflowStatusAtom);
  const [workflowId] = useAtom(workflowIdAtom);

  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [isWorkflowTestOnly] = useAtom(isWorkflowTestOnlyAtom);
  const [accessRole] = useAtom(workflowAccessRoleAtom);

  const [isWorkflowLive] = useAtom(isWorkflowLiveAtom);
  const [approvalInfoWorkflow] = useAtom(approvalInfoWorkflowAtom);
  const [versionInfoWorkflow] = useAtom(versionInfoWorkflowAtom);

  const [, setActivePanel] = useAtom(activePanelWorkflowAtom);
  const [, setVCListTabIndex] = useAtom(vcListTabIndexWorkflowAtom);

  const { isHide: hideEditBtn } = useCheckPermissions({
    allowedPermission: [permissionObj.create, permissionObj.edit],
    entityList: [ENTITY_ID.workflow],
    statusShouldBe: ['published'],
    entityStatus: workflowStatus,
    entityAccessRole: accessRole,
  });

  const showEditBtn = isWorkflowLive && !hideEditBtn;

  const isMenuDisabled = !isWorkflowLive && workflowStatus === 'published';

  const isEditAccessDisable = editEntityAccessDisableRoles.includes(
    accessRole ?? ''
  );

  const { sendEventToGTM } = useSendEventToGTM();

  const { isHide: isImportDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.create],
    entityList: [ENTITY_ID.workflow],
  });

  const { openWithProps: openImportModal } = useLayer(
    <ImportEntityModal entityType="workflow" />
  );

  const { openWithProps: openExportModal } = useLayer(
    <ExportEntityModal entityId={workflowId ?? ''} entityType="workflow" />
  );

  const { openWithProps: openEntityAccessModal } = useLayer(
    <EntityLevelAccessModal />
  );

  const plan = JSON.parse(window.sessionStorage.getItem('userPlan') ?? '{}');

  const workflowName = useWatch({
    name: 'name',
    control,
  });

  const showFooterAction = searchParams.get('type') !== 'view';

  const { selectedOptionId, handleOptionButtonClick } =
    useUpdateModalStateOnButtonOptionClick({
      entityId: workflowId ?? '',
      entityType: 'workflow',
      entityName: workflowName,
      entityStatus: workflowStatus,
      handleParentStateUpdate: handleEntityUpdate,
      formData: {
        requestReview: approvalInfoWorkflow,
        publishModal: {
          title: approvalInfoWorkflow?.title,
        },
      },
    });

  const openPublishedVersions = () => {
    setActivePanel('versionControl');
    setVCListTabIndex(2);
  };

  const handleMenuItemClick = (value: string) => {
    if (value === 'export' && !_isNil(id)) {
      openExportModal({
        entityId: workflowId,
        isLive: isWorkflowLive,
        version: versionInfoWorkflow?.currentVersion,
      });
    } else if (value === 'import') {
      openImportModal({
        handleFetchEntity: handleFetchWorkflow,
        entityName: workflowName,
      });
    } else if (value === 'editAccess') {
      openEntityAccessModal({
        entityInfo: {
          id,
          name: workflowName,
          type: 'workflow',
          status: workflowStatus,
          accessRole,
        },
      });
    }
  };

  return (
    <WSHeaderContainer className="workflow-header-container" padding="1rem">
      <WorkflowNodeSheetHeader styleClassName="wfNodeContainer">
        <StyledHeaderContainer gutter="0.8rem">
          <EntityHeader
            nameFieldKey="name"
            descriptionFieldKey="description"
            createdAtFieldKey="createdAt"
            publishedAtFieldKey="publishedAt"
            entityName="Workflow"
            status={workflowStatus}
            control={control}
            setValue={setValue}
            isReadOnly={isWorkflowReadOnly}
            versionInfo={versionInfoWorkflow}
            openPublishedVersions={openPublishedVersions}
            loaderComponent={
              <>
                {showFooterAction && (
                  <AutoSaveLoader isLoading={isLoading} shouldDelayBy={1} />
                )}
              </>
            }
          />
        </StyledHeaderContainer>

        <Inline align="center" gutter="1rem">
          <Menu
            launcher={
              <IconButton>
                <BsThreeDots />
              </IconButton>
            }
            ref={menuRef}
            onMenuItemClick={handleMenuItemClick}
          >
            <MenuItem
              disabled={isEditAccessDisable}
              value="editAccess"
              key="editAccess"
              tooltipText={
                isEditAccessDisable
                  ? `You don't have permission to edit access of this workflow. Contact owner or admin`
                  : ''
              }
            >
              <MenuItemInlineStyled
                align="center"
                justify="center"
                gutter="1rem"
              >
                <Typography>Edit Access</Typography>
                <MdOutlineGroups />
              </MenuItemInlineStyled>
            </MenuItem>
            <MenuItem
              disabled={isMenuDisabled}
              tooltipText="Only draft and live versions can be exported, as of now."
              value="export"
              key="export"
            >
              <MenuItemInlineStyled
                id={`inside-${
                  getValueFromObject(plan, 'plan.export.componentId') as string
                }`}
                data-premium-component-id={getValueFromObject(
                  plan,
                  'plan.export.componentId'
                )}
                data-premium-component-trigger={getValueFromObject(
                  plan,
                  'plan.export.trigger'
                )}
                align="center"
                justify="center"
                gutter="1rem"
              >
                <Typography>Export</Typography>
                <TbUpload />
              </MenuItemInlineStyled>
            </MenuItem>
            <MenuItem
              disabled={isImportDisable || isMenuDisabled}
              value="import"
              key="import"
              tooltipText={
                isMenuDisabled
                  ? 'Import is enabled on draft and live versions.'
                  : isImportDisable
                  ? `You don't have permission to import workflow. Contact owner or admin`
                  : ''
              }
            >
              <MenuItemInlineStyled
                id={`inside-${
                  getValueFromObject(plan, 'plan.import.componentId') as string
                }`}
                data-premium-component-id={`inside-${
                  getValueFromObject(plan, 'plan.import.componentId') as string
                }`}
                data-premium-component-trigger={getValueFromObject(
                  plan,
                  'plan.import.trigger'
                )}
                align="center"
                justify="center"
                gutter="1rem"
              >
                <Typography>Import</Typography>
                <TbDownload />
              </MenuItemInlineStyled>
            </MenuItem>
          </Menu>
          {showEditBtn && showFooterAction && (
            <Button onClick={onEdit}>Edit</Button>
          )}

          <Button
            appearance="filled"
            disabled={!isWorkflowTestOnly}
            onClick={() => {
              sendEventToGTM({
                event: 'workflow',
                source: 'listing',
                element: '',
                action: 'test_click',
                type: '',
              });

              onTest();
            }}
          >
            Test in Staging
          </Button>

          {showFooterAction &&
            !['published', 'archived'].includes(workflowStatus) && (
              <ButtonWithOptions
                id="workflow-footer"
                entityType={ENTITY_ID.workflow}
                entityStatus={workflowStatus}
                defaultSelectedOptionId={selectedOptionId}
                onClick={handleOptionButtonClick}
                entityAccessRole={accessRole}
              />
            )}
        </Inline>
      </WorkflowNodeSheetHeader>
    </WSHeaderContainer>
  );
}
