import { Inline } from '@bedrock-layout/primitives';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { Ref, forwardRef } from 'react';
import { Typography } from 'ui';

import { ErrorPopoverPositioned } from '../Error/ErrorPopoverPositioned';
import { sendEventToGTMType } from '../RuleBlock/RuleBlock';
import { ContainerStyled } from './RuleParamPopover.styled';

type OperatorsLauncherProps = {
  error: string | null;
  text?: string;
  dataType?: string;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
};

export const OperatorsLauncher = forwardRef(
  (
    {
      error,
      text = 'Operator',
      dataType,
      handleSendEventToGTM,
    }: OperatorsLauncherProps,
    ref: Ref<HTMLElement>
  ) => {
    const handleClick = () => {
      if (typeof handleSendEventToGTM === 'function') {
        handleSendEventToGTM({
          action: _isEmpty(text) ? 'add' : 'edit',
          element: 'operator',
          actionName: dataType ?? '',
        });
      }
    };

    return (
      <ContainerStyled onClick={handleClick} padding={[3, 8]} ref={ref}>
        {!_isNil(error) && <ErrorPopoverPositioned error={error} />}

        <Inline stretch={1} align="center">
          <Typography>{!_isEmpty(text) ? text : 'Operator'}</Typography>
          ▾
        </Inline>
      </ContainerStyled>
    );
  }
);

OperatorsLauncher.displayName = 'OperatorsLauncher';
