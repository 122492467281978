import _isNil from 'lodash/isNil';
import { Id as ToastId, toast } from 'react-toastify';
import type { SeverityLevel } from '@sentry/types';
import { ToastType } from './Toast';

type MetaPayload = {
  context?: string;
  extra?: Record<string, any>;
  level?: SeverityLevel;
};

declare global {
  interface Window {
    reportToSentry?: (
      error: Error | string,
      metaPayload?: MetaPayload,
      captureContext?: boolean
    ) => string | null;
  }
}

const safeReportToSentry = (
  message: string, 
  context: string, 
  level: SeverityLevel, 
  extra: Record<string, any> = {}
) => {
  try {
    if (
      typeof window !== 'undefined' && 
      window.reportToSentry && 
      typeof window.reportToSentry === 'function'
    ) {
      window.reportToSentry(message, {
        context,
        extra,
        level,
      });
    }
  } catch (e) {
    console.warn('Failed to report to Sentry:', e);
  }
};

export const toasts: Record<
  ToastType,
  (message: string, toastId: string) => ToastId
> = {
  info: (message: string, toastId: string) => {
    safeReportToSentry(message, 'ToastInfo', 'info', { toastId });
    return toast.info(message, { toastId });
  },
  infoBlue: (message: string, toastId: string) => {
    safeReportToSentry(message, 'ToastInfoBlue', 'info', { toastId });
    return toast.info(message, { toastId });
  },
  success: (message: string, toastId: string) => {
    return toast.success(message, { toastId });
  },
  error: (message: string, toastId: string) => {
    safeReportToSentry(message, 'ToastError', 'error', { toastId });
    return toast.error(message, { toastId });
  },
  warning: (message: string, toastId: string) => {
    safeReportToSentry(message, 'ToastWarning', 'warning', { toastId });
    return toast.warning(message, { toastId });
  },
  warningBlue: (message: string, toastId: string) => {
    safeReportToSentry(message, 'ToastWarningBlue', 'warning', { toastId });
    return toast.info(message, { toastId });
  },
  infoRed: (message: string, toastId: string) => {
    safeReportToSentry(message, 'ToastInfoRed', 'info', { toastId });
    return toast.info(message, { toastId });
  },
};