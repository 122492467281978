import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import _reduce from 'lodash/reduce';
import React, {
  ChangeEvent,
  Ref,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import {
  ExecutedValueTooltip,
  TooltipReact,
  Typography,
  formatNectedDate,
  getDataTypeNected,
  useLayer,
} from 'ui';

import { isRuleReadOnlyAtom } from '../../..';
import { CalenderIconPicker } from '../../../../../components/CalenderIconPicker/CalenderIconPicker';
import { TextInputModal } from '../../../../../components/Modals/TextInputModal/TextInputModal';
import {
  DATE_TIME_FORMAT,
  convertArrayAsInput,
  convertArrayToString,
  extractSourceAndAttributeFromValue,
  getPropertyIfExists,
  isArrayAsInputValid,
  isFieldReadOnly,
  removeOuterBrackets,
} from '../../../../../utils/common';
import {
  EXTRACT_TOKEN_REGEX,
  LIST_KEY_REGEX,
} from '../../../../../utils/regex';
import {
  CorrectValues,
  getConstantNode,
  getParamNode,
  getPlaceholderByDataType,
  primitiveDataTypesList,
  validateRhsValue,
} from '../../../utils/common';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import {
  InputWithPillStyled,
  RightIconContainer,
} from '../../RestltRhs/ResultRhs.styled';
import { ErrorPopoverPositioned } from '../Error/ErrorPopoverPositioned';
import { sendEventToGTMType } from '../RuleBlock/RuleBlock';
import { simpleNodeErrors, simpleRuleNodesAtom } from '../index';
import type { ErrorByNodeId, SimpleRuleNodesModel } from '../models';
import {
  InputBrackets,
  InputContainer,
  InputFieldStyled,
  InputStyled,
  RoundIcon,
} from './RhsParamPopover.styled';

type RhsLauncherProps = {
  nodeId: string;
  from: string;
  panelVisible: boolean;
  text: string | number | any[];
  parentId: string;
  dataType: string;
  selectedOperator?: string;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
  isOpen?: any;
  isActiveDate?: boolean;
  handleHidePanel?: () => void;
  rightIcon?: any;
  isSchemaMandatory?: boolean;
};

export const RhsLauncher = forwardRef(
  (
    {
      from,
      text,
      nodeId,
      parentId,
      panelVisible = false,
      dataType,
      selectedOperator,
      handleSendEventToGTM,
      isOpen,
      isActiveDate = true,
      handleHidePanel,
      rightIcon,
      isSchemaMandatory = false,
    }: RhsLauncherProps,
    ref: Ref<any>
  ) => {
    const [rules, setRules] = useAtom(simpleRuleNodesAtom);
    const [errorByRuleId, setErrorByRuleId] = useAtom(simpleNodeErrors);
    const [dataset] = useAtom(dataSetParamsAtom);
    const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

    const [value, setValue] = useState<string | number | any[]>(text);
    const [nodeType, setNodeType] = useState(rules[nodeId]?.nodeType);
    const [hasError, setHasError] = useState(false);
    const [, setIsToolTipVisible] = useState(true);

    const { openWithProps } = useLayer(<TextInputModal />);

    const [isFocused, setIsFocused] = useState(false);

    const error = !_isNil(errorByRuleId[nodeId])
      ? errorByRuleId[nodeId].message
      : null;

    useEffect(() => {
      if (!_isUndefined(rules[nodeId]) && nodeType !== rules[nodeId].nodeType) {
        setNodeType(rules[nodeId].nodeType);
        const title = rules[nodeId].attribute;

        if (rules[nodeId].nodeType === 'params' && !_isNil(title)) {
          setValue(title);
          setHasError(false);

          if (!_isNil(errorByRuleId[nodeId])) {
            setErrorByRuleId((prev) =>
              _reduce(
                prev,
                (result: ErrorByNodeId, value, key) => {
                  if (key === nodeId) {
                    return result;
                  }

                  return {
                    ...result,
                    [key]: prev[key],
                  };
                },
                {}
              )
            );
          }
        }
      }
    }, [rules[nodeId]]);

    useEffect(() => {
      setValue(text);
    }, [text]);

    const { source: sourceType, attribute } = isActiveDate
      ? {
          source: rules[nodeId]?.sourceType,
          attribute: rules[nodeId]?.attribute,
        }
      : extractSourceAndAttributeFromValue(value as string, dataset);

    const rhsDataType = rules[nodeId]?.dataType;

    const toolTipMessage =
      !_isNil(sourceType) &&
      !_isEmpty(sourceType) &&
      !_isNil(dataset[sourceType])
        ? getPropertyIfExists(
            JSON.parse(
              JSON.stringify(
                Object.keys(dataset[sourceType]?.attributes ?? {}).reduce(
                  (acc, curr) => {
                    return {
                      ...acc,
                      [curr]:
                        dataset[sourceType ?? ''].attributes[`${curr}`]
                          .executedValue,
                    };
                  },
                  {}
                )
              )
            ) ?? {},
            attribute ?? ''
          )
        : value.toString();

    const handleClick = () => {
      if (typeof handleSendEventToGTM === 'function') {
        handleSendEventToGTM({
          action: _isEmpty(text) ? 'add' : 'edit',
          element: 'rhs_value',
          actionName: dataType ?? '',
        });
      }
    };

    const transformListValue = (type: string, value: any) => {
      if (type === 'LIST_TO_STRING') {
        if (Array.isArray(value)) {
          const convertedValue = convertArrayToString(value);

          return _isEmpty(convertedValue) ? '' : `${convertedValue}`;
        } else {
          return typeof value === 'string' ? removeOuterBrackets(value) : value;
        }
      }

      if (type === 'STRING_TO_LIST') {
        if (typeof value === 'string') {
          // Check if there is token present, then return string
          // otherwise convert to list

          const tokens = (typeof value === 'string' ? value : '').match(
            EXTRACT_TOKEN_REGEX
          );

          if (!_isNil(tokens)) {
            return value;
          }

          return convertArrayAsInput(value);
        } else {
          return value;
        }
      }

      return value;
    };

    function returnDataType() {
      if (
        dataType === 'list' &&
        !['contains', 'notContains'].includes(selectedOperator ?? '')
      ) {
        return dataType;
      }

      const isValidOperator = [
        'notContainsIn',
        'containsIn',
        'in',
        'nin',
      ].includes(selectedOperator ?? '');

      if (primitiveDataTypesList.includes(dataType) && isValidOperator) {
        if (isArrayAsInputValid(value) && dataType === 'string') {
          return 'list';
        }

        if (dataType !== 'string') {
          return 'list';
        }
      }

      return dataType;
    }

    const calculateValue = (
      type: string,
      result: CorrectValues,
      currNodeData: SimpleRuleNodesModel
    ) => {
      const relativeDateParams =
        typeof currNodeData.value === 'object' &&
        !Array.isArray(currNodeData.value)
          ? currNodeData.value
          : {};

      if (type === 'constant') {
        const finalValue =
          returnDataType() === 'list'
            ? transformListValue('STRING_TO_LIST', result.value)
            : result.value;

        if (isActiveDate) {
          return finalValue;
        } else {
          return {
            ...relativeDateParams,
            value: finalValue,
          };
        }
      } else {
        if (isActiveDate) {
          return '';
        } else {
          return {
            ...relativeDateParams,
            value: '',
          };
        }
      }
    };

    const getNodeParamsBasedOnType = (
      type: string,
      result: CorrectValues,
      currNodeData: SimpleRuleNodesModel
    ) => {
      const mappedValue =
        !_isUndefined(sourceType) && !_isNil(dataset[sourceType])
          ? getPropertyIfExists(
              JSON.parse(
                JSON.stringify(
                  Object.keys(dataset[sourceType]?.attributes ?? {}).reduce(
                    (acc, curr) => {
                      return {
                        ...acc,
                        [curr]:
                          dataset[sourceType ?? ''].attributes[`${curr}`]
                            .executedValue,
                      };
                    },
                    {}
                  )
                )
              ) ?? {},
              result.value as string
            )
          : undefined;

      return type === 'constant'
        ? {
            ...getConstantNode(
              parentId,
              returnDataType(),
              rules[nodeId].siblingIndex
            ),
            value: calculateValue(type, result, currNodeData),
            dataType: returnDataType(),
          }
        : {
            ...getParamNode(parentId, rules[nodeId].siblingIndex),
            nodeType: isActiveDate ? 'params' : 'noCodeFunc',
            value: calculateValue(type, result, currNodeData),
            sourceType: isActiveDate ? sourceType : '',
            attribute: isActiveDate ? (value as string) : '',
            dataType: !_isUndefined(mappedValue)
              ? getDataTypeNected(mappedValue)
              : 'unknown',
          };
    };

    const onChange = (e: ChangeEvent<any>) => {
      e.preventDefault();

      // eslint-disable-next-line
      const isList = !!e.target.value.match(LIST_KEY_REGEX)?.length;

      const nodeType = isList
        ? isActiveDate
          ? 'params'
          : 'noCodeFunc'
        : 'constant';

      if (typeof handleSendEventToGTM === 'function') {
        handleSendEventToGTM({
          action: 'selection',
          element: 'rhs_value',
          actionName: nodeType,
        });
      }

      setNodeType(nodeType);

      const result = validateRhsValue(
        e.target.value,
        returnDataType(),
        nodeType
      );

      setValue(e.target.value);
      setHasError(!result.isCorrect);

      if (!result.isCorrect) {
        setErrorByRuleId((prev) => ({
          ...prev,
          [nodeId]: {
            code: 500,
            message:
              !_isNil(result.message) && !_isEmpty(result.message)
                ? result.message
                : 'Field is required',
          },
        }));
      } else {
        setErrorByRuleId((prev) =>
          _reduce(
            prev,
            (result: ErrorByNodeId, value, key) => {
              if (key === nodeId) {
                return result;
              }

              return {
                ...result,
                [key]: prev[key],
              };
            },
            {}
          )
        );
      }
    };

    const showExpandIcon =
      nodeType === 'constant' && returnDataType() === 'list';

    const formatValue = (input: any) => {
      if (returnDataType() === 'list') {
        return transformListValue('LIST_TO_STRING', input);
      }

      if (returnDataType() === 'date') {
        try {
          const { attribute } = extractSourceAndAttributeFromValue(
            input,
            dataset
          );

          if (!_isNil(attribute) && !_isEmpty(attribute)) {
            return attribute;
          }

          return formatNectedDate(input, 'date');
        } catch (err) {
          return value;
        }
      }

      if (returnDataType() === 'dateTime') {
        try {
          const { attribute } = extractSourceAndAttributeFromValue(
            input,
            dataset
          );

          if (!_isNil(attribute) && !_isEmpty(attribute)) {
            return attribute;
          }

          return formatNectedDate(input, 'dateTime');
        } catch (err) {
          return value;
        }
      }

      return input;
    };

    const isValueToken = !_isNil(sourceType) && !_isEmpty(sourceType);

    const fieldHasPillComponent = [
      'json',
      'list',
      'jsFormula',
      'excelFormula',
    ].includes(nodeType);

    const formattedValue = formatValue(value);

    return (
      <Inline gutter="0.4rem">
        {!['excelFormula', 'jsFormula'].includes(nodeType) && (
          <InputContainer align="center" gutter={0} onClick={handleClick}>
            {!_isNil(error) && !_isEmpty(error) && (
              <ErrorPopoverPositioned error={error} />
            )}
            {nodeType !== 'constant' && isValueToken && (
              <InputBrackets direction="left">
                <Typography>{'{{'}</Typography>
              </InputBrackets>
            )}
            {nodeType === 'constant' && returnDataType() === 'list' && (
              <InputBrackets direction="left">
                <Typography>{'['}</Typography>
              </InputBrackets>
            )}
            <ExecutedValueTooltip
              id={nodeId}
              attribute={attribute ?? undefined}
              isVisible={nodeType !== 'constant' && isValueToken}
              value={
                returnDataType() === 'list'
                  ? transformListValue('STRING_TO_LIST', toolTipMessage)
                  : toolTipMessage
              }
              dataType={rhsDataType}
              source={sourceType}
            >
              {returnDataType() === 'string' ? (
                <InputStyled
                  ref={ref}
                  value={formatValue(value)}
                  $hasError={hasError}
                  $showExpandIcon={showExpandIcon}
                  $isLarge={dataType === 'dateTime'}
                  disabled={isRuleReadOnly || isSchemaMandatory}
                  onChange={onChange}
                  onFocus={() => {
                    setIsFocused(true);
                    setIsToolTipVisible(false);
                  }}
                  readOnly={isFieldReadOnly(returnDataType() ?? '')}
                  onBlur={() => {
                    if (
                      ['excelFormula', 'jsFormula'].includes(nodeType ?? '')
                    ) {
                      return;
                    }
                    const result = validateRhsValue(
                      value,
                      returnDataType(),
                      nodeType
                    );

                    setRules((prev) => ({
                      ...prev,
                      [nodeId]: getNodeParamsBasedOnType(
                        nodeType,
                        result,
                        prev[nodeId]
                      ),
                    }));

                    setIsToolTipVisible(true);
                    setTimeout(() => {
                      setIsFocused(false);
                    }, 100);
                  }}
                  rows={
                    returnDataType() === 'string' && nodeType === 'constant'
                      ? isFocused
                        ? 4
                        : 2
                      : 1
                  }
                  placeholder={getPlaceholderByDataType(dataType)}
                />
              ) : (
                <InputFieldStyled
                  value={formatValue(value)}
                  ref={ref}
                  $hasError={false}
                  $showExpandIcon={showExpandIcon}
                  $rightIcon={!_isNil(rightIcon)}
                  isActiveDate={
                    isActiveDate &&
                    ['date', 'dateTime'].includes(dataType ?? '')
                  }
                  placeholder={getPlaceholderByDataType(dataType)}
                  disabled={isRuleReadOnly || isSchemaMandatory}
                  onChange={(e: any) => {
                    onChange(e);
                  }}
                  onFocus={() => {
                    setIsFocused(true);
                    setIsToolTipVisible(false);
                  }}
                  readOnly={isFieldReadOnly(returnDataType() ?? '')}
                  onBlur={() => {
                    if (
                      ['excelFormula', 'jsFormula'].includes(nodeType ?? '')
                    ) {
                      return;
                    }
                    const result = validateRhsValue(
                      value,
                      returnDataType(),
                      nodeType
                    );

                    setRules((prev) => ({
                      ...prev,
                      [nodeId]: getNodeParamsBasedOnType(
                        nodeType,
                        result,
                        prev[nodeId]
                      ),
                    }));
                    setIsFocused(false);
                    setIsToolTipVisible(true);
                  }}
                />
              )}
            </ExecutedValueTooltip>

            {nodeType !== 'constant' && isValueToken && (
              <InputBrackets direction="right">
                <Typography>{'}}'}</Typography>
              </InputBrackets>
            )}
            {nodeType === 'constant' && returnDataType() === 'list' && (
              <InputBrackets direction="right">
                <Typography>{']'}</Typography>
              </InputBrackets>
            )}
            {value === '' && dataType === 'dateTime' && (
              <InputBrackets direction="right">
                <TooltipReact id={nodeId}>
                  Enter Date in {DATE_TIME_FORMAT} format
                </TooltipReact>
              </InputBrackets>
            )}
          </InputContainer>
        )}

        {returnDataType() === 'string' &&
          !['excelFormula', 'jsFormula'].includes(nodeType) && (
            <RoundIcon
              type="button"
              onClick={() => {
                if (typeof handleHidePanel === 'function') {
                  handleHidePanel();
                }
                openWithProps({
                  onSubmit: (val: Record<string, any>) => {
                    setRules((prev) => ({
                      ...prev,
                      [nodeId]: {
                        ...prev[nodeId],
                        nodeType: 'constant',
                        value: val.value,
                        sourceType: '',
                        attribute: '',
                      },
                    }));
                  },
                  value: formatValue(value),
                });
              }}
              disabled={!(nodeType === 'constant') || isSchemaMandatory}
            >
              <>⤢</>
            </RoundIcon>
          )}

        {['dateTime', 'date'].includes(dataType) &&
        !['excelFormula', 'jsFormula'].includes(nodeType) &&
          returnDataType() !== 'list' && (
            <RoundIcon type="button" onClick={handleHidePanel}>
              <CalenderIconPicker
                value={value}
                dataType={dataType}
                disabled={isRuleReadOnly || isSchemaMandatory}
                onPick={(val) => {
                  const currNodeValue = rules[nodeId].value;
                  const relativeDateParams =
                    typeof currNodeValue === 'object' &&
                    !Array.isArray(currNodeValue)
                      ? currNodeValue
                      : {};

                  setRules((prev) => ({
                    ...prev,
                    [nodeId]: {
                      ...prev[nodeId],
                      value: isActiveDate
                        ? (formatNectedDate(val, dataType) as string)
                        : {
                            ...relativeDateParams,
                            value: formatNectedDate(val, dataType) as string,
                          },
                      sourceType: undefined,
                      attribute: undefined,
                      nodeType: isActiveDate ? 'constant' : 'noCodeFunc',
                      dataType,
                    },
                  }));
                }}
              />
            </RoundIcon>
          )}
        <>
          {fieldHasPillComponent && (
            <InputContainer>
              <ExecutedValueTooltip
                id={nodeId}
                attribute={attribute ?? undefined}
                isVisible={nodeType !== 'constant' && isValueToken}
                value={
                  returnDataType() === 'list'
                    ? transformListValue('STRING_TO_LIST', toolTipMessage)
                    : toolTipMessage
                }
                dataType={rhsDataType}
                source={sourceType}
              >
                <InputWithPillStyled
                  $hasPill={!_isNil(rightIcon) && fieldHasPillComponent}
                  $hasError={!_isNil(error)}
                  $isSmall={true}
                  $isFull={true}
                  $isPlaceholder={
                    _isNil(formattedValue) || _isEmpty(formattedValue)
                  }
                >
                  <Inline align="center" gutter="0.5rem">
                    {nodeType !== 'constant' && isValueToken && (
                      <Typography>{'{{'}</Typography>
                    )}
                    <div className="text-style">
                      {_isNil(formattedValue) || _isEmpty(formattedValue)
                        ? 'Enter value'
                        : formatValue(value).length > 12
                        ? formatValue(value).substring(0, 12) + '...'
                        : formatValue(value)}
                    </div>
                    {nodeType !== 'constant' && isValueToken && (
                      <Typography>{'}}'}</Typography>
                    )}
                  </Inline>
                </InputWithPillStyled>
              </ExecutedValueTooltip>

              {!_isNil(rightIcon) && (
                <RightIconContainer
                  $ruleType={'simpleRule'}
                  className="right-icon-container"
                >
                 {rightIcon(handleHidePanel)}
                </RightIconContainer>
              )}
              {!_isNil(error) && !_isEmpty(error) && (
                <ErrorPopoverPositioned error={error} />
              )}
            </InputContainer>
          )}
        </>
      </Inline>
    );
  }
);

RhsLauncher.displayName = 'RhsLauncher';
