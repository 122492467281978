import type { Dataset } from 'ui';

import { generateUid } from '../../utils/common';

export const moduleSchemaDefaultValues: Record<string, any> = {
  name: '',
  dataType: null,
  options: [],
  value: [],
  usageType: 'optional',
};

export const defaultDataTypesForSchema: Record<string, Dataset> = {
  primitive: {
    name: 'Datatypes',
    id: generateUid('primitive_'),
    attributes: {
      string: {
        name: 'String',
        dataType: 'string',
      },
      numeric: {
        name: 'Numeric',
        dataType: 'numeric',
      },
      date: {
        name: 'Date',
        dataType: 'date',
      },
      dateTime: {
        name: 'DateTime',
        dataType: 'dateTime',
      },
      boolean: {
        name: 'Boolean',
        dataType: 'boolean',
      },
      list: {
        name: 'List',
        dataType: 'list',
      },
      json: {
        name: 'JSON',
        dataType: 'json',
      },
    },
  },
};
