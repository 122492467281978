import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { atom, useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import {
  UseControllerProps,
  UseFormSetValue,
  useController,
} from 'react-hook-form';
import { MdEdit } from 'react-icons/md';
import { Dataset, NectedSuggestionModel, Typography } from 'ui';

import { useOpenJsEditorSheet } from '../../../hooks/useOpenJsEditor';
import { sectionAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { FieldJsPillStyled, JsPillStyled } from './JsNodePill.styled';
import type { ResultType } from './Results';

export type JsNodePillProps = UseControllerProps & {
  name: string;
  index?: number;
  suggestions?: string[];
  section?: ResultType;
  isAdditionalData?: boolean;
  hideOptionalCustomAttributes?: boolean;
  suggestionObjs?: NectedSuggestionModel[];
  setOriginalValue?: UseFormSetValue<any>;
  returnTypeName?: string;
  executedValueName?: string;
  additionalDataIndex?: number;
  dataSet?: Record<string, Dataset>;
  usingFrom?: string;
  hidePopover: ()=> void;
};

export const suggestionsAtom = atom<string[]>([]);
export const suggestionsObjAtom = atom<NectedSuggestionModel[]>([]);

export function JsNodePill({
  name,
  control,
  index = 0,
  suggestions = [],
  section = 'thenDataParams',
  isAdditionalData,
  hideOptionalCustomAttributes = false,
  suggestionObjs = [],
  setOriginalValue,
  returnTypeName,
  executedValueName,
  additionalDataIndex,
  dataSet,
  usingFrom,
  hidePopover
}: JsNodePillProps) {
  const [, setSectionAtom] = useAtom(sectionAtom);

  const { openWithProps: openJsEditor } = useOpenJsEditorSheet({
    name,
    control,
    index,
    section,
    isAdditionalData,
    dataSet,
  });

  // const [, setSuggestions] = useAtom(suggestionsAtom);
  // const [, setSuggestionsObj] = useAtom(suggestionsObjAtom);

  const { fieldState } = useController({
    name,
    control,
  });

  const errorMessage = fieldState.error?.message;

  // useEffect(() => {
  //   setSuggestions(suggestions);
  // }, [suggestions]);

  // useEffect(() => {
  //   setSuggestionsObj(suggestionObjs);
  // }, [suggestionObjs]);

  const getPadding = () => {
    if (name.includes('ruleResult') || name.includes('additionalData')) {
      return { size: true, pill: ['0rem', '0.6rem'] };
    }

    return { size: false, pill: ['0.4rem', '1rem'] };
  };

  return (
    <Stack gutter="0.5rem">
      <Inline
        onClick={() => {
          if(typeof hidePopover === "function"){
            hidePopover();
          }
          setSectionAtom(section);
          openJsEditor({
            hideOptionalCustomAttributes,
            suggestionObjs,
            setOriginalValue,
            returnTypeName,
            executedValueName,
            additionalDataIndex,
            dataSet,
          });
        }}
        as={usingFrom === 'fieldComponent' ? FieldJsPillStyled : JsPillStyled}
        gutter={8}
        align="center"
        justify="center"
        padding={usingFrom === 'fieldComponent' ? getPadding().pill : [2, 12]}
        $hasError={!_isEmpty(errorMessage)}
      >
        <Typography
          fontWeight={usingFrom === 'fieldComponent' ? 400 : 700}
          name={usingFrom === 'fieldComponent' ? 'paragraphXs' : 'paragraph'}
          style={{ padding: '0.5rem 0rem' }}
        >
          JS Code
        </Typography>
        <MdEdit />
      </Inline>

      {!_isEmpty(errorMessage) && (
        <Typography name="error">{errorMessage}</Typography>
      )}
    </Stack>
  );
}
