import { Inline, PadBox } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { useRef } from 'react';
import { GoArrowRight } from 'react-icons/go';
import { RiFormula } from 'react-icons/ri';
import { Menu, MenuItem, PopoverMethods, Typography } from 'ui';

import { isRuleReadOnlyAtom } from '../../..';

type SwitchToFormulaMenuProps = {
  onMenuClick: (dataType: string) => void;
};
export const SwitchToFormulaMenu = ({
  onMenuClick,
}: SwitchToFormulaMenuProps) => {
  const menuRef = useRef<PopoverMethods>(null);

  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const launcher = (
    <Inline
      gutter={'0.2rem'}
      justify="center"
      align="center"
      style={{ cursor: 'pointer' }}
    >
      <GoArrowRight size={14} />
      <RiFormula size={16} />
    </Inline>
  );

  const onItemSelect = (value: any) => {
    onMenuClick(value);
    menuRef?.current?.hide();
  };

  return (
    <Menu
      ref={menuRef}
      onMenuItemClick={onItemSelect}
      launcher={launcher}
      disabled={isRuleReadOnly}
      placement="bottom"
    >
      <MenuItem value="jsFormula" key="switchToJsCode">
        <PadBox padding={['0.4rem', '0.8rem']}>
          <Typography>Switch to Js Code</Typography>
        </PadBox>
      </MenuItem>
      <MenuItem value="excelFormula" key="switchToFormula">
        <PadBox padding={['0.4rem', '0.8rem']}>
          <Typography>Switch to Formula</Typography>
        </PadBox>
      </MenuItem>
    </Menu>
  );
};
