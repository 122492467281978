import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { UseFormSetError } from 'react-hook-form';

import { DATE_FORMAT, DATE_TIME_FORMAT, isValidJson } from '../../utils/common';
import { FLOATING_NUMBER_REGEX } from '../../utils/regex';
import { isValidDate, isValidDateTime } from '../../utils/validation';
import { ModuleSchemaFormValues } from './types';

const validateConstantValue = (value: any, dataType: string) => {
  if (dataType === 'string') {
    if (typeof value === 'string' && !_isNil(value)) {
      return { value, isCorrect: true };
    }

    return { value, isCorrect: false, message: 'Invalid string value' };
  }

  if (dataType === 'numeric') {
    if (FLOATING_NUMBER_REGEX.test(value)) {
      return { value: parseFloat(value), isCorrect: true };
    }

    return { value, isCorrect: false, message: 'Invalid numeric value' };
  }

  if (dataType === 'date') {
    if (isValidDate(value, 'validation')) {
      return { value, isCorrect: true };
    }

    return { value, isCorrect: false, message: `only ${DATE_FORMAT} allowed` };
  }

  if (dataType === 'dateTime') {
    if (isValidDateTime(value, 'validation')) {
      return { value, isCorrect: true };
    }

    return {
      value,
      isCorrect: false,
      message: `only ${DATE_TIME_FORMAT} allowed`,
    };
  }

  if (dataType === 'boolean') {
    if (value === true || value === false) {
      return {
        value,
        isCorrect: true,
      };
    } else {
      return {
        value,
        isCorrect: false,
        message: 'Invalid boolean value',
      };
    }
  }

  if (dataType === 'list') {
    if (Array.isArray(value)) {
      return { value, isCorrect: true };
    } else {
      return {
        value,
        isCorrect: false,
        message: 'Invalid list value',
      };
    }
  }

  if (dataType === 'json') {
    const isValid =
      !_isNil(value) &&
      typeof value === 'object' &&
      isValidJson(JSON.stringify(value));

    if (isValid) {
      return { value, isCorrect: true };
    } else {
      return {
        value,
        isCorrect: false,
        message: 'Invalid json value',
      };
    }
  }

  return { value, isCorrect: true };
};

export const schemaFormValidation = (
  data: ModuleSchemaFormValues,
  setError: UseFormSetError<ModuleSchemaFormValues>
) => {
  let isValid = true;

  const schemaData = data.moduleSchema;

  const duplicateKeyNames: Record<string, number[]> = {};

  (schemaData ?? [])?.forEach((obj, index: number) => {
    const usage = obj.usageType;

    if (!_isNil(duplicateKeyNames[obj.name])) {
      duplicateKeyNames[obj.name] = [...duplicateKeyNames[obj.name], index];
    } else {
      duplicateKeyNames[obj.name] = [index];
    }

    if (usage === 'mandatory' && (_isNil(obj?.value) || _isEmpty(obj?.value))) {
      isValid = false;

      setError(`moduleSchema.${index}.value`, {
        message: `Predefined values required as usage is set to mandatory`,
        type: 'validate',
      });
    }

    const isArray = Array.isArray(obj?.value ?? []);

    if (!isArray) {
      isValid = false;

      setError(`moduleSchema.${index}.value`, {
        message: `Predefined values should be inside list`,
        type: 'validate',
      });
    }

    const dataType: string = obj.dataType?.value ?? 'string';

    obj?.value?.forEach((currentValue: any) => {
      const { isCorrect, message } = validateConstantValue(
        currentValue,
        dataType
      );

      if (!isCorrect) {
        isValid = false;

        setError(`moduleSchema.${index}.value`, {
          message:
            message ??
            `All predefined values are not of valid ${dataType} dataType`,
          type: 'validate',
        });
      }
    });
  });

  _forEach(duplicateKeyNames, (value, key) => {
    if (value.length > 1) {
      isValid = false;

      value.forEach((val) => {
        setError(`moduleSchema.${val}.name`, {
          message: 'Attribute name must be unique',
          type: 'validate',
        });
      });
    }
  });

  return isValid;
};
