import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const JsFieldContainer = styled.div`
  block-size: calc(100vh - 25rem);
  border: 1px solid var(--color-solitude);
  border-radius: 4px;
`;

export const SaveButtonContainer = styled(PadBox)`
  border-top: 1px solid var(--color-darkGray);
  position: sticky;
  margin-top: auto;
  bottom: 0;
  background-color: var(--color-white);
`;

export const JsBlockContainer = styled(Stack)`
  block-size: 100vh;
  display: flex;
  flex-direction: column;
`;
