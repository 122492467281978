import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { Attributes, Dataset, getDataTypeNected } from 'ui';

import { generateUid } from '../../../utils/common';
import {
  VariablesResult,
  useGetVariables,
} from '../../Rules/hooks/graphql/useGetVariables';
import { getDefaultValueByDataTypeV2 } from '../../Rules/utils/common';
import type { WorkflowNodeType } from './useOpenWorkflow';

type UseGetDatasetArgs = {
  parentNodes?: WorkflowNodeType[];
  parentLoopNodes?: WorkflowNodeType[];
};

export function useGetDataset({
  parentNodes,
  parentLoopNodes,
}: UseGetDatasetArgs) {
  const [updatedDataSet, setUpdatedDataSet] = useState<Record<string, Dataset>>(
    {}
  );

  const [getVariables] = useGetVariables();

  const getRuleDataByProps = async (parents: WorkflowNodeType[]) => {
    for (const parent of parents) {
      // if (!_isEmpty(parent.data?.entityId ?? '')) {
      // eslint-disable-next-line
      setUpdatedDataSet((ds) => ({
        ...ds,
        [parent.data?.name ?? '']: {
          id: !_isEmpty(parent.data.entityId ?? parent.data?.name)
            ? parent.data.entityId ?? parent.data?.name
            : generateUid('trigger_'),
          name: parent.data?.name ?? '',
          blockName: parent.data?.blockName ?? '',
          attributes:
            Object.keys(parent.data?.executedValue ?? {}).reduce(
              (acc, curr) => {
                if (curr === 'resetOutput') {
                  return acc;
                }

                return {
                  ...acc,
                  [curr]: {
                    id: curr,
                    name: curr,
                    dataType:
                      parent.type === 'trigger'
                        ? parent.data.input?.[curr]?.dataType ?? 'unknown'
                        : getDataTypeNected(parent.data?.executedValue[curr]),
                    executedValue: parent.data?.executedValue[curr],
                  },
                };
              },
              {}
            ) ?? {},
        },
      }));
      // }
    }
  };

  const getParentLoopNodesData = (parents: WorkflowNodeType[]) => {
    for (const parent of parents) {
      const input = parent.data.input;

      const inputValue = input.inputValue.executedValue;
      const chunkSize = input.chunkSize.value;

      setUpdatedDataSet((ds) => ({
        ...ds,
        [parent.data?.name ?? '']: {
          id: !_isEmpty(parent.data.entityId ?? parent.data?.name)
            ? parent.data.entityId ?? parent.data?.name
            : generateUid('trigger_'),
          name: parent.data?.name ?? '',
          attributes: {
            currentItem: {
              id: 'currentItem',
              name: 'currentItem',
              dataType: 'list',
              executedValue:
                !_isNil(inputValue) && !_isEmpty(inputValue)
                  ? inputValue.slice(0, chunkSize)
                  : [],
            },
            currentIdx: {
              id: 'currentIdx',
              name: 'currentIdx',
              dataType: 'numeric',
              executedValue: 0,
            },
          },
        },
      }));
    }
  };

  const setGlobalCustomToDataSet = (data?: VariablesResult) => {
    if (_isNil(data)) {
      return;
    }

    if (data.getGlobalVars?.data?.length > 0) {
      setUpdatedDataSet((dataSet) => ({
        ...dataSet,
        [data.getGlobalVars.data[0].category]: {
          name: 'Global Attributes',
          id: generateUid('global_'),
          order: 2,
          attributes: data.getGlobalVars.data.reduce<
            Record<string, Attributes>
          >((acc, curr) => {
            return {
              ...acc,
              [curr.name]: {
                name: curr.name,
                dataType: curr.dataType,
                executedValue:
                  curr.dataType === 'json' && typeof curr.value === 'string'
                    ? JSON.parse(curr.value)
                    : curr.value,
              },
            };
          }, {}),
        },
      }));
    }

    if (data.getSystemVars?.data?.length > 0) {
      setUpdatedDataSet((dataSet) => ({
        ...dataSet,
        [data.getSystemVars.data[0].category]: {
          name: 'System Attributes',
          id: generateUid('system_'),
          order: 3,
          attributes: data.getSystemVars.data.reduce<
            Record<string, Attributes>
          >((acc, curr) => {
            return {
              ...acc,
              [curr.name]: {
                name: curr.name,
                dataType: curr.dataType,
                executedValue:
                  curr.name === 'Environment'
                    ? 'staging'
                    : getDefaultValueByDataTypeV2(curr.dataType),
              },
            };
          }, {}),
        },
      }));
    }
  };

  useEffect(() => {
    if (!_isNil(parentNodes) && !_isEmpty(parentNodes)) {
      void getRuleDataByProps(parentNodes);
    }
  }, [parentNodes]);

  useEffect(() => {
    if (!_isNil(parentLoopNodes) && !_isEmpty(parentLoopNodes)) {
      void getParentLoopNodesData(parentLoopNodes);
    }
  }, [JSON.stringify(parentLoopNodes)]);

  useEffect(() => {
    const getGlobalSystemVars = async () => {
      try {
        const { data } = await getVariables();

        setGlobalCustomToDataSet(data);
      } catch {}
    };

    void getGlobalSystemVars();
  }, []);

  return {
    updatedDataSet,
  };
}
