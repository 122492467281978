import { gql, useLazyQuery } from '@apollo/client';

import { RuleSetResponseModel } from '../../components/RuleSet/models';

export const getRuleSetById = gql`
  query getRule($id: String, $live: Boolean, $filters: QueryOperators) {
    getRule(id: $id, live: $live, filters: $filters) {
      data {
        id
        name
        description
        version
        workspaceId
        type
        staticUrl
        ruleChain
        ruleSet
        ruleSetPolicy
        firstRuleChain
        checksum
        warnings
        customInput
        datasetDetail {
          connector {
            id
          }
        }
        settings {
          startAt
          endAt
          timezone
          dateFormat
          isEnabled
          auditIO
          api {
            isEnabled
            isPrivate
          }
          cache {
            duration {
              unit
              value
            }
            enabled
            cacheKeys
          }
          schedule {
            startAt
            endAt
            inputParam
            unit
            spec {
              minute
              hour
              dayOfWeek
              dayOfMonth
              month
            }
            timezone
            isEnabled
          }
        }
        createdAt
        publishedAt
        status
        isLive
        approvalInfo {
          title
          description
          approvers
        }
        versionInfo {
          currentVersion
          currentLiveVersion
          lastVersion
        }
        accessRole
        dependencyMap {
          id
          name
          type
          version
          commitTitle
          subType
          nodeId
        }
      }
    }
  }
`;

export function useGetRuleSetById() {
  return useLazyQuery<RuleSetResponseModel>(getRuleSetById);
}
