import { PadBox } from '@bedrock-layout/padbox';
import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _reduce from 'lodash/reduce';
import { useEffect, useState } from 'react';
import { UseFormSetValue, useFieldArray, useForm } from 'react-hook-form';
import {
  Button,
  Sheet,
  Spinner,
  TooltipReact,
  Typography,
  useCurrentLayer,
} from 'ui';

import { siteConstantsAtom } from '../../../../../atom';
import { customAttributesAtom } from '../../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import { useSendEventToGTM } from '../../../../../hooks/useSendEventToGTM';
import {
  getTestValuesDefaultRuleSet,
  getTooltipText,
} from '../../../../../utils/common';
import { StatusCode } from '../../../../../utils/response/statusCode';
import { useTestRule } from '../../../hooks/restApi/useTestRule';
import { useServerResponse } from '../../../hooks/useServerResponse';
import type { AttributeModel, TestObjectModel } from '../../../models';
import type {
  RuleSetActionInfoObject,
  RuleSetTestActionFetchResponseType,
  RuleSetTestActionResponse,
  RuleSetTestOutputType,
  RuleTestActionObject,
  RuleTestActionPollingPayload,
} from '../../../types';
import {
  convertRuleSetDataToTestableData,
  getActionInfoForRuleTestActions,
  getAllInProgressActions,
  getAllRuleTestActions,
  getDataTypeByKeyV2,
  getFormattedJsonForRuleTestActions,
  getRuleSetTestModel,
  validateTestParameters,
} from '../../../utils/common';
import {
  isRulePublishableAtom,
  isRulePublishedAtom,
} from '../../CreateRuleSheet/CreateRuleSheet';
import { RuleTestWarnings } from '../../RuleComponents/RuleTestWarnings/RuleTestWarnings';
import { RuleTestHeader } from '../../RuleTestHeader/RuleTestHeader';
import { TestNodesDataModel } from '../../SimpleRule/TestNodeSheet/TestNodeSheet';
import { TestAddItems } from '../../TestNodeComponents/TestAddItems';
import { TestNodeField } from '../../TestNodeComponents/TestNodeField';
import { TestNodeFooter } from '../../TestNodeComponents/TestNodeFooter';
import { ExecutionTest } from '../ExecutionTest/ExecutionTest';
import { ruleSetNodeId } from '../RuleSet';
import { resultByRuleAtom } from '../RuleSetEditor/RuleSetEditor';
import type { CustomAttributeByRuleId } from '../models';
import {
  EmptyInputText,
  Form,
  HeaderItemStyled,
  HeaderStyled,
  RowItemStyled,
  RowStyled,
  SheetContainer,
  TestNodesContainerStyled,
} from './TestNodeSheet.styled';
import type { WorkerReceiveMessageProp } from './workerTypes';

type TestNodesHeaderModel = {
  label: string;
  dataType: string;
};

type TestNodeSheetProps = {
  ruleName: string;
  setValue: UseFormSetValue<any>;
};

// eslint-disable-next-line
function getDefaultValues(
  customAttributesInFields: Record<string, CustomAttributeByRuleId[]>
) {
  return _reduce(
    customAttributesInFields,
    (result: Record<string, TestObjectModel>, value, key) => {
      const keyByValue = {
        ...customAttributesInFields[key].reduce<
          Record<string, TestObjectModel>
        >((acc, k) => {
          return {
            ...acc,
            [k.key]: getRuleSetTestModel(k),
          };
        }, {}),
      };

      return {
        ...result,
        ...keyByValue,
      };
    },
    {}
  );
}

function getDefaultValuesV2(
  customAttributesInFields: Record<string, AttributeModel>
) {
  return _reduce(
    customAttributesInFields,
    (result: Record<string, TestObjectModel>, value, key) => {
      return {
        ...result,
        [key]: {
          isNullable: value.isNullable,
          isOptional: value.isOptional,
          value: value.executedValue,
          schemaId: value.schemaId,
        },
      };
    },
    {}
  );
}

export function getPropertiesByKeyName(
  customAttributesInFields: Record<string, CustomAttributeByRuleId[]>
) {
  return _reduce(
    customAttributesInFields,
    (result: Record<string, TestObjectModel>, value, key) => {
      const keyByValue = customAttributesInFields[key].reduce<
        Record<string, TestObjectModel>
      >((acc, k) => {
        return {
          ...acc,
          [k.key]: {
            value: getTestValuesDefaultRuleSet(k),
            isNullable: k.isNullable ?? false,
            isOptional: k.isOptional ?? false,
          },
        };
      }, {});

      return {
        ...result,
        ...keyByValue,
      };
    },
    {}
  );
}

export function TestNodeSheet({
  ruleName,
  setValue: setParentValue,
}: TestNodeSheetProps) {
  const accessToken = window.localStorage.getItem('accessToken') ?? '';
  const workspaceUUID =
    window.sessionStorage.getItem('workspaceUUID') ??
    window.localStorage.getItem('workspaceUUID') ??
    '';
  const [customAttributes] = useAtom(customAttributesAtom);

  const dataTypeByKey = getDataTypeByKeyV2(customAttributes);
  const [ruleSetId] = useAtom(ruleSetNodeId);
  const [isRulePublished] = useAtom(isRulePublishedAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);

  const { control, handleSubmit, setError, setValue } =
    useForm<TestNodesDataModel>({
      defaultValues: {
        test: [{ ...getDefaultValuesV2(customAttributes) }],
        headers: [],
        queryParams: [],
      },
    });

  const { sendEventToGTM } = useSendEventToGTM();

  const { serverResponse, setServerResponse } = useServerResponse();
  const [ruleActionResponse, setRuleActionResponse] =
    useState<RuleSetTestActionResponse>({});

  const [actionInfo, setActionInfo] = useState<RuleSetActionInfoObject>({});

  const { fields } = useFieldArray({
    control,
    name: 'test',
  });

  const [headerList, setHeaderList] = useState<TestNodesHeaderModel[]>([]);
  const {
    testRule,
    isLoading: isTesting,
    data,
    error,
  } = useTestRule({ ruleName, ruleType: 'ruleSet', setValue: setParentValue });

  const [, setRulePublishable] = useAtom(isRulePublishableAtom);

  const [ruleResultById] = useAtom(resultByRuleAtom);
  const [ruleOutputMapping, setRuleOutputMapping] =
    useState<RuleSetTestOutputType>({});

  const [pollingPayload, setPollingPayload] = useState<
    RuleTestActionPollingPayload[]
  >([]);
  const [isInitiateWorker, setIsInitiateWorker] = useState(false);

  const [actionResponse, setActionResponse] =
    useState<RuleSetTestActionFetchResponseType>({
      actionError: {},
      actionData: {},
      actionLoading: false,
    });

  const { actionError, actionData } = actionResponse;

  const actionPollingWorker: Worker = new Worker(
    new URL('./ActionPollingWorker.tsx', import.meta.url),
    {
      type: 'module',
    }
  );

  useEffect(() => {
    if (pollingPayload.length > 0) {
      setIsInitiateWorker(true);
    } else {
      setIsInitiateWorker(false);
    }
  }, [pollingPayload]);

  useEffect(() => {
    if (actionPollingWorker instanceof Worker) {
      actionPollingWorker.postMessage({
        pollingPayload,
        accessToken,
        workspaceUUID,
        type: isInitiateWorker ? 'start' : 'terminate',
      });
    }
  }, [isInitiateWorker]);

  useEffect(() => {
    if (actionPollingWorker instanceof Worker) {
      actionPollingWorker.onmessage = (
        e: MessageEvent<WorkerReceiveMessageProp>
      ) => {
        const { data } = e;
        setActionResponse(data);
      };
    }
  }, [actionPollingWorker]);

  const { close } = useCurrentLayer();

  useEffect(() => {
    if (!_isNil(customAttributes)) {
      const headers: TestNodesHeaderModel[] = [];

      _forEach(Object.keys(customAttributes), (value) => {
        headers.push({
          dataType: customAttributes[value].dataType?.value ?? 'string',
          label: value,
        });
      });

      setHeaderList(headers.filter((header) => header.label !== 'CI0'));
    }
  }, [customAttributes]);

  const onSubmit = async (data: TestNodesDataModel) => {
    sendEventToGTM({
      event: 'rule',
      ruleId: ruleSetId,
      ruleName,
      type: 'simpleRule',
      action: 'test',
      nec_source: '',
    });

    const isValid = validateTestParameters(data, setError);

    if (!_isNil(ruleSetId) && isValid) {
      try {
        setIsInitiateWorker(false);

        await testRule({
          id: ruleSetId,
          params: convertRuleSetDataToTestableData(data.test[0], dataTypeByKey),
          headers: data.headers,
        });
      } catch {
        setRulePublishable(false);
      }
    }
  };

  useEffect(() => {
    const json = !_isNil(data) ? data.data : {};
    const errorJson = error?.response?.data;

    if (!_isNil(json.data)) {
      setServerResponse({
        json: JSON.stringify(json, null, 2),
        executionTime: json.data.executionTime,
      });

      const output = json.data.output ?? [];

      const currRuleActionResponse: RuleSetTestActionResponse = _reduce(
        output,
        (accum, currData: any) => {
          const { ruleId } = currData;

          return {
            ...accum,
            [ruleId]: {
              ruleName: !_isNil(ruleResultById[ruleId])
                ? ruleResultById[ruleId].name
                : ruleId,
              ruleId,
              actionResponse: !_isNil(currData.action)
                ? getAllRuleTestActions(currData.action)
                : [],
            },
          };
        },
        {}
      );

      const currOutputRule = _reduce(
        output,
        (accum, currRule) => {
          return {
            ...accum,
            [currRule.ruleId]: currRule.output,
          };
        },
        {}
      );

      const allActionList = _reduce(
        currRuleActionResponse,
        (accum: RuleTestActionObject[], currRule) => {
          return [...accum, ...currRule.actionResponse];
        },
        []
      );

      setRuleActionResponse(currRuleActionResponse);
      setPollingPayload(getAllInProgressActions(allActionList));
      setRuleOutputMapping(currOutputRule);

      if (!isRulePublished) {
        setRulePublishable(true);
      }
    } else if (!_isNil(errorJson)) {
      setServerResponse({
        json: JSON.stringify(errorJson, null, 2),
        executionTime: '',
      });

      setRulePublishable(false);
    }
  }, [data, error]);

  useEffect(() => {
    const actionInfoData =
      !_isNil(actionData) && !_isEmpty(actionData) ? actionData.data : {};

    if (!_isNil(actionInfoData.data)) {
      const data = actionInfoData.data;

      const currRuleActionResponse = _reduce(
        ruleActionResponse,
        (accum, currRule) => {
          const ruleId = currRule.ruleId;

          currRule.actionResponse.forEach((action: RuleTestActionObject) => {
            const { executionId } = action.actionData;

            if (_has(data, executionId)) {
              const { executionTime, output, status } = data[executionId];
              const hasError =
                !_isNil(output?.error) && !_isEmpty(output?.error);

              action.actionData = {
                isCollapsed: true,
                executionTime,
                status,
                executionId,
                hasError,
                json: getFormattedJsonForRuleTestActions(
                  hasError ? output?.error : output?.Result
                ),
                ruleOutput: (action.actionData.ruleOutput =
                  getFormattedJsonForRuleTestActions(
                    ruleOutputMapping[ruleId]
                  )),
              };
            }
          });

          return {
            ...accum,
            [ruleId]: {
              ...currRule,
              actionResponse: currRule.actionResponse,
            },
          };
        },
        {}
      );

      let currPollingPayload = structuredClone(pollingPayload);
      currPollingPayload = currPollingPayload.filter(
        (actionPayload: RuleTestActionPollingPayload) =>
          _has(data, actionPayload.executionId) &&
          data[actionPayload.executionId].status === StatusCode.RUNNING
      );

      setRuleActionResponse(currRuleActionResponse);
      setPollingPayload(currPollingPayload);
    }
  }, [JSON.stringify(actionData)]);

  useEffect(() => {
    if (!_isEmpty(actionError)) {
      const { status, data } = actionError;
      const message = !_isEmpty(data) ? data.message : '';

      if (
        !_isNil(status) &&
        (status === 400 || status === 401 || status >= 500)
      ) {
        const updatedRuleActionResponse = _reduce(
          ruleActionResponse,
          (accum, currRule) => {
            const ruleId = currRule.ruleId;

            const updatedActionResponse = currRule.actionResponse.map(
              (action: RuleTestActionObject) => {
                if (action.actionData.status === StatusCode.RUNNING) {
                  action.actionData.status = StatusCode.FAILED;
                  action.actionData.json = message;
                  action.actionData.hasError = true;
                  action.actionData.ruleOutput =
                    getFormattedJsonForRuleTestActions(
                      ruleOutputMapping[ruleId]
                    );
                }

                return action;
              }
            );

            return {
              ...accum,
              [ruleId]: {
                ...currRule,
                actionResponse: updatedActionResponse,
              },
            };
          },
          {}
        );
        setRuleActionResponse(updatedRuleActionResponse);
        setPollingPayload([]);
      }
    }
  }, [actionError]);

  useEffect(() => {
    const currActionInfo: RuleSetActionInfoObject = _reduce(
      ruleActionResponse,
      (accum, currRule) => {
        return {
          ...accum,
          [currRule.ruleId]: getActionInfoForRuleTestActions(
            currRule.actionResponse
          ),
        };
      },
      {}
    );

    setActionInfo(currActionInfo);
  }, [JSON.stringify(ruleActionResponse)]);

  useEffect(() => {
    return () => {
      actionPollingWorker.terminate();
    };
  }, []);

  const handleExpandIconClick = (actionName: string, ruleId: string) => {
    const updatedRuleActionResponse: RuleSetTestActionResponse = _reduce(
      ruleActionResponse,
      (accum, currRule) => {
        const actionResponse = currRule.actionResponse.map((action) => {
          if (currRule.ruleId === ruleId && action.actionName === actionName) {
            action.actionData.isCollapsed = !action.actionData.isCollapsed;
          } else {
            action.actionData.isCollapsed = true;
          }

          return action;
        });

        return {
          ...accum,
          [currRule.ruleId]: {
            ...currRule,
            actionResponse,
          },
        };
      },
      {}
    );

    setRuleActionResponse(updatedRuleActionResponse);
  };

  return (
    <Sheet size="large">
      <SheetContainer>
        <RuleTestHeader />
        <RuleTestWarnings />

        <Stack as={Form} gutter="1.6rem" onSubmit={handleSubmit(onSubmit)}>
          {headerList.length > 0 ? (
            <>
              <Typography fontWeight={700}>Input Attributes</Typography>
              <TestNodesContainerStyled as="ul">
                <HeaderStyled gutter={0}>
                  {headerList.map((header, index) => (
                    <HeaderItemStyled key={`attributeHeader_${index}`}>
                      <PadBox padding={[5, 10]}>
                        <Inline stretch="start" align="end">
                          {header.label.length > 15 ? (
                            <TooltipReact
                              id={`attributeHeader_${header.label}`}
                              launcher={
                                <span>
                                  <Typography>
                                    {header.label.substring(0, 15)}...
                                  </Typography>
                                </span>
                              }
                            >
                              <Typography>{header.label}</Typography>
                            </TooltipReact>
                          ) : (
                            <Typography>{header.label}</Typography>
                          )}
                          <Typography name="secondaryXs">
                            {header.dataType}
                          </Typography>
                        </Inline>
                      </PadBox>
                    </HeaderItemStyled>
                  ))}
                </HeaderStyled>

                {fields.map((field, index) => (
                  <RowStyled
                    gutter={0}
                    key={`testRowItem_${field.id}`}
                    align="center"
                  >
                    {Object.keys(field)
                      .filter((key) => key !== 'id')
                      .map((key, fieldIndex) => (
                        <RowItemStyled
                          key={`testRowItem_${index}_${fieldIndex}`}
                        >
                          <PadBox padding={[5, 10]} as={Inline} align="center">
                            <TestNodeField
                              nodeKey={key}
                              index={index}
                              dataType={
                                customAttributes[key]?.dataType?.value ??
                                'string'
                              }
                              control={control}
                              setValue={setValue}
                              isNullable={customAttributes[key]?.isNullable}
                              isOptional={customAttributes[key]?.isOptional}
                            />
                          </PadBox>
                        </RowItemStyled>
                      ))}
                  </RowStyled>
                ))}
              </TestNodesContainerStyled>{' '}
            </>
          ) : (
            <EmptyInputText name="secondarySmall">
              You do not have any input attributes configured. Please go ahead
              and click on {`"Test Now"`} to test the rule.
            </EmptyInputText>
          )}

          <Stack gutter={16}>
            <TestAddItems
              control={control}
              label="Headers"
              name="headers"
              tooltipText={getTooltipText(siteConstants, 'rules', 'testHeader')}
            />
          </Stack>

          <Inline justify="end">
            <Button type="submit" disabled={isTesting}>
              {isTesting ? <Spinner size="extraSmall" /> : 'Test Now'}
            </Button>
          </Inline>

          {!_isEmpty(serverResponse.json) && (
            <ExecutionTest
              serverResponse={serverResponse}
              isTesting={isTesting}
              ruleActionResponse={ruleActionResponse}
              actionInfo={actionInfo}
              handleExpandIconClick={handleExpandIconClick}
            />
          )}
        </Stack>
        <TestNodeFooter>
          <Button onClick={close}>Close</Button>
        </TestNodeFooter>
      </SheetContainer>
    </Sheet>
  );
}
