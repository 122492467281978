import { Inline, Stack } from '@bedrock-layout/primitives';
import { zodResolver } from '@hookform/resolvers/zod';
import Cookies from 'js-cookie';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useSearchParams } from 'react-router-dom';
import {
  Button,
  CheckboxField,
  Link,
  Spinner,
  TextField,
  Typography,
} from 'ui';
import { z } from 'zod';

import { SignupFormContainer } from '../../../components/authentication/layout/AuthPagesLayout.styled';
import { useSendEventToGTM } from '../../../hooks/useSendEventToGTM';
import {
  generateCaptchaToken,
  getPublicIP,
  getTimeZone,
} from '../../../utils/common';
import { envMap } from '../../../utils/constant';
import {
  emailValidationSchema,
  passwordValidationSchema,
} from '../../../utils/schema';
import { CustomLinks } from '../../Signin/components/Signin.styled';
import { InlineStyled } from './Signup.styled';
import { SignupRequestPayload, useSignup } from './useSignup';

const FormValidationSchema = z.object({
  ...emailValidationSchema,
  ...passwordValidationSchema,
});

type SignupFormValues = {
  email: string;
  password: string;
  confirmPassword: string;
  acceptTermsAndCondition: boolean;
};

export const SignupForm = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { signup, isLoading: isApiLoading } = useSignup({ setIsLoading });

  const showSpinner = isLoading || isApiLoading;

  const { sendEventToGTM } = useSendEventToGTM();

  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);

  const [searchParams] = useSearchParams();

  const { control, handleSubmit, watch } = useForm<SignupFormValues>({
    resolver: zodResolver(FormValidationSchema),
    defaultValues: {
      email: window.atob(window.localStorage.getItem('signup_onb') ?? '') ?? '',
      password: '',
      confirmPassword: '',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    sendEventToGTM({
      event: 'page_view',
      page_path: window.location.pathname,
      page_title: 'Signup',
      page_location: window.location.href,
    });
    const source: Record<string, string> = {
      ...JSON.parse(window.localStorage.getItem('source') ?? '{}'),
      ...JSON.parse(Cookies.get('nected_source') ?? '{}'),
      nec_visited_pages: JSON.parse(
        Cookies.get('nected_page_visits') ?? '[]'
      ).join(','),
    };
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    for (const [key, value] of params.entries()) {
      if (key.includes('utm_')) {
        source[key] = value;
      }
    }
    source.utm_timeZone = getTimeZone();

    window.localStorage.setItem('source', JSON.stringify(source));
    void getPublicIP();
  }, []);

  const isTermsAndConditionChecked = watch('acceptTermsAndCondition', false);

  const onSubmit = ({ email, password, confirmPassword }: SignupFormValues) => {
    const source: Record<string, string> = JSON.parse(
      window.localStorage.getItem('source') ?? '{}'
    );

    const payload: SignupRequestPayload = {
      email,
      password,
      confirmPassword,
      source,
      timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    if (!_isNil(searchParams.get('token'))) {
      const token = window.atob(searchParams.get('token') ?? '');

      if (!_isEmpty(token)) payload.token = token;
    }

    generateCaptchaToken(signup, payload);
  };

  return (
    <SignupFormContainer onSubmit={handleSubmit(onSubmit)}>
      <Stack gutter="2.4rem">
        <Stack gutter="1.6rem">
          <TextField name="email" control={control} label="Email" disabled={window.localStorage.getItem('signup_onb') ? true : false}/>

          <TextField
            onClickInputIcon={() => setTogglePassword((isShow) => !isShow)}
            name="password"
            type={togglePassword ? 'text' : 'password'}
            icon={
              togglePassword ? (
                <AiOutlineEye size={20} />
              ) : (
                <AiOutlineEyeInvisible size={20} />
              )
            }
            control={control}
            label="Password"
          />

          <TextField
            name="confirmPassword"
            type={toggleConfirmPassword ? 'text' : 'password'}
            icon={
              toggleConfirmPassword ? (
                <AiOutlineEye size={20} />
              ) : (
                <AiOutlineEyeInvisible size={20} />
              )
            }
            control={control}
            onClickInputIcon={() =>
              setToggleConfirmPassword((isShow) => !isShow)
            }
            label="Confirm Password"
          />
        </Stack>

        <Stack gutter=".4rem">
          <Inline as="label" align="center">
            <CheckboxField
              control={control}
              name="acceptTermsAndCondition"
              defaultValue={false}
              label={
                <Stack gutter={0}>
                  <Typography name="secondary2">
                    By registering your details, you agree with our
                    <CustomLinks
                      onClick={() =>
                        window.open(envMap.VITE_TANDC_URL, '_blank')
                      }
                    >
                      {' '}
                      Terms & Conditions{' '}
                    </CustomLinks>{' '}
                    and
                    <CustomLinks
                      onClick={() => window.open(envMap.VITE_PP_URL, '_blank')}
                    >
                      {' '}
                      Privacy Policy
                    </CustomLinks>
                    .
                  </Typography>
                </Stack>
              }
            />
          </Inline>
        </Stack>

        <Stack gutter="1.6rem">
          <Button
            type="submit"
            disabled={showSpinner || !isTermsAndConditionChecked}
            size="large"
          >
            {showSpinner ? <Spinner size="extraSmall" /> : 'Sign Up'}
          </Button>

          <InlineStyled align="center">
            <Typography name="heading3" element="span">
              Already have an account?
            </Typography>

            <Link to={`/signin${window.location.search}`}>Log in</Link>
          </InlineStyled>
        </Stack>
      </Stack>
    </SignupFormContainer>
  );
};
