/* eslint-disable  */
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTransition } from 'react';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import { OnClickRuleArgs, Typography, useLayer } from 'ui';

import { isRuleReadOnlyAtom } from '../../..';
import { siteConstantsAtom } from '../../../../../atom';
import { AddIcon } from '../../../../../components/icons/AddIcon';
import { useSendEventToGTM } from '../../../../../hooks/useSendEventToGTM';
import { generateUid, getTooltipText } from '../../../../../utils/common';
import { sendEventToGTMType } from '../../../types';
import {
  getConstantNode,
  getDecisionTableBlock,
  getGroupNode,
  getParamNode,
  getRequiredKey,
} from '../../../utils/common';
import {
  checkIfSiblingsHaveSameParent,
  deleteDecisionTableNodes,
  getIfHasParentGroup,
  getOperatorByRow,
} from '../../../utils/decisionTable';
import {
  decisionTableNodeIdAtom,
  decisionTableNodesAtom,
  decisionTableRenderError,
} from '../DecisionTable';
import RuleProperty from '../TableNodes/RuleProperty';
import { DecisionTableNodesModel, DecisionTableRow } from '../models';
import {
  ColumnHeaderContainer,
  ConditionBar,
  HeaderInputContainer,
  PropertyHeader,
} from './DecisionTableBlock.styled';
import { OperatorSelection } from './OperatorSelection';
import { PropertyActions } from './PropertyActions';
import { PropertyChangeModal } from './PropertyChangeModal';
import { PropertyMenu } from './PropertyMenu';

type PropertyBlockProps = Omit<UseControllerProps, 'name'> & {
  id: string;
  isFirst: boolean;
  isLast: boolean;
  setValue: UseFormSetValue<any>;
  index: number;
  length: number;
  indexToObserve: number;
  setIndexToObserve: (index: number) => void;
  groupToObserve?: string;
  renderedGroups: string[];
  setGroupToObserve: Dispatch<SetStateAction<string | undefined>>;
  setRenderedGroups: Dispatch<SetStateAction<string[]>>;
};

export default function PropertyBlock({
  id,
  isFirst,
  isLast,
  setValue,
  control,
  index,
  length,
  indexToObserve,
  setIndexToObserve,
  groupToObserve,
  renderedGroups,
  setGroupToObserve,
  setRenderedGroups,
}: PropertyBlockProps) {
  const properties = useWatch({ control, name: 'properties' });
  const rows = useWatch({ control, name: 'rows' });
  const [, startTransition] = useTransition();
  const [nodes, setNodes] = useAtom(decisionTableNodesAtom);
  const [counter, setCounter] = useState(0);

  const [keepProperty, setKeepProperty] = useState(false);

  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [, setDtRenderError] = useAtom(decisionTableRenderError);

  const [ruleId] = useAtom(decisionTableNodeIdAtom);

  const { sendEventToGTM } = useSendEventToGTM();

  const ruleName = useWatch({
    control,
    name: 'ruleName',
  });

  const currentProperty = properties[index];

  const currentKey = Object.keys(currentProperty).filter(
    (key) => key !== 'id'
  )[0];

  const containerRef = useRef<HTMLDivElement>(null);

  const doesHaveParent = getIfHasParentGroup(rows, nodes, index);
  const hasSameParent = checkIfSiblingsHaveSameParent(rows, index, nodes);

  useEffect(() => {
    if (doesHaveParent.hasParent) {
      if (groupToObserve !== doesHaveParent.parent) {
        if (groupToObserve !== undefined) {
          setRenderedGroups((prev) => [...prev, groupToObserve]);
        }

        if (!renderedGroups.includes(doesHaveParent.parent)) {
          setGroupToObserve(doesHaveParent.parent);
        } else {
          // Group is already rendered
          setDtRenderError(true);
        }
      }
    }
  }, [JSON.stringify(doesHaveParent)]);

  useEffect(() => {
    if (index === indexToObserve && index !== -1) {
      containerRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });

      setIndexToObserve(-1);
    }
  }, [indexToObserve]);

  const handleAddProperty = (
    type: string = 'condition',
    skipParent: boolean = false
  ) => {
    if (isRuleReadOnly) return;

    const paramId = generateUid('rule_');
    const paramNode = getParamNode();
    const indexToBeAddedAt =
      hasSameParent.hasSame && skipParent
        ? index + hasSameParent.total
        : index + (skipParent ? hasSameParent.total - 1 : 0) + 1;

    // Queue GTM event
    queueMicrotask(() => {
      sendEventToGTM({
        event: 'rule',
        ruleId,
        ruleName,
        type: 'decisionTable',
        action: 'add',
        element: 'condition',
        action_name: 'and',
        nec_source: '',
      });
    });

    startTransition(() => {
      const propertyToInsert = {
        [paramId]: { value: '', key: '', dataType: '' },
      };

      const newProperties = [
        ...properties.slice(0, indexToBeAddedAt),
        propertyToInsert,
        ...properties.slice(indexToBeAddedAt),
      ];

      // Initialize all updates
      const nodeUpdates = { [paramId]: paramNode };
      const rowsUpdates = [...rows];

      // Process all rows at once but only collect updates
      rows.forEach((row: Record<string, any>, i: number) => {
        const rowKey = getRequiredKey(row, ['id']);
        const currentGroup = row[rowKey];

        if (_isNil(currentGroup) || _isEmpty(currentGroup)) return;

        const newRhsId = generateUid('rule_');
        const newConditionId = generateUid('rule_');
        const group = nodes[rowKey];
        const siblings =
          !_isNil(group.children) && group.children.length > 0
            ? [...group.children]
            : [];

        let currentNodeUpdates = {};

        if (type === 'condition' && (!doesHaveParent.hasParent || skipParent)) {
          siblings.splice(indexToBeAddedAt, 0, newConditionId);
          currentNodeUpdates = {
            [newConditionId]: {
              parent: rowKey,
              nodeType: 'condition',
              operator: '',
              siblingIndex: 1,
              leftNode: [paramId],
              rightNode: [newRhsId],
            },
            [newRhsId]: getConstantNode(newConditionId, ''),
            [rowKey]: {
              ...(nodes[rowKey] || {}),
              children: siblings,
            },
          };
        } else if (type === 'group') {
          const newGroupId = generateUid('rule_');
          siblings.splice(indexToBeAddedAt, 0, newGroupId);
          currentNodeUpdates = {
            [newGroupId]: getGroupNode(rowKey, [newConditionId], 'and'),
            [newConditionId]: {
              parent: newGroupId,
              nodeType: 'condition',
              operator: '',
              siblingIndex: 1,
              leftNode: [paramId],
              rightNode: [newRhsId],
            },
            [newRhsId]: getConstantNode(newConditionId, ''),
            [rowKey]: {
              ...(nodes[rowKey] || {}),
              children: siblings,
            },
          };
        } else if (type === 'condition' && doesHaveParent.hasParent) {
          const doesHaveParentByRowIndex = getIfHasParentGroup(
            rows,
            nodes,
            index,
            i
          );
          const children = [
            ...(nodes[doesHaveParentByRowIndex.group ?? 0]?.children ?? []),
          ];
          children.splice(indexToBeAddedAt, 0, newConditionId);

          currentNodeUpdates = {
            [newConditionId]: {
              parent: doesHaveParentByRowIndex.group,
              nodeType: 'condition',
              operator: '',
              siblingIndex: 1,
              leftNode: [paramId],
              rightNode: [newRhsId],
            },
            [newRhsId]: getConstantNode(newConditionId, ''),
            [doesHaveParentByRowIndex.group ?? 0]: {
              ...nodes[doesHaveParentByRowIndex.group ?? 0],
              children,
            },
          };
        }

        Object.assign(nodeUpdates, currentNodeUpdates);

        const updatedGroup = { ...currentGroup };
        const updatedCondition = [...currentGroup.condition];
        const conditionToInsert = {
          [newConditionId]: {
            value: '',
            type: '',
            leftOperands: 1,
            rightOperands: 1,
          },
          rhs: [
            {
              [newRhsId]: {
                value: '',
                key: '',
                dataType: '',
              },
            },
          ],
        };

        updatedCondition.splice(indexToBeAddedAt, 0, conditionToInsert);
        updatedGroup.condition = updatedCondition;

        // Update in the copied rows array
        const updatedRow = { ...row };
        updatedRow[rowKey] = updatedGroup;
        rowsUpdates[i] = updatedRow;
      });

      // Single batch update for nodes
      setNodes((prev) => ({
        ...prev,
        ...nodeUpdates,
      }));

      // Single batch update for properties
      setValue('properties', newProperties);

      // Single batch update for all rows
      setValue('rows', rowsUpdates);

      setIndexToObserve(index + 1);
    });
  };

  useEffect(() => {
    setCounter(counter + 1);

    if (!_isNil(currentProperty) && !keepProperty && counter > 0) {
      const isJsOrExcelCondition = ['jsCondition', 'excelCondition'].includes(
        currentProperty[currentKey].dataType
      );

      if (!isJsOrExcelCondition) {
        const updatedRows = [...rows];
        const nodeUpdates: Record<string, DecisionTableNodesModel | null> = {};

        rows?.forEach((row: any, rowIndex: number) => {
          const rowKey = getRequiredKey(row, ['id']);
          const rowCondition = row[rowKey].condition[index];

          if (!_isNil(row) && !_isNil(rowCondition)) {
            const rowConditionKey = Object.keys(rowCondition).filter(
              (key) => key !== 'id' && key !== 'rhs'
            )[0];

            if (
              currentProperty[currentKey].dataType !==
              rowCondition[rowConditionKey].type
            ) {
              const addDefaultType = !_isEmpty(
                currentProperty[currentKey].dataType
              );

              const newRhsNodeId = generateUid('rule_');
              const newConstantNode = getConstantNode(
                rowConditionKey,
                currentProperty[currentKey].dataType ?? ''
              );

              const updatedRow = {
                ...row,
                [rowKey]: {
                  ...row[rowKey],
                  condition: [...row[rowKey].condition],
                },
              };

              updatedRow[rowKey].condition[index] = {
                ...rowCondition,
                [rowConditionKey]: {
                  leftOperands: 1,
                  type: currentProperty[currentKey].dataType ?? '',
                  rightOperands: addDefaultType ? 0 : 1,
                  value: addDefaultType ? 'any' : '',
                },
                rhs: addDefaultType
                  ? []
                  : [
                      {
                        [newRhsNodeId]: {
                          value: '',
                          key: '',
                          dataType: currentProperty[currentKey].dataType ?? '',
                        },
                      },
                    ],
              };

              updatedRows[rowIndex] = updatedRow;

              nodeUpdates[rowConditionKey] = {
                ...(nodes[rowConditionKey] || {}),
                dataType: currentProperty[currentKey].dataType ?? '',
                value: '',
                operator: 'any',
                rightNode: addDefaultType ? [] : [newRhsNodeId],
                nodeType: 'condition',
                name: '',
                query: '',
              } as DecisionTableNodesModel;

              if (!addDefaultType) {
                nodeUpdates[newRhsNodeId] = {
                  ...newConstantNode,
                  dataType: currentProperty[currentKey].dataType ?? '',
                } as DecisionTableNodesModel;
              }

              nodes[rowConditionKey]?.rightNode?.forEach((node) => {
                nodeUpdates[node] = null;
              });
            }
          }
        });

        setNodes((prev) => {
          const newNodes = { ...prev };
          Object.entries(nodeUpdates).forEach(([key, value]) => {
            if (value === null) {
              delete newNodes[key];
            } else {
              newNodes[key] = value;
            }
          });
          return newNodes;
        });

        setValue('rows', updatedRows);
      } else {
        const updatedRows = [...rows];
        const nodeUpdates: Record<string, DecisionTableNodesModel | null> = {};

        rows?.forEach((row: any, rowIndex: number) => {
          const rowKey = getRequiredKey(row, ['id']);
          const rowCondition = row[rowKey].condition[index];

          if (!_isNil(row) && !_isNil(rowCondition)) {
            const rowConditionKey = Object.keys(rowCondition).filter(
              (key) => key !== 'id' && key !== 'rhs'
            )[0];

            if (
              currentProperty[currentKey].dataType !==
              rowCondition[rowConditionKey].type
            ) {
              const jsCommentText = getTooltipText(
                siteConstants,
                'rules',
                'formulaInCondition',
                'otherText'
              );
              const defaultValue = ['jsCondition', 'excelCondition'].includes(
                currentProperty[currentKey].dataType
              )
                ? `1===1`
                : '';

              const updatedRow = {
                ...row,
                [rowKey]: {
                  ...row[rowKey],
                  condition: [...row[rowKey].condition],
                },
              };

              updatedRow[rowKey].condition[index] = {
                ...rowCondition,
                [rowConditionKey]: {
                  leftOperands: 1,
                  rightOperands: 0,
                  type: currentProperty[currentKey].dataType ?? '',
                  value: defaultValue,
                },
              };

              updatedRows[rowIndex] = updatedRow;

              nodeUpdates[rowConditionKey] = {
                ...(nodes[rowConditionKey] || {}),
                dataType: currentProperty[currentKey].dataType ?? '',
                value: '',
                operator: '',
                rightNode: [],
                nodeType: currentProperty[currentKey].dataType,
                name: 'Any',
                query: defaultValue,
              } as DecisionTableNodesModel;

              nodes[rowConditionKey].rightNode?.forEach((node) => {
                nodeUpdates[node] = null;
              });
            }
          }
        });

        setNodes((prev) => {
          const newNodes = { ...prev };
          Object.entries(nodeUpdates).forEach(([key, value]) => {
            if (value === null) {
              delete newNodes[key];
            } else {
              newNodes[key] = value;
            }
          });
          return newNodes;
        });

        setValue('rows', updatedRows);
      }

      setKeepProperty(false);
    }
  }, [currentProperty[currentKey].dataType, keepProperty]);

  const { openWithProps: openPropertyChangeModal } = useLayer(
    <PropertyChangeModal
      title="Changing Property Type"
      description={
        <span>
          Are you sure you want to change the property with existing conditions?
          You will lose all the associated conditions with this property and
          won&apos;t be able to recover them back.
        </span>
      }
    />
  );

  const handleSendEventToGTM = ({
    action = '',
    element = '',
    actionName = '',
  }: sendEventToGTMType) => {
    sendEventToGTM({
      event: 'rule',
      ruleId,
      ruleName,
      type: 'decisionTable',
      nec_source: '',
      action,
      element,
      action_name: actionName,
    });
  };

  const handleRuleClick = (args: OnClickRuleArgs) => {
    if (
      currentProperty[currentKey].dataType !== '' &&
      currentProperty[currentKey].dataType !== args.dataType
    ) {
      setKeepProperty(true);

      openPropertyChangeModal({
        onPropertyChange: () => {
          setKeepProperty(false);

          handleSendEventToGTM({
            action: 'selection',
            element: 'property',
            actionName: args.dataType,
          });
        },
        onKeepPropertyChange: () => {
          setValue(
            `properties.${index}.${currentKey}`,
            currentProperty[currentKey]
          );

          setKeepProperty(true);

          handleSendEventToGTM({
            action: 'selection',
            element: 'property',
            actionName: currentProperty[currentKey].dataType,
          });
        },
      });
    }
  };

  const padding = getDecisionTableBlock(isLast, isFirst, length);

  return (
    <PropertyHeader padding={padding} ref={containerRef}>
      <ColumnHeaderContainer gutter={'1rem'}>
        {doesHaveParent.hasParent && hasSameParent.isFirst && (
          <ConditionBar
            justify="start"
            stretch="start"
            align="center"
            $zIndex={2}
            $isGroup
            $inlineSize={
              hasSameParent.isFirst
                ? `calc(100% + ${35 * hasSameParent.count}rem)`
                : '100%'
            }
          >
            <Typography
              style={{ textAlign: 'center', paddingLeft: '4rem' }}
              fontWeight={700}
            >
              Group
            </Typography>

            <PropertyMenu
              onItemSelect={(type) => {
                handleAddProperty(type, true);
              }}
              launcher={
                <span>
                  <AddIcon color="var(--color-persianBlue)" />
                </span>
              }
              showGroup
              disabled={isRuleReadOnly}
            />

            {length > 1 && (
              <PropertyActions
                index={index}
                setValue={setValue}
                control={control}
                handleSendEventToGTM={handleSendEventToGTM}
                isGroup
                groupKey={doesHaveParent.group}
                indexes={hasSameParent.indexes}
                hasSameParent={hasSameParent}
                canUnGroup
              />
            )}
          </ConditionBar>
        )}
        <ConditionBar
          justify="start"
          stretch="start"
          align="center"
          $marginTop={hasSameParent.hasSame ? '3.5rem' : '0rem'}
        >
          <Typography
            fontWeight={700}
            style={{ textAlign: 'center', paddingLeft: '4rem' }}
          >
            Condition
          </Typography>

          <PropertyMenu
            onItemSelect={(type) => {
              handleAddProperty(type);
            }}
            showGroup={!doesHaveParent.hasParent}
            launcher={
              <span>
                <AddIcon color="var(--color-persianBlue)" />
              </span>
            }
            disabled={isRuleReadOnly}
          />

          {length > 1 && (
            <PropertyActions
              index={index}
              setValue={setValue}
              control={control}
              handleSendEventToGTM={handleSendEventToGTM}
              hasSameParent={hasSameParent}
              canJoin={!doesHaveParent.hasParent}
              canRemove={
                hasSameParent.hasSame ? hasSameParent.total > 1 : length > 1
              }
              canLeave={doesHaveParent.hasParent}
            />
          )}
        </ConditionBar>

        {!_isNil(currentKey) && !_isEmpty(currentKey) && (
          <HeaderInputContainer>
            <RuleProperty
              setValue={setValue}
              control={control}
              name={`properties.${index}.${currentKey}`}
              currentKey={currentKey}
              onClick={handleRuleClick}
              handleSendEventToGTM={handleSendEventToGTM}
            />
          </HeaderInputContainer>
        )}

        {!isLast && (length > 0 || hasSameParent.total === 2) && (
          <OperatorSelection
            onOperatorChange={(o) => {
              rows.forEach((r: Record<string, DecisionTableRow>, i: number) => {
                const op = getOperatorByRow(rows, nodes, currentKey, index, i);
                setNodes((prev) => ({
                  ...prev,
                  [!hasSameParent.isLast ? op.groupId : op.rowKey]: {
                    ...prev[!hasSameParent.isLast ? op.groupId : op.rowKey],
                    operator: o,
                  },
                }));
              });
            }}
            marginTop={hasSameParent.count > 0 ? '3.5rem' : '0rem'}
            operator={
              getOperatorByRow(rows, nodes, currentKey, index)[
                !hasSameParent.isLast ? 'operator' : 'rowOperator'
              ]
            }
            disabled={isRuleReadOnly}
            showMenu
          />
        )}
      </ColumnHeaderContainer>
    </PropertyHeader>
  );
}
