import { PadBox } from '@bedrock-layout/padbox';
import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { Control, UseFormSetValue } from 'react-hook-form';
import { CheckboxField, DropdownField, Expander, Toast, TooltipReact, Typography } from 'ui';

import { siteConstantsAtom } from '../../../../../atom';
import { getTooltipText } from '../../../../../utils/common';
import {
  isWorkflowReadOnlyAtom,
  workflowEnvironmentAtom,
  workflowStatusAtom,
} from '../../../atoms/atoms';
import { FormContainer } from './WorkflowSettingsSheet.styled';
import { WorkSpaceFormFieldContainer } from '../../../../Workspace/component/WorkspaceSettings/WorkspaceSettings.styled';
import { DateTimeSettingPopover } from '../../../../Workspace/component/WorkspaceSettings/DateTimeSettingPopover';
import { timezoneOptions } from '../../../../Workspace/component/WorkspaceSettings/constant';

type WorkflowSettingsSheetProps = {
  setOriginalValue?: UseFormSetValue<any>;
  parentControl?: Control<any>;
};

export function WorkflowSettingsSheet({
  setOriginalValue,
  parentControl,
}: WorkflowSettingsSheetProps) {
  const [workflowEnv] = useAtom(workflowEnvironmentAtom);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [siteConstant] = useAtom(siteConstantsAtom);
  const [workflowStatus] = useAtom(workflowStatusAtom);

  return (
    <FormContainer gutter="2rem">
      <Expander title="Settings">
        <>
          <PadBox padding="1rem">
            <Inline align="center" gutter={12}>
              <Typography>Enable Workflow</Typography>

              <TooltipReact id="enable-workflow" placement="bottom-start">
                <Typography>
                  {getTooltipText(siteConstant, 'workflow', 'enableWorkflow')}
                </Typography>
              </TooltipReact>

              <CheckboxField
                control={parentControl}
                name="isEnabled"
                useId="isEnabled"
                appearance="switch"
                disabled={isWorkflowReadOnly}
              />
            </Inline>
          </PadBox>

          <PadBox padding="1rem">
            <Inline align="center" gutter={12}>
              <Typography>Auditing</Typography>

              <TooltipReact id="enable-auditIO" placement="bottom-start">
                <Typography>
                  {getTooltipText(siteConstant, 'rules', 'auditing')}
                </Typography>
              </TooltipReact>

              <CheckboxField
                control={parentControl}
                name={`${workflowEnv}.auditIO`}
                useId={`${workflowEnv}.auditIO`}
                appearance="switch"
                disabled={isWorkflowReadOnly}
              />
            </Inline>
          </PadBox>
        </>
        {workflowStatus !== 'published' && (
          <Toast
            message="The changes in the settings will be live when workflow is published to production"
            type="warningBlue"
          />
        )}
      </Expander>
      <Expander
        title="Timezone"
      >
        <Stack>
        <WorkSpaceFormFieldContainer gutter={'1rem'}>
          <Inline align="center">
            <Typography fontWeight={700}>Dateformat</Typography>
          </Inline>
          <DateTimeSettingPopover control={parentControl} setValue={setOriginalValue} name={`${workflowEnv}.dateFormat`} />
        </WorkSpaceFormFieldContainer>

        <WorkSpaceFormFieldContainer gutter={'1rem'}>
          <Typography fontWeight={700}>Time Zone</Typography>
          <DropdownField
            control={parentControl}
            placeholder="Select Timezone"
            name={`${workflowEnv}.timezone`}
            isMulti={false}
            showCustomMenuV2
            options={timezoneOptions}
          />
        </WorkSpaceFormFieldContainer>

        </Stack>
      </Expander>
      {/* <Typography name="heading3">Settings</Typography> */}
    </FormContainer>
  );
}
