import { PadBox } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const PillStyled = styled(PadBox)<{ $hasError: boolean }>`
  border-radius: 0.4rem;
  cursor: pointer;
  background: var(--color-gainsboro);
  outline: 1px solid
    ${({ $hasError }) =>
      $hasError ? 'var(--color-fireEngineRed)' : 'var(--color-lavender)'};
`;

export const StyledContainer = styled(PadBox)`
  block-size: inherit;
`;
