import { Stack } from '@bedrock-layout/stack';
import { ChangeEvent } from 'react';

import { RuleNodeBuilder } from './RuleNodeBuilder';
import { OnClickRuleArgs } from './RulePopover';

type RuleNodesProps = {
  dataset: Record<string, any>;
  searchInput: string;
  searchedLeaves: string[];
  expandedNodes: Record<string, boolean>;
  parentNodeClickHandler: (id: string) => void;
  onClick: (
    value: string,
    key: string,
    dataType: string,
    executedValue?: any
  ) => void;
  allowList?: boolean;
  typesToAllow?: string[];
  onlyJson?: boolean;
  showTooltip?: boolean;
  version?: 'v1' | 'v2';
  showMultiSelect?: boolean;
  restrictNestedNodeSelection?: boolean;
  selectedCheckboxData?: Record<string, OnClickRuleArgs>;
  onCheckboxClick?: (
    e: ChangeEvent<HTMLInputElement>,
    value: string,
    key: string,
    dataType: string,
    executedValue?: any
  ) => void;
};

export function RuleNodes({
  dataset,
  searchInput,
  searchedLeaves,
  expandedNodes,
  parentNodeClickHandler,
  onClick,
  allowList = false,
  typesToAllow,
  onlyJson,
  showTooltip,
  version = 'v1',
  showMultiSelect = false,
  selectedCheckboxData,
  restrictNestedNodeSelection = false,
  onCheckboxClick,
}: RuleNodesProps) {
  return (
    <Stack gutter="3px">
      {Object.keys(dataset)
        .sort((a, b) => dataset[a]?.order - dataset[b]?.order)
        .map((key, index) => {
          const depth = 0;

          return (
            <RuleNodeBuilder
              key={index}
              nodeKey={key}
              parentNodeClickHandler={parentNodeClickHandler}
              depth={depth}
              nodeValue={dataset[key]}
              onClick={onClick}
              expandedNodes={expandedNodes}
              searchInput={searchInput}
              searchedLeaves={searchedLeaves}
              allowList={allowList}
              typesToAllow={typesToAllow}
              onlyJson={onlyJson}
              showTooltip={showTooltip}
              restrictNestedNodeSelection={restrictNestedNodeSelection}
              version={version}
              showMultiSelect={showMultiSelect}
              selectedCheckboxData={selectedCheckboxData}
              onCheckboxClick={onCheckboxClick}
            />
          );
        })}
    </Stack>
  );
}
