import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useFieldArray, useWatch } from 'react-hook-form';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { FiPlusCircle } from 'react-icons/fi';
import { Typography, toasts, useLayer } from 'ui';

import { isRuleReadOnlyAtom } from '../../..';
import {
  predefineTokenDatasetAtom,
  siteConstantsAtom,
} from '../../../../../atom';
import { AddButton } from '../../../../../components/AddButton';
import { ExcelModal } from '../../../../../components/ExcelLikeFormula/ExcelModal/ExcelModal';
import { onClickTokenSelectionArgs } from '../../../../../components/TokenComponents/PredefineTokenPopover/PredefineTokenPopover';
import { TokenMenu } from '../../../../../components/TokenComponents/TokenMenu/TokenMenu';
import { getTooltipText } from '../../../../../utils/common';
import { useOpenJsEditorSheet } from '../../../hooks/useOpenJsEditor';
import type { sendEventToGTMType } from '../../../types';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { DecisionTableResultRow } from '../types';
import { AdditionalData } from './AdditionalData';
import {
  AddDataContainer,
  AdditionalDataContainer,
} from './AdditionalOutputData.styled';
import { RowActionsContainer } from './RowsFieldArray.styled';

type AdditionalOutputDataProps = Omit<UseControllerProps, 'name'> & {
  handleSendEventToGTM: (obj: sendEventToGTMType) => void;
  setValue: UseFormSetValue<any>;
};

export function AdditionalOutputData({
  control,
  handleSendEventToGTM,
  setValue,
}: AdditionalOutputDataProps) {
  const { fields, append, remove } = useFieldArray({
    name: 'additionalData',
    control,
  });

  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [dataset] = useAtom(dataSetParamsAtom);
  const [predefineTokenDataset] = useAtom(predefineTokenDatasetAtom);

  const { openWithProps: openJsEditor } = useOpenJsEditorSheet({});
  const { openWithProps: openExcelEditor } = useLayer(<ExcelModal name="" />);

  const decisionTableResults: DecisionTableResultRow[] = useWatch({
    control,
    name: 'results',
  });

  const checkIsKeyNameUnique = (name?: string) => {
    let exists = false;
    fields.forEach((result: any) => {
      if (result.name === name) {
        exists = true;
      }
    });

    return !exists;
  };

  const handleAddData = (
    type: string = 'js-code',
    predefineTokenData?: onClickTokenSelectionArgs
  ) => {
    if (isRuleReadOnly) {
      return {
        isKeyNameUnique: true,
      };
    }

    handleSendEventToGTM({
      action: 'add',
      element: 'additional_data',
      actionName: '',
    });

    if (!_isNil(predefineTokenData)) {
      const isKeyNameUnique = checkIsKeyNameUnique(
        predefineTokenData.schemaName
      );

      if (!isKeyNameUnique) {
        toasts.infoBlue(
          'Cannot add attribute with same key name',
          'keyname-unique'
        );

        return {
          isKeyNameUnique,
        };
      }
    }

    const index = fields.length;

    append({
      name: predefineTokenData?.schemaName ?? 'untitled',
      schemaId: predefineTokenData?.schemaId,
      value: getTooltipText(
        siteConstants,
        'rules',
        'formulaInAdditionalData',
        'otherText'
      ),
      dataType: type,
      returnType: '',
      executedValue: '',
    });

    if (type === 'jsFormula') {
      openJsEditor({
        name: `additionalData.${index}.value`,
        control,
        section: 'thenDataParams',
        isAdditionalData: true,
        setOriginalValue: setValue,
        returnTypeName: `additionalData.${index}.returnType`,
        executedValueName: `additionalData.${index}.executedValue`,
        index: (decisionTableResults ?? []).length,
        additionalDataIndex: index,
      });
    } else if (type === 'excelFormula') {
      openExcelEditor({
        name: `additionalData.${index}.value`,
        control,
        section: 'thenDataParams',
        isAdditionalData: true,
        setOriginalValue: setValue,
        returnTypeName: `additionalData.${index}.returnType`,
        executedValueName: `additionalData.${index}.executedValue`,
        index: (decisionTableResults ?? []).length,
        additionalDataIndex: index,
        dataSet: dataset,
      });
    }

    return {
      isKeyNameUnique: true,
    };
  };

  const handlePredefineTokenSelection = (data: onClickTokenSelectionArgs) => {
    const { isKeyNameUnique } = handleAddData(data.dataType, data);

    return {
      canCloseModal: isKeyNameUnique,
    };
  };

  const launcher = (
    <span
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 8,
      }}
    >
      {isRuleReadOnly ? (
        <FiPlusCircle color="var(--color-darkGray)" />
      ) : (
        <AddButton />
      )}
      <Typography>Add additional data</Typography>
    </span>
  );

  return (
    <Inline gutter={0} align="end">
      <RowActionsContainer />
      <PadBox padding={[0, 8, 0, 8]}>
        <Stack gutter="0.8rem">
          {fields.length > 0 && (
            <AdditionalDataContainer gutter="2.4rem">
              {fields.map(({ id, dataType }: any, index) => {
                return (
                  <AdditionalData
                    handleDeleteData={remove}
                    control={control}
                    index={index}
                    // eslint-disable-next-line
                    key={`then_${index}_${id}`}
                    handleSendEventToGTM={handleSendEventToGTM}
                    setValue={setValue}
                    dataType={dataType}
                  />
                );
              })}
            </AdditionalDataContainer>
          )}
          <AddDataContainer
            $disabled={isRuleReadOnly}
            align="center"
            gutter={5}
          >
            <TokenMenu
              entity="rules"
              disabled={isRuleReadOnly}
              launcher={launcher}
              tokenDataset={predefineTokenDataset}
              handleAddCustomToken={handleAddData}
              handlePredefineTokenSelection={handlePredefineTokenSelection}
            />
          </AddDataContainer>
        </Stack>
      </PadBox>
    </Inline>
  );
}
