import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useWatch } from 'react-hook-form';
import type { Control, UseFieldArrayUpdate } from 'react-hook-form';
import { BiSolidError } from 'react-icons/bi';
import { CiSearch } from 'react-icons/ci';
import { FiPlusCircle, FiRefreshCcw } from 'react-icons/fi';
import { MdModeEdit } from 'react-icons/md';
import {
  Button,
  IconButton,
  Image,
  PopoverMethods,
  PopoverPanel,
  TextButton,
  TextInput,
  Toast,
  TooltipReact,
  Typography,
  toasts,
  useLayer,
} from 'ui';

import { siteConstantsAtom } from '../../../../../atom';
import { useSendEventToGTM } from '../../../../../hooks/useSendEventToGTM';
import { useGetDataSets } from '../../../../../pages/DataSets/hooks/useGetDataSets';
import {
  dataSetFieldsByIdAtom,
  ruleLimitsConfigAtom,
} from '../../../../../pages/Rules';
import { PopoverContainerStyled } from '../../../../../pages/Rules/components/SimpleRule/RulePopovers/RuleParamPopover.styled';
import { useGetDataSetById } from '../../../../../pages/Rules/hooks/graphql/useGetDataSetById';
import type {
  FieldsByID,
  SelectedDataSet,
} from '../../../../../pages/Rules/types';
import { getDataSetUsedInRuleById } from '../../../../../pages/Rules/utils/common';
import {
  RotateIconButtonProps,
  VersionMappingInfoType,
} from '../../../../../types';
import { getTooltipText, isValidImageURL } from '../../../../../utils/common';
import { ENTITY_ID } from '../../../../../utils/constant';
import { AddButton } from '../../../../AddButton';
import { CreateButton } from '../../../../CreateButton/CreateButton';
import { EntityVersionSelection } from '../../../../EntityVersionSelection/EntityVersionSelection';
import { roleJsonAtom } from '../../../../authentication/router/AuthProvider';
import { AddSourceList } from '../../AddSource/AddSource.styled';
import { DataSetModalV2 } from '../../AddSource/components/DataSetModalV2';
import {
  CustomAttributeTabCommonProps,
  FormHeader,
  customAttributeDefaultValues,
} from '../CustomAttributeSheet';
import {
  FormFooter,
  FormPanel,
  Table,
  TableData,
  TableHeader,
  TableRow,
} from '../CustomAttributeSheet.styled';
import { DatasetAttributePopover } from '../DatasetAttributePopover';
import { getFilteredAttributesBasedOnSelectedDataset } from '../common';
import type { FormValues } from '../validation';
import {
  CreateDataSourceContainer,
  DataSourceListItem,
  EditDatasetPill,
  ErrorIcon,
  SearchContainer,
} from './DataSourceAttribute.styled';
import { DataSourceLauncher } from './DataSourceLauncher';
import { RefreshStyled } from './DataSourceLauncher.styled';
import { SelectInputAttrPopover } from './SelectInputAttrPopover';

type DataSourceAttributesProps = CustomAttributeTabCommonProps & {
  update: UseFieldArrayUpdate<FormValues, 'attributes'>;
  control: Control<FormValues, any>;
  localSelectedDataSets: string[];
  setLocalDataSetFieldsById: (data: FieldsByID) => void;
  hasConnectorError?: boolean;
  localDependencyUsingMap: VersionMappingInfoType[];
  setLocalDependencyUsingMap: Dispatch<
    SetStateAction<VersionMappingInfoType[]>
  >;
  showDraftAssetErr?: boolean;
};

export function DataSourceAttributes({
  fields,
  append,
  remove,
  update,
  control,
  setValue,
  onSubmit,
  isReadOnly,
  localSelectedDataSets,
  localDataSetFieldsById,
  setLocalDataSetFieldsById,
  localDependencyUsingMap,
  setLocalDependencyUsingMap,
  hasConnectorError = false,
  showDraftAssetErr = false,
}: DataSourceAttributesProps) {
  const ref = useRef<PopoverMethods>(null);

  const [limitConfig] = useAtom(ruleLimitsConfigAtom);
  const [roleJson] = useAtom(roleJsonAtom);
  const [isRefresh, setIsRefresh] = useState(false);

  const [dataSetFieldById] = useAtom(dataSetFieldsByIdAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);

  const [dataSets, setDataSets] = useState<SelectedDataSet[]>([]);
  const [filteredDataSets, setFilteredDataSets] = useState<SelectedDataSet[]>(
    []
  );
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [getTableData] = useGetDataSets();
  const [getDataSetById] = useGetDataSetById();

  const attributesArray = useWatch({
    name: 'attributes',
    control,
  });

  const inputAttrArray = attributesArray.filter(
    (obj) => !['restAPI', 'dataSet'].includes(obj.sourceType ?? '')
  );

  const inputAttributesFields = useMemo(() => {
    const notUsedInputAttributes = inputAttrArray.filter((obj) => {
      const index = attributesArray.find((field) => field.name === obj.name);

      if (_isUndefined(index)) {
        return true;
      }

      return false;
    });

    let filteredInputAttributes = notUsedInputAttributes;

    if (
      !_isNil(localSelectedDataSets[0]) &&
      !_isNil(localDataSetFieldsById[localSelectedDataSets[0]])
    ) {
      filteredInputAttributes = getFilteredAttributesBasedOnSelectedDataset(
        inputAttrArray,
        localDataSetFieldsById[localSelectedDataSets[0]].type ?? ''
      );
    }

    return filteredInputAttributes;
  }, [
    JSON.stringify(attributesArray),
    JSON.stringify(inputAttrArray),
    localDataSetFieldsById,
  ]);

  useEffect(() => {
    void getDataSets();
  }, []);

  useEffect(() => {
    if (_isEmpty(searchInput)) {
      setFilteredDataSets(dataSets);
    } else {
      setFilteredDataSets(
        dataSets.filter((dataSet) =>
          dataSet.name.toLowerCase().includes(searchInput.toLowerCase())
        )
      );
    }
  }, [searchInput]);

  useEffect(() => {
    if (
      localSelectedDataSets.length > 0 &&
      !_isNil(localSelectedDataSets[0]) &&
      localSelectedDataSets[0] !== ''
    ) {
      void getSelectedDatasetData();
    } else {
      setLocalDependencyUsingMap([]);
    }
  }, [localSelectedDataSets]);

  useEffect(() => {
    if (
      localSelectedDataSets.length > 0 &&
      !_isNil(localSelectedDataSets[0]) &&
      localSelectedDataSets[0] !== ''
    ) {
      void getSelectedDatasetData();
    }
  }, [
    JSON.stringify(localDependencyUsingMap),
    JSON.stringify(localSelectedDataSets),
  ]);

  useEffect(() => {
    setLocalDataSetFieldsById(dataSetFieldById);
  }, [dataSetFieldById]);

  const getSelectedDatasetData = async () => {
    const key = localSelectedDataSets[0];
    const result = await getDataSetUsedInRuleById(
      key,
      getDataSetById,
      localDependencyUsingMap?.[0]?.version
    );

    if (!_isNil(result)) {
      setLocalDataSetFieldsById({
        [key]: {
          name: result[key].name,
          fields: result[key].fields,
          type: result[key].type,
          connector: result[key].connector,
        },
      });
    }
  };

  const getDataSets = async () => {
    try {
      setIsLoading(true);
      const { data } = await getTableData({
        variables: {
          page: 1,
          perPage: 300,
        },
      });
      const dataSetData = data?.getDataSet;
      if (!_isNil(dataSetData)) {
        const currentDataSetData = dataSetData.data.map((ruleData) => {
          return {
            id: ruleData.id,
            name: ruleData.name,
            icon: ruleData.connector.plugin.imageUrl,
            settings : ruleData.settings
          };
        });

        setDataSets(currentDataSetData);
        setFilteredDataSets(currentDataSetData);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const { open: openDataSet } = useLayer(
    <DataSetModalV2 getDataSets={getDataSets} />
  );

  const getSelectedDataSetName = useMemo(() => {
    if (
      !_isNil(localSelectedDataSets) &&
      localSelectedDataSets.length > 0 &&
      localSelectedDataSets[0] !== '' &&
      !_isNil(localSelectedDataSets[0]) &&
      dataSets.length > 0
    ) {
      const selectedDatasetObj = dataSets.find(
        (obj) => obj.id === localSelectedDataSets[0]
      );

      return selectedDatasetObj?.name ?? '';
    }

    return 'Select';
  }, [localSelectedDataSets, dataSets]);

  const formHeaders: FormHeader[] = [
    {
      label: 'Name',
    },
    {
      label: 'Primary Key',
    },

    {
      label: '',
    },
  ];

  const { sendEventToGTM } = useSendEventToGTM();

  const handleSendGTMEvent = (action: string) => {
    sendEventToGTM({
      event: 'rule',
      type: 'dataSourceAttributes',
      action,
      element: '',
      nec_source: '',
      action_name: '',
    });
  };

  const handleAddConfig = () => {
    handleSendGTMEvent('add');

    if (!_isEmpty(limitConfig) && fields.length < limitConfig.maxCi.value) {
      if (
        localSelectedDataSets.length > 0 &&
        localSelectedDataSets[0] !== '' &&
        !_isNil(localSelectedDataSets[0])
      ) {
        append({
          ...customAttributeDefaultValues,
          sourceType: 'dataSet',
        });
      } else {
        toasts.info('Please select DataSet', 'success');
      }
    } else if (
      !_isNil(limitConfig?.maxCi?.message) &&
      !_isEmpty(limitConfig?.maxCi?.message)
    ) {
      toasts.info(limitConfig.maxCi.message, 'success');
    }
  };

  const handleRemoveConfig = (index: number) => {
    handleSendGTMEvent('delete');

    const datasetAttrToRemove = fields[index];

    // if the dataset attribute is mapped to input attribute then update the attribute
    // otherwise remove the attribute from the dataset
    if (!_isEmpty(datasetAttrToRemove.name)) {
      update(index, {
        ...datasetAttrToRemove,
        sourceType: '',
        selectedType: {
          key: 'primitive',
          value: datasetAttrToRemove.dataType?.value,
          dataType: datasetAttrToRemove.dataType?.value ?? 'string',
        },
      });
    } else {
      remove(index);
    }
  };

  const handleInputAttrSelection = (
    inputAttrIndex: number,
    datasetAtrrIndex: number
  ) => {
    const seletedInputAtrr = inputAttributesFields[inputAttrIndex];

    const selectedInputAttrIndex = attributesArray.findIndex(
      (attr) => attr.name === seletedInputAtrr.name
    );

    const datasetAttr = fields[datasetAtrrIndex];

    if (!_isEmpty(datasetAttr.name)) {
      // In this case there is already input attribute mapped with dataset
      // so append the input attribute
      append({
        ...datasetAttr,
        sourceType: '',
        selectedType: {
          key: 'primitive',
          value: datasetAttr.dataType?.value,
          dataType: datasetAttr.dataType?.value ?? 'string',
        },
      });
    }

    setValue(`attributes.${datasetAtrrIndex}.name`, seletedInputAtrr.name);
    setValue(
      `attributes.${datasetAtrrIndex}.dataType`,
      seletedInputAtrr.dataType
    );
    setValue(`attributes.${datasetAtrrIndex}.selectedType`, {
      key: 'dataSet',
      value: localSelectedDataSets[0],
      dataType: 'dataSet',
    });
    setValue(
      `attributes.${datasetAtrrIndex}.schemaId`,
      seletedInputAtrr?.schemaId
    );

    remove(selectedInputAttrIndex);
  };

  const resetMappedFields = () => {
    attributesArray.forEach((attribute, index) => {
      if (attribute.sourceType === 'dataSet') {
        setValue(`attributes.${index}.attribute`, null);
      }
    });
  };

  const handleClearDataSet = () => {
    setValue('selectedDataSets', ['']);
    setLocalDataSetFieldsById({});
    resetMappedFields();
  };

  const handleCreateDataSource = (e: ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();

    sendEventToGTM({
      event: 'custom-attribute-sheet',
      action: 'create_dataset',
      element: 'data-source-attributes-tab',
    });

    openDataSet();
  };

  const RotateIconButton = ({
    isLoading,
    onClick,
    isDisabled = false,
  }: RotateIconButtonProps) => {
    return (
      <motion.div
        animate={{
          rotate: isLoading ? 360 : 0,
        }}
        transition={{
          duration: isLoading ? 2 : 0,
          repeat: isLoading ? Infinity : 0,
          ease: 'linear',
        }}
      >
        <IconButton disabled={isDisabled} onClick={onClick}>
          <FiRefreshCcw />
        </IconButton>
      </motion.div>
    );
  };

  const handleOpenDataset = () => {
    const id = localSelectedDataSets[0];
    const editorType = localDataSetFieldsById[id].type;

    const version = localDependencyUsingMap?.[0]?.version;

    let link = '';

    if (!_isNil(editorType)) {
      link = `/datasets/${id}?stage=staging&editor=${editorType}&wsid=${
        sessionStorage.getItem('workspaceUUID') as string
      }`;
    }

    if (!_isNil(version) && !_isEmpty(version) && version !== 'draft') {
      link += `&type=view&isLive=true`;

      if (version !== 'live') {
        link += `&version=${version}`;
      }
    } else {
      link += `&type=edit`;
    }

    if (!_isEmpty(link)) {
      window.open(link, window !== window.parent ? '_self' : '_blank');
    }
  };

  const handleDataUpdate = (data: Record<string, any>) => {
    if ('selectedVersion' in data) {
      const versionInfo = data.selectedVersion;

      setLocalDependencyUsingMap([
        {
          entityId: versionInfo.id as string,
          type: 'dataSet',
          version: versionInfo.version as string,
          nodeId: `dataSet`,
        },
      ]);
    }
  };

  const dataSetPermissions = roleJson.internals?.datasets?.permissions;

  const isCreateRoleJson =
    !_isNil(dataSetPermissions) &&
    (dataSetPermissions.create as unknown as boolean);

  return (
    <Stack as={FormPanel}>
      <Stack as={FormPanel} gutter={'2.4rem'} padding={['1.2rem', '2.4rem']}>
        <Inline align="end" gutter="1rem">
          <PopoverPanel
            ref={ref}
            trigger="click"
            padding={0}
            placement="bottom-start"
            disabled={isReadOnly}
            launcher={
              <Inline>
                <DataSourceLauncher
                  text={getSelectedDataSetName}
                  handleClearDataSet={handleClearDataSet}
                  handleRefresh={async () => await getDataSets()}
                />
              </Inline>
            }
          >
            <PopoverContainerStyled>
              <SearchContainer>
                <TextInput
                  type="text"
                  placeholder="Search Datasets"
                  icon={<CiSearch size={18} color="var(--color-suvaGray)" />}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setSearchInput(e.target.value);
                  }}
                  value={searchInput}
                  disabled={isReadOnly}
                />
              </SearchContainer>
              <AddSourceList>
                {filteredDataSets.map(({ id, name, icon }, index) => {
                  return (
                    <PadBox
                      padding={['0.4rem', '1.6rem']}
                      key={`dataSet_${index}`}
                    >
                      <DataSourceListItem
                        gutter={16}
                        align="center"
                        onClick={() => {
                          setValue('selectedDataSets', [id]);
                          resetMappedFields();
                          ref.current?.hide();
                        }}
                      >
                        <Image src={isValidImageURL(icon)} alt={name} round />

                        <Typography name="heading3" element="p">
                          {name}
                        </Typography>
                      </DataSourceListItem>
                    </PadBox>
                  );
                })}

                {filteredDataSets.length === 0 && (
                  <Inline
                    as={PadBox}
                    justify="center"
                    align="center"
                    padding={'1rem'}
                  >
                    <Typography>No Dataset</Typography>
                  </Inline>
                )}
              </AddSourceList>
              {!!isCreateRoleJson && (
                <CreateDataSourceContainer>
                  <Inline justify="center" align="center" gutter={8}>
                    <CreateButton
                      buttonLabel="Create Data Source"
                      onClick={handleCreateDataSource}
                    />
                  </Inline>
                </CreateDataSourceContainer>
              )}
            </PopoverContainerStyled>
          </PopoverPanel>

          {!_isNil(localSelectedDataSets) &&
            !_isEmpty(localSelectedDataSets) &&
            !_isEmpty(localSelectedDataSets[0]) && (
              <EditDatasetPill
                onClick={handleOpenDataset}
                align="center"
                justify="center"
              >
                <MdModeEdit />
                <Typography>Edit</Typography>
                {hasConnectorError && (
                  <ErrorIcon>
                    <TooltipReact
                      id={`connector_error_dataset}`}
                      launcher={
                        <BiSolidError color="var(--color-fireEngineRed)" />
                      }
                    >
                      <Typography>
                        {getTooltipText(
                          siteConstants,
                          'integrations',
                          'integrationNotConnected'
                        )}
                      </Typography>
                    </TooltipReact>
                  </ErrorIcon>
                )}
              </EditDatasetPill>
            )}

          {!_isNil(localSelectedDataSets) &&
            !_isEmpty(localSelectedDataSets) &&
            !_isEmpty(localSelectedDataSets[0]) && (
              <EntityVersionSelection
                entityInfo={{
                  type: ENTITY_ID.datasets,
                  id: localSelectedDataSets[0] ?? '',
                }}
                launcherSize="medium"
                isReadOnly={isReadOnly}
                updateDataOnParent={handleDataUpdate}
                selectedVersion={localDependencyUsingMap?.[0]?.version}
                showError={showDraftAssetErr}
                isRefresh={isRefresh}
                setIsRefresh={setIsRefresh}
              />
            )}

          <RefreshStyled>
            <RotateIconButton
              isLoading={isLoading}
              onClick={async () => {
                if (!isReadOnly) {
                  await getDataSets();
                  await getSelectedDatasetData();

                  setIsRefresh(true);
                }
              }}
            />
          </RefreshStyled>

          {hasConnectorError && (
            <Typography fontWeight={700} name="error">
              {getTooltipText(
                siteConstants,
                'integrations',
                'integrationNotConnected'
              )}{' '}
            </Typography>
          )}
        </Inline>
        <Stack gutter={15}>
          <Inline>
            <Toast
              type="infoBlue"
              message="Map your Primary Key from the dataset selected to your Input attributes. We don’t recommend adding more than 2 Primary keys"
            />
          </Inline>
        </Stack>
        <Stack gutter="1rem">
          <Table>
            <TableRow>
              {formHeaders.map((header) => (
                <TableHeader key={header.label}>
                  <PadBox padding="1.2rem">
                    <Inline align="center" gutter={8}>
                      <Typography>{header.label}</Typography>

                      {!_isNil(header.tooltipContent) && (
                        <TooltipReact id={header.label} placement="top-start">
                          {header.tooltipContent}
                        </TooltipReact>
                      )}
                    </Inline>
                  </PadBox>
                </TableHeader>
              ))}
            </TableRow>

            {fields.map((field, index) => {
              if (field.sourceType !== 'dataSet') {
                return null;
              }

              return (
                <TableRow key={field.id}>
                  <TableData>
                    <PadBox padding={'1.2rem'}>
                      <SelectInputAttrPopover
                        control={control}
                        name={`attributes.${index}.name`}
                        inputAttributesFields={inputAttributesFields}
                        showErrorIcon={false}
                        disabled={isReadOnly}
                        handleInputAttrSelection={(inputAttrIndex: number) =>
                          handleInputAttrSelection(inputAttrIndex, index)
                        }
                      />
                    </PadBox>
                  </TableData>

                  <TableData>
                    <PadBox padding={'1.2rem'}>
                      <DatasetAttributePopover
                        control={control}
                        setValue={setValue}
                        index={index}
                        isDisabled={isReadOnly}
                        isFilterBasedOnDataType={true}
                        selectedDataSets={localSelectedDataSets}
                        dataSetFieldsById={localDataSetFieldsById}
                      />
                    </PadBox>
                  </TableData>

                  <TableData>
                    <PadBox padding="2.3rem">
                      <TextButton
                        onClick={() => handleRemoveConfig(index)}
                        disabled={isReadOnly}
                      >
                        Delete
                      </TextButton>
                    </PadBox>
                  </TableData>
                </TableRow>
              );
            })}
          </Table>

          <Inline align="center">
            {isReadOnly ? (
              <FiPlusCircle color="var(--color-darkGray)" />
            ) : (
              <AddButton />
            )}

            <TextButton disabled={isReadOnly} onClick={handleAddConfig}>
              Add Field
            </TextButton>
          </Inline>
        </Stack>
      </Stack>
      {!isReadOnly && (
        <Inline as={FormFooter} padding="0.8rem" justify="end">
          <Button appearance="filled" onClick={() => onSubmit('nextTab')}>
            Save and Next
          </Button>
          <Button appearance="contained" onClick={() => onSubmit('closeSheet')}>
            Save and Close
          </Button>
        </Inline>
      )}
    </Stack>
  );
}
