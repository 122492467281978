import { ApolloError } from '@apollo/client';
import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Typography, toasts, useLayer } from 'ui';

import { siteConstantsAtom } from '../../atom';
import { ListingComponent } from '../../components/Listing';
import { PermissionType } from '../../components/PermissionComponent/constant';
import { tabList } from '../../components/VersionControl/constant';
import { PagesBodyContainer } from '../../components/layouts/PagesBodyContainer';
import { useGetSiteMeta } from '../../hooks/useGetSiteMeta';
import type { PanelType } from '../../types';
import {
  handleGetCheckSumByEntityName,
  handleGetConstantData,
  handleSetCheckSumByEntityName,
  setDateAndTZinContext,
} from '../../utils/common';
import { ENTITY_MAP } from '../../utils/constant';
import { useGetDataSetById } from '../Rules/hooks/graphql/useGetDataSetById';
import { activePanelDatasetAtom, vcListTabIndexDatasetAtom } from './atom/atom';
import { CreateDataSetMenu } from './components/CreateDataSetMenu';
import { DataSetSheet } from './components/DataSetSheet';
import { DeleteDataSetModal } from './components/DeleteDataSetModal';
import { useDeleteDataSet } from './hooks/useDeleteDataSet';
import type { GetDataSetsQueryResult } from './hooks/useGetDataSets';
import { useGetDataSets } from './hooks/useGetDataSets';
import { useGetPublishedConnectors } from './hooks/useGetPublishedConnectors';

type DataSetsProp = {
  subModules: Record<string, boolean>;
  permissions: Record<PermissionType, boolean>;
};

export function DataSets({ permissions, subModules }: DataSetsProp) {
  const navigate = useNavigate();
  const { siteMeta } = useGetSiteMeta();

  const [getTableData, { data, refetch }] = useGetDataSets();
  const { data: publishedConnectorsList } = useGetPublishedConnectors(true);
  const [deleteDataSet] = useDeleteDataSet();

  const [getDataSetById] = useGetDataSetById();

  const [searchParams] = useSearchParams();
  const { datasetId } = useParams();

  const type = searchParams.get('type') ?? '';

  const isLiveParams = searchParams.get('isLive') === 'true';
  const commitIdParams = searchParams.get('commitId');
  const activePanelParams = searchParams.get('currentTab');
  const vcActiveTabParams = searchParams.get('activeVcTab');

  const [siteConstant, setSiteConstant] = useAtom(siteConstantsAtom);
  const [, setActivePanel] = useAtom(activePanelDatasetAtom);
  const [, setVcListTabIndex] = useAtom(vcListTabIndexDatasetAtom);

  const entity = ENTITY_MAP[window.location.pathname.split('/')[1]];

  const [listData, setListData] = useState<
    GetDataSetsQueryResult | undefined
  >();

  const { openWithProps: openDataSheet, closeAllLayers } = useLayer(
    <DataSetSheet refetch={refetch} from={type} />
  );

  const { openWithProps: openDeleteConfirmationModal } = useLayer(
    <DeleteDataSetModal />
  );

  useEffect(() => {
    void handleGetConstantData(
      'integrations,datasets',
      setSiteConstant,
      siteConstant
    );
  }, []);

  useEffect(() => {
    if (!_isNil(activePanelParams) && !_isEmpty(activePanelParams)) {
      setActivePanel(activePanelParams as PanelType);
    }
  }, [activePanelParams]);

  useEffect(() => {
    if (!_isNil(vcActiveTabParams) && !_isEmpty(vcActiveTabParams)) {
      const index = tabList.findIndex(
        (tabObj) => tabObj.value === vcActiveTabParams
      );
      setVcListTabIndex(index !== -1 ? index : 0);
    }
  }, [vcActiveTabParams]);

  useEffect(() => {
    if (!_isNil(data)) setListData(data);
  }, [JSON.stringify(data)]);

  useEffect(() => {
    const getConnecterID = searchParams.get('connectorId') ?? '';

    if (
      type === 'create' &&
      !_isNil(publishedConnectorsList) &&
      !_isUndefined(listData)
    ) {
      const connector = publishedConnectorsList?.getConnector.data.filter(
        (i: any) => i.id === getConnecterID
      )[0];

      if (!_isNil(connector)) {
        openDataSheet({
          connector,
        });
      }
    } else if (!['edit', 'view'].includes(type)) {
      closeAllLayers();
    }
  }, [JSON.stringify(publishedConnectorsList), JSON.stringify(listData), type]);

  useEffect(() => {
    if (
      ['edit', 'view'].includes(type) &&
      !_isEmpty(datasetId) &&
      !_isNil(datasetId)
    ) {
      void handleGetDataSetById();
    }
  }, [type, datasetId]);

  const handleDeleteDataSet = async (id: string) => {
    const checksum = handleGetCheckSumByEntityName('datasets');

    try {
      await deleteDataSet({
        variables: {
          id,
          checksum: checksum ?? '',
        },
      });

      const response = await refetch();
      setListData(response.data);

      toasts.success('Data Source deleted successfully', 'deleted-success');
    } catch (error) {
      if (error instanceof ApolloError) {
        if (error?.graphQLErrors[0]?.extensions?.code === 'server_error') {
          toasts.error('Failed to delete rule', 'error');
        } else {
          toasts.error(error.message, 'error');
        }
      }
    }
  };

  const handleGetDataSetById = async () => {
    try {
      const { data } = await getDataSetById({
        variables: {
          dataSetId: datasetId,
        },
        fetchPolicy: 'no-cache',
      });

      const dataSetObject = data.getDataSet.data[0];

      if (!_isNil(dataSetObject)) {
        openDataSheet({
          connector: dataSetObject.connector,
          id: dataSetObject.id,
          version: dataSetObject.version,
          isLive: isLiveParams,
          commitId: commitIdParams,
        });
      }
    } catch (error) {}
  };

  const handleRowClick = (data: any) => {
    if(!_isNil(data.settings)){
      setDateAndTZinContext({
        timezone:
          typeof data.settings?.timezone === 'object'
            ? data.settings?.timezone.value
            : data.settings?.timezone,
        dateFormat: data.settings?.dateFormat,
      });
    }
    const state = 'staging';
    handleSetCheckSumByEntityName('datasets', data.checksum);

    const editorObject = data.connector?.plugin;

    if (!_isNil(window.location.search) && !_isEmpty(window.location.search)) {
      window.sessionStorage.setItem(
        'dataSetRedirection',
        window.location.search
      );
    }

    navigate(
      `/datasets/${data.id as string}?type=edit&stage=${state}&editor=${
        editorObject?.name as string
      }`
    );
  };

  const handleDeleteActionClick = async (data: any) => {
    handleSetCheckSumByEntityName('datasets', data.checksum);

    await handleDeleteDataSet(data.id);
  };

  const handleActionColumnClick = useCallback((actionData: any) => {
    const { type, data } = actionData;

    if (type === 'delete') {
      openDeleteConfirmationModal({
        onDeleteClick: async () => await handleDeleteActionClick(data),
      });
    }
  }, []);

  const HeaderComponent = useCallback(() => {
    return (
      <Inline gutter={25}>
        <Typography name="heading1" fontWeight={700}>
          Data Sources
        </Typography>
        <CreateDataSetMenu refetch={refetch} />
      </Inline>
    );
  }, []);

  return (
    <PagesBodyContainer>
      <Helmet>
        <title>
          {siteMeta[window.location.pathname.substring(1)]?.title ?? ''}
        </title>
        <meta
          name="description"
          content={
            siteMeta[window.location.pathname.substring(1)]?.description ?? ''
          }
        />
      </Helmet>
      <ListingComponent
        entity={entity}
        headerTitle="Data Sources"
        callOnRowClick={handleRowClick}
        callOnCellClick={() => {}}
        errorTitle="No Data Source found"
        HeaderComponent={HeaderComponent}
        isGraphQL={true}
        getListDataQuery={getTableData}
        queryListData={listData}
        handleActionItemClick={handleActionColumnClick}
        permissions={permissions}
        subModules={subModules}
      />
    </PagesBodyContainer>
  );
}
