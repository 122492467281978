import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const Container = styled(Inline)<{
  position: string;
  disabled: boolean;
}>`
  ${({ position }) => `position: ${position};`}
  ${({ position }) => position === 'absolute' && `z-index: 1; top: -3rem;`}
  background: var(--color-solitude);
  padding: 0.5rem;
  border-radius: 0.5rem;

  ${({ disabled = false }) => !disabled && 'cursor: pointer'};

  .active-text {
    background-color: var(--color-primary1);
    color: var(--color-white);
    padding: 0rem 0.5rem;
    border-radius: 0.25rem;
  }
`;
