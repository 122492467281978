import { Inline, Stack } from '@bedrock-layout/primitives';
import dateFormat from 'dateformat';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { ReactElement, useMemo } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { BiEdit } from 'react-icons/bi';
import {
  IconButton,
  PublishedStatusLabel,
  RuleTypeLabel,
  TooltipReact,
  Typography,
  customParseDate,
  formatNectedDate,
  useLayer,
} from 'ui';
import { PublishedStatusType } from 'ui/src/WorkflowNodeSheetHeader/PublishedStatusLabel';
import type { RuleType } from 'ui/src/WorkflowNodeSheetHeader/RuleTypeLabel';

import type { VersionInfoType } from '../../types';
import {
  EntityInfoStyled,
  EntityNameContainer,
  NameStyled,
  StyledVersionText,
} from './EntityHeader.styled';
import {
  EntityInfoModalFormData,
  EntityInfoUpdateModal,
} from './EntityInfoUpdateModal';
import { DatasetTypePill } from './components/DatasetTypePill/DatasetTypepill';

type EntityHeaderProps = {
  entityName: string;
  nameFieldKey: string;
  descriptionFieldKey: string;
  status: PublishedStatusType;
  isReadOnly: boolean;
  type?: string;
  iconUrl?: string;
  createdAtFieldKey?: string;
  publishedAtFieldKey?: string;
  versionInfo?: VersionInfoType;
  control?: Control<any>;
  setValue?: UseFormSetValue<any>;
  openPublishedVersions?: () => void;
  loaderComponent?: ReactElement;
};

export function EntityHeader({
  entityName,
  nameFieldKey,
  descriptionFieldKey,
  status,
  isReadOnly,
  type,
  iconUrl,
  versionInfo,
  createdAtFieldKey,
  publishedAtFieldKey,
  control,
  setValue,
  openPublishedVersions,
  loaderComponent,
}: EntityHeaderProps) {
  const name = useWatch({
    name: nameFieldKey,
    control,
  });

  const description = useWatch({
    name: descriptionFieldKey,
    control,
  });

  const createdAt = !_isNil(createdAtFieldKey)
    ? useWatch({
        name: createdAtFieldKey,
        control,
      })
    : null;

  const publishedAt = !_isNil(publishedAtFieldKey)
    ? useWatch({
        name: publishedAtFieldKey,
        control,
      })
    : null;

  const { openWithProps } = useLayer(
    <EntityInfoUpdateModal
      entityName={entityName}
      name={name}
      description={description}
    />
  );

  const onSave = (data: EntityInfoModalFormData) => {
    if (!_isNil(setValue)) {
      setValue(nameFieldKey, data.name);
      setValue(descriptionFieldKey, data.description);
    }
  };

  const EntityTypePill = useMemo(() => {
    switch (entityName) {
      case 'rule':
      case 'Rule':
        if (_isNil(type)) {
          return null;
        }

        return <RuleTypeLabel ruleType={type as keyof typeof RuleType} />;
      case 'dataset':
      case 'Data Source':
        if (_isNil(type)) {
          return null;
        }

        return <DatasetTypePill type={type} iconUrl={iconUrl} />;

      default:
        return null;
    }
  }, [type, entityName]);

  const showLastVersion =
    !_isNil(versionInfo?.lastVersion) &&
    !_isEmpty(versionInfo?.lastVersion) &&
    versionInfo?.lastVersion !== 'draft' &&
    !['published', 'archived'].includes(status);

  const showLiveVersion =
    !_isNil(versionInfo?.currentLiveVersion) &&
    !_isEmpty(versionInfo?.currentLiveVersion) &&
    !_isNil(publishedAt);

  return (
    <Stack gutter="1rem">
      <Inline align="center">
        <EntityInfoStyled
          onClick={() => {
            if (isReadOnly) {
              return;
            }

            openWithProps({ name, description, onSave });
          }}
        >
          <EntityNameContainer justify="start" align="center" gutter="0.5rem">
            <IconButton disabled={isReadOnly}>
              <BiEdit size={20} color="var(--color-darkGray)" />
            </IconButton>

            <TooltipReact
              id="action-rest-base-url"
              placement="bottom-start"
              customStyles={{
                wordBreak: 'break-word',
              }}
              launcher={
                <NameStyled name="heading3" fontWeight={700}>
                  {name}
                </NameStyled>
              }
            >
              <Stack gutter={'1rem'}>
                <Typography>{name}</Typography>
                <Typography>{description}</Typography>
              </Stack>
            </TooltipReact>
          </EntityNameContainer>
          {EntityTypePill}
        </EntityInfoStyled>
        <PublishedStatusLabel
          publishedStatus={status}
          currentVersion={versionInfo?.currentVersion}
        />
        {showLastVersion && (
          <Inline gutter="0.5rem">
            <Typography>Forked From</Typography>
            <div onClick={openPublishedVersions}>
              <StyledVersionText>{versionInfo?.lastVersion}</StyledVersionText>
            </div>
          </Inline>
        )}
        {loaderComponent}
      </Inline>
      <Inline>
        {showLiveVersion && (
          <Inline gutter="0.5rem">
            <div onClick={openPublishedVersions}>
              <StyledVersionText fontWeight={700}>
                {versionInfo?.currentLiveVersion}
              </StyledVersionText>
            </div>

            <Typography>
              {`is currently live since ${
                dateFormat(
                  new Date(
                    formatNectedDate(customParseDate(publishedAt), 'dateTime')
                  ),
                  'ddd, mmmm d, yyyy'
                ) ?? ''
              }`}
            </Typography>
            <Typography name="secondary2">|</Typography>
          </Inline>
        )}
        {!_isNil(createdAt) && (
          <Typography>
            Created on{' '}
            {dateFormat(
              new Date(
                formatNectedDate(customParseDate(createdAt), 'dateTime')
              ),
              'ddd, mmmm d, yyyy'
            )}
          </Typography>
        )}
      </Inline>
    </Stack>
  );
}
