const indianDateFormat = {
  date: 'yyyy-mm-dd',
  dateTime: "yyyy-mm-dd'T'HH:MM:ssp",
};

const usDateFormat = {
  date: 'mm-dd-yyyy',
  dateTime: "mm-dd-yyyy'T'HH:MM:ssp",
};

const DateFormatMap: Record<string, any> = {
  in: indianDateFormat,
  us: indianDateFormat,
};

export { indianDateFormat, usDateFormat, DateFormatMap };
