import { Stack } from '@bedrock-layout/stack';
import _isNil from 'lodash/isNil';
import { useRef } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { FaChevronDown } from 'react-icons/fa6';
import { IoCheckbox } from 'react-icons/io5';
import {
  PopoverMethods,
  PopoverPanel,
  RadioInput,
  Typography,
} from 'ui';

import {
  DateTimeLauncherStyled,
  DateTimePopoverStyledContainer,
  SupportedFormatItem,
} from './WorkspaceSettings.styled';
import { supportedDateTimeOptions, workspaceSettingsObject } from './constant';
import { _isEmpty } from '../../../Rules/worker/DTTableTransform';
const { DATE_FORMAT } = workspaceSettingsObject;
type DateTimeSettingpopover = {
  control?: Control<any>;
  setValue: any;
  name? : string;
  disabled? : boolean;
};

export const DateTimeSettingPopover = ({
  control,
  setValue,
  name = DATE_FORMAT,
  disabled
}: DateTimeSettingpopover) => {
  

  const ref = useRef<PopoverMethods>(null);

  const value: string = useWatch({
    name,
    control,
  });


  const label = !_isNil(value) && !_isEmpty(value)
  ? supportedDateTimeOptions[value as keyof typeof supportedDateTimeOptions].label
  : supportedDateTimeOptions.in.label;

  const selectedValue  = !_isNil(value) && !_isEmpty(value) ? value  : 'in'

  const launcher = (
    <DateTimeLauncherStyled>
      <Typography>{label}</Typography>
      <FaChevronDown />
    </DateTimeLauncherStyled>
  );

  const handleOptionClick = (optionId: string) => {
    if (typeof setValue === 'function' && optionId !== "iso") {
      setValue(name, optionId);
    }
    ref?.current?.hide();
  };

  const getFormatComponent = (formatObj: {
    label: string;
    id: string;
    options: string[];
  }) => {
    const { id, label, options } = formatObj;

    let checked = false;

    if (id === selectedValue) {
      checked = true;
    }

    return (
      <SupportedFormatItem onClick={() => handleOptionClick(id)}>
        {id === 'iso' ? (
          <IoCheckbox color="var(--color-darkGray)" size={20} />
        ) : (
          <RadioInput
            displayRadio={true}
            checked={checked}
            id={id}
            value={id}
          />
        )}
        <Stack gutter="0.5rem">
          <Typography name="heading3" fontWeight={700}>
            {label}
          </Typography>
          <div className="format-list">
            {options.map((currFormat, index) => (
              <Typography key={index}>{currFormat}</Typography>
            ))}
          </div>
        </Stack>
      </SupportedFormatItem>
    );
  };

  return (
    <PopoverPanel
      trigger="click"
      placement="bottom-start"
      launcher={launcher}
      padding={'1rem'}
      ref={ref}
      disabled={disabled}
    >
      <DateTimePopoverStyledContainer>
        <div>
          {getFormatComponent(supportedDateTimeOptions.us)}
          {getFormatComponent(supportedDateTimeOptions.in)}
          {getFormatComponent(supportedDateTimeOptions.commonFormat)}
        </div>
      </DateTimePopoverStyledContainer>
    </PopoverPanel>
  );
};