import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const DataContainer = styled(Inline)`
  padding: 0 0.4rem 0rem 0rem;
  align-items: center;
`;

export const StyledContainer = styled(Inline)`
  border-right: 1px solid var(--color-lightGray8);
  padding-right: 1rem;

  &:last-child {
    border-right: none;
    padding-right: 0rem;
  }
`;
