import { Stack } from '@bedrock-layout/stack';
import AceEditor from 'react-ace';
import { JsonHeroTreeViewer } from 'ui';

import { copyToClipboard } from '../../../utils/common';
import { JsonInTableView } from '../../JsonInTableView/JsonInTableView';
import { ExecutionView } from './ExecutionView';
import {
  JsonContainer,
  JsonOutputContainer,
  JsonTreeContainer,
} from './RuleExecutionOutput.styled';

type RuleExecutionOutputProps = {
  executionTime: string;
  responseJson: string;
  isTesting: boolean;
  forceStatus?: boolean;
  tableOutput?: any;
};

export const RuleExecutionOutput = ({
  executionTime,
  responseJson = '',
  isTesting,
  forceStatus,
  tableOutput,
}: RuleExecutionOutputProps) => {
  let val: any = responseJson;

  try {
    val = JSON.parse(val);
  } catch {}

  return (
    <JsonOutputContainer padding="1.6rem">
      <Stack gutter={8}>
        <ExecutionView
          onCopy={() => {
            void copyToClipboard(responseJson, 'Key copied');
          }}
        >
          {!isTesting && (
            <JsonContainer>
              <AceEditor
                className="json-result-readonly"
                mode="json"
                theme="chrome"
                width="100%"
                fontSize={12}
                showPrintMargin={false}
                highlightActiveLine={true}
                showGutter={true}
                setOptions={{
                  useWorker: false,
                  showLineNumbers: true,
                  tabSize: 2,
                }}
                readOnly
                value={responseJson}
              />
            </JsonContainer>
          )}

          {!isTesting && (
            <JsonTreeContainer>
              <JsonHeroTreeViewer json={val} />
            </JsonTreeContainer>
          )}

          {!isTesting && (
            <JsonTreeContainer>
              <JsonInTableView json={{ output: tableOutput ?? null }} />
            </JsonTreeContainer>
          )}
        </ExecutionView>
      </Stack>
    </JsonOutputContainer>
  );
};
