import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _startCase from 'lodash/startCase';
import { SetStateAction, useEffect, useState, useTransition } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toasts } from 'ui';

import {
  predefineTokenDatasetAtom,
  siteConstantsAtom,
  usedConnectorMappingAtom,
} from '../../../atom';
import { permissionObj } from '../../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../../components/PermissionComponent/hooks/useCheckPermissions';
import { UsedConnectorMappingInEntityType } from '../../../types';
import {
  convertModuleDataIntoDataset,
  getRuleEditOutputDataValue,
  getTooltipText,
  handleSetCheckSumByEntityName,
  unionStringArrays,
} from '../../../utils/common';
import { ENTITY_ID } from '../../../utils/constant';
import { useGetAllModuleAndSchema } from '../../DataSets/hooks/graphql/useGetAllModuleAndSchema';
import {
  firstCustomAttributeAtom,
  isRulePublishableAtom,
  isRulePublishedAtom,
} from '../components/CreateRuleSheet/CreateRuleSheet';
import {
  decisionTableInitialNodes,
  exampleDecisionTableResponse,
} from '../components/DecisionTable/fixtures/sample';
import type {
  AdditionalActionFormData,
  DataOutputModel,
  DecisionTableModel,
  DecisionTableNodesModel,
  DecisionTableResponse,
  PropertiesNodeStructure,
} from '../components/DecisionTable/models';
import {
  DecisionTableResultRow,
  DecisionTableRow,
} from '../components/DecisionTable/types';
import type { RulePolicyDropdownModel } from '../components/RuleSet/models';
import type {
  ProductionConfigModel,
  StagingConfigModel,
} from '../components/SimpleRule/models';
import {
  approvalInfoRuleAtom,
  datasetDetailsInRuleAtom,
  isRuleLiveAtom,
  versionInfoRuleAtom,
  versionMappingInfoAtom,
} from '../components/atom/atom';
import {
  dataSetFieldsByIdAtom,
  isRuleReadOnlyAtom,
  ruleWarningsAtom,
} from '../index';
import type { AttributeModel } from '../models';
import type { ResultAction } from '../types';
import {
  formatTriggersDataFromResponse,
  getDataSetUsedInRuleById,
  transformActionNode,
  transformPayloadCustomInputToTableData,
} from '../utils/common';
import { useTableTransformWorker } from '../worker/useWorkerTableTransform';
import { useDiscardDecisionTable } from './graphql/useDiscardDecisionTable';
import { useGetDataSetById } from './graphql/useGetDataSetById';
import { useGetDecisionTableById } from './graphql/useGetDecisionTableByID';
import { useCIWarning } from './useCIWarning';

type UseEditDecisionTableArguments = {
  isClone: boolean;
  isLive: boolean;
  ruleId?: string;
  ruleIdExist?: boolean;
  isLiveNodeSheetClosed?: boolean;
  commitId?: string;
};

function getAuthType(editRuleData: DecisionTableResponse) {
  return editRuleData.settings.api.isPrivate
    ? { label: 'Private', value: 'private' }
    : { label: 'None', value: 'none' };
}

export function useEditDecisionTable({
  ruleId,
  isClone,
  isLive,
  ruleIdExist = false,
  isLiveNodeSheetClosed = false,
  commitId,
}: UseEditDecisionTableArguments) {
  const [searchParams] = useSearchParams();
  const { workerApi } = useTableTransformWorker();
  const [, startTransition] = useTransition();
  const version = searchParams.get('version') ?? '';

  const [getRuleById, { loading: ruleLoading, data: editRuleData }] =
    useGetDecisionTableById();
  const [loadingData, setLoadingData] = useState(true);
  const [dataClone, setDataClone] = useState(false);

  const [discardRuleById, { data: discardRule, error: discardError }] =
    useDiscardDecisionTable();
  const [getDataSetById] = useGetDataSetById();
  const { handleSetWarning } = useCIWarning();

  const [getAllModuleAndSchema] = useGetAllModuleAndSchema();

  const [, setFirstRuleChain] = useState('');
  const [stagingConfig] = useState<StagingConfigModel>();
  const [productionConfig, setProductionConfig] =
    useState<ProductionConfigModel>();
  const [customDataParams, setCustomDataParams] = useState<
    Record<string, AttributeModel>
  >({});
  const [selectedDataSets, setSelectedDataSet] = useState<string[]>([]);

  const [isMounted, setIsMounted] = useState(false);
  const [properties, setProperties] = useState<PropertiesNodeStructure[]>([]);
  const [outputData, setOutputData] = useState<Record<string, any>>({});
  const [result, setResult] = useState<DecisionTableResultRow[]>([]);
  const [decisionTableName, setDecisionTableName] = useState('Untitled');
  const [ruleVersion, setRuleVersion] = useState('draft');
  const [decesionTableDescription, setDecesionTableDescription] = useState('');
  const [isDemo, setIsDemo] = useState(false);
  const [decisionTablePolicy, setDecisionTablePolicy] =
    useState<RulePolicyDropdownModel | null>(null);
  const [thenActionResponse, setThenActionResponse] = useState<ResultAction[]>(
    []
  );
  const [additionalData, setAdditionalData] = useState<
    AdditionalActionFormData[]
  >([]);

  const [decisionTableRow, setDecisionTableRow] = useState<
    Array<Record<string, DecisionTableRow>>
  >([]);

  const [nodeList, setNodeList] = useState<
    Record<string, DecisionTableNodesModel>
  >(structuredClone(decisionTableInitialNodes));

  const [sheetMode, setSheetMode] = useState('');

  const [currentRuleData, setCurrentRuleData] = useState<any>();

  const [, setIsRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [, setIsRulePublishable] = useAtom(isRulePublishableAtom);
  const [, setIsRulePublished] = useAtom(isRulePublishedAtom);
  const [, setDataSetFieldById] = useAtom(dataSetFieldsByIdAtom);
  const [, setFirstCustomAttribute] = useAtom(firstCustomAttributeAtom);
  const [, setRuleWarnings] = useAtom(ruleWarningsAtom);

  const [, setIsRuleLive] = useAtom(isRuleLiveAtom);
  const [, setApprovalInfoRule] = useAtom(approvalInfoRuleAtom);
  const [, setVersionInfoRule] = useAtom(versionInfoRuleAtom);
  const [, setVersionMappingInfo] = useAtom(versionMappingInfoAtom);

  const [, setDatasetDetailsInRule] = useAtom(datasetDetailsInRuleAtom);
  const [, setUsedConnectorMappingInRules] = useAtom(usedConnectorMappingAtom);
  // const [, setDtRenderError] = useAtom(decisionTableRenderError); // Need to check  main branch where it was being used.
  const [, setPredefineTokenDataset] = useAtom(predefineTokenDatasetAtom);

  const [siteConstant] = useAtom(siteConstantsAtom);

  const { isHide: editDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.create, permissionObj.edit],
    entityList: [ENTITY_ID.rules],
  });

  const isEditSheetDisable = sheetMode === 'view' || editDisable;

  useEffect(() => {
    setDataClone(isClone);
  }, [isClone]);

  useEffect(() => {
    void handleGetModuleAndSchemaData();
  }, []);

  const handleGetModuleAndSchemaData = async () => {
    const response = await getAllModuleAndSchema({
      variables: {
        page: 1,
        perPage: 20,
      },
      fetchPolicy: 'no-cache',
    });

    const moduledata = response?.data?.getModule?.data;

    if (!_isNil(moduledata)) {
      const tokenDataset = convertModuleDataIntoDataset(moduledata);

      setPredefineTokenDataset(tokenDataset);
    }
  };

  const handleGetRuleById = async () => {
    try {
      const filters: Record<string, any> = {};

      if (!_isNil(commitId) && !_isEmpty(commitId)) {
        filters.eq = { commitId };
      }

      if (!_isNil(version) && !_isEmpty(version)) {
        filters.eq = { version };
      }

      const response = await getRuleById({
        variables: { id: ruleId, live: isLive, filters },
        fetchPolicy: 'no-cache',
      });

      if (response?.data?.getRule.data.length === 0) {
        toasts.error(
          getTooltipText(siteConstant, 'rules', 'ruleNotExists', 'otherText'),
          'error'
        );
        setTimeout(() => {
          window.history.back();
        }, 3000);
      }
    } catch (error) {
      // eslint-disable-next-line no-console -- outputting error
      console.error(error);
    }
  };

  const handleGetRuleAfterStateTransition = async () => {
    try {
      const response = await getRuleById({
        variables: { id: ruleId },
        fetchPolicy: 'no-cache',
      });

      if (response?.data?.getRule.data.length === 0) {
        toasts.error(
          getTooltipText(siteConstant, 'rules', 'ruleNotExists', 'otherText'),
          'error'
        );
        setTimeout(() => {
          window.history.back();
        }, 3000);
      }
    } catch (error) {
      // eslint-disable-next-line no-console -- outputting error
      console.error(error);
    }
  };

  const transformPropertyNodes = (
    decisionTable: DecisionTableModel,
    conditions: Record<string, DecisionTableNodesModel>
  ) => {
    let currentNodeId = decisionTable.firstProperty;

    const array: string[] = [];

    while (
      !_isEmpty(currentNodeId) &&
      !_isNil(currentNodeId) &&
      !_isNil(decisionTable.properties[currentNodeId])
    ) {
      array.push(currentNodeId);
      currentNodeId = decisionTable.properties[currentNodeId].nextId;
    }

    return array.map((item) => {
      return {
        [item]: {
          value: conditions[item]?.attribute ?? '',
          key: conditions[item]?.sourceType ?? '',
          dataType: conditions[item]?.dataType ?? '',
        },
      };
    });
  };

  const handleTransformOutputData = (
    actionData: Record<string, DataOutputModel>
  ) => {
    return Object.keys(actionData).reduce<Record<string, any>>((acc, key) => {
      acc[key] = getRuleEditOutputDataValue(actionData[key]);

      return acc;
    }, {});
  };

  const handleTransformAggData = (decisionTableData: DecisionTableResponse) => {
    const { outputData } = decisionTableData.action.then;
    const array: AdditionalActionFormData[] = [];
    let current = decisionTableData.decisionTable.firstAggOutputData;

    while (!_isNil(current) && !_isNil(outputData[current])) {
      const currentOutput = outputData[current];

      const payload: AdditionalActionFormData = {
        name: currentOutput.name,
        value: currentOutput.value,
        dataType: currentOutput.dataType,
        returnType: currentOutput.returnType ?? '',
        executedValue: currentOutput.executedValue ?? '',
        schemaId:
          typeof outputData.schemaId === 'string' ? outputData.schemaId : '',
      };

      if (
        !_isEmpty(currentOutput.attribute) &&
        !_isNil(currentOutput.attribute)
      ) {
        payload.attribute = currentOutput.attribute;
      }
      if (!_isEmpty(currentOutput.source) && !_isNil(currentOutput.source)) {
        payload.source = currentOutput.source;
      }

      array.push(payload);

      current = currentOutput.next;
    }

    return array;
  };

  useEffect(() => {
    setSheetMode(searchParams.get('type') ?? '');
  }, []);

  useEffect(() => {
    if (_isNil(ruleId)) {
      setFirstRuleChain('');
    } else if (ruleIdExist) {
      void handleGetRuleById();
    }
  }, [ruleId, isLive, ruleIdExist, isLiveNodeSheetClosed]);

  const initialize = async (ruleData?: DecisionTableResponse) => {
    setLoadingData(true);

    if (!_isNil(ruleData)) {
      const newProperties = transformPropertyNodes(
        ruleData.decisionTable,
        ruleData.conditions.nodes
      );

      setProperties(newProperties);

      setNodeList(ruleData.conditions.nodes);

      handleSetCheckSumByEntityName('rule', ruleData.checksum);

      if (!_isNil(ruleData.version) && !dataClone) {
        setRuleVersion(ruleData.version);
      }

      if (!_isNil(ruleData.isDemo)) {
        setIsDemo(ruleData.isDemo);
      }

      setIsRuleLive(ruleData.isLive ?? false);

      setApprovalInfoRule(ruleData.approvalInfo);
      setVersionInfoRule(ruleData.versionInfo);
      setDatasetDetailsInRule(ruleData.datasetDetail);

      setVersionMappingInfo(
        ruleData.dependencyMap?.map((currMapping) => ({
          entityId: currMapping.id,
          type: currMapping.type,
          version: currMapping.version,
          nodeId: currMapping.nodeId,
        })) ?? []
      );
      setCurrentRuleData({
        createdAt: ruleData.createdAt,
        publishedAt: ruleData.publishedAt,
        status: ruleData.status,
        accessRole: ruleData.accessRole,
      });

      const usedConnectorMapping: UsedConnectorMappingInEntityType = {};

      if (!_isNil(ruleData.datasetDetail)) {
        usedConnectorMapping[ruleData.datasetDetail.connector.id] = {
          status: true,
          source: ['dataset'],
        };
      }

      if (!_isNil(ruleData.action?.then?.outputData)) {
        setOutputData(
          handleTransformOutputData(ruleData.action?.then?.outputData)
        );
      }

      if (!_isNil(ruleData.action.then.actionNode)) {
        const thenActionNode = transformActionNode(
          ruleData.action.then.actionNode,
          ruleData.action.then.firstActionNode
        );

        setThenActionResponse(thenActionNode);

        thenActionNode.forEach((currActionNode) => {
          const id = currActionNode.connectorId;

          const originalSource = usedConnectorMapping?.[id]?.source ?? [];

          const source = originalSource?.includes('action')
            ? originalSource
            : [...originalSource, 'action'];

          usedConnectorMapping[id] = {
            status: true,
            source,
          };
        });
      }

      if (!_isNil(ruleData.decisionTable?.results)) {
        startTransition(() => {
          try {
            // eslint-disable-next-line
            workerApi
              .handleTransformResults(ruleData.decisionTable)
              .then((result) => {
                setResult(result);
              });
          } catch (error) {
            // eslint-disable-next-line
            console.log('Processing error:', error);
          }
        });
      }

      if (
        !_isNil(ruleData.decisionTable.firstAggOutputData) &&
        !_isEmpty(ruleData.decisionTable.firstAggOutputData)
      ) {
        setAdditionalData(handleTransformAggData(ruleData));
      }

      if (
        !_isNil(ruleData.decisionTable?.rows) &&
        !_isNil(ruleData.decisionTable?.firstRow) &&
        !_isEmpty(ruleData.decisionTable?.rows) &&
        !_isEmpty(ruleData.decisionTable?.firstRow)
      ) {
        startTransition(() => {
          try {
            const timezoneOptions = {
              timezone: window.sessionStorage.getItem('nected-tz'),
              dateFormat: window.sessionStorage.getItem('nected-df'),
            };
            // eslint-disable-next-line
            workerApi
              .handleTransformDecisionTableRow(
                timezoneOptions,
                ruleData,
                newProperties
              )
              .then(
                (
                  result: SetStateAction<
                    Array<Record<string, DecisionTableRow>>
                  >
                ) => {
                  setDecisionTableRow(result);
                }
              );
          } catch (error) {
            // eslint-disable-next-line
            console.log('Processing error:', error);
          }
        });
      }

      if (!_isNil(ruleData.settings)) {
        const authType = getAuthType(ruleData);

        setProductionConfig(
          formatTriggersDataFromResponse(
            ruleData.settings,
            ruleData.staticUrl,
            isClone,
            authType
          )
        );
      }

      if (!_isNil(ruleData.policy) && !_isEmpty(ruleData.policy)) {
        setDecisionTablePolicy({
          label: _startCase(ruleData.policy),
          value: ruleData.policy,
        });
      }

      setDecisionTableName(`${ruleData.name}${isClone ? '_copy' : ''}`);

      if (!_isNil(ruleData.dataSetId) && !_isEmpty(ruleData.dataSetId)) {
        const dataSetKey = ruleData.dataSetId;

        setSelectedDataSet([dataSetKey]);

        if (!_isNil(dataSetKey)) {
          const mappedDatasetVersionInfo = ruleData.dependencyMap?.find(
            (versionMap) => versionMap.nodeId === 'dataSet'
          );

          const result = await getDataSetUsedInRuleById(
            dataSetKey,
            getDataSetById,
            mappedDatasetVersionInfo?.version
          );

          if (!_isNil(result)) {
            setDataSetFieldById(result);
          }
        }
      }

      setDecesionTableDescription(ruleData.description);

      setFirstCustomAttribute(ruleData.firstCustomInput);

      if (!_isNil(ruleData.customInput)) {
        const customInput = transformPayloadCustomInputToTableData(
          ruleData.customInput
        );

        setCustomDataParams(customInput);

        handleSetWarning(customInput, ruleData.dataSetId, selectedDataSets);

        Object.keys(customInput).forEach((key) => {
          const currCustomInput = customInput[key];
          const id = currCustomInput.attribute;

          if (currCustomInput.dataType?.value === 'restAPI' && !_isNil(id)) {
            const originalSource = usedConnectorMapping?.[id]?.source ?? [];

            const source = originalSource?.includes('restApi')
              ? originalSource
              : [...originalSource, 'restApi'];

            usedConnectorMapping[id] = {
              status: true,
              source,
            };
          }
        });
      }

      setUsedConnectorMappingInRules(usedConnectorMapping);

      if (!_isNil(ruleData.warnings)) {
        setRuleWarnings((prev) =>
          unionStringArrays(prev, ruleData.warnings ?? [])
        );
      }

      if (dataClone) {
        setIsRulePublishable(false);
        setIsRulePublished(false);
        setDataClone(false);
      } else if (!_isNil(ruleData.isPublish) && !!ruleData.isPublish) {
        setIsRulePublishable(false);
        setIsRulePublished(true);
      } else if (!_isNil(ruleData.isTested) && !!ruleData.isTested) {
        setIsRulePublishable(true);
      } else {
        setIsRulePublishable(false);
        setIsRulePublished(false);
      }
    }

    setLoadingData(false);
  };

  useEffect(() => {
    if (!_isNil(editRuleData) && isLiveNodeSheetClosed) {
      void initialize(editRuleData?.getRule.data[0]);
    } else if (!isLive) {
      void initialize(exampleDecisionTableResponse);
    }
  }, [
    editRuleData,
    isLive,
    isLiveNodeSheetClosed,
    JSON.stringify(productionConfig),
  ]);

  useEffect(() => {
    if (!_isNil(discardError)) {
      toasts.error(discardError.message, 'error');
    }
  }, [discardError]);

  useEffect(() => {
    setIsMounted(true);

    return () => {
      if (isLive && isMounted && !isEditSheetDisable) {
        setIsRuleReadOnly(false);
      }
    };
  }, [isMounted, isEditSheetDisable]);

  useEffect(() => {
    if (!_isNil(ruleId) && !_isNil(discardRule)) {
      void initialize(discardRule.discardRule);
    }
  }, [discardRule]);

  return {
    stagingConfig,
    productionConfig,
    properties,
    nodeList,
    decisionTablePolicy,
    decisionTableName,
    decesionTableDescription,
    outputData,
    result,
    decisionTableRow,
    customDataParams,
    discardRuleById,
    ruleLoading,
    ruleVersion,
    selectedDataSets,
    thenActionResponse,
    additionalData,
    isDemo,
    loadingData,
    currentRuleData,
    handleGetRuleAfterStateTransition,
  };
}
