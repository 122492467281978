import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _reduce from 'lodash/reduce';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Button, Sheet, Spinner, TooltipReact, Typography } from 'ui';

import {
  formatTriggerAttributesForTest,
  handleSetCheckSumByEntityName,
} from '../../../../../utils/common';
import {
  TestNodesDataModel,
  TestNodesHeaderModel,
} from '../../../../Rules/components/SimpleRule/TestNodeSheet/TestNodeSheet';
import { TestNodeField } from '../../../../Rules/components/TestNodeComponents/TestNodeField';
import { TestObjectModel } from '../../../../Rules/models';
import {
  convertDataToTestableData,
  validateTestParameters,
} from '../../../../Rules/utils/common';
import { workflowIdAtom, workflowNodesAtom } from '../../../atoms/atoms';
import { useTestWorkflow } from '../../../hooks/restApi/useTestWorkflow';
import { useGetDataset } from '../../../hooks/useGetDataset';
import { getErrorState, sortObjectByStep } from '../../../utils/common';
import { WorkflowInput } from './WorkflowInput/WorkflowInput';
import { WorkflowKey } from './WorkflowKey/WorkflowKey';
import { WorkflowResult } from './WorkflowResult/WorkflowResult';
import { WorkflowResultRs } from './WorkflowResult/WorkflowResultRs';
import {
  HeaderItemStyled,
  HeaderStyled,
  HeaderWrapper,
  ResponseContainer,
  ResponseHeader,
  ResponseHeaderContainer,
  ResponseResult,
  ResponseResultContainer,
  ResponseWrapper,
  RowItemStyled,
  RowStyled,
} from './WorkflowTestSheet.styled';

type WorkflowTestSheetProps = {
  onTestSuccess?: () => void;
};

export function WorkflowTestSheet({ onTestSuccess }: WorkflowTestSheetProps) {
  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [workflowId] = useAtom(workflowIdAtom);
  const [headerList, setHeaderList] = useState<TestNodesHeaderModel[]>([]);
  const [inputs, setInputs] = useState<Record<string, any>>({});

  const { updatedDataSet } = useGetDataset({});

  const triggerExecutedValue =
    workflowNodes.find((node) => node.type === 'trigger')?.data.executedValue ??
    {};

  const customAttributes =
    workflowNodes.find((node) => node.type === 'trigger')?.data.input ?? {};
  const { setValue, handleSubmit, setError, control } = useForm<any>({
    defaultValues: {
      test: [
        _reduce(
          customAttributes,
          (result: Record<string, TestObjectModel>, value, key) => {
            if (key !== 'CI0') {
              return {
                ...result,
                [key]: {
                  value: formatTriggerAttributesForTest(
                    customAttributes,
                    triggerExecutedValue,
                    key
                  ),
                  isNullable: false,
                  isOptional: false,
                  schemaId: customAttributes[key].schemaId,
                },
              };
            }

            return result;
          },
          {}
        ),
      ],
      headers: [],
      queryParams: [],
    },
  });
  const { fields } = useFieldArray({
    control,
    name: 'test',
  });

  const [testResults, setTestResults] = useState<Array<Record<string, any>>>(
    []
  );
  // eslint-disable-next-line

  // eslint-disable-next-line
  const { testWorkflow, data, error, isLoading } = useTestWorkflow(true);

  const onSubmit = async (data: TestNodesDataModel) => {
    const isValid = validateTestParameters(data, setError);

    if (!_isNil(workflowId) && isValid) {
      const params = convertDataToTestableData(data.test[0], customAttributes);
      setInputs(params);

      try {
        await testWorkflow({
          id: workflowId ?? '',
          params,
        });
      } catch {}
    }
  };

  useEffect(() => {
    if (!_isEmpty(customAttributes)) {
      setHeaderList(
        _reduce(
          customAttributes,
          (result: TestNodesHeaderModel[], value, key) => {
            if (key !== 'CI0') {
              result.push({
                label: key,
                dataType: customAttributes[key]?.dataType ?? 'unknown',
              });
            }

            return result;
          },
          []
        )
      );
    }

    return () => {
      setHeaderList([]);
    };
  }, [JSON.stringify(customAttributes)]);

  useEffect(() => {
    if (!_isNil(data?.data)) {
      const { testOutput, ...finalOutput } = data?.data?.data ?? {
        testOutput: {},
      };

      let finalData: any = {};

      if (getErrorState(data?.data ?? {})) {
        finalData = {
          // eslint-disable-next-line
          status:
            !_isNil(data?.data?.data?.error) && data?.data?.data?.error !== ''
              ? 'error'
              : 'success',
          data: finalOutput,
          code: 'unable_to_execute',
          message: data?.data?.data?.error ?? null,
          key: 'Workflow Response',
        };
      } else {
        finalData = {
          ...(data?.data ?? {}),
          data: { ...finalOutput },
          key: 'Workflow Response',
        };
      }

      let nodeResponses = sortObjectByStep(data?.data?.data?.testOutput ?? {});
      const responseNodes = workflowNodes.reduce<Record<string, boolean>>(
        (acc, curr) => {
          if (curr.type === 'responseNode') {
            return { ...acc, [curr.data.name]: true };
          }

          return acc;
        },
        {}
      );

      // eslint-disable-next-line
      nodeResponses = nodeResponses.filter((n) => !responseNodes[n.key]);

      setTestResults([
        ...workflowNodes
          .filter((node) => node.type === 'trigger')
          .map((node) => {
            return {
              key: node.data.name,
              result: node.data.executedValue,
            };
          }),
        ...nodeResponses,
        finalData,
      ]);

      if (!_isNil(data?.data?.checksum)) {
        handleSetCheckSumByEntityName('workflow', data?.data.checksum);
      }

      if (typeof onTestSuccess === 'function') {
        onTestSuccess();
      }
    }
  }, [JSON.stringify(data)]);

  return (
    <Sheet size="mediumLarge">
      <Stack
        gutter="2rem"
        style={{
          overflowY: 'auto',
          blockSize: '100vh',
        }}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <PadBox padding="1rem">
          <Typography name="heading3" fontWeight={700}>
            Test Workflow
          </Typography>
        </PadBox>

        <PadBox padding="1rem">
          <HeaderWrapper gutter={0}>
            <HeaderStyled gutter={0}>
              {headerList.map((header, index) => (
                <HeaderItemStyled key={`attributeHeader_${index}`}>
                  <PadBox padding={[5, 10]}>
                    <Inline stretch="start" align="end">
                      {header.label.length > 15 ? (
                        <TooltipReact
                          id={`attributeHeader_${header.label}`}
                          launcher={
                            <span>
                              <Typography>
                                {header.label.substring(0, 15)}...
                              </Typography>
                            </span>
                          }
                        >
                          <Typography>{header.label}</Typography>
                        </TooltipReact>
                      ) : (
                        <Typography>{header.label}</Typography>
                      )}
                      <Typography name="secondaryXs">
                        {header.dataType}
                      </Typography>
                    </Inline>
                  </PadBox>
                </HeaderItemStyled>
              ))}
            </HeaderStyled>

            <Inline>
              {fields.map((field, index) => (
                <RowStyled
                  gutter={0}
                  key={`testRowItem_${field.id}`}
                  align="stretch"
                >
                  {Object.keys(field)
                    .filter((key) => key !== 'id')
                    .map((key, fieldIndex) => (
                      <RowItemStyled key={`testRowItem_${index}_${fieldIndex}`}>
                        <PadBox
                          className={
                            customAttributes[key]?.dataType === 'boolean'
                              ? 'bool-container-style'
                              : ''
                          }
                          padding={[5, 10]}
                          as={Inline}
                          align="center"
                        >
                          <TestNodeField
                            nodeKey={key}
                            index={index}
                            dataType={
                              customAttributes[key]?.dataType ?? 'unknown'
                            }
                            control={control}
                            isNullable={customAttributes[key]?.isNullable}
                            isOptional={customAttributes[key]?.isOptional}
                            setValue={setValue}
                          />
                        </PadBox>
                      </RowItemStyled>
                    ))}
                </RowStyled>
              ))}
            </Inline>
          </HeaderWrapper>
        </PadBox>

        <Inline justify="end" as={PadBox} padding="1rem">
          <Button type="submit" disabled={isLoading}>
            {isLoading ? <Spinner size="extraSmall" /> : 'Test Now'}
          </Button>
        </Inline>

        {!isLoading && !_isNil(data) && (
          <ResponseContainer padding="1rem">
            <ResponseWrapper>
              <ResponseHeaderContainer>
                <ResponseHeader $width="15%">Steps</ResponseHeader>
                <ResponseHeader $width="25%">Input</ResponseHeader>
                <ResponseHeader $width="60%">Result</ResponseHeader>
              </ResponseHeaderContainer>

              <>
                {testResults.map((res, i) => {
                  const { key, step, status, checksum, ...rest } = res;

                  const currentNode = workflowNodes.find(
                    (w) => w.data?.name === res.key
                  );

                  const isFail = getErrorState(rest);

                  const isRuleSet =
                    currentNode?.data.nodeType === 'ruleSetNode';

                  return (
                    <ResponseResultContainer key={res.key}>
                      <ResponseResult $width="15%">
                        <WorkflowKey
                          isSuccess={
                            !_isNil(status) ? status === 'success' : !isFail
                          }
                          name={res.key}
                          node={currentNode}
                        />
                      </ResponseResult>

                      <ResponseResult $width="25%">
                        <WorkflowInput
                          id={
                            workflowNodes.find((w) => w.data?.name === res.key)
                              ?.id ?? ''
                          }
                          inputs={inputs}
                          updatedDataSet={updatedDataSet}
                          index={i}
                        />
                      </ResponseResult>

                      <ResponseResult $width="60%">
                        {isRuleSet ? (
                          <WorkflowResultRs
                            result={
                              i === 0 ? inputs : rest?.executedValue ?? rest
                            }
                            state={
                              !_isNil(status) ? status === 'success' : !isFail
                            }
                          />
                        ) : (
                          <WorkflowResult
                            result={
                              i === 0 ? inputs : rest?.executedValue ?? rest
                            }
                            state={
                              !_isNil(status) ? status === 'success' : !isFail
                            }
                          />
                        )}
                      </ResponseResult>
                    </ResponseResultContainer>
                  );
                })}
              </>
            </ResponseWrapper>
          </ResponseContainer>
        )}
      </Stack>
    </Sheet>
  );
}
