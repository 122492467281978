import { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import {
  CheckboxField,
  Dataset,
  DateField,
  NectedSuggestionModel,
  TextField,
} from 'ui';

import type { ResultType } from '../../pages/Rules/components/SimpleRule/Results';
import { JsNodePill } from '../../pages/Rules/components/SimpleRule/Results/JsNodePill';
import { JsonNodePill } from '../../pages/Rules/components/SimpleRule/Results/JsonNodePill';
import { ExcelPill } from '../ExcelLikeFormula/ExcelPill/ExcelPill';

type FieldByDataTypeComponentProps = Omit<UseControllerProps<any>, 'name'> & {
  nodeName: string;
  showError: boolean;
  index?: number;
  suggestions?: string[];
  disabled?: boolean;
  section?: ResultType;
  isSmallInput?: boolean;
  isAdditionalData?: boolean;
  hideOptionalCustomAttributes?: boolean;
  suggestionObjs?: NectedSuggestionModel[];
  setOriginalValue?: UseFormSetValue<any>;
  returnTypeName?: string;
  executedValueName?: string;
  additionalDataIndex?: number;
  dataSet?: Record<string, Dataset>;
  dataType: string;
  hidePopover: () => void;
};

function TextComponent({
  control,
  nodeName,
  rules,
  showError,
  disabled = false,
  section,
  isSmallInput = false,
  dataType,
}: FieldByDataTypeComponentProps) {
  return (
    <TextField
      control={control}
      name={nodeName}
      rules={rules}
      showErrorIcon={false}
      disabled={disabled}
      isSmallInput={isSmallInput}
    />
  );
}

function NumericComponent({
  control,
  nodeName,
  rules,
  showError,
  disabled = false,
  section,
  isSmallInput = false,
  dataType,
}: FieldByDataTypeComponentProps) {
  return (
    <TextField
      control={control}
      name={nodeName}
      rules={rules}
      showErrorIcon={false}
      type="number"
      size="small"
      disabled={disabled}
      isSmallInput={isSmallInput}
    />
  );
}

function BooleanComponent({
  control,
  nodeName,
  rules,
  disabled = false,
  section,
  dataType,
}: FieldByDataTypeComponentProps) {
  return (
    <CheckboxField
      control={control}
      name={nodeName}
      rules={rules}
      appearance="switch"
      useId={nodeName}
      disabled={disabled}
    />
  );
}

function DateComponent({
  nodeName,
  control,
  rules,
  showError,
  disabled = false,
  section,
  isSmallInput = false,
  dataType,
}: FieldByDataTypeComponentProps) {
  return (
    <DateField
      control={control}
      name={nodeName}
      rules={rules}
      showError={showError}
      disabled={disabled}
      isSmallInput={isSmallInput}
    />
  );
}

function JsonComponent({
  nodeName,
  control,
  rules,
  suggestions,
  index,
  disabled = false,
  section,
  hideOptionalCustomAttributes,
  suggestionObjs,
  setOriginalValue,
  returnTypeName,
  executedValueName,
  dataType,
  dataSet,
  isAdditionalData,
  hidePopover
}: FieldByDataTypeComponentProps) {
  return (
    <JsonNodePill
      type={dataType}
      name={nodeName}
      control={control}
      index={index}
      suggestions={suggestions}
      section={section}
      hideOptionalCustomAttributes={hideOptionalCustomAttributes}
      suggestionObjs={suggestionObjs}
      setOriginalValue={setOriginalValue}
      returnTypeName={returnTypeName}
      executedValueName={executedValueName}
      dataSet={dataSet}
      isAdditionalData={isAdditionalData}
      hidePopover={hidePopover}
    />
  );
}

function JsFormulaComponent({
  nodeName,
  control,
  rules,
  suggestions,
  index,
  disabled = false,
  section,
  isAdditionalData,
  hideOptionalCustomAttributes,
  suggestionObjs,
  setOriginalValue,
  returnTypeName,
  executedValueName,
  additionalDataIndex,
  dataType,
  dataSet,
  hidePopover
}: FieldByDataTypeComponentProps) {
  return (
    <JsNodePill
      name={nodeName}
      control={control}
      index={index}
      suggestions={suggestions}
      section={section}
      isAdditionalData={isAdditionalData}
      hideOptionalCustomAttributes={hideOptionalCustomAttributes}
      suggestionObjs={suggestionObjs}
      setOriginalValue={setOriginalValue}
      returnTypeName={returnTypeName}
      executedValueName={executedValueName}
      additionalDataIndex={additionalDataIndex}
      dataSet={dataSet}
      usingFrom="fieldComponent"
      hidePopover={hidePopover}
    />
  );
}

function ExcelFormulaComponent({
  nodeName,
  control,
  rules,
  suggestions,
  index,
  disabled = false,
  section,
  isAdditionalData,
  hideOptionalCustomAttributes,
  suggestionObjs,
  setOriginalValue,
  returnTypeName,
  executedValueName,
  additionalDataIndex,
  dataSet,
  dataType,
  hidePopover
}: FieldByDataTypeComponentProps) {
  return (
    <ExcelPill
      name={nodeName}
      control={control}
      index={index}
      setValue={setOriginalValue}
      returnTypeName={returnTypeName}
      executedValueName={executedValueName}
      dataSet={dataSet}
      isReadOnly={disabled}
      isAdditionalData={isAdditionalData}
      usingFrom="fieldComponent"
      hidePopover={hidePopover}
    />
  );
}

export const fieldByDataType = {
  string: TextComponent,
  numeric: NumericComponent,
  boolean: BooleanComponent,
  date: DateComponent,
  json: JsonComponent,
  jsFormula: JsFormulaComponent,
  excelFormula: ExcelFormulaComponent,
  list: JsonComponent,
};
