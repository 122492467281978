import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';
import { Typography } from 'ui';

export const HeaderConatiner = styled.div`
  border: 1px solid var(--color-DTBorderGrey);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

export const RuleInfoContainer = styled(Inline)`
  padding: 8px;
  justify-content: space-between;
`;

export const PolicyText = styled(Typography)`
  padding-right: 8px;
`;

export const RowNumContainer = styled.div`
  min-inline-size: 4rem;
  border-top: 1px solid var(--color-DTBorderGrey);
`;

export const ErrorMessage = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  inline-size: 20rem;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;
