/* eslint-disable prettier/prettier */
import * as Sentry from '@sentry/react';
import type { SeverityLevel } from '@sentry/types';
import type { AxiosError } from 'axios';
import axios, { AxiosResponse } from 'axios';
import { isValidCron } from 'cron-validator';
import cronstrue from 'cronstrue';
import dateFormat from 'dateformat';
import { SetStateAction } from 'jotai';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _isInteger from 'lodash/isInteger';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import _reduce from 'lodash/reduce';
import _upperFirst from 'lodash/upperFirst';
import type { UseFormSetError } from 'react-hook-form';
import {
  Attributes,
  Dataset,
  NectedSuggestionModel,
  flattenKeysAndTypes,
  flattenKeysAndTypesV2,
  formatCustomAttributes,
  formatNectedDate,
  toasts,
} from 'ui';
import * as CookieConsent from 'vanilla-cookieconsent';

import { axiosVidhanPrivate } from '../api/axios';
import { timeToExpireUnits } from '../pages/DataSets/utils';
import {
  CompletedTaskProps,
  OnboardingDataProps,
  SubTasks,
} from '../pages/Home/models';
import { PublishedConnectors } from '../pages/Rules/components/DecisionTable/types';
import { CustomAttributeByRuleId } from '../pages/Rules/components/RuleSet/models';
import type { AttributeModel, LocalCronRuleModel } from '../pages/Rules/models';
import { FieldsByID, SelectedType } from '../pages/Rules/types';
import {
  extractExecutedValueFromObject,
  getDefaultValuesForTest,
} from '../pages/Rules/utils/common';
import {
  checkUsedTokensAreValid,
  sanitizedStringV2,
} from '../pages/Workflow/utils/common';
import { WorkSpaceDetailsType } from '../pages/Workspace/component/types';
import { HttpCodes } from '../responseStatusCode/httpCodes';
import type {
  CheckSumEntityNames,
  GTMScriptType,
  GenerateEntityLinkArgsType,
  RoleJsonType,
  SiteConstantsModel,
  UsedConnectorMappingInEntityType,
  msClarityScriptType,
} from '../types';
import { SubscriptionPlanType } from '../types/index';
import { TokenScores, envMap } from './constant';
import {
  EXTRACT_TOKEN_REGEX,
  HEX_CODE_REGEX,
  REMOVE_DOUBLE_DOLLAR_REGEX,
  REMOVE_JAVASCRIPT_COMMENTS_REGEX,
} from './regex';
import type { CustomAxiosError } from './response/types';
import { isValidDate } from './validation';

const deploymentType = envMap.VITE_DEPLOYMENT_TYPE;
export const DATE_FORMAT: string = 'yyyy-mm-dd';
export const DATE_TIME_FORMAT: string = "yyyy-mm-dd'T'HH:MM:ssp";

export const generateUid = (prefix = '') => {
  const uid = Date.now().toString(36) + Math.random().toString(36).substring(2);

  return `${prefix}${uid}`;
};

export const createUUID = () => {
  try {
    return crypto.randomUUID();
  } catch (error) {
    return generateUUID();
  }
};

export const generateRandomKey = (length: number) => {
  const alphanumericChars =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let key = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * alphanumericChars.length);
    key += alphanumericChars.charAt(randomIndex);
  }

  return key;
};

export const generateUUID = () => {
  const hexChars = '0123456789abcdef';
  let uuid = '';
  for (let i = 0; i < 32; i++) {
    const randomIndex = Math.floor(Math.random() * 16);
    uuid += hexChars[randomIndex];
  }
  uuid =
    uuid.slice(0, 8) +
    '-' +
    uuid.slice(8, 12) +
    '-' +
    uuid.slice(12, 16) +
    '-' +
    uuid.slice(16, 20) +
    '-' +
    uuid.slice(20);

  return uuid;
};

export const copyToClipboard = async (
  textToBeCopied: string,
  toastMessage?: string
) => {
  try {
    await navigator.clipboard.writeText(textToBeCopied);
    toasts.info(_isNil(toastMessage) ? 'Text copied' : toastMessage, 'info');
  } catch (err) {
    toasts.warning('Unable to copy this text', 'warning');
  }
};

export function getResponseErrorMessage(
  error: AxiosError<CustomAxiosError>,
  userMessage?: string
) {
  if (!_isNil(userMessage)) {
    return userMessage;
  }

  const { message, response } = error;

  if (!_isNil(response) && !_isEmpty(response.data.message)) {
    return response.data.message;
  }

  return message;
}

export function showErrorToast(
  error: AxiosError<CustomAxiosError>,
  userMessage?: string
) {
  const { response } = error;

  if (
    response?.status !== HttpCodes.INTERNAL_SERVER_ERROR &&
    response?.status !== HttpCodes.BAD_REQUEST
  ) {
    toasts.error(getResponseErrorMessage(error, userMessage), 'error');
  }
}

export const generateRandomHex = (size: number) =>
  [...Array(size)]
    .map(() => Math.floor(Math.random() * 16).toString(16))
    .join('');

export const isValidJson = (jsonString: string) => {
  try {
    JSON.parse(jsonString);
  } catch (e) {
    return false;
  }

  return true;
};

export const isValidJS = (jsString: string) => {
  try {
    // eslint-disable-next-line -- We're disabling the next line for checking validity of the code
    new Function(jsString);
  } catch {
    return false;
  }

  return true;
};

export function showGraphQlErrorToast(error: unknown) {
  if (error instanceof Error) {
    const { message } = error;
    toasts.error(message, 'error');
  }
}

export const getCustomAttributeDataTypeLabel = (
  type: SelectedType | null,
  dataSet: string[],
  fieldsById: FieldsByID,
  publishConnectors: PublishedConnectors,
  dataTypeLabel: string = ''
) => {
  if (_isNil(type)) {
    return 'Select';
  }

  if (type.key === 'primitive') {
    return _upperFirst(type.dataType);
  }

  if (
    !_isNil(dataSet[0]) &&
    !_isNil(fieldsById[dataSet[0]]) &&
    type.key === 'dataSet'
  ) {
    return `${_upperFirst(dataTypeLabel)} via ${fieldsById[dataSet[0]].name}`;
  }

  if (type.key === 'restAPI' && !_isNil(publishConnectors)) {
    return `JSON via ${publishConnectors[type?.value ?? '']?.name ?? ''}`;
  }

  return 'Select';
};

export const convertMapToArrayOfObject = (mapObj: Record<string, any>) => {
  const array: Array<{
    key: string;
    value: any;
  }> = [];
  _forEach(mapObj, function (value, key) {
    array.push({
      key,
      value,
    });
  });

  return array;
};

export const getActionNameByConnectorName = (actionName: string) => {
  switch (actionName.toLocaleLowerCase()) {
    case 'mongodb':
      return 'Mongo DB';
    case 'postgres':
      return 'Postgres';
    case 'mysql':
      return 'MySQL';
    case 'sqlserver':
      return 'MS SQL Server';
    default:
      return '';
  }
};

export const handleGetCheckSumByEntityName = (name: CheckSumEntityNames) => {
  const checkSum =
    window.sessionStorage.getItem('checkSum') ??
    window.localStorage.getItem('checkSum');
  let decodedCheckSum: Record<string, string> = {};

  if (!_isNil(checkSum) && !_isEmpty(checkSum)) {
    const decodedString = window.atob(checkSum);

    try {
      decodedCheckSum = JSON.parse(decodedString);
    } catch (error) {}
  }

  if (!_isEmpty(decodedCheckSum)) {
    return decodedCheckSum[name];
  }

  return undefined;
};

export const handleSetCheckSumByEntityName = (
  name: CheckSumEntityNames,
  value: string = ''
) => {
  const checkSum =
    window.sessionStorage.getItem('checkSum') ??
    window.localStorage.getItem('checkSum');
  let decodedCheckSum: Record<string, string> = {};

  if (!_isNil(checkSum) && !_isEmpty(checkSum)) {
    const decodedString = window.atob(checkSum);

    try {
      decodedCheckSum = JSON.parse(decodedString);
    } catch (error) {}
  }

  decodedCheckSum[name] = value;

  const encodedString = window.btoa(JSON.stringify(decodedCheckSum));

  window.sessionStorage.setItem('checkSum', encodedString);
  window.localStorage.setItem('checkSum', encodedString);

  return undefined;
};

function capitalizeWords(arr: string[]) {
  return arr.map((word) => {
    return _upperFirst(word);
  });
}

export const capitalizeHeaderKey = (text: string) => {
  const splitText = text.split('-');

  return capitalizeWords(splitText).join('-');
};

export const getRoleJson = (): RoleJsonType => {
  return JSON.parse(window.localStorage.getItem('FEATURE_FLAG_JSON') ?? '{}');
};

export const updateRoleJson = (roleJson: RoleJsonType) => {
  window.localStorage.setItem('FEATURE_FLAG_JSON', JSON.stringify(roleJson));
};

export const getTrimmedText = (text: string, trimAfter: number = 20) => {
  if (text.length > trimAfter && trimAfter > 0) {
    return text.substring(0, trimAfter) + '...';
  }

  return text;
};

export const htmlEncode = (input: string): string => {
  const entityMap: Record<string, string> = {
    '+': '&#43;',
  };
  /* eslint-disable no-useless-escape */

  return input.replace(/[+]/g, (char) => entityMap[char]);
};

// This function send an event to GTM
export const sendEventToGTM = (event: Record<string, any>) => {
  if (envMap.VITE_ENVIRONMENT === 'production') {
    try {
      window.dataLayer.push(event);
    } catch (error) {
      // eslint-disable-next-line
      console.info('Data layer stopped');
    }
  }
};

export const fetchResources = (
  promises: Array<Promise<Response>>,
  callback: (arg0: any, arg1: number) => void
) => {
  Promise.all(promises)
    .then((responses) => {
      // Process each response
      responses.forEach((response, index) => {
        if (!_isNil(response.ok)) {
          response
            .json()
            .then((data: SiteConstantsModel) => {
              callback(data, index);
            })
            .catch((error: any) => {
              // eslint-disable-next-line no-console
              console.error('Error parsing JSON:', error);
            });
        } else {
          // eslint-disable-next-line no-console
          console.error('Error fetching data:', response.status);
        }
      });
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error:', error);
    });
};

export const handleGetConstantData = (
  path: string,
  setSiteConstants: (update: SetStateAction<SiteConstantsModel | null>) => void,
  siteConstant: SiteConstantsModel | null
) => {
  const urls = path
    .split(',')
    .filter((url) => _isNil(siteConstant) || _isNil(siteConstant[url]));

  const urlsToAvoid = [
    '',
    'signup',
    'signin',
    'switch-to-desktop',
    'questionnaire',
    'forgot-password',
    'redirect',
    'email-not-verified',
  ];

  // eslint-disable-next-line @typescript-eslint/promise-function-async
  const promises = urls
    .filter((url) => !urlsToAvoid.includes(url))
    .map(
      async (url: string) =>
        await fetch(
          `/assets/konark/json/${
            localStorage.getItem('currentLang') ?? ('en' as string)
          }/constants/${url}.json`
        )
    );
  fetchResources(promises, (data, index) => {
    void setSiteConstants((prev) => ({
      ...prev,
      [urls[index]]: data,
    }));
  });
};

export const getTooltipText = (
  siteConstant: SiteConstantsModel | null,
  entity: string,
  key: string,
  type: 'tooltipText' | 'otherText' | 'howToLinks' = 'tooltipText'
) => {
  if (
    _isNil(siteConstant) ||
    _isNil(siteConstant[entity]) ||
    _isNil(siteConstant[entity][type]) ||
    _isNil(siteConstant[entity][type][key])
  ) {
    return '';
  }

  return siteConstant[entity][type][key];
};

export const convertObjecttoStringCSS = (
  styles: Record<string, string> | undefined
) => {
  if (_isUndefined(styles)) {
    return '';
  }

  return Object.entries(styles)
    .map(([key, value]) => `${key}:${value}`)
    .join(';');
};

export const installGA = (trackingId: string, additionalConfigInfo = {}) => {
  const scriptId = 'ga-gtag';

  if (!_isNil(document.getElementById(scriptId))) return;

  const { head } = document;
  const script = document.createElement('script');
  script.id = scriptId;
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
  head.insertBefore(script, head.firstChild);

  window.dataLayer = window.dataLayer ?? [];

  sendToGA('js', new Date());
  sendToGA('config', trackingId);
};

export const installClarity = (CLARITY_ID: string) => {
  /* eslint-disable */
  (function ({ c, l, a, r, i, t, y }: msClarityScriptType) {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = 1;
    t.src = 'https://www.clarity.ms/tag/' + i;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })({
    c: window,
    l: document,
    a: 'clarity',
    r: 'script',
    i: CLARITY_ID,
  });
};

export const installGTM = (GTM_ID: string) => {
  /* eslint-disable */
  (function ({ w, d, s, l, i, j }: GTMScriptType) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'nectedLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })({
    w: window,
    d: document,
    s: 'script',
    l: 'nectedLayer',
    i: GTM_ID,
  });
};

export const sendToGA = function (...args: any[]) {
  if (!_isNil(window.dataLayer)) {
    window.dataLayer.push(arguments);
  }
};

export const getDataSetSuggestionsObj = (
  dataSetVariables: Record<string, Dataset>,
  skipJson: boolean = false
) => {
  return _reduce(
    dataSetVariables,
    (result: NectedSuggestionModel[], value, key) => {
      if (!_isNil(value.attributes)) {
        return [
          ...result,
          ..._reduce(
            value.attributes,
            (res: NectedSuggestionModel[], attributeValue, attributeKey) => {
              const mappedValue = flattenKeysAndTypes(
                !Array.isArray(attributeValue.executedValue)
                  ? attributeValue.executedValue ?? {}
                  : {}
              ).map((kt) => {
                if (
                  ['string', 'dateTime', 'date'].includes(
                    attributeValue.dataType
                  )
                ) {
                  return {
                    name: `"<<${key}.${attributeKey}.${kt.key}>>"`,
                    value: `"<<${key}.${attributeKey}.${kt.key}>>"`,
                    score: TokenScores[key] ?? 998,
                    meta: kt.type,
                    executedValue: attributeValue.executedValue[kt.key],
                  };
                }

                return {
                  name: `<<${key}.${attributeKey}.${kt.key}>>`,
                  value: `<<${key}.${attributeKey}.${kt.key}>>`,
                  score: TokenScores[key] ?? 998,
                  meta: kt.type,
                  executedValue: extractExecutedValueFromObject(
                    attributeValue.executedValue,
                    kt.key
                  ),
                };
              });

              if (
                ['string', 'dateTime', 'date', 'list'].includes(
                  attributeValue.dataType
                )
              ) {
                return [
                  ...res,
                  {
                    name: `"<<${key}.${attributeKey}>>"`,
                    value: `"<<${key}.${attributeKey}>>"`,
                    score: TokenScores[key] ?? 998,
                    meta: attributeValue.dataType,
                    executedValue: attributeValue.executedValue,
                  },
                  ...mappedValue,
                ];
              } else if (attributeValue.dataType === 'json' && skipJson) {
                return [...res, ...mappedValue];
              }

              return [
                ...res,
                {
                  name: `<<${key}.${attributeKey}>>`,
                  value: `<<${key}.${attributeKey}>>`,
                  score: TokenScores[key] ?? 998,
                  meta: attributeValue.dataType,
                },
                ...mappedValue,
              ];
            },
            []
          ),
        ];
      }

      return result;
    },
    []
  );
};

export const getDataSetSuggestionsObjV2 = (
  dataSetVariables: Record<string, Dataset>,
  skipJson: boolean = false
) => {
  return _reduce(
    dataSetVariables,
    (result: NectedSuggestionModel[], value, key) => {
      if (!_isNil(value.attributes)) {
        return [
          ...result,
          ..._reduce(
            value.attributes,
            (res: NectedSuggestionModel[], attributeValue, attributeKey) => {
              const mappedValue = flattenKeysAndTypesV2({
                obj: attributeValue.executedValue ?? {},
              }).map((kt) => {
                if (
                  ['string', 'dateTime', 'date'].includes(
                    attributeValue.dataType
                  )
                ) {
                  return {
                    name: `{{.${key}.${attributeKey}.${kt.key}}}`,
                    value: `{{.${key}.${attributeKey}.${kt.key}}}`,
                    score: TokenScores[key] ?? 998,
                    meta: kt.dataType,
                    executedValue: kt.value,
                  };
                }

                if (['list'].includes(attributeValue.dataType)) {
                  return {
                    name: `{{.${key}.${attributeKey}${kt.key}}}`,
                    value: `{{.${key}.${attributeKey}${kt.key}}}`,
                    score: TokenScores[key] ?? 998,
                    meta: kt.dataType,
                    executedValue: kt.value,
                  };
                }

                return {
                  name: `{{.${key}.${attributeKey}.${kt.key}}}`,
                  value: `{{.${key}.${attributeKey}.${kt.key}}}`,
                  score: TokenScores[key] ?? 998,
                  meta: kt.dataType,
                  executedValue: kt.value,
                };
              });

              if (
                ['string', 'dateTime', 'date', 'list'].includes(
                  attributeValue.dataType
                )
              ) {
                return [
                  ...res,
                  {
                    name: `{{.${key}.${attributeKey}}}`,
                    value: `{{.${key}.${attributeKey}}}`,
                    score: TokenScores[key] ?? 998,
                    meta: attributeValue.dataType,
                    executedValue: attributeValue.executedValue,
                  },
                  ...mappedValue,
                ];
              } else if (attributeValue.dataType === 'json' && skipJson) {
                return [...res, ...mappedValue];
              }

              return [
                ...res,
                {
                  name: `{{.${key}.${attributeKey}}}`,
                  value: `{{.${key}.${attributeKey}}}`,
                  score: TokenScores[key] ?? 998,
                  meta: attributeValue.dataType,
                  executedValue: attributeValue.executedValue,
                },
                ...mappedValue,
              ];
            },
            []
          ),
        ];
      }

      return result;
    },
    []
  );
};
// Used to get List of all variables present in the expression (data inside {} is variable)
export const getAllVariablesFromExpression = (
  expression: string | undefined
) => {
  const variableList = new Set<string>([]);

  if (_isUndefined(expression)) {
    return [];
  }

  let appendString = false;
  let stringId = '';
  for (const char of expression) {
    if (char === '{') {
      appendString = true;
    } else if (char === '}') {
      appendString = false;
      variableList.add(stringId);
      stringId = '';
    } else if (appendString) {
      stringId += char;
    }
  }

  return Array.from(variableList);
};

// Used to remove curly braces from expression
export const removeCurlyBracesFromExpression = (expression: string) => {
  return expression.replaceAll(/{|}/g, '');
};

export const removeDoubleDollarFromExpression = (expression: string) => {
  return expression.replaceAll(REMOVE_DOUBLE_DOLLAR_REGEX, '$1');
};

export const evaluateExpression = (expression: string) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-implied-eval, no-new-func
    return new Function('return ' + expression)();
  } catch (err) {
    toasts.error('Incorrect expression, Please re-evaluate', 'eval-error');

    return false;
  }
};

export const getTimeZone = () => {
  if (typeof Intl.DateTimeFormat === 'function') {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return timeZone;
  } else {
    return 'NA';
  }
};

export const getPublicIP = async () => {
  const IP_INFO_KEY = envMap.VITE_IP_INFO_KEY;
  if (!_isEmpty(IP_INFO_KEY) && !_isNil(IP_INFO_KEY)) {
    try {
      const response = await fetch(
        `https://ipinfo.io/json?token=${IP_INFO_KEY}`
      );
      const source = JSON.parse(window.localStorage.getItem('source') ?? '{}');

      if (response.ok) {
        const data = await response.json();
        const { country, city, region, ip } = data;
        const location = `${city as string}_${region as string}_${
          country as string
        }`;
        source.utm_location = location;
        source.utm_country = country;
        localStorage.setItem('Nected-User-IP', ip);
      } else {
        source.utm_location = 'NA';
      }
      window.localStorage.setItem('source', JSON.stringify(source));
    } catch {}
  }
};

type ObjectType = Record<string, string>;

export const objectToString = (obj: ObjectType): string => {
  if (obj === null || obj === undefined) {
    return String(obj);
  }

  let str = '';
  let isFirst = true;

  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      if (!isFirst) {
        str += ',';
      }
      str += `${key}:${obj[key]}`;
      isFirst = false;
    }
  }

  return str === 'undefined' ? 'NA' : str;
};

export const getDataTypeByReturnType = (element: Record<string, any>) => {
  if (['jsFormula', 'excelFormula'].includes(element.dataType)) {
    if (_isNil(element.returnType) || _isEmpty(element.returnType)) {
      return 'unknown';
    }

    return element.returnType;
  }

  return element.dataType;
};

export const getRuleEditOutputDataValue = (
  element: Record<string, any>,
  original: boolean = false
) => {
  if (original) {
    return element.value;
  }

  if (element.dataType === 'date' || element.dataType === 'dateTime') {
    if (_isNil(element.value) || _isEmpty(element.value)) {
      return new Date();
    }

    return new Date(element.value);
  }

  return !_isNil(element.value) ? element.value.toString() : '';
};

export const getOutputDataByExecutedValue = (
  data: any,
  isAdditionalData: boolean,
  firstOutput: any
) => {
  if (['jsFormula', 'excelFormula', 'list'].includes(data.dataType)) {
    if (isAdditionalData) {
      return [firstOutput.executedValue];
    }

    return firstOutput.executedValue;
  } else if (data.dataType === 'json') {
    if (isAdditionalData) {
      if (
        _isEmpty(firstOutput.executedValue) ||
        _isNil(firstOutput.executedValue)
      ) {
        return [firstOutput.value];
      }

      return [firstOutput.executedValue];
    }

    if (
      _isEmpty(firstOutput.executedValue) ||
      _isNil(firstOutput.executedValue)
    ) {
      return firstOutput.value;
    }

    return firstOutput.executedValue;
  }

  if (isAdditionalData) {
    return [extractValueByDataType(firstOutput.value, data.dataType)];
  }

  return firstOutput.value;
};

export const getLocale = () => new Intl.NumberFormat().resolvedOptions().locale;

export const isDateStringValid = (dateString: string) => {
  if (isValidDate(dateString)) {
    return true;
  }

  const dateObject = new Date(dateString);

  return !isNaN(dateObject.getTime()) && dateString.trim() !== '';
};

// Define the request payload type
interface RequestPayload {
  language: string;
  snippet: string;
  timezone?: string;
  dateFormat?: string;
}

// Function to make a single Axios POST request
async function makePostRequest<T>(
  url: string,
  payload: RequestPayload
): Promise<T> {
  const config: Record<string, string> = {
    Authorization: `Bearer ${
      window.localStorage.getItem('accessToken') as string
    }`,
    'Nected-WS': `${window.sessionStorage.getItem('workspaceUUID') as string}`,
  };

  const response: AxiosResponse<T> = await axios.post(url, payload, {
    headers: config,
  });

  return response.data;
}

// Function to make multiple parallel Axios POST requests
export async function executeJSParallelPostRequests(
  requests: Array<{
    payload: RequestPayload;
  }>,
  suggestions: NectedSuggestionModel[] = []
) {
  const url = `${envMap.VITE_API_HOST as string}/integration/code/execute`;
  const responses = await Promise.all(
    requests.map(
      async (request) =>
        await makePostRequest(url, {
          language: request.payload.language,
          snippet: sanitizedString(
            request.payload.snippet,
            formatCustomAttributes(suggestions)
          ),
          timezone: window.sessionStorage.getItem('nected-tz') ?? '',
          dateFormat: window.sessionStorage.getItem('nected-df') ?? 'in',
        })
    )
  );

  return responses;
}

export const extractTokens = (inputString: string) => {
  var regex = /\{\{\s*(\.[a-zA-Z0-9-_[\]]+)+\s*\}\}/gm;
  var matches = inputString.match(regex) || [];
  return matches;
};

export const CUSTOM_DATA_TYPES: Record<string, any> = {
  customInput: {
    type: 'map',
    value: '{}',
  },
  globalVar: {
    type: 'map',
    value: '{}',
  },
  outputData: {
    type: 'map',
    value: '{}',
  },
  additionalData: {
    type: 'map',
    value: '{}',
  },
  dataSet: {
    type: 'map',
    value: '{}',
  },
  outputDataList: {
    type: 'array',
    value: '[]',
  },
};
export const DATA_TYPES_VALUE: Record<string, any> = {
  string: 'test_string',
  integer: 1,
  numeric: 1,
  number: 1,
  dateTime: '2021-01-01T00:00:00.000Z',
  date: '2021-01-01',
  boolean: true,
  object: '{}',
  map: '{}',
  json: '{}',
  list: '[]',
  array: '[]',
  'array[strings]': '[]',
  'array[integers]': '[]',
  'array[numeric]': '[]',
  'array[numbers]': '[]',
  'array[dateTime]': '[]',
  'array[dates]': '[]',
  'array[boolean]': '[]',
  'array[objects]': '[]',
};

export const sanitizedString = (
  value: string,
  allAutocomplete: NectedSuggestionModel[] = []
) => {
  let updatedValue = value;
  const tokens = extractTokens(value);
  tokens.forEach((i) => {
    const tokenValue = allAutocomplete.find((j: NectedSuggestionModel) =>
      j?.value.includes(i)
    );

    if (!_isNil(tokenValue)) {
      updatedValue = updatedValue.replace(
        i,
        !_isNil(tokenValue.executedValue)
          ? convertValues(
              detectType(tokenValue.executedValue),
              tokenValue.executedValue
            )
          : DATA_TYPES_VALUE[tokenValue.meta]
      );
    } else {
      Object.keys(CUSTOM_DATA_TYPES).forEach((element) => {
        const token = i.split('.')[1];

        if (token === element) {
          updatedValue = updatedValue.replace(
            i,
            CUSTOM_DATA_TYPES[element].value
          );
        }
      });
    }
  });

  return updatedValue;
};

export const convertValues = (type: any, value: string) => {
  if (type === 'array' || type === 'object') {
    return JSON.stringify(value, null);
  } else {
    return value;
  }
};

export const detectType = (value: string | null) => {
  if (value === null) {
    return null;
  }

  if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return 'array';
    }

    return 'object';
  }

  return typeof value;
};

export const prepareCodeStringForExecution = (value: string, mode: string) => {
  if (mode === 'json') {
    const updatedJSONString = `var a=${value} \n a;`;

    return updatedJSONString;
  }

  return value;
};

function formatAMPM(date: Date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();

  // Convert to 12-hour format
  const hourFormatText = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // Adjust 0 to be 12

  // Pad minutes to two digits if necessary
  minutes =
    minutes < 10 ? (('0' + minutes) as string as unknown as number) : minutes;

  return hours + ':' + minutes + ' ' + hourFormatText;
}

export const getCronExpressionByUnit = (values: any) => {
  try {
    const startTime = new Date(values.startAt);

    switch (values.unit.value) {
      case 'minute':
        // eslint-disable-next-line
        return cronstrue.toString(`*/${values.minutes} * * * *`);
      case 'hourly':
        // eslint-disable-next-line
        return cronstrue.toString(
          // eslint-disable-next-line
          `${startTime.getMinutes().toString()} */${values.hours} * * *`
        );
      case 'daily':
        // eslint-disable-next-line
        return `Everyday at ${formatAMPM(startTime)}`;

      case 'weekly':
        // eslint-disable-next-line
        return cronstrue.toString(
          `${startTime.getMinutes().toString()} ${startTime
            .getHours()
            .toString()} * * ${(values.weekdays as string[]).join(',')}`
        );

      case 'monthly':
        // eslint-disable-next-line
        return cronstrue.toString(
          `${startTime.getMinutes()} ${startTime.getHours()} ${(
            values.days as string[]
          ).join(',')} * *`
        );

      case 'cron':
        return cronstrue.toString(
          // eslint-disable-next-line
          `${values.cron.minutes} ${values.cron.hours} ${values.cron.days} ${values.cron.month} ${values.cron.weekdays}`
        );
    }
  } catch (error) {
    return 'Please check the values';
  }

  return '';
};

export const getCronExpressionByData = (values: any) => {
  try {
    const startTime = new Date(values.startAt);

    switch (values.unit) {
      case 'minute':
        // eslint-disable-next-line
        return cronstrue.toString(`*/${values.spec.minute} * * * *`);
      case 'hourly':
        // eslint-disable-next-line
        return cronstrue.toString(
          `${startTime.getMinutes().toString()} */${
            values.spec.hour as string
          } * * *`
        );
      case 'daily':
        // eslint-disable-next-line
        return `Everyday at ${formatAMPM(startTime)}`;

      case 'weekly':
        // eslint-disable-next-line
        return cronstrue.toString(
          `${startTime.getMinutes().toString()} ${startTime
            .getHours()
            .toString()} * * ${values.spec.dayOfWeek as string}`
        );

      case 'monthly':
        // eslint-disable-next-line
        return cronstrue.toString(
          `${startTime.getMinutes().toString()} ${startTime
            .getHours()
            .toString()} ${values.spec.dayOfMonth as string} * *`
        );

      case 'cron':
        return cronstrue.toString(
          // eslint-disable-next-line
          `${values.spec.minute} ${values.spec.hour} ${values.spec.dayOfMonth} ${values.spec.month} ${values.spec.dayOfWeek}`
        );
    }
  } catch (error) {
    return 'Please check the values';
  }

  return '';
};

export const validateCron = (
  data: LocalCronRuleModel,
  setError: UseFormSetError<any>
) => {
  let isValid = true;

  if (_isNil(data.startAt)) {
    setError('startAt', {
      message: 'Start date must be there',
    });

    isValid = false;
  }

  if (!_isNil(data.endAt)) {
    if (new Date(data.startAt) >= new Date(data.endAt)) {
      setError('endAt', {
        message: 'End date must be greater than start date',
      });

      isValid = false;
    }
  }

  if (data.unit.value === 'hourly') {
    if (data.hours < 1) {
      setError('hours', {
        message: 'Hours must be greater than 0',
      });

      isValid = false;
    }

    if (!_isInteger(parseFloat(data.hours.toString()))) {
      setError('hours', {
        message: 'Hours must not be floating point',
      });

      isValid = false;
    }
  } else if (data.unit.value === 'minute') {
    if (data.minutes < 1) {
      setError('minutes', {
        message: 'Minutes must be greater than 0',
      });

      isValid = false;
    }

    if (!_isInteger(parseFloat(data.minutes.toString()))) {
      setError('minutes', {
        message: 'Minutes must not be floating point',
      });

      isValid = false;
    }
  } else if (data.unit.value === 'weekly') {
    if (data.weekdays.length < 1) {
      setError('weekdays', {
        message: 'Must select at least one weekday',
      });

      isValid = false;
    }
  } else if (data.unit.value === 'monthly') {
    if (data.days.length < 1) {
      setError('days', {
        message: 'Must select at least one day',
      });

      isValid = false;
    }
  } else if (data.unit.value === 'cron') {
    for (const key in data.cron) {
      const stars = ['*', '*', '*', '*', '*'];

      // @ts-expect-error
      stars[CRON_POSITION[key]] = data.cron[key];

      if (!isValidCron(stars.join(' '))) {
        // Do something
        setError(`cron.${key}`, {
          message: 'Use Correct values please',
        });
        isValid = false;
      }
    }
  }

  return isValid;
};

export const CRON_POSITION: Record<string, number> = {
  minutes: 0,
  hours: 1,
  days: 2,
  month: 3,
  weekdays: 4,
};

export const sanitizeArrayAsInput = (inputString: string) => {
  let sanitizedString = inputString;
  const firstChar = inputString[0];
  const lastChar = inputString[inputString.length - 1];

  if (firstChar === '|' || firstChar === ',' || firstChar === ';') {
    sanitizedString = inputString.slice(1);
  }

  if (lastChar === '|' || lastChar === ',' || lastChar === ';') {
    sanitizedString = inputString.slice(0, -1);
  }

  return sanitizedString;
};

export const convertStringToListHelper = (str: string) => {
  const resultingArrayStr = `${sanitizeArrayAsInput(str.toString().trim())
    .replaceAll('|', ',')
    .replaceAll(';', ',')
    .replaceAll("'", '"')}`;

  return convertStringToList(resultingArrayStr);
};

export const convertArrayAsInput = (
  str: string | null | any[],
  skipTry = false
) => {
  let value = str;
  if (_isNil(value) || value === '') {
    return [];
  }

  if (Array.isArray(value)) {
    return value;
  }

  if (typeof value === 'string') {
    value = value.replaceAll('\\"', '"');
  }

  if (!skipTry) {
    try {
      JSON.parse(value);
      return Array.isArray(JSON.parse(value))
        ? JSON.parse(value)
        : [JSON.parse(value)];
    } catch (error) {
      return convertStringToListHelper(value);
    }
  }

  return convertStringToListHelper(value);
};

export const convertStringToList = (input: string) => {
  const tokenRegex = /\{\{\s*(\.[a-zA-Z0-9-_[\]]+)+\s*\}\}/gm;
  const tokenMatches: string[] = input.match(tokenRegex) || [];

  // Replace tokens with placeholder strings surrounded by double quotes
  const sanitizedInput: string = tokenMatches.reduce<string>(
    (acc: string, token: string) => acc.replace(token, `"${token}"`),
    input
  );

  // Remove square brackets if present at the beginning and end of the string
  let processedInput: string = sanitizedInput;
  if (processedInput.startsWith('[') && processedInput.endsWith(']')) {
    processedInput = processedInput.slice(1, -1);
  }

  const stack: any[] = [];
  let currentItem = '';
  let inQuotes = false;
  let bracesCount = 0;
  let bracketsCount = 0;

  for (let i = 0; i < processedInput.length; i++) {
    const char = processedInput[i];

    if (char === ',' && !inQuotes && bracesCount === 0 && bracketsCount === 0) {
      const parsedItem = parseItem(currentItem.trim());
      if (parsedItem !== undefined) {
        stack.push(parsedItem);
      }
      currentItem = '';
    } else {
      if (char === '"') {
        inQuotes = !inQuotes;
      } else if (
        char === '{' &&
        !inQuotes &&
        processedInput.slice(i).match(tokenRegex)
      ) {
        let tokenMatch = processedInput.slice(i).match(tokenRegex);
        if (!_isNil(tokenMatch) && !_isNil(tokenMatch[0])) {
          stack.push(tokenMatch[0]);
          i += tokenMatch[0].length - 1;
        }
      } else if (char === '{' && !inQuotes) {
        bracesCount++;
      } else if (char === '}' && !inQuotes) {
        bracesCount--;
      } else if (char === '[' && !inQuotes) {
        bracketsCount++;
      } else if (char === ']' && !inQuotes) {
        bracketsCount--;
      }

      currentItem += char;
    }
  }

  const parsedItem = parseItem(currentItem.trim());
  if (parsedItem !== undefined) {
    stack.push(parsedItem);
  }

  return stack;
};

// Old parseItem function
const parseItem = (item: any) => {
  if (item === 'true' || item === 'false') {
    return item === 'true';
  } else if (!isNaN(item)) {
    return parseFloat(item);
  } else if (item.startsWith('{') || item.startsWith('[')) {
    try {
      return JSON.parse(item);
    } catch (error) {
      // If JSON parsing fails, return the original item
      return item;
    }
  } else if (item.startsWith("'") || item.startsWith('"')) {
    return item.slice(1, -1);
  } else if (item === 'null') {
    return null;
  } else {
    return item;
  }
};

export const convertArrayToString = <T>(value: T[]) => {
  if (_isEmpty(value) || Object.keys(value).length === 0) {
    return '';
  }

  return value
    .map((item) => {
      if (typeof item === 'string') {
        if (!isNaN(Number(item))) {
          return `"${item}"`;
        } else {
          return `${item}`;
        }
      } else if (typeof item === 'object' && item !== null) {
        return JSON.stringify(item);
      } else if (item === null) {
        return 'null';
      } else {
        return item;
      }
    })
    .join('|');
};

export const getFieldValueAsArray = (fieldValue: any) => {
  try {
    if (typeof fieldValue === 'string') {
      return convertArrayAsInput(fieldValue);
    }

    return fieldValue;
  } catch (error) {
    return [];
  }
};
export const isArrayAsInputValid = (value: any) => {
  let isArrayValid = true;
  try {
    convertArrayAsInput(value);

    isArrayValid = true;
  } catch (err) {
    isArrayValid = false;
  }

  return isArrayValid;
};

export const convertCaTestValue = <T>(
  dataType: string,
  value: T,
  stringify: boolean
): T | string => {
  if (stringify) {
    if (Array.isArray(value) && dataType === 'list') {
      return convertArrayToString(value);
    }

    return value?.toString() ?? '';
  }

  return value;
};

export const transformSampleValue = (dataType: string, value: any) => {
  if (
    (dataType === 'json' || dataType === 'restAPI') &&
    typeof value === 'object' &&
    !Array.isArray(value)
  ) {
    return JSON.stringify(value ?? {}, null, 2);
  } else if (dataType === 'list' && Array.isArray(value)) {
    return JSON.stringify(value ?? [], null, 2).replaceAll('\\"', '"');
  } else if (dataType === 'list' && !Array.isArray(value)) {
    return `${value ?? ''}`.replaceAll('\\"', '"');
  } else if (dataType === 'string') {
    return value ?? '';
  } else if (['date', 'dateTime'].includes(dataType)) {
    return formatNectedDate(value, dataType);
  }
  return value;
};

/*
updateNullValue -> if its true then do not update the null value in case of string datatype 
 */
export const convertCaSampleValues = (
  dataType: string,
  value?: any,
  updateNullValue: boolean = false
) => {
  let finalValue: any = null;

  if (value === '' || value === null) {
    return dataType === 'string' && !updateNullValue ? '' : null;
  }

  if (dataType === 'json' || dataType === 'object' || dataType === 'list') {
    try {
      const tokens = extractTokens(value);
      if (tokens.length > 0) {
        finalValue = value;
      } else {
        finalValue = JSON.parse(value);
      }
    } catch (error) {
      finalValue = value;
    }
  } else if (dataType === 'restAPI' && typeof value === 'string') {
    finalValue = JSON.parse(value);
  } else if (dataType === 'restAPI' && typeof value === 'object') {
    finalValue = value;
  } else if (dataType === 'numeric') {
    if (!isNaN(value)) {
      finalValue = parseFloat(value);
    }
  } else if (dataType === 'boolean') {
    finalValue = value === true || value === 'true';
  } else if (['date', 'dateTime'].includes(dataType)) {
    finalValue = value;
  } else if (['string'].includes(dataType)) {
    finalValue = value;
  }

  return finalValue;
};

export const getOutputValueParsed = (
  dataType: string,
  value: string | null
) => {
  if (value === null || value === '') {
    return null;
  }

  if (dataType === 'date') {
    if (isValidDate(value)) {
      return value;
    }

    const newDate = new Date(value);

    return formatNectedDate(newDate, 'date');
  }

  return `${value}`;
};

export const extractValueByDataType = (value: any, dataType: string) => {
  if (dataType === 'boolean') {
    return value === 'true';
  } else if (dataType === 'numeric') {
    return parseFloat(value as string);
  } else if (dataType === 'json') {
    try {
      return JSON.stringify(value as string);
    } catch {
      return '{}';
    }
  } else if (dataType === 'boolean') {
    return value === 'true';
  }

  return value;
};

export const filteredObject = (
  myObject: Record<string, any>,
  keysToRemove: string[]
) =>
  Object.keys(myObject)
    .filter((key) => !keysToRemove.includes(key))
    .reduce((result: Record<string, any>, key) => {
      result[key] = myObject[key];

      return result;
    }, {});

const transformTestValue = (source: AttributeModel) => {
  return !_isNil(source.testValue) &&
    !_isEmpty(source.testValue) &&
    _isEmpty(source.sampleValue?.toString())
    ? convertCaTestValue(
        source.dataType?.value ?? 'string',
        source.testValue,
        false
      )
    : convertCaTestValue(
        source.dataType?.value ?? 'string',
        source.sampleValue,
        true
      );
};

export const transformCustomInputs = (data: {
  attributes: AttributeModel[];
}) => {
  let customInput = {};
  data.attributes.forEach((source, index) => {
    customInput = {
      ...customInput,
      [source.name]: {
        ...source,
        name: source.name,
        isNullable: source.isNullable,
        isList: source.isList,
        isOptional: source.isOptional,
        dataType: source.dataType?.value,
        isCaseSensitive: source.isCaseSensitive,
        attribute:
          source.sourceType === 'restAPI'
            ? source.selectedType?.value
            : source.attribute,
        sourceType: source.sourceType,
        selectedType: source.selectedType,
        testValue: transformTestValue(source),
        executedValue: convertCaSampleValues(
          source.dataType?.value ?? 'string',
          source.executedValue
        ),
        sampleValue: convertCaSampleValues(
          source.dataType?.value ?? 'string',
          source.sampleValue
        ),
        config: source.config,
        next: data.attributes[index + 1]?.name ?? '',
        previous: data.attributes[index - 1]?.name ?? '',
      },
    };
  });

  return customInput;
};

export const updateDatasetVariable = (
  localCustomInput: Record<string, any>,
  dataSetVariables: Record<string, Dataset>
): Record<string, Dataset> => {
  if (!_isNil(dataSetVariables) && !_isNil(localCustomInput)) {
    let updateDataSetVariables = JSON.parse(JSON.stringify(dataSetVariables));

    if (!_isNil(updateDataSetVariables.customInput)) {
      updateDataSetVariables.customInput.attributes = localCustomInput;
    }

    if (!_isNil(updateDataSetVariables.dataSet)) {
      updateDataSetVariables = filteredObject(updateDataSetVariables, [
        'dataSet',
      ]);
    }

    return updateDataSetVariables;
  }

  return dataSetVariables;
};

export const flattenObject = (obj: Record<string, any>, depth = 10) => {
  const result: Record<string, any> = {};

  function recurse(
    current: Record<string, any>,
    prop: string,
    currentDepth: number
  ) {
    if (currentDepth <= depth) {
      if (Object(current) !== current || Array.isArray(current)) {
        result[prop] = current;
      } else {
        for (const p in current) {
          if (Object.prototype.hasOwnProperty.call(current, p)) {
            recurse(current[p], `${prop}.${p}`, currentDepth + 1);
          }
        }
      }
    } else {
      result[prop] = current;
    }
  }

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      recurse(obj[key], key, 1);
    }
  }

  return result;
};

export const isValidTokenPresentInLeafNodesV2 = (
  obj: Record<string, any>,
  dataset: Record<string, Dataset>,
  fieldName?: string,
  setError?: UseFormSetError<any>
) => {
  let isSourceValid = true;
  const sourceFlattened = flattenObject(obj);

  Object.keys(sourceFlattened).forEach((key) => {
    const { isValidTokenUsed, message } = checkUsedTokensAreValid(
      sourceFlattened[key] ? sourceFlattened[key].toString() : '',
      dataset
    );

    if (!_isNil(setError) && !_isNil(fieldName) && !isValidTokenUsed) {
      isSourceValid = false;

      setError(`${fieldName}.${key}`, {
        message: 'Invalid token used',
      });
    }
  });

  return isSourceValid;
};

export const isValidTokenPresentInLeafNodes = (
  obj: Record<string, any>,
  tokenArray: Record<string, any>,
  fieldName?: string,
  setError?: UseFormSetError<any>
) => {
  let isSourceValid = true;
  const sourceFlattened = flattenObject(obj);

  Object.keys(sourceFlattened).forEach((key) => {
    const extractedTokens = extractTokens(
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      sourceFlattened[key] ? sourceFlattened[key].toString() : ''
    );
    extractedTokens.forEach((i) => {
      const tokenValue = tokenArray.find(
        (j: any) => j.token.toString() === `${i}`.toString()
      );

      if (_isNil(tokenValue)) {
        isSourceValid = false;

        if (!_isNil(setError) && !_isNil(fieldName)) {
          setError(`${fieldName}.${key}`, {
            message: 'Invalid token used',
          });
        }
      }
    });
  });

  return isSourceValid;
};

export const getTestValuesDefaultDTSR = (
  customAttributes: Record<string, AttributeModel>,
  key: string
) => {
  const valType = typeof customAttributes[key].executedValue;

  if (valType === 'string' || valType === 'boolean') {
    return customAttributes[key].executedValue;
  }

  return customAttributes[key].executedValue !== '' &&
    !_isNil(customAttributes[key].executedValue)
    ? transformSampleValue(
        customAttributes[key].selectedType?.value ?? 'string',
        customAttributes[key].executedValue
      )
    : getDefaultValuesForTest(
        customAttributes[key].dataType?.value ?? 'string'
      );
};

export const getTestValuesDefaultRuleSet = (k: CustomAttributeByRuleId) => {
  if (typeof k.testValue === 'string' && k.dataType === 'boolean') {
    return k.testValue === 'true';
  }

  return !_isEmpty(k.testValue) && !_isNil(k.testValue)
    ? k.testValue
    : getDefaultValuesForTest(k.dataType ?? 'string');
};

export const isValidImageURL = (url: string) => {
  let absoluteURL = '';

  if (!_isNil(url) && !_isEmpty(url)) {
    try {
      // eslint-disable-next-line no-new
      new URL(url);
      absoluteURL = url;
    } catch (error) {
      let constructedURL = '';
      if (url.includes('assets')) {
        constructedURL = `${window.location.origin}/${url}`;
        absoluteURL;
      } else if (url.includes('nalanda')) {
        if (deploymentType === 'onpremise') {
          constructedURL = `${window.location.origin}${
            envMap.VITE_ASSETS_URL as string
          }${url}`;
        } else {
          constructedURL = `${envMap.VITE_ASSETS_URL}${url}`;
        }
      } else {
        constructedURL = `${envMap.VITE_ASSETS_URL as string}${url}`;
      }
      try {
        absoluteURL = `${new URL(constructedURL).origin}${new URL(
          constructedURL
        ).pathname.replace(/\/\/+/g, '/')}`;
      } catch (error) {
        absoluteURL = constructedURL.replace(/\/\/+/g, '/');
      }
    }
  }

  return absoluteURL;
};

export const getPropertyIfExists = (obj: any, path: string): any => {
  let tempObj: any | any[] = obj;

  try {
    const keys = path.split(/\[|\]\.?|\./).filter(Boolean);

    for (const key of keys) {
      if (tempObj && Array.isArray(tempObj)) {
        const index = parseInt(key, 10);
        tempObj = tempObj[index];
      } else if (tempObj && typeof tempObj === 'object') {
        tempObj = tempObj[key];
      } else {
        return undefined;
      }
    }
  } catch {}

  return tempObj;
};

export const generateCaptchaToken = (
  callback: (payload: any) => void,
  payload: any
) => {
  if (
    !_isNil(window.grecaptcha) &&
    !_isNil(callback) &&
    !_isNil(envMap.VITE_RECAPTCHA_SITE_KEY) &&
    !_isEmpty(envMap.VITE_RECAPTCHA_SITE_KEY)
  ) {
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(envMap.VITE_RECAPTCHA_SITE_KEY, { action: 'submit' })
        .then(function (token: any) {
          localStorage.setItem('X-CSRF-Token', token);
          callback(payload);
        })
        .catch((error: any) => {
          callback(payload);
        });
    });
  } else {
    callback(payload);
  }
};

export const getNectedDatatype = (returnType: string) => {
  if (returnType.includes('array')) {
    return 'list';
  } else if (returnType === 'object') {
    return 'json';
  } else if (returnType === 'number') {
    return 'numeric';
  }

  return returnType;
};

export const CookieConsentConfig = () =>
  CookieConsent.run({
    revision: 0,
    cookie: {
      name: 'cc_cookie',
      domain:
        envMap.VITE_ENVIRONMENT === 'development' ? '.nected.io' : '.nected.ai',
      path: '/',
      expiresAfterDays: 182,
      sameSite: 'Lax',
      useLocalStorage: false,
    },
    guiOptions: {
      consentModal: {
        layout: 'box inline',
        position: 'bottom right',
        equalWeightButtons: true,
        flipButtons: true,
      },
      preferencesModal: {
        layout: 'box',
        position: 'right',
        equalWeightButtons: true,
        flipButtons: false,
      },
    },
    categories: {
      necessary: {
        readOnly: true,
      },
      analytics: {
        enabled: true,
      },
      marketing: {
        enabled: true,
      },
    },
    language: {
      default: 'en',
      autoDetect: 'browser',
      translations: {
        en: {
          consentModal: {
            title: 'Cookie Settings',
            description:
              'By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage and assist in our marketing efforts.',
            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Reject all',
            showPreferencesBtn: 'Manage preferences',
            // footer: `<a href=${envMap.VITE_PP_URL}>Privacy Policy</a>\n<a href=${envMap.VITE_TANDC_URL}>Terms and conditions</a>`,
          },
          preferencesModal: {
            title: 'Consent Preferences Center',
            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Reject all',
            savePreferencesBtn: 'Save preferences',
            closeIconLabel: 'Close modal',
            serviceCounterLabel: 'Service|Services',
            sections: [
              {
                title: 'Cookie Usage',
                description:
                  'By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage and assist in our marketing efforts.',
              },
              {
                title:
                  'Strictly Necessary Cookies <span class="pm__badge">Always Enabled</span>',
                description:
                  'Cookies required to enable basic website functionality.',
                linkedCategory: 'necessary',
              },
              {
                title: 'Analytics Cookies',
                description:
                  'Cookies used to deliver advertising that is more relevant to you and your interests.',
                linkedCategory: 'analytics',
              },
              {
                title: 'Advertisement Cookies',
                description:
                  'Cookies allowing the website to remember choices you make (such as your user name, language, or the region you are in).',
                linkedCategory: 'marketing',
              },
              {
                title: 'More information',
                description:
                  'For any query in relation to my policy on cookies and your choices, please <a class="cc__link" href="mailto:assist@nected.ai">contact us</a>.',
              },
            ],
          },
        },
      },
    },
    onFirstConsent: ({ cookie }: any) => {
      if (cookie.services.analytics.length < 3) {
        window.location.reload();
      }
    },
  });

export const getReplacedItem = (str: string, item: string, replace: string) => {
  return str.replace(item, replace);
};

export const constructEventPayload = (
  eventName: string,
  eventType: string,
  entity: string,
  entityId: string,
  payload: Record<string, any>
) => {
  return {
    event: eventName, // event name
    entityType: eventType, // sr
    entity: entity, // rule
    entityId: entityId, // can be empty
    meta: JSON.parse(localStorage.getItem('source') ?? '{}'),
    payload: payload,
    workspaceId: localStorage.getItem('workspaceUUID') ?? '',
    userId: localStorage.getItem('userUUID') ?? '',
    eventTime: Math.floor(Date.now() / 1000).toString(),
  };
};

export const getFieldsByRow = (row: Record<string, any>) => {
  return Object.keys(row).map((item, i) => ({
    columnName: item,
    order: i + 1,
    dataType: getNectedDatatype(row[item]),
  }));
};

export const checkDateValue = (value: string | Date): Date => {
  if (value instanceof Date) {
    return value;
  } else if (typeof value === 'string') {
    const parsedDate = Date.parse(value);
    if (!isNaN(parsedDate)) {
      return new Date(value);
    }
  }
  return value as unknown as Date;
};

export function unionStringArrays(
  arr1: string[] = [],
  arr2: string[] = []
): string[] {
  const unionSet = new Set([...arr1, ...arr2]);

  const unionArray = Array.from(unionSet);
  return unionArray;
}

export const checkLimitExceeded = (
  entity: string,
  plan: SubscriptionPlanType | null
): boolean => {
  if (!_isNil(plan) && !plan.plan.isPaid) {
    if (
      (entity === 'rule' || entity === 'rules') &&
      plan.plan.ruleLimit !== 0
    ) {
      return plan.usage.rule >= plan.plan.ruleLimit;
    }
    if (
      (entity === 'workflow' || entity === 'workflows') &&
      plan.plan.workflowLimit !== 0
    ) {
      return plan.usage.workflow >= plan.plan.workflowLimit;
    }

    if (entity === 'editor' && plan.plan.editorLimit !== 0) {
      if (plan.plan.isPaid) {
        return false;
      } else {
        return plan.usage.editor >= plan.plan.editorLimit;
      }
    }
  }
  return false;
};

export const getThresholdStatusColorTextByState = (
  current: number,
  max: number,
  entity: string
) => {
  const percentage = Math.round((current / max) * 100);

  if (percentage >= 100) {
    return {
      state: 'danger',
      color: 'var(--color-fireEngineRed)',
      text: `${current}/${max} ${entity.toLocaleLowerCase()} used`,
      percentage,
    };
  }
  if (percentage > 80) {
    return {
      state: 'danger',
      color: 'var(--color-fireEngineRed)',
      text: `${max - current} ${entity.toLocaleLowerCase()} remaining`,
      percentage,
    };
  } else if (percentage > 70) {
    return {
      state: 'warning',
      color: 'orange',
      text: `${max - current} ${entity.toLocaleLowerCase()} remaining`,
      percentage,
    };
  } else {
    return {
      state: 'neutral',
      color: 'var(--color-black)',
      text: `${max - current} ${entity.toLocaleLowerCase()} remaining`,
      percentage,
    };
  }
};

export const showUpgradeLinks = (
  current: number,
  max: number,
  entity: string
) => {
  const percentage = Math.round((current / max) * 100);

  if (percentage > 85) {
    return true;
  }

  return false;
};

export const shortenName = (name: string, length: number) => {
  if (name.length > length) {
    return name.slice(0, length - 3) + '...';
  }
  return name;
};

export const sleep = (delay: number) =>
  new Promise((resolve) => setTimeout(resolve, delay));

export const isOnboardingCompleted = (stateName: string) => {
  const obstate = window.localStorage.getItem('obstate');

  if (!_isNil(obstate) && window.atob(obstate) !== 'undefined') {
    const obstateData = JSON.parse(window.atob(obstate) ?? '{}');

    if (obstateData[stateName] === true) {
      return true;
    } else {
      return false;
    }
  }

  return false;
};

export const getSubTasks = (
  tasksObj: Record<string, any>[],
  subTaskId: string
) => {
  const subTasks = tasksObj.find((task) => task.id === subTaskId);
  return subTasks;
};

export const updateSubTaskCompletion = (
  obj: OnboardingDataProps,
  completedTaskObj: CompletedTaskProps[],
  firstEntityData: Record<string, any>
) => {
  // Helper function to create rule path
  const createRulePath = () => {
    const ruleData = firstEntityData.rule.getFirstRule.data[0];
    return `/rules/${ruleData.id}?type=edit&stage=staging&ruleType=${
      ruleData.type
    }&wsid=${window.sessionStorage.getItem('workspaceUUID')}`;
  };

  // Helper function to create workflow path
  const createWorkflowPath = () => {
    const workflowData = firstEntityData.workflow.getFirstWorkflow.data[0];
    return `/workflow/${
      workflowData.id
    }?type=edit&stage=staging&wsid=${window.sessionStorage.getItem(
      'workspaceUUID'
    )}`;
  };

  // Iterate through each key (todo task) in the object
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const todo = obj[key as keyof OnboardingDataProps];

      // Update rulePath and workflowPath based on todo type and first entity data
      let rulePath = '/rules?nected_action=open_create_rule_popup';
      let workflowPath = `/workflow?type=create&stage=staging&workflowTrigger=api&wsid=${window.sessionStorage.getItem(
        'workspaceUUID'
      )}`;

      if (todo.id === 'createRule' || todo.path === '/rules') {
        if (
          !_isNil(firstEntityData.rule) &&
          firstEntityData.rule.getFirstRule.data.length > 0
        ) {
          rulePath = createRulePath();
        }
      }

      if (todo.id === 'createWorkflow' || todo.path === '/workflow') {
        if (
          !_isNil(firstEntityData.workflow) &&
          firstEntityData.workflow.getFirstWorkflow.data.length > 0
        ) {
          workflowPath = createWorkflowPath();
        }
      }

      // Update todo.isCompleted if all subtasks are completed
      if (todo.subTasks.every((subTask: SubTasks) => subTask.isCompleted)) {
        todo.isCompleted = true;
      }

      // Update subtask paths and isCompleted status based on matching completedTaskObj
      todo.subTasks.forEach((subTask: SubTasks) => {
        if (subTask.path === '/rules') {
          subTask.path = firstEntityData.rule.getFirstRule.data.length
            ? createRulePath()
            : rulePath;
        }
        if (subTask.path === '/workflow') {
          subTask.path =
            firstEntityData.workflow.getFirstWorkflow.data.length > 0
              ? createWorkflowPath()
              : workflowPath;
        }

        const matchingSubTask = completedTaskObj.find(
          (item) => item.id === subTask.id
        );

        if (matchingSubTask) {
          subTask.isCompleted = matchingSubTask.value;
        }
      });
    }
  }

  return obj;
};

export const formatTriggerAttributesForTest = (
  customAttributes: Record<string, any>,
  executedValue: Record<string, any>,
  key: string
) => {
  if (customAttributes[key].dataType === 'json') {
    return JSON.stringify(executedValue[key]);
  } else if (customAttributes[key].dataType === 'list') {
    return (
      customAttributes[key].sampleValue ?? customAttributes[key].executedValue
    );
  } else if (customAttributes[key].dataType === 'boolean') {
    return executedValue[key] === true || executedValue[key] === 'true';
  }

  return `${executedValue[key]}`;
};

export function arrayMove(array: any[], oldIndex: number, newIndex: number) {
  const old = array[oldIndex];
  const newEl = array[newIndex];

  let nArray = structuredClone(array);

  nArray[newIndex] = old;
  nArray[oldIndex] = newEl;

  return nArray;
}

export const checksumMessage = 'entity is currently being modified';

export const isCorrectJsSyntaxV2 = (
  result: string,
  dataSet: Record<string, Dataset>
): { status: boolean; message: string } => {
  if (!_isEmpty(result)) {
    const newStr = sanitizedStringV2(result, dataSet);

    const outputString = newStr.replaceAll(
      REMOVE_JAVASCRIPT_COMMENTS_REGEX,
      ''
    );
    try {
      // eslint-disable-next-line -- We're disabling the next line for checking validity of the code
      eval(outputString);

      return { status: true, message: '' };
    } catch (error: any) {
      let errorMessage = error.message as string;
      if (errorMessage.includes('Illegal return statement')) {
        errorMessage =
          'Dont need to add return explicitly in last statement that you need to return.';
      }

      const keywords = [
        'BASE64_ENCODE',
        'BASE64_DECODE',
        'AES_ENCRYPT',
        'AES_DECRYPT',
        'SUM_LIST',
        'AVG_LIST',
        'MIN_LIST',
        'MAX_LIST',
        'SUM',
        'AVG',
        'COUNT',
        'MIN',
        'MAX',
        'FILTER',
        'LIMIT',
        'DISTINCT',
        'SORT',
        'SQRT',
        'POW',
        'PMT',
        'PV',
        'EMI',
        'DATEDIFF',
        'DATECOMPUTE',
        'DATEFORMAT',
        'DATEPARSE',
        'NOW',
        'TODAY',
        'YEAR',
        'MONTH',
        'WEEK',
        'DAY',
        'HOUR',
        'MINUTE',
        'SECOND',
      ];

      if (
        keywords.some((keyword) => errorMessage.toUpperCase().includes(keyword))
      ) {
        return { status: true, message: '' };
      }

      return { status: false, message: errorMessage };
    }
  }

  return { status: true, message: '' };
};

export const getRedirectUrl = (inputUrl: string) => {
  try {
    if (inputUrl.includes('redirect')) {
      let url = inputUrl.split('redirect=');
      if (!_isNil(url[1])) {
        return url[1];
      } else {
        return null;
      }
    }
    return null;
  } catch (error) {
    console.error('Invalid URL:', error);
    return null;
  }
};

/**
 * Updates connector mappings for entity (rules/workflow)
 * @param mappedConnectorsDataFromAPI - API data of connectors attached to entity
 * @param usedConnectorMapping - Object of used connectors in entity
 * @returns Updated connector mapping or empty object
 */
export const getUpdatedUsedConnectorsMapping = (
  mappedConnectorsDataFromAPI: Record<string, any>,
  usedConnectorMapping: UsedConnectorMappingInEntityType
): UsedConnectorMappingInEntityType => {
  if (!usedConnectorMapping) return {};

  // Extract data with default to empty array
  const connectors = mappedConnectorsDataFromAPI.getConnector?.data || [];

  // find object for connectors
  const connectorMap = Object.fromEntries(
    connectors.map((connector: { id: any }) => [connector.id, connector])
  );

  // Clone map
  const updatedMapping = structuredClone(usedConnectorMapping);

  // Update statuses
  for (const key of Object.keys(updatedMapping)) {
    const connector = connectorMap[key];
    updatedMapping[key].status = connector
      ? connector.staging?.isTested && connector.staging?.isPublish
      : false;
  }

  return updatedMapping;
};

// For Matching and Non-Matching Rows.
export const createResultDataset = (
  dataSet: Record<string, Dataset>
): Record<string, Dataset> => {
  const objectMap: Record<string, any> = {};

  Object.keys(dataSet).forEach((i) => {
    objectMap[i] = {
      tooltip: dataSet[i].tooltip,
      footer: dataSet[i].footer,
    };
  });

  const updatedDataSet = JSON.parse(JSON.stringify(dataSet));

  return Object.keys(updatedDataSet).reduce((acc, curr) => {
    return {
      ...acc,
      [curr]: {
        ...updatedDataSet[curr],
        ...objectMap[curr],
      },
    };
  }, {});
};

// Need to remove post diagnosis
export const SocialLoggerConfig = {
  url: 'https://nected-79.nected.io/nected/rule/668694b439dc7581ea450ab3',
  payload: {
    environment: 'production',
    params: {
      loginDetails: {
        fid: '',
        location: '',
        mode: '',
        state: 'INIT',
        timeStamp: '',
        email: '',
      },
      fid: '',
    },
  },
};

export const logEvent = async (state: string, mode: string, email?: string) => {
  const source: Record<string, string> = JSON.parse(
    window.localStorage.getItem('source') ?? '{}'
  );
  const apiConfig: Record<string, any> = { ...SocialLoggerConfig };
  if (_isNil(window.localStorage.getItem('nec_fid'))) {
    window.localStorage.setItem('nec_fid', createUUID());
  }
  const fid = window.localStorage.getItem('nec_fid');
  apiConfig.payload.params.loginDetails = {
    fid,
    mode,
    state,
    timeStamp: dateFormat(new Date(), 'dd/mm/yyyy HH:MM:ss'),
    path: window.location.href,
    email: email ?? '',
    country: source.utm_country ?? '',
    source: JSON.stringify(source),
  };
  apiConfig.payload.params.fid = fid;
  if (envMap.VITE_ENVIRONMENT === 'production') {
    await axiosVidhanPrivate.post(apiConfig.url, apiConfig.payload);
  }
};

// Need to remove post diagnosis

export const slugify = (str: string) => {
  return String(str)
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
};

export const isArrayNotPresent = (val: any) => {
  if (typeof val === 'object') {
    if (Array.isArray(val)) {
      return val.length === 0;
    } else {
      return Object.keys(val ?? {}).length === 0;
    }
  }

  if (typeof val === 'string') {
    try {
      const parsedValue = JSON.parse(val);
      return Object.keys(parsedValue).length === 0;
    } catch {}
  }

  return !val;
};

export const getEntityTypeForUI = (type: string) => {
  switch (type) {
    case 'rules':
    case 'rule':
      return 'rule';
    case 'datasets':
    case 'dataSet':
      return 'datasource';
    case 'workflow':
      return 'workflow';
    case 'connector':
    case 'integrations':
      return 'integration';
    case 'remoteconfig':
    case 'variable':
      return 'global variable';
    case 'module_schema':
      return 'Attribute Library';
    default:
      return '';
  }
};

export const getMethodNameForApi = (type: string) => {
  switch (type) {
    case 'rules':
    case 'rule':
      return 'getRule';
    case 'datasets':
      return 'getDataSet';
    case 'workflow':
      return 'getWorkflow';
    default:
      return null;
  }
};

export const getMethodNameForMutationApi = (type: string) => {
  switch (type) {
    case 'rules':
    case 'rule':
      return 'createRule';
    case 'datasets':
      return 'createDataSet';
    case 'workflow':
      return 'createWorkflow';
    default:
      return null;
  }
};

export const generateEntityLink = ({
  entityId,
  entityType,
  category = '',
  version = '',
}: GenerateEntityLinkArgsType) => {
  let link = '';
  const wsid = window.sessionStorage.getItem('workspaceUUID');

  if (entityType === 'rule' && !_isNil(wsid) && !_isEmpty(wsid)) {
    link = `/rules/${entityId}?stage=staging&ruleType=${category}&wsid=${wsid}`;

    if (!_isNil(version) && !_isEmpty(version) && version !== 'draft') {
      link += `&type=view&isLive=true`;

      if (version !== 'live') {
        link += `&version=${version}`;
      }
    } else {
      link += `&type=edit`;
    }
  } else if (entityType === 'workflow' && !_isNil(wsid) && !_isEmpty(wsid)) {
    link = `/workflow/${entityId}?stage=staging&wsid=${wsid}`;

    if (!_isNil(version) && !_isEmpty(version) && version !== 'draft') {
      link += `&type=view&isLive=true`;

      if (version !== 'live') {
        link += `&version=${version}`;
      }
    } else {
      link += `&type=edit`;
    }
  } else if (entityType === 'dataSet') {
    link = `/datasets/${entityId}?stage=staging&editor=${category}`;

    if (!_isNil(version) && !_isEmpty(version) && version !== 'draft') {
      link += `&type=view&isLive=true`;

      if (version !== 'live') {
        link += `&version=${version}`;
      }
    } else {
      link += `&type=edit`;
    }
  } else if (entityType === 'connector') {
    link = `/integrations/${entityId}?stage=staging&connector=${category}`;
  } else if (entityType === 'variable') {
    link = `/remoteconfig`;
  }

  return link;
};

export const SITE_META: Record<string, any> = {
  home: {
    title: 'Home | Nected',
    descripition: '',
  },
  rules: {
    title: 'Rules | Nected',
    descripition: '',
  },
  workflow: {
    title: 'Workflow | Nected',
    descripition: '',
  },
  onpremise: {
    title: 'On Premise | Nected',
    descripition: '',
  },
  auditlogs: {
    title: 'Audit Trail | Nected',
    descripition: '',
  },
  credentials: {
    title: 'Authorization | Nected',
    descripition: '',
  },
  integrations: {
    title: 'Integrations | Nected',
    descripition: '',
  },
  datasets: {
    title: 'Data Sources | Nected',
    descripition: '',
  },
  remoteconfig: {
    title: 'Global Variables | Nected',
    descripition: '',
  },
  'workspace/settings': {
    title: 'Workspace Settings | Nected',
    descripition: '',
  },
  workspace: {
    title: 'Workspace | Nected',
    descripition: '',
  },
  'workspace/team': {
    title: 'Workspace Team | Nected',
    descripition: '',
  },
  guides: {
    title: 'Video Guides | Nected',
    descripition: '',
  },
  questionnaire: {
    title: 'Questionnaire | Nected',
    descripition: '',
  },
  signin: {
    title: 'Signin | Nected',
    descripition: '',
  },
  signup: {
    title: 'Signup | Nected',
    descripition: '',
  },
  'forgot-password': {
    title: 'Forgot Password | Nected',
    descripition: '',
  },
  'reset-password': {
    title: 'Reset Password | Nected',
    descripition: '',
  },
  module: {
    title: 'Attribute Library | Nected',
    descripition: '',
  },
  customapp: {
    title: 'Apps | Nected',
    descripition: '',
  },
};

export const handleChangeApiToWebhookUrl = (
  url: string,
  env: string,
  version?: string
) => {
  let urlNew = '';
  if (_isNil(url) || url === '') {
    return urlNew;
  }

  try {
    const list = url.split('https://');
    const items = list[1].split('/');
    items.splice(2, 0, env);
    items.splice(2, 0, 'webhook');
    urlNew = 'https://' + items.join('/');

    if (!_isNil(version) && !_isEmpty(version) && env === 'production') {
      urlNew += `/${version}`;
    }
  } catch (error) {}

  return urlNew;
};

export const getRuleNameByNodeType = (nodeType: string) => {
  switch (nodeType) {
    case 'srNode':
      return 'SimpleRule';
    case 'dtNode':
      return 'Decision Table';
    case 'ruleSetNode':
      return 'RuleSet Node';
  }

  return '';
};

export const getRuleTypeByNodeType = (nodeType: string) => {
  switch (nodeType) {
    case 'srNode':
      return 'simpleRule';
    case 'dtNode':
      return 'decisionTable';
    case 'ruleSetNode':
      return 'ruleSet';
  }

  return '';
};

export const isFieldReadOnly = (dataType: string) => {
  return dataType === 'date' || dataType === 'dateTime';
};

export function stringifyWithoutQuotes(obj: Record<string, any>) {
  let str = '';

  // Helper function to recursively build the string representation of the object
  function processObject(o: Record<string, any>) {
    let result = '';
    for (const key in o) {
      if (o.hasOwnProperty(key)) {
        const value = o[key];
        // If the value is an object, recursively process it
        if (typeof value === 'object' && value !== null) {
          result += `${key}: ${processObject(value)}, `;
        } else if (typeof value === 'string') {
          // If the value is a string, add it with quotes
          result += `${key}: "${value}", `;
        } else {
          // Otherwise, just add the value without quotes
          result += `${key}: ${value}, `;
        }
      }
    }
    // Remove the last comma and space, then wrap in curly braces
    return `{ ${result.slice(0, -2)} }`;
  }

  str = processObject(obj);
  return str;
}
export const getDataTypesByAllowedTypes = (dataType: string): any => {
  switch (dataType) {
    case 'number':
    case 'integer':
    case 'bigint':
      return ['number', 'numeric', 'bigint'];

    case 'object':
    case 'json':
      return ['object', 'json'];

    case 'array':
    case 'list':
      return ['array', 'list'];
    default:
      return [dataType];
  }
};

export const getDataTypeByAllowedTypes = (dataType: string): any => {
  switch (dataType) {
    case 'number':
    case 'integer':
    case 'bigint':
      return 'numeric';
    default:
      return dataType;
  }
};

export const getDatatypeForUI = (dataType: string) => {
  switch (dataType) {
    case 'numeric':
      return 'number';
    default:
      return dataType;
  }
};

export const extractSourceAndAttributeFromValue = (
  value: string | null | undefined,
  dataset: Record<string, Dataset>
) => {
  if (_isNil(value)) {
    return {
      source: undefined,
      attribute: undefined,
    };
  }
  const { isValidTokenUsed } = checkUsedTokensAreValid(value, dataset);

  if (!isValidTokenUsed) {
    return {
      source: undefined,
      attribute: undefined,
    };
  }

  const tokens = (typeof value === 'string' ? value : '')
    .match(EXTRACT_TOKEN_REGEX)
    ?.map((token) => {
      const newToken = token.replace('{{.', '').replace('}}', '');

      return {
        source: newToken.split('.')[0],
        attribute: newToken.split('.').splice(1).join('.'),
      };
    });

  if (!_isNil(tokens)) {
    return tokens[0];
  }

  return {
    source: undefined,
    attribute: undefined,
  };
};

export const getInitalValueifRelativeFieldActive = (obj: any) => {
  if (!_isUndefined(obj) && !_isUndefined(obj.value)) {
    if (
      typeof obj.value === 'object' &&
      !_isNil(obj.value) &&
      !Array.isArray(obj.value)
    ) {
      return true;
    }
  }

  return false;
};

export const validateResponseCacheError = (
  cache: Record<string, any>,
  dataset: Record<string, Dataset>
) => {
  let isValid = true;
  const errors = {
    enabled: {
      isValid: true,
      message: 'Invalid response cache',
    },
    duration: {
      isValid: false,
      message: 'Invalid response duration',
    },
    cacheKeys: {
      isValid: true,
      message: 'Invalid parameters mapped',
    },
  };

  if (cache.enabled === false || Object.keys(cache).length === 0) {
    return { isValid, errors };
  }

  try {
    const duration = parseInt(cache.duration?.value);
    if (
      !Number.isNaN(duration) &&
      cache.duration?.value !== '' &&
      duration >= 0
    ) {
      if (
        (cache?.duration?.unit === 'd' ||
          cache?.duration?.unit?.value === 'd') &&
        duration > 30
      ) {
        errors.duration.isValid = false;
        errors.duration.message = 'Duration cannot be more than 30 days';
        isValid = false;
      } else if (
        (cache?.duration?.unit === 'h' ||
          cache?.duration?.unit?.value === 'h') &&
        duration > 720
      ) {
        errors.duration.isValid = false;
        errors.duration.message = 'Duration cannot be more than 720 hours';
        isValid = false;
      } else if (
        (cache?.duration?.unit === 'm' ||
          cache?.duration?.unit?.value === 'm') &&
        duration > 43200
      ) {
        errors.duration.isValid = false;
        errors.duration.message = 'Duration cannot be more than 43200 minutes';
        isValid = false;
      } else {
        errors.duration.isValid = true;
      }
    } else {
      isValid = false;
    }
  } catch {
    isValid = false;
  }

  const updatedQuery = `{"array": ${cache.cacheKeys?.split(',') ?? ''}}`;
  checkUsedTokensAreValid(updatedQuery, dataset);

  const isTokenValid = checkUsedTokensAreValid(updatedQuery, dataset);

  errors.cacheKeys.isValid = isTokenValid.isValidTokenUsed;
  if (!isTokenValid.isValidTokenUsed) {
    isValid = isTokenValid.isValidTokenUsed;
  }

  return { isValid, errors };
};

export const transformResponseCache = (
  cache?: Record<string, any>,
  keepUnit: boolean = false
) => {
  const cachedResponse = {
    enabled: !!cache?.enabled,
    duration: {
      unit: !keepUnit
        ? cache?.duration?.unit?.value ?? 'd'
        : cache?.duration?.unit ?? timeToExpireUnits[0],
      value:
        !_isNil(cache?.duration?.value) && !_isEmpty(cache?.duration?.value)
          ? cache?.duration?.value
          : 0,
    },
    cacheKeys: cache?.cacheKeys ?? '',
  };

  try {
    const duration = parseInt(cache?.duration?.value);
    if (!Number.isNaN(duration)) {
      cachedResponse.duration.value = duration;
    }
  } catch {}

  return cachedResponse;
};

export const transformResponseCacheForWorkflow = (
  cache?: Record<string, any>
) => {
  const cachedResponse = {
    cacheEnabled: !!cache?.enabled,
    durationValue:
      !_isNil(cache?.duration?.value) && !_isEmpty(cache?.duration?.value)
        ? cache?.duration?.value
        : 0,
    durationUnit:
      !_isNil(cache?.duration?.unit?.value) &&
      !_isEmpty(cache?.duration?.unit?.value)
        ? cache?.duration?.unit?.value
        : 0,
    cacheKeys: cache?.cacheKeys ?? '',
  };

  try {
    const duration = parseInt(cache?.duration?.value);
    if (!Number.isNaN(duration)) {
      cachedResponse.durationValue = duration;
    }
  } catch {}

  return cachedResponse;
};

export const formatWorkflowAttribute = (
  data: WorkSpaceDetailsType,
  hideTheme: boolean = false
) => {
  const { theme, ...rest } = data;
  const dataToBeUpdated: Record<string, any> = { ...rest, theme: {} };

  if (!_isNil(theme) && !hideTheme) {
    Object.keys(theme).forEach((k) => {
      if (k === 'baseFontFamily') {
        dataToBeUpdated['theme'][k] = theme[k]?.value ?? 'Lato';
      } else if (k === 'baseFontSize') {
        const value = theme[k] ?? 8;

        try {
          const v = parseInt(value, 10);
          if (!Number.isNaN(v)) {
            dataToBeUpdated['theme'][k] = v;
          } else {
            dataToBeUpdated['theme'][k] = 8;
          }
        } catch (error) {
          if (typeof value === 'number') {
            dataToBeUpdated['theme'][k] = value;
          } else {
            dataToBeUpdated['theme'][k] = value;
          }
        }
      } else if (k === 'logoUrl' || k === 'favicon') {
        if (typeof theme[k] !== 'string') {
          dataToBeUpdated['theme'][k] = '';
        } else {
          dataToBeUpdated['theme'][k] = theme[k];
        }
      } else {
        dataToBeUpdated['theme'][k] = theme[k];
      }
    });
  } else if (hideTheme) {
    dataToBeUpdated['theme'] = null;
  }

  return dataToBeUpdated;
};

export const transformNectedWorkspaceThemeData = (
  theme: Record<string, any>
) => {
  const updatedTheme: Record<string, any> = {};
  Object.keys(theme).forEach((key) => {
    if (!_isNil(theme[key]) && key === 'baseFontFamily') {
      if (typeof theme[key] === 'object') {
        updatedTheme[key] = theme[key];
      } else {
        updatedTheme[key] = {
          label: theme[key] ?? 'Lato',
          value: theme[key] ?? 'Lato',
        };
      }
    } else {
      updatedTheme[key] = theme[key];
    }
  });
  return updatedTheme;
};

export const removeOuterBrackets = (str: any): string => {
  // Check if input is a string
  if (typeof str !== 'string') {
    return '';
  }

  // Check if the string starts with "[" and ends with "]"
  if (str?.startsWith('[') && str?.endsWith(']')) {
    // Remove the first and last character (i.e., the square brackets)
    return str.slice(1, -1);
  }

  return str; // Return the string as-is if it doesn't have outer brackets
};

function isValidHexColor(hex: string) {
  return HEX_CODE_REGEX.test(hex);
}

export const validateWorkspaceSettings = (
  settings: Record<string, any>,
  setError: UseFormSetError<any>
) => {
  let isValid = true;

  settings.theme?.colors?.primary?.forEach((color: any, i: number) => {
    if (!isValidHexColor(color.value)) {
      setError(`theme.colors.primary.${i}.value`, {
        message: 'incorrect color value used',
      });

      isValid = false;
    }
  });

  if (settings.theme?.baseFontSize < 8 || settings.theme?.baseFontSize > 12) {
    setError(`theme.baseFontSize`, {
      message: 'Base font size must be between 8 and 12 pixels',
    });

    isValid = false;
  }

  return isValid;
};

export const checkIfThemeEnabled = (backend?: boolean, env?: boolean) => {
  if (env === true) {
    return true;
  }

  return backend;
};

export const convertModuleDataIntoDataset = (data: any) => {
  if (_isNil(data) || _isEmpty(data)) {
    return {};
  }

  return data.reduce(
    (
      finalDataset: Record<string, Dataset>,
      currentModule: any,
      index: number
    ) => {
      return {
        ...finalDataset,
        [currentModule.id]: {
          id: currentModule.id,
          name: currentModule.name,
          order: index,

          attributes: currentModule.schemas.data.reduce(
            (
              finalAttributes: Record<string, Attributes>,
              currAttribute: any
            ) => {
              return {
                ...finalAttributes,
                [currAttribute.name]: {
                  name: currAttribute.name,
                  id: currAttribute.id,
                  dataType: currAttribute.dataType,
                  usageType: currAttribute.usageType,
                  options: currAttribute.options,
                  executedValue: currAttribute.dataType === 'json' ? {} : null,
                },
              };
            },
            {}
          ),
        },
      };
    },
    {}
  );
};

export const useGetQuestionnaire = async (axiosPrivate: any) => {
  if (!_isNil(window.sessionStorage.getItem('questionnaireData'))) {
    return {
      data: JSON.parse(
        window.sessionStorage.getItem('questionnaireData') ?? '[]'
      ),
      isLoading: false,
    };
  } else {
    try {
      const response = await axiosPrivate.get(`/user/signup/questionnaire`);
      if (response?.data?.data?.pages) {
        window.sessionStorage.setItem(
          'questionnaireData',
          JSON.stringify(response.data.data.pages)
        );
        return {
          data: response.data.data.pages,
          isLoading: false,
        };
      }
      return {
        data: [],
        isLoading: false,
      };
    } catch (error) {
      return {
        data: [],
        isLoading: false,
      };
    }
  }
};

export const setDateAndTZinContext = (payload: Record<string, any>) => {
  window.sessionStorage.setItem(
    'nected-tz',
    payload.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  window.sessionStorage.setItem('nected-df', payload.dateFormat || 'in');
};

export const isValidDateorDateTime = (
  inputString: string,
  from = ''
): DateValidationResult => {
  // Get user preference for date format
  const globalDateSettings = window.sessionStorage.getItem('nected-df') ?? 'in';
  const isUSFormat = globalDateSettings === 'us';

  // Regular expressions for different date formats
  // Standard Date Formats
  const dateFormat1 = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/; // mm/dd/yyyy or dd/mm/yyyy
  const dateFormat2 = /^(\d{4})\/(\d{1,2})\/(\d{1,2})$/; // yyyy/mm/dd
  const dateFormat3 = /^(\d{1,2})-(\d{1,2})-(\d{4})$/; // mm-dd-yyyy or dd-mm-yyyy
  const dateFormat4 = /^(\d{4})-(\d{1,2})-(\d{1,2})$/; // yyyy-mm-dd

  // Standard DateTime Formats
  const dateTimeFormat1 =
    /^(\d{1,2})\/(\d{1,2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})$/; // mm/dd/yyyy HH:mm:ss or dd/mm/yyyy HH:mm:ss
  const dateTimeFormat2 =
    /^(\d{4})\/(\d{1,2})\/(\d{1,2}) (\d{2}):(\d{2}):(\d{2})$/; // yyyy/mm/dd HH:mm:ss
  const dateTimeFormat3 =
    /^(\d{4})-(\d{1,2})-(\d{1,2}) (\d{2}):(\d{2}):(\d{2})$/; // yyyy-mm-dd HH:mm:ss
  const dateTimeFormat4 =
    /^(\d{1,2})-(\d{1,2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})$/; // mm-dd-yyyy HH:mm:ss or dd-mm-yyyy HH:mm:ss

  // ISO and Timezone Formats with nanosecond support
  const dateTimeTZFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/; // yyyy-MM-ddTHH:mm:ssZ
  const dateTimeTZMilliSecFormat =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,9})?Z$/; // yyyy-MM-ddTHH:mm:ss.aaaaaaaaaZ (supports nanoseconds)
  const rfc3339Format =
    /^(\d{4})-(\d{2})-(\d{2})(?:T|\s)(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,9}))?(?:(Z)|(?:([+-])(\d{2}):(\d{2})))$/; // RFC 3339 with optional nanoseconds
    
  // New format for nanosecond precision without timezone
  const nanoSecFormat = 
    /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{1,9})$/; // yyyy-MM-ddTHH:mm:ss.nnnnnnnnn

  // New formats with timezone offset and nanosecond support
  const formattedDashTimeWithTZOffset =
    /^(\d{1,2})-(\d{1,2})-(\d{4})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,9}))?([+-]\d{2}:\d{2})$/; // mm-dd-yyyyTHH:mm:ss.nnnnnnnnn+TZ or dd-mm-yyyyTHH:mm:ss.nnnnnnnnn+TZ
  const formattedSlashTimeWithTZOffset =
    /^(\d{1,2})\/(\d{1,2})\/(\d{4})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,9}))?([+-]\d{2}:\d{2})$/; // mm/dd/yyyyTHH:mm:ss.nnnnnnnnn+TZ or dd/mm/yyyyTHH:mm:ss.nnnnnnnnn+TZ

  // Helper function to check if a date object is valid
  const isValidDate = (date: Date): boolean => !isNaN(date.getTime());

  // Helper function to determine if a date is ambiguous (valid in both US and Indian formats)
  const isAmbiguousDate = (
    first: number,
    second: number,
    year: number
  ): boolean => {
    // Both components must be valid days (1-31) and valid months (1-12)
    if (first >= 1 && first <= 12 && second >= 1 && second <= 31) {
      // Check if second is a valid day for first as a month (US format)
      const lastDayOfMonthUS = new Date(year, first, 0).getDate();
      const validInUS = second <= lastDayOfMonthUS;

      // Check if first is a valid day for second as a month (Indian format)
      const lastDayOfMonthIN = new Date(year, second, 0).getDate();
      const validInIndian = first <= lastDayOfMonthIN;

      // If valid in both formats, it's ambiguous
      return validInUS && validInIndian;
    }

    return false;
  };

  // Helper to test standard date formats and validate the date
  const testAndValidate = (
    regex: RegExp,
    type: 'date' | 'dateTime'
  ): DateValidationResult | null => {
    if (typeof inputString !== 'string') {
      return { isValid: false, type: 'string' };
    }
    const match = (inputString || '').match(regex);

    if (match != null) {
      // For formats that could be either mm/dd or dd/mm based on user preference
      if (
        (regex === dateFormat1 ||
          regex === dateFormat3 ||
          regex === dateTimeFormat1 ||
          regex === dateTimeFormat4) &&
        match[1].length <= 2 &&
        match[2].length <= 2
      ) {
        const first = parseInt(match[1], 10);
        const second = parseInt(match[2], 10);
        const year = parseInt(match[3], 10);

        // Check if the date is ambiguous (valid in both US and Indian formats)
        const ambiguous = isAmbiguousDate(first, second, year);

        // Strict validation for dates during validation mode
        if (from === 'validation') {
          // If we're in validation mode, we need to be more strict about format

          // For ambiguous dates with both components between 1-12
          if (ambiguous && first <= 12 && second <= 12) {
            // In validation mode, we should enforce that dates must be entered in the expected format
            // For ambiguous dates, this means rejecting dates that appear to be in the wrong format

            // If the date components are similar or identical, allow it (like 1-1-2025)
            if (first === second) {
              // This is fine in either format
            }
            // For US format, enforce that dates should be entered as MM-DD-YYYY
            else if (isUSFormat) {
              // For US format, if it looks like DD-MM-YYYY (day typically > month), reject it
              if (first > second && first <= 12) {
                return { isValid: false, type: 'string' }; // Likely DD-MM format but we expect MM-DD
              }
            }
            // For Indian format, enforce that dates should be entered as DD-MM-YYYY
            else {
              // For Indian format, if it looks like MM-DD-YYYY (month typically < day), reject it
              if (second > first && second <= 12) {
                return { isValid: false, type: 'string' }; // Likely MM-DD format but we expect DD-MM
              }
            }
          }
        }

        // Additional validation for US/Indian format
        // For US format (mm/dd), first should be 1-12 (month), second should be 1-31 (day)
        // For Indian format (dd/mm), first should be 1-31 (day), second should be 1-12 (month)
        if (isUSFormat) {
          // Validate US format: first should be month (1-12), second should be day (1-31)
          if (first < 1 || first > 12) {
            return { isValid: false, type: 'string' }; // Invalid month
          }

          // Check if day is valid for the given month
          const lastDayOfMonth = new Date(year, first, 0).getDate();

          if (second < 1 || second > lastDayOfMonth) {
            return { isValid: false, type: 'string' }; // Invalid day for this month
          }
        } else {
          // Validate Indian format: first should be day (1-31), second should be month (1-12)
          if (second < 1 || second > 12) {
            return { isValid: false, type: 'string' }; // Invalid month
          }

          // Check if day is valid for the given month
          const lastDayOfMonth = new Date(year, second, 0).getDate();

          if (first < 1 || first > lastDayOfMonth) {
            return { isValid: false, type: 'string' }; // Invalid day for this month
          }
        }

        // Validate based on user's date format preference
        let date: Date;

        if (type === 'date') {
          if (isUSFormat) {
            // US format: mm/dd/yyyy
            date = new Date(year, first - 1, second);
          } else {
            // Indian format: dd/mm/yyyy
            date = new Date(year, second - 1, first);
          }
        } else {
          // dateTime
          const hours = parseInt(match[4], 10);
          const minutes = parseInt(match[5], 10);
          const seconds = parseInt(match[6], 10);

          // Validate time components
          if (
            hours < 0 ||
            hours > 23 ||
            minutes < 0 ||
            minutes > 59 ||
            seconds < 0 ||
            seconds > 59
          ) {
            return { isValid: false, type: 'string' }; // Invalid time components
          }

          if (isUSFormat) {
            // US format: mm/dd/yyyy HH:mm:ss
            date = new Date(year, first - 1, second, hours, minutes, seconds);
          } else {
            // Indian format: dd/mm/yyyy HH:mm:ss
            date = new Date(year, second - 1, first, hours, minutes, seconds);
          }
        }

        return isValidDate(date)
          ? { isValid: true, type }
          : { isValid: false, type: 'string' };
      }
      // For timezone offset formats with nanoseconds
      else if (
        regex === formattedDashTimeWithTZOffset ||
        regex === formattedSlashTimeWithTZOffset
      ) {
        const first = parseInt(match[1], 10);
        const second = parseInt(match[2], 10);
        const year = parseInt(match[3], 10);
        const hours = parseInt(match[4], 10);
        const minutes = parseInt(match[5], 10);
        const seconds = parseInt(match[6], 10);
        
        // Handle nanoseconds
        let milliseconds = 0;
        if (match[7]) {
          // Get first 3 digits for milliseconds (JS Date limit)
          const nanoStr = match[7].padEnd(9, '0');
          milliseconds = parseInt(nanoStr.substring(0, 3), 10);
        }
        
        // Get timezone offset
        const tzOffset = match[8] || "";

        // Additional validation for US/Indian format
        if (isUSFormat) {
          // Validate US format: first should be month (1-12), second should be day (1-31)
          if (first < 1 || first > 12) {
            return { isValid: false, type: 'string' }; // Invalid month
          }

          // Check if day is valid for the given month
          const lastDayOfMonth = new Date(year, first, 0).getDate();

          if (second < 1 || second > lastDayOfMonth) {
            return { isValid: false, type: 'string' }; // Invalid day for this month
          }
        } else {
          // Validate Indian format: first should be day (1-31), second should be month (1-12)
          if (second < 1 || second > 12) {
            return { isValid: false, type: 'string' }; // Invalid month
          }

          // Check if day is valid for the given month
          const lastDayOfMonth = new Date(year, second, 0).getDate();

          if (first < 1 || first > lastDayOfMonth) {
            return { isValid: false, type: 'string' }; // Invalid day for this month
          }
        }

        // Validate time components
        if (
          hours < 0 ||
          hours > 23 ||
          minutes < 0 ||
          minutes > 59 ||
          seconds < 0 ||
          seconds > 59
        ) {
          return { isValid: false, type: 'string' }; // Invalid time components
        }

        // Create a proper ISO string based on user preference
        let isoString;

        if (isUSFormat) {
          // US format: MM-DD-YYYY to YYYY-MM-DD
          isoString = `${year}-${first.toString().padStart(2, '0')}-${second
            .toString()
            .padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')}:${seconds
            .toString()
            .padStart(2, '0')}`;
        } else {
          // Indian format: DD-MM-YYYY to YYYY-MM-DD
          isoString = `${year}-${second.toString().padStart(2, '0')}-${first
            .toString()
            .padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')}:${seconds
            .toString()
            .padStart(2, '0')}`;
        }
        
        // Add milliseconds if present
        if (match[7]) {
          isoString += `.${milliseconds.toString().padStart(3, '0')}`;
        }
        
        // Add timezone offset if present
        if (tzOffset) {
          isoString += tzOffset;
        }

        const date = new Date(isoString);

        return isValidDate(date)
          ? { isValid: true, type: 'dateTime' }
          : { isValid: false, type: 'string' };
      }
      // For the nanosecond format without timezone
      else if (regex === nanoSecFormat) {
        const year = parseInt(match[1], 10);
        const month = parseInt(match[2], 10) - 1;
        const day = parseInt(match[3], 10);
        const hours = parseInt(match[4], 10);
        const minutes = parseInt(match[5], 10);
        const seconds = parseInt(match[6], 10);
        
        // Handle nanoseconds - convert to milliseconds
        let milliseconds = 0;
        if (match[7]) {
          const nanoStr = match[7].padEnd(9, '0');
          milliseconds = parseInt(nanoStr.substring(0, 3), 10);
        }
        
        // Create date with millisecond precision
        const date = new Date(year, month, day, hours, minutes, seconds, milliseconds);
        
        return isValidDate(date)
          ? { isValid: true, type: 'dateTime' }
          : { isValid: false, type: 'string' };
      }
      // Handle RFC3339 format with improved nanoseconds support
      else if (regex === rfc3339Format) {
        const year = parseInt(match[1], 10);
        const month = parseInt(match[2], 10) - 1;
        const day = parseInt(match[3], 10);
        const hours = parseInt(match[4], 10);
        const minutes = parseInt(match[5], 10);
        const seconds = parseInt(match[6], 10);
        
        // Handle nanoseconds - convert to milliseconds
        let milliseconds = 0;
        if (match[7]) {
          const nanoStr = match[7].padEnd(9, '0');
          milliseconds = parseInt(nanoStr.substring(0, 3), 10);
        }
        
        let date: Date;
        if (match[8] === 'Z') {
          // UTC time
          date = new Date(Date.UTC(year, month, day, hours, minutes, seconds, milliseconds));
        } else if (match[9]) {
          // Has timezone offset
          const offsetSign = match[9] === '+' ? 1 : -1;
          const offsetHours = parseInt(match[10], 10);
          const offsetMinutes = parseInt(match[11], 10);
          
          // Create date in UTC, then apply offset
          const utcDate = new Date(Date.UTC(year, month, day, hours, minutes, seconds, milliseconds));
          const offsetMilliseconds = offsetSign * ((offsetHours * 60 + offsetMinutes) * 60 * 1000);
          date = new Date(utcDate.getTime() - offsetMilliseconds);
        } else {
          // Local time
          date = new Date(year, month, day, hours, minutes, seconds, milliseconds);
        }
        
        return isValidDate(date)
          ? { isValid: true, type: 'dateTime' }
          : { isValid: false, type: 'string' };
      }
      // Special handling for dateTimeTZMilliSecFormat with nanoseconds
      else if (regex === dateTimeTZMilliSecFormat) {
        // Extract the date components from the string to properly handle nanoseconds
        const dtMatch = inputString.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,9}))?Z$/);
        
        if (dtMatch) {
          const year = parseInt(dtMatch[1], 10);
          const month = parseInt(dtMatch[2], 10) - 1;
          const day = parseInt(dtMatch[3], 10);
          const hours = parseInt(dtMatch[4], 10);
          const minutes = parseInt(dtMatch[5], 10);
          const seconds = parseInt(dtMatch[6], 10);
          
          // Handle nanoseconds
          let milliseconds = 0;
          if (dtMatch[7]) {
            const nanoStr = dtMatch[7].padEnd(9, '0');
            milliseconds = parseInt(nanoStr.substring(0, 3), 10);
          }
          
          // Create date in UTC
          const date = new Date(Date.UTC(year, month, day, hours, minutes, seconds, milliseconds));
          
          return isValidDate(date)
            ? { isValid: true, type: 'dateTime' }
            : { isValid: false, type: 'string' };
        }
      }
      // For other formats (yyyy-mm-dd, etc.) use standard parsing
      else {
        const date = new Date(inputString);

        return isValidDate(date)
          ? { isValid: true, type }
          : { isValid: false, type: 'string' };
      }
    }

    return null;
  };

  // Define the array of formats and iterate over them
  const formats: Array<{ regex: RegExp; type: 'date' | 'dateTime' }> = [
    { regex: dateFormat1, type: 'date' },
    { regex: dateFormat2, type: 'date' },
    { regex: dateFormat3, type: 'date' },
    { regex: dateFormat4, type: 'date' },
    { regex: dateTimeFormat1, type: 'dateTime' },
    { regex: dateTimeFormat2, type: 'dateTime' },
    { regex: dateTimeFormat3, type: 'dateTime' },
    { regex: dateTimeFormat4, type: 'dateTime' },
    { regex: dateTimeTZFormat, type: 'dateTime' },
    { regex: dateTimeTZMilliSecFormat, type: 'dateTime' },
    { regex: rfc3339Format, type: 'dateTime' },
    { regex: nanoSecFormat, type: 'dateTime' }, // New format for nanoseconds
    { regex: formattedDashTimeWithTZOffset, type: 'dateTime' },
    { regex: formattedSlashTimeWithTZOffset, type: 'dateTime' },
  ];

  // Iterate through all formats and use testAndValidate
  for (const { regex, type } of formats) {
    const result = testAndValidate(regex, type);

    if (result !== null) {
      return result;
    }
  }

  // If no valid date format matches, return based on the 'from' parameter
  if (from === 'validation') {
    return { isValid: false, type: 'string' };
  }

  return { isValid: true, type: 'string' };
};

// TypeScript interface for return type
interface DateValidationResult {
  isValid: boolean;
  type: 'date' | 'dateTime' | 'string';
}