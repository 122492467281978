import { ApolloError } from '@apollo/client';
import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiPlus } from 'react-icons/bi';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Typography, toasts, useLayer } from 'ui';

import { siteConstantsAtom, subscriptionPlanAtom } from '../../atom';
import { CloneEntityModal } from '../../components/CloneEntityModal/CloneEntityModal';
import { ListingComponent } from '../../components/Listing';
import { WorkflowTemplatePopup } from '../../components/Listing/gettingStarted/components/workflowTemplate';
import { EntityLevelAccessModal } from '../../components/Modals/EntityLevelAccessModal/EntityLevelAccessModal';
import { ExportEntityModal } from '../../components/Modals/ExportEntityModal/ExportEntityModal';
import {
  type PermissionType,
  permissionObj,
} from '../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../components/PermissionComponent/hooks/useCheckPermissions';
import { Threshold } from '../../components/Threshold/Threshold';
import { tabList } from '../../components/VersionControl/constant';
import { PagesBodyContainer } from '../../components/layouts/PagesBodyContainer';
import { useGetSiteMeta } from '../../hooks/useGetSiteMeta';
import type { PanelType } from '../../types';
import {
  checkLimitExceeded,
  handleGetConstantData,
  handleSetCheckSumByEntityName,
  sendEventToGTM,
  setDateAndTZinContext,
} from '../../utils/common';
import { ENTITY_ID } from '../../utils/constant';
import { DeleteRuleModal } from '../Rules/components/RulesTable/DeleteRuleModal/DeleteRuleModal';
import {
  activePanelWorkflowAtom,
  vcListTabIndexWorkflowAtom,
  workflowIdAtom,
} from './atoms/atoms';
import { WorkflowSheet } from './components/WorkflowSheet/WorkflowSheet';
import { useDeleteWorkflow } from './hooks/graphql/useDeleteWorkflow';
import { useGetWorkflowList } from './hooks/graphql/useGetWorkflowList';

type PermissionProps = {
  subModules: Record<string, boolean>;
  permissions: Record<PermissionType, boolean>;
  limits: Record<string, any>;
};

export function Workflow(props: PermissionProps) {
  const navigate = useNavigate();
  const [, setWorkflowId] = useAtom(workflowIdAtom);
  const { openWithProps: openWorkflowSheet } = useLayer(<WorkflowSheet />);

  const { siteMeta } = useGetSiteMeta();

  const [getTableData, { data, refetch }] = useGetWorkflowList();
  const [deleteWorkflow] = useDeleteWorkflow();

  const [listData, setListData] = useState<any | undefined>();
  const [refreshListData, setRefreshListData] = useState(false);

  const { subModules, permissions } = props;

  const { openWithProps: openWorkflowTemplatePopup } = useLayer(
    <WorkflowTemplatePopup
      title="Create Workflow"
      type="WORKFLOW_TEMPLATE"
      selectedTab={0}
      subModules={subModules}
    />
  );

  const { openWithProps: openDeleteConfirmationModal } = useLayer(
    <DeleteRuleModal
      title="Delete Workflow"
      description="Are you sure you want to delete this Workflow?"
    />
  );

  const { openWithProps: openCloneModal } = useLayer(
    <CloneEntityModal entityType="workflow" />
  );

  const { openWithProps: openExportModal } = useLayer(
    <ExportEntityModal entityType="workflow" />
  );

  const { openWithProps: openEntityAccessModal } = useLayer(
    <EntityLevelAccessModal />
  );

  const [searchParams] = useSearchParams();
  const { workflowId } = useParams();
  const [subscriptionPlan] = useAtom(subscriptionPlanAtom);
  const [siteConstant, setSiteConstant] = useAtom(siteConstantsAtom);

  const [, setActivePanel] = useAtom(activePanelWorkflowAtom);
  const [, setVcListTabIndex] = useAtom(vcListTabIndexWorkflowAtom);

  const type = searchParams.get('type');
  const activePanelParams = searchParams.get('currentTab');
  const vcActiveTabParams = searchParams.get('activeVcTab');

  const isLiveParams = searchParams.get('isLive') === 'true';
  const commitIdParams = searchParams.get('commitId');

  const { isHide: editDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.create],
    entityList: [ENTITY_ID.workflow],
  });

  useEffect(() => {
    if (!_isNil(data)) {
      setListData(data);
    }
  }, [data]);

  useEffect(() => {
    if (!_isNil(activePanelParams) && !_isEmpty(activePanelParams)) {
      setActivePanel(activePanelParams as PanelType);
    }
  }, [activePanelParams]);

  useEffect(() => {
    if (!_isNil(vcActiveTabParams) && !_isEmpty(vcActiveTabParams)) {
      const index = tabList.findIndex(
        (tabObj) => tabObj.value === vcActiveTabParams
      );
      setVcListTabIndex(index !== -1 ? index : 0);
    }
  }, [vcActiveTabParams]);

  const handleRowClick = (data: any) => {
    if (!_isNil(data.settings)) {
      setDateAndTZinContext({
        timezone:
          typeof data.settings?.timezone === 'object'
            ? data.settings?.timezone.value
            : data.settings?.timezone,
        dateFormat: data.settings?.dateFormat,
      });
    }
    sendEventToGTM({
      event: 'workflow',
      source: 'listing',
      type: data.type,
      element: '',
      action: 'edit_click',
    });

    const state = 'staging';

    if (!_isNil(window.location.search) && !_isEmpty(window.location.search)) {
      window.sessionStorage.setItem(
        'workflowRedirection',
        window.location.search
      );
    }

    navigate(
      `/workflow/${data.id as string}?type=edit&stage=${state}&wsid=${
        sessionStorage.getItem('workspaceUUID') as string
      }`
    );
  };

  useEffect(() => {
    void handleGetConstantData(
      'rules,integrations,datasets',
      setSiteConstant,
      siteConstant
    );
  }, []);

  const HeaderComponent = useCallback(() => {
    return (
      <Inline gutter={25}>
        <Typography name="heading1" fontWeight={700}>
          Workflow
        </Typography>

        <Inline align="center">
          <Button
            disabled={editDisable}
            leadingIcon={<BiPlus size={20} />}
            onClick={() => openWorkflowTemplatePopup({})}
          >
            Create Workflow
          </Button>
          <Threshold
            align="start"
            entity="workflow"
            current={subscriptionPlan?.usage.workflow}
            max={subscriptionPlan?.plan.workflowLimit}
          />
        </Inline>
      </Inline>
    );
  }, [subscriptionPlan, checkLimitExceeded]);

  const handleDeleteRule = async (id: string, checksum: string) => {
    handleSetCheckSumByEntityName('workflow', checksum);

    sendEventToGTM({
      event: 'workflow',
      source: 'listing',
      type: 'workflow',
      element: '',
      action: 'delete_click',
    });

    try {
      await deleteWorkflow({
        variables: {
          id,
          checksum,
        },
      });

      toasts.success('Workflow deleted successfully', 'deleted-success');

      const response = await refetch();
      setListData(response.data);
    } catch (error) {
      if (error instanceof ApolloError) {
        if (error?.graphQLErrors[0]?.extensions?.code === 'server_error') {
          toasts.error('Failed to delete rule', 'error');
        } else {
          toasts.error(error.message, 'error');
        }
      }
    }
  };

  const handleCloneActionClick = (data: any) => {
    sendEventToGTM({
      event: 'workflow',
      source: 'listing',
      type: 'workflow',
      element: '',
      action: 'clone_click',
    });

    handleSetCheckSumByEntityName('workflow', data.checksum);

    openCloneModal({
      id: data.id,
    });

    // const state = 'staging';
    // navigate(
    //   `/workflow/${data.id as string}?type=clone&stage=${state}&workflowName=${
    //     data.name as string
    //   }_copy&wsid=${sessionStorage.getItem('workspaceUUID') as string}`
    // );
  };

  const onDeleteClick = async (data: any) => {
    await handleDeleteRule(data.id, data.checksum);
  };

  const updateListingData = () => {
    setRefreshListData(true);
  };

  const handleActionColumnClick = useCallback((actionData: any) => {
    const { type, data } = actionData;

    if (type === 'delete') {
      openDeleteConfirmationModal({
        onDeleteClick: async () => await onDeleteClick(data),
      });
    } else if (type === 'clone') {
      handleCloneActionClick(data);
    } else if (type === 'export') {
      openExportModal({
        entityId: data.id,
        entityName: 'workflow',
        isLive: data.isLive,
        version: data.versionInfo.currentVersion,
      });
    } else if (type === 'edit_access') {
      const { id, name, status, accessRole } = data;
      openEntityAccessModal({
        entityInfo: {
          id,
          name,
          type: 'workflow',
          status,
          accessRole,
        },
        updateListingData,
      });
    }
  }, []);

  useEffect(() => {
    if (!_isNil(type)) {
      if (type === 'create') {
        setWorkflowId(null);
        openWorkflowSheet({
          type: 'create',
        });
      } else if (['edit', 'clone', 'view'].includes(type)) {
        openWorkflowSheet({
          type: 'create',
          id: workflowId,
          isLive: isLiveParams,
          commitId: commitIdParams,
        });
      }
    }
  }, [type]);

  return (
    <PagesBodyContainer>
      <Helmet>
        <title>
          {siteMeta[window.location.pathname.substring(1)]?.title ?? ''}
        </title>
        <meta
          name="description"
          content={
            siteMeta[window.location.pathname.substring(1)]?.description ?? ''
          }
        />
      </Helmet>
      <ListingComponent
        entity={'workflow'}
        headerTitle="Workflow"
        callOnRowClick={handleRowClick}
        callOnCellClick={() => {}}
        errorTitle="No Workflow found"
        HeaderComponent={HeaderComponent}
        isGraphQL={true}
        getListDataQuery={getTableData}
        queryListData={listData}
        handleActionItemClick={handleActionColumnClick}
        permissions={permissions}
        refreshListData={refreshListData}
        setRefreshListData={setRefreshListData}
      />
    </PagesBodyContainer>
  );
}
