import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import React, {
  ChangeEvent,
  Ref,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import { UseControllerProps, UseFormSetValue, useWatch } from 'react-hook-form';
import {
  ExecutedValueTooltip,
  TooltipReact,
  Typography,
  formatNectedDate,
  useLayer,
} from 'ui';

import { CalenderIconPicker } from '../../../../../components/CalenderIconPicker/CalenderIconPicker';
import { TextInputModal } from '../../../../../components/Modals/TextInputModal/TextInputModal';
import {
  DATE_TIME_FORMAT,
  convertArrayAsInput,
  convertArrayToString,
  extractSourceAndAttributeFromValue,
  isArrayAsInputValid,
  isFieldReadOnly,
  removeOuterBrackets,
} from '../../../../../utils/common';
import {
  EXTRACT_TOKEN_REGEX,
  LIST_KEY_REGEX,
} from '../../../../../utils/regex';
import { sendEventToGTMType } from '../../../types';
import {
  getPlaceholderByDataType,
  primitiveDataTypesList,
  validateRhsValue,
} from '../../../utils/common';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import {
  InputWithPillStyled,
  RightIconContainer,
} from '../../RestltRhs/ResultRhs.styled';
import { ErrorPopoverPositioned } from '../../SimpleRule/Error/ErrorPopoverPositioned';
import { decisionTableNodesAtom } from '../DecisionTable';
import { DecisionTableNodesModel } from '../models';
import {
  InputBrackets,
  InputContainer,
  InputFieldStyled,
  InputStyled,
  RoundIcon,
} from './RhsNode.styled';

type RhsLauncherProps = Omit<UseControllerProps<any>, 'name'> & {
  nodeId: string;
  from: string;
  nodeName: string;
  conditionKey: string;
  setValue: UseFormSetValue<any>;
  text?: string;
  panelVisible?: boolean;
  error?: string;
  disabled?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  dataType?: string;
  selectedOperator?: string;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
  isActiveDate?: boolean;
  handleHidePanel?: () => void;
  rightIcon?: any;
  isSchemaMandatory?: boolean;
};

export const RhsLauncher = forwardRef(
  (
    {
      from,
      text,
      nodeId,
      panelVisible = false,
      control,
      setValue,
      nodeName,
      conditionKey,
      error,
      onFocus,
      onBlur,
      disabled,
      dataType,
      selectedOperator,
      handleSendEventToGTM,
      isActiveDate = true,
      handleHidePanel,
      rightIcon,
      isSchemaMandatory = false,
    }: RhsLauncherProps,
    ref: Ref<any>
  ) => {
    const [nodes, setNodes] = useAtom(decisionTableNodesAtom);
    const [dataset] = useAtom(dataSetParamsAtom);
    const [localSourceType, setLocalSourceType] = useState<string>();

    const nodeType = !_isNil(nodes[nodeId])
      ? nodes[nodeId].nodeType
      : 'constant';

    const [localNodeType, setLocalNodeType] = useState<string>(nodeType);

    const [isFocused, setIsFocused] = useState(false);

    const value = useWatch({
      control,
      name: `${nodeName}.value`,
    });

    const query = useWatch({
      control,
      name: `${nodeName}.query`,
    });

    const [localValue, setLocalValue] = useState('');

    const isFormulaNode = ['jsFormula', 'excelFormula'].includes(localNodeType);

    const { source: sourceType, attribute } = isActiveDate
      ? {
          source: nodes[nodeId]?.sourceType,
          attribute: nodes[nodeId]?.attribute,
        }
      : extractSourceAndAttributeFromValue(
          ((!isActiveDate ? value?.value : value) ?? '') as string,
          dataset
        );

    const rhsDataType = nodes[nodeId]?.dataType;

    useEffect(() => {
      if (localNodeType !== 'constant') {
        setLocalSourceType(sourceType);
      }
    }, [sourceType, localNodeType]);

    useEffect(() => {
      setLocalNodeType(nodes[nodeId]?.nodeType ?? 'constant');
    }, [nodes[nodeId]]);

    interface Attribute {
      executedValue: string | unknown;
    }

    const toolTipMessage: string = (() => {
      if (
        typeof localSourceType !== 'string' ||
        localSourceType.length === 0 ||
        _isNil(dataset) ||
        _isNil(dataset[localSourceType])
      ) {
        return JSON.stringify(value);
      }

      const datasetEntry = dataset[localSourceType];
      const attributes = datasetEntry?.attributes;

      if (_isNil(attributes)) {
        return JSON.stringify(value);
      }

      if (
        typeof attribute === 'string' &&
        attribute.length > 0 &&
        Object.prototype.hasOwnProperty.call(attributes, attribute) &&
        !_isNil(attributes[attribute])
      ) {
        return (attributes[attribute] as Attribute).executedValue as string;
      }

      const reducedData = Object.keys(attributes).reduce<
        Record<string, unknown>
      >((acc, curr) => {
        const attr = attributes[curr] as Attribute;
        acc[curr] = attr.executedValue;

        return acc;
      }, {});

      return JSON.stringify(reducedData);
    })();

    const returnDataType = (datatype?: string) => {
      const type = datatype ?? dataType;

      if (
        type === 'list' &&
        !['contains', 'notContains'].includes(selectedOperator ?? '')
      ) {
        return type;
      }

      const isValidOperator = [
        'notContainsIn',
        'containsIn',
        'in',
        'nin',
      ].includes(selectedOperator ?? '');

      if (primitiveDataTypesList.includes(type ?? '') && isValidOperator) {
        if (isArrayAsInputValid(value) && type === 'string') {
          return 'list';
        }

        if (type !== 'string') {
          return 'list';
        }
      }

      return dataType;
    };

    const transformListValue = (type: string, value: any) => {
      if (type === 'LIST_TO_STRING') {
        if (Array.isArray(value)) {
          const convertedValue = convertArrayToString(value);

          return _isEmpty(convertedValue) ? '' : `${convertedValue}`;
        } else {
          return typeof value === 'string' ? removeOuterBrackets(value) : value;
        }
      }

      if (type === 'STRING_TO_LIST') {
        if (typeof value === 'string') {
          // Check if there is token present, then return string
          // otherwise convert to list

          const tokens = (typeof value === 'string' ? value : '').match(
            EXTRACT_TOKEN_REGEX
          );

          if (!_isNil(tokens)) {
            return value;
          }

          return convertArrayAsInput(value);
        } else {
          return value;
        }
      }

      return value;
    };

    const onChange = (e: ChangeEvent<any>) => {
      e.preventDefault();

      const localNodeType = LIST_KEY_REGEX.test(localValue)
        ? isActiveDate
          ? 'params'
          : 'noCodeFunc'
        : 'constant';

      setLocalNodeType(localNodeType);

      setLocalValue(e.target.value);
    };

    useEffect(() => {
      if (isFormulaNode) {
        setLocalValue(query);
      } else {
        if (isActiveDate) {
          if (!_isNil(sourceType) && sourceType !== '') {
            setLocalValue(`${attribute ?? ''}`);
          } else {
            setLocalValue(value);
          }
        } else {
          setLocalValue(value?.value);
        }
      }
    }, [
      JSON.stringify(sourceType),
      JSON.stringify(attribute),
      isActiveDate,
      isFormulaNode,
    ]);

    useEffect(() => {
      if (isFormulaNode) {
        setLocalValue(query);
      } else {
        if (
          (_isNil(sourceType) || sourceType === '') &&
          value !== localValue &&
          !_isNil(value) &&
          value !== ''
        ) {
          setLocalValue(!isActiveDate ? value?.value : value);
        }
      }
    }, [JSON.stringify(value), isFormulaNode]);

    const calculateValue = (
      type: string,
      value: any,
      currNodeData: DecisionTableNodesModel
    ) => {
      const relativeDateParams =
        typeof currNodeData.value === 'object' &&
        !Array.isArray(currNodeData.value)
          ? currNodeData.value
          : {};

      if (type === 'constant' || type === 'noCodeFunc') {
        const finalValue =
          returnDataType() === 'list'
            ? transformListValue('STRING_TO_LIST', value)
            : value;

        if (isActiveDate) {
          return finalValue;
        } else {
          return {
            ...relativeDateParams,
            value: finalValue,
          };
        }
      } else {
        if (isActiveDate) {
          return '';
        } else {
          return {
            ...relativeDateParams,
            value: '',
          };
        }
      }
    };

    const showExpandIcon =
      localNodeType === 'constant' && returnDataType() === 'list';

    const formatValue = (input: any) => {
      if (returnDataType() === 'list') {
        return transformListValue('LIST_TO_STRING', input);
      }

      if (returnDataType() === 'date') {
        try {
          const { attribute } = extractSourceAndAttributeFromValue(
            input,
            dataset
          );

          if (!_isNil(attribute) && !_isEmpty(attribute)) {
            return attribute;
          }

          return formatNectedDate(input, 'date');
        } catch (err) {
          return input;
        }
      }

      if (returnDataType() === 'dateTime') {
        try {
          const { attribute } = extractSourceAndAttributeFromValue(
            input,
            dataset
          );

          if (!_isNil(attribute) && !_isEmpty(attribute)) {
            return attribute;
          }

          return formatNectedDate(input, 'dateTime');
        } catch (err) {
          return input;
        }
      }

      return input;
    };

    const { openWithProps } = useLayer(<TextInputModal />);

    const isValueToken = !_isNil(sourceType) && !_isEmpty(sourceType);

    const fieldHasPillComponent = [
      'json',
      'list',
      'jsFormula',
      'excelFormula',
    ].includes(nodeType);

    const formattedValue = formatValue(value);
    return (
      <Inline align="center">
        {!['excelFormula', 'jsFormula'].includes(nodeType) && (
          <InputContainer align="center" gutter={2}>
            {localNodeType !== 'constant' && isValueToken && (
              <InputBrackets direction="left">
                <Typography>{'{{'}</Typography>
              </InputBrackets>
            )}

            {localNodeType === 'constant' && returnDataType() === 'list' && (
              <InputBrackets direction="left">
                <Typography>{'['}</Typography>
              </InputBrackets>
            )}

            {!_isNil(error) && <ErrorPopoverPositioned error={error} />}

            <ExecutedValueTooltip
              attribute={attribute}
              value={
                returnDataType() === 'list'
                  ? transformListValue('STRING_TO_LIST', toolTipMessage)
                  : toolTipMessage
              }
              isVisible={localNodeType !== 'constant' && isValueToken}
              dataType={rhsDataType}
              id={nodeId}
              source={sourceType}
            >
              {returnDataType() === 'string' ? (
                <InputStyled
                  value={formatValue(localValue)}
                  ref={ref}
                  $hasError={false}
                  $showExpandIcon={showExpandIcon}
                  placeholder={getPlaceholderByDataType(dataType)}
                  disabled={disabled}
                  rows={
                    returnDataType() === 'string' &&
                    localNodeType === 'constant'
                      ? isFocused
                        ? 2
                        : 1
                      : 1
                  }
                  onChange={(e) => {
                    onChange(e);
                  }}
                  onFocus={(e) => {
                    setIsFocused(true);

                    if (onFocus != null) {
                      onFocus();
                    }
                  }}
                  readOnly={
                    isFieldReadOnly(returnDataType() ?? '') || isSchemaMandatory
                  }
                  onBlur={() => {
                    setTimeout(() => {
                      setIsFocused(false);
                    }, 100);
                    // eslint-disable-next-line
                    if (localValue === `${attribute}`) {
                      return;
                    }

                    if (
                      ['excelFormula', 'jsFormula'].includes(nodeType ?? '')
                    ) {
                      return;
                    }

                    if (!_isNil(onBlur)) {
                      onBlur();
                    }

                    if (localNodeType === 'constant') {
                      const result = validateRhsValue(
                        localValue,
                        returnDataType(),
                        localNodeType
                      );

                      if (result.isCorrect) {
                        setNodes((prev) => ({
                          ...prev,
                          [nodeId]: {
                            ...prev[nodeId],
                            nodeType: 'constant',
                            value: calculateValue(
                              localNodeType,
                              result.value,
                              prev[nodeId]
                            ),
                            sourceType: '',
                            attribute: '',
                          },
                        }));
                        setValue(
                          `${nodeName}.value`,
                          calculateValue(
                            localNodeType,
                            result.value,
                            nodes[nodeId]
                          )
                        );
                      } else {
                        setNodes((prev) => ({
                          ...prev,
                          [nodeId]: {
                            ...prev[nodeId],
                            nodeType: 'constant',
                            value: calculateValue(
                              localNodeType,
                              localValue,
                              prev[nodeId]
                            ),
                            sourceType: '',
                            attribute: '',
                          },
                        }));

                        setValue(
                          `${nodeName}.value`,
                          calculateValue(
                            localNodeType,
                            localValue,
                            nodes[nodeId]
                          )
                        );
                      }
                    } else {
                      setNodes((prev) => ({
                        ...prev,
                        [nodeId]: {
                          ...prev[nodeId],
                          nodeType: localNodeType,
                          sourceType: isActiveDate ? sourceType : '',
                          attribute: isActiveDate ? localValue : '',
                          value: calculateValue(
                            localNodeType,
                            localValue,
                            nodes[nodeId]
                          ),
                        },
                      }));
                    }
                  }}
                />
              ) : (
                <InputFieldStyled
                  isRelativeDate={['date', 'dateTime'].includes(dataType ?? '')}
                  value={formatValue(localValue)}
                  ref={ref}
                  $hasError={false}
                  $showExpandIcon={showExpandIcon}
                  placeholder={getPlaceholderByDataType(dataType)}
                  disabled={disabled}
                  isActiveDate={
                    isActiveDate &&
                    ['date', 'dateTime'].includes(dataType ?? '')
                  }
                  isFormulaNode={isFormulaNode}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  onFocus={(e) => {
                    setIsFocused(true);

                    if (onFocus != null) {
                      onFocus();
                    }
                  }}
                  readOnly={
                    isFieldReadOnly(returnDataType() ?? '') || isSchemaMandatory
                  }
                  onBlur={() => {
                    setTimeout(() => {
                      setIsFocused(false);
                    }, 100);

                    if (
                      ['excelFormula', 'jsFormula'].includes(nodeType ?? '')
                    ) {
                      return;
                    }

                    if (!_isNil(onBlur)) {
                      onBlur();
                    }

                    if (localNodeType === 'constant') {
                      const result = validateRhsValue(
                        localValue,
                        returnDataType(),
                        localNodeType
                      );

                      if (result.isCorrect) {
                        setNodes((prev) => ({
                          ...prev,
                          [nodeId]: {
                            ...prev[nodeId],
                            nodeType: 'constant',
                            value: calculateValue(
                              localNodeType,
                              localValue,
                              prev[nodeId]
                            ),
                            sourceType: '',
                            attribute: '',
                          },
                        }));
                        setValue(
                          `${nodeName}.value`,
                          calculateValue(
                            localNodeType,
                            localValue,
                            nodes[nodeId]
                          )
                        );
                      } else {
                        setNodes((prev) => ({
                          ...prev,
                          [nodeId]: {
                            ...prev[nodeId],
                            nodeType: 'constant',
                            value: calculateValue(
                              localNodeType,
                              localValue,
                              prev[nodeId]
                            ),
                            sourceType: '',
                            attribute: '',
                          },
                        }));
                        setValue(
                          `${nodeName}.value`,
                          calculateValue(
                            localNodeType,
                            localValue,
                            nodes[nodeId]
                          )
                        );
                      }
                    } else if (localNodeType === 'params') {
                      setNodes((prev) => ({
                        ...prev,
                        [nodeId]: {
                          ...prev[nodeId],
                          nodeType: 'params',
                          sourceType,
                          attribute: localValue,
                          value: undefined,
                        },
                      }));
                      setValue(`${nodeName}.value`, undefined);
                    } else if (localNodeType === 'noCodeFunc') {
                      const value = calculateValue(
                        localNodeType,
                        localValue,
                        nodes[nodeId]
                      );

                      setNodes((prev) => ({
                        ...prev,
                        [nodeId]: {
                          ...prev[nodeId],
                          nodeType: 'noCodeFunc',
                          sourceType: '',
                          attribute: '',
                          value,
                        },
                      }));
                      setValue(`${nodeName}.value`, value);
                    }
                  }}
                />
              )}
            </ExecutedValueTooltip>

            {localNodeType !== 'constant' && isValueToken && (
              <InputBrackets direction="right">
                <Typography>{'}}'}</Typography>
              </InputBrackets>
            )}

            {localNodeType === 'constant' && returnDataType() === 'list' && (
              <InputBrackets direction="right">
                <Typography>{']'}</Typography>
              </InputBrackets>
            )}

            {value === '' && dataType === 'dateTime' && (
              <InputBrackets direction="right">
                <TooltipReact id={nodeId}>
                  Enter Date in {DATE_TIME_FORMAT} format
                </TooltipReact>
              </InputBrackets>
            )}
          </InputContainer>
        )}
        {!['excelFormula', 'jsFormula'].includes(nodeType) &&
          returnDataType() === 'string' && (
            <RoundIcon
              type="button"
              disabled={!(localNodeType === 'constant') || isSchemaMandatory}
              onClick={() => {
                if (typeof handleHidePanel === 'function') {
                  handleHidePanel();
                }

                openWithProps({
                  onSubmit: (val: Record<string, any>) => {
                    setNodes((prev) => ({
                      ...prev,
                      [nodeId]: {
                        ...prev[nodeId],
                        nodeType: 'constant',
                        value: val.value,
                        sourceType: '',
                        attribute: '',
                      },
                    }));

                    setValue(`${nodeName}.value`, val.value);
                  },
                  value: formatValue(localValue),
                });
              }}
            >
              <>⤢</>
            </RoundIcon>
          )}

        {['dateTime', 'date'].includes(rhsDataType ?? '') &&
          !['excelFormula', 'jsFormula'].includes(nodeType) &&
          returnDataType() !== 'list' && (
            <RoundIcon type="button" onClick={handleHidePanel}>
              <CalenderIconPicker
                value={localValue}
                dataType={rhsDataType ?? ''}
                disabled={disabled}
                onPick={(val) => {
                  const fValue = formatNectedDate(
                    val,
                    rhsDataType ?? 'date'
                  ) as unknown as string;

                  if (isActiveDate) {
                    setNodes((prev) => ({
                      ...prev,
                      [nodeId]: {
                        ...prev[nodeId],
                        value: fValue,
                        sourceType: undefined,
                        attribute: undefined,
                        nodeType: 'constant',
                      },
                    }));

                    setValue(`${nodeName}.value`, fValue);
                    setValue(`${nodeName}.key`, '');
                  } else {
                    setNodes((prev) => ({
                      ...prev,
                      [nodeId]: {
                        ...prev[nodeId],
                        value: calculateValue(
                          'noCodeFunc',
                          fValue,
                          nodes[nodeId]
                        ),
                        sourceType: undefined,
                        attribute: undefined,
                        nodeType: 'noCodeFunc',
                      },
                    }));

                    setValue(`${nodeName}.value.value`, fValue);
                    setValue(`${nodeName}.key`, '');
                  }
                }}
              />
            </RoundIcon>
          )}

        <>
          {fieldHasPillComponent && (
            <InputContainer>
              <ExecutedValueTooltip
                id={nodeId}
                attribute={attribute ?? undefined}
                isVisible={nodeType !== 'constant' && isValueToken}
                value={
                  returnDataType() === 'list'
                    ? transformListValue('STRING_TO_LIST', toolTipMessage)
                    : toolTipMessage
                }
                dataType={rhsDataType}
                source={sourceType}
              >
                <InputWithPillStyled
                  ref={ref}
                  $hasPill={!_isNil(rightIcon) && fieldHasPillComponent}
                  $hasError={!_isNil(error)}
                  $isSmall={true}
                  $isFull={true}
                  $isPlaceholder={
                    _isNil(formattedValue) || _isEmpty(formattedValue)
                  }
                >
                  <Inline align="center" gutter="0.5rem">
                    {nodeType !== 'constant' && isValueToken && (
                      <Typography>{'{{'}</Typography>
                    )}
                    <div className="text-style">
                      {_isNil(formattedValue) || formattedValue === ''
                        ? 'Enter value'
                        : formatValue(value).length > 12
                        ? formatValue(value).substring(0, 12) + '...'
                        : formatValue(value)}
                    </div>
                    {nodeType !== 'constant' && isValueToken && (
                      <Typography>{'}}'}</Typography>
                    )}
                  </Inline>
                </InputWithPillStyled>
              </ExecutedValueTooltip>
              {!_isNil(rightIcon) && (
                <RightIconContainer
                  $ruleType={'decisionTable'}
                  className="right-icon-container"
                >
                  {rightIcon(handleHidePanel)}
                </RightIconContainer>
              )}
              {!_isNil(error) && !_isEmpty(error) && (
                <ErrorPopoverPositioned error={error} />
              )}
            </InputContainer>
          )}
        </>
      </Inline>
    );
  }
);

RhsLauncher.displayName = 'RhsLauncher';
