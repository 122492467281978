import { Stack } from '@bedrock-layout/stack';
import type { AxiosError } from 'axios';
import { isValidPhoneNumber } from 'libphonenumber-js';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Typography, toasts } from 'ui';

import { useAxiosPrivate } from '../../../hooks';
import { useSendEventToGTM } from '../../../hooks/useSendEventToGTM';
import { showErrorToast, useGetQuestionnaire } from '../../../utils/common';
import { CustomAxiosError } from '../../../utils/response/types';
import { useUpdateQuestionnaire } from '../hooks/useQuestionnaire';
import { useQuestionnairePages } from '../hooks/useQuestionnairePages';
import type { PageModel, QuestionModel } from '../models';
import { convertRequestPayload } from '../utils';
import {
  CoverStyled,
  FormContainer,
  FormStyled,
  QuestionnaireFormHeader,
} from './Questionnaire.styled';
import { QuestionnairePage } from './QuestionnairePage';
import { QuestionnaireTracker } from './QuestionnaireTracker';

export const otherValueText: string = 'otherValue';

export function QuestionnaireForm() {
  const [searchParams] = useSearchParams();
  const { axiosPrivate } = useAxiosPrivate();
  const { control, handleSubmit } = useForm({});

  const [questionnairePages, setQuestionnairePages] = useState<PageModel[]>([]);
  const [isQuestionnaireLoading, setIsQuestionnaireLoading] =
    useState<boolean>(true);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  const fetchQuestionareData = async () => {
    const data = await useGetQuestionnaire(axiosPrivate);
    setQuestionnairePages(data.data);
    setIsQuestionnaireLoading(data.isLoading);
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsSmallScreen(window.innerWidth <= 768);
    });
    void fetchQuestionareData();

    return () =>
      window.addEventListener('resize', () => {
        setIsSmallScreen(false);
      });
  }, []);

  const { pages, currentPage, currentIndex, nextPage, goToPage, isLastPage } =
    useQuestionnairePages(questionnairePages);

  const { updateQuestion, isLoading } = useUpdateQuestionnaire();

  const { sendEventToGTM } = useSendEventToGTM();

  const navigate = useNavigate();

  const isFromHome = searchParams.get('fromHome') === 'true';

  useEffect(() => {
    const page = searchParams.get('page');

    if (page === '0' && !isFromHome) {
      sendEventToGTM({
        event: 'signup_completed',
      });
    }
  }, []);

  useEffect(() => {
    const newUrl = new URL('/questionnaire', location.origin);
    newUrl.searchParams.set('page', currentIndex.toString());

    if (isFromHome) {
      newUrl.searchParams.set('fromHome', isFromHome.toString());
    }

    const { pathname, search } = newUrl;

    navigate([pathname, search].join(''));
  }, [currentIndex]);

  const onClickSkipPage = () => {
    sendEventToGTM({
      event: 'onboarding',
      status: 'inprogress',
      action: 'skip',
    });

    if (currentIndex === pages.length - 1) {
      return navigate('/home', { replace: true });
    }

    return nextPage();
  };

  const isFormValid = (
    formValues: Record<string, string | string[] | any>,
    currentPageQuestions: QuestionModel[]
  ) => {
    let isValid = true;

    currentPageQuestions.forEach((question: Record<string, any>) => {
      const value = formValues[question.key];

      if (
        question.questionType === 'phone' &&
        !_isEmpty(value) &&
        value !== '+91'
      ) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        const phone = `+${value}`;

        if (!isValidPhoneNumber(phone)) {
          isValid = false;
          toasts.error(
            `Please enter a valid phone number`,
            'wrong-phone-number'
          );
        }
      }
    });

    return isValid;
  };

  const sendQuestionnareEvents = (formValues: Record<string, any>) => {
    if (!_isNil(formValues.codingSkill)) {
      const codingSkillMap: Record<string, string> = {
        '0': 'Never Coded',
        '1': 'Can code a bit',
        '2': 'Can figure most things out',
        '3': 'Pro Coder',
      };

      sendEventToGTM({
        event: 'codingSkills',
        nec_value: codingSkillMap[formValues.codingSkill as string],
      });

      sendEventToGTM({
        event: ' tech_nonTech',
        nec_value: formValues.codingSkill === '0' ? 'Non-Tech' : 'Tech',
      });
    }

    if (!_isNil(formValues.orgSize) && !_isEmpty(formValues.orgSize)) {
      sendEventToGTM({
        event: ' orgSize',
        nec_value: formValues.orgSize.label,
      });
    }

    if (!_isNil(formValues.orgType) && !_isEmpty(formValues.orgType)) {
      sendEventToGTM({
        event: ' orgType',
        nec_value: formValues.orgType
          .map((orgObj: any) => orgObj.label)
          .join(','),
      });
    }
  };

  const onSubmit = async (formValues: Record<string, string | string[]>) => {
    if (!isFormValid(formValues, currentPage.questions)) {
      return false;
    }

    const questions = convertRequestPayload(formValues, currentPage.questions);

    const page = searchParams.get('page');

    if (page === '0' && !isFromHome) {
      sendEventToGTM({
        event: 'onboarding_first_completed',
      });
    }

    sendEventToGTM({
      event: 'onboarding',
      status: 'inprogress',
      action: page === '3' ? 'submit' : 'next',
    });

    try {
      void sendQuestionnareEvents(formValues);

      await updateQuestion({
        pages: [
          {
            questions,
          },
        ],
      });

      if (isLastPage) {
        return navigate('/home', { replace: true });
      }

      const questionnairePagesCloned = questionnairePages.map(
        (pageDetails, index) => {
          let { questions } = pageDetails;

          if (currentIndex === index) {
            questions = questions.map((questionDetails) => {
              const { key } = questionDetails;
              let value = questionDetails.value;
              const formValueAgainstAQuestion = formValues[key];

              if (Array.isArray(formValueAgainstAQuestion)) {
                value = formValueAgainstAQuestion
                  .filter((val) => val)
                  .join(',');
              }

              return { ...questionDetails, value };
            });
          }

          return {
            ...pageDetails,
            questions,
          };
        }
      );

      setQuestionnairePages(questionnairePagesCloned);

      return nextPage();
    } catch (error: unknown) {
      return showErrorToast(error as AxiosError<CustomAxiosError>);
    }
  };

  if (isQuestionnaireLoading) {
    return <Typography>Loading...</Typography>;
  }

  const totalQuestions = pages.reduce(
    (acc, page) => acc + page.questions.length,
    0
  );

  const questions = currentPage.questions.map((i) => {
    if (i.questionType === 'phone') {
      i.required = localStorage.getItem('aB_Params_phoneRequired') === 'true';
    }

    return i;
  });

  return (
    <CoverStyled>
      <FormContainer centerChildren>
        <FormStyled onSubmit={handleSubmit(onSubmit)}>
          <Stack gutter="2.2rem">
            {!_isNil(currentPage) && pages.length >= 0 && (
              <>
                <QuestionnaireTracker
                  totalQuestions={totalQuestions}
                  currentQuestions={currentPage.questions.length}
                  currentStep={currentIndex + 1}
                  canSkip={currentPage.canSkip}
                  onClickSkipPage={onClickSkipPage}
                  pages={pages.length}
                  isFromHome={isFromHome}
                  goToPage={goToPage}
                />
                <QuestionnaireFormHeader>
                  <Typography name="heading2">{currentPage.heading}</Typography>
                  <Typography name="secondary">
                    {currentPage.subHeading}
                  </Typography>
                </QuestionnaireFormHeader>
                <QuestionnairePage
                  questions={questions}
                  control={control}
                  isSmallScreen={isSmallScreen}
                />
                <Button
                  size={isSmallScreen ? 'medium' : 'large'}
                  type="submit"
                  disabled={isLoading}
                >
                  {isLastPage ? 'Submit' : 'Continue'}
                </Button>
              </>
            )}
          </Stack>
        </FormStyled>
      </FormContainer>
    </CoverStyled>
  );
}
