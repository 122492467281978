import { z } from 'zod';

const dataSetSettingsSchema = z.object({
  rowLimit: z.coerce
    .number()
    .min(1, 'Row limit must be greater than 0')
    .max(100000, 'Row limit must be less than or equal to 100000'),
  cacheEnabled: z.boolean(),
  durationUnit: z.object({
    label: z.string(),
    value: z.string(),
  }),
  durationValue: z.coerce
    .number()
    .min(1, 'Duration must be greater than 0')
    .max(1000, 'Duration limit must be less than or equal to 1000'),
  timezone : z.any(),
  dateFormat : z.string()
});

export const dataSetSchema = z.object({
  name: z
    .string()
    .trim()
    .min(1, 'Data Source name cannot be empty')
    .max(100, 'Data Source name cannot be more than 100 characters'),
  query: z.string(),
  productionSettings: dataSetSettingsSchema,
});
