import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { Ref, forwardRef } from 'react';
import { ExecutedValueTooltip, Typography } from 'ui';

import { getPropertyIfExists } from '../../../../../utils/common';
import type { sendEventToGTMType } from '../../../types';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { ErrorPopoverPositioned } from '../../SimpleRule/Error/ErrorPopoverPositioned';
import { PropertyNodeStructure } from '../models';
import {
  LabelStyled,
  PropertyContainer,
  RuleLauncherContainer,
} from './TableNodes.styled';

type RuleLauncherProps = {
  error?: string;
  label?: string;
  currentProperty: PropertyNodeStructure;
  id: string;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
};

export const RuleLauncher = forwardRef(
  (
    {
      label = 'Property',
      id,
      error,
      currentProperty,
      handleSendEventToGTM,
    }: RuleLauncherProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const [dataset] = useAtom(dataSetParamsAtom);

    const source = currentProperty?.key;
    const attribute = currentProperty?.value;

    const propertyValue = !_isEmpty(label) ? `${label}` : 'Property';

    const message =
      !_isNil(source) && !_isEmpty(source) && !_isNil(dataset[source])
        ? getPropertyIfExists(
            JSON.parse(
              JSON.stringify(
                Object.keys(dataset[source].attributes).reduce((acc, curr) => {
                  return {
                    ...acc,
                    [curr]:
                      dataset[source ?? ''].attributes[`${curr}`].executedValue,
                  };
                }, {})
              )
            ) ?? {},
            attribute ?? ''
          )
        : attribute;

    const handleClick = () => {
      if (typeof handleSendEventToGTM === 'function') {
        handleSendEventToGTM({
          action: _isEmpty(label) ? 'add' : 'edit',
          element: 'property',
          actionName: currentProperty.dataType ?? '',
        });
      }
    };

    return (
      <RuleLauncherContainer padding={[3, 8]} onClick={handleClick}>
        {!_isNil(error) && <ErrorPopoverPositioned error={error ?? ''} />}

        <ExecutedValueTooltip
          value={message}
          attribute={attribute}
          source={source}
          showExecutedValue={false}
          id={id}
          dataType={currentProperty?.dataType}
        >
          <PropertyContainer stretch="start" align="center" ref={ref}>
            <Typography name="paragraphSmall">
              <LabelStyled>{propertyValue}</LabelStyled>
            </Typography>
            ▾
          </PropertyContainer>
        </ExecutedValueTooltip>
      </RuleLauncherContainer>
    );
  }
);

RuleLauncher.displayName = 'RuleLauncher';
