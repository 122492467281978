import { Inline, PadBox } from '@bedrock-layout/primitives';
import _isNil from 'lodash/isNil';
import styled, { css } from 'styled-components';

import type {
  TBodyCSSProps,
  TDProps,
  THeadProps,
  TableCSSProps,
} from './types';

export const ListingTable = styled.table<TableCSSProps>`
  margin-top: 1rem;
  border-spacing: 0px;
  border-collapse: collapse;
  max-inline-size: 100%;
  ${({ listType }) =>
    listType === 'SUBTABLE' &&
    css`
      margin-top: 1rem;
      border-collapse: inherit;
      border: 2px solid var(--color-tableBorder);
      border-radius: 0.8rem;
      border-top: 1px solid var(--color-tableBorder);
    `}
`;
export const ListingThead = styled.thead`
  border-top: 1px solid var(--color-tableBorder);
  border-bottom: 1px solid var(--color-tableBorder);
  display: table;
  width: 100%;
  table-layout: fixed;
`;
export const ListingTHead = styled.th<THeadProps>`
  font-size: 1.4rem;
  color: var(--color-suvaGray);
  text-align: left;
  padding: 0.7rem 0.7rem 0.7rem 2rem;
  word-wrap: break-word;

  ${({ width }) =>
    !_isNil(width) &&
    css`
      width: ${width};
    `}
`;

export const THeadInlineStyle = styled(Inline)`
  flex-wrap: wrap;
`;

export const PaginationContainer = styled(Inline)`
  justify-content: space-between;
  border-top: 1px solid #eee;
  align-items: center;
`;

export const UsageContainer = styled(PadBox)`
  inline-size: 20rem;
`;

export const ListingTBody = styled.tbody<TBodyCSSProps>`
  display: block;
  overflow: auto;
  ${({ gettingStartedCollapsed }) =>
    gettingStartedCollapsed
      ? `block-size: calc(100vh - 45rem)`
      : `block-size: calc(100vh - 60rem)`};

  @media (max-height: 500px) {
    block-size: 100%;
  }

  ${({ listType }) =>
    listType === 'SUBTABLE' &&
    css`
      height: auto;
    `}
`;
export const ListingTBodyTR = styled.tr`
  border-bottom: 1px solid var(--color-tableTRBorder);
  display: table;
  width: 100%;
  table-layout: fixed;
  cursor: pointer;
  min-block-size: 6.4rem;

  &:hover {
    background: var(--color-lightGray3);
  }
`;

export const ListingTD = styled.td<TDProps>`
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  color: var(--color-darkGray);
  font-size: 1.4rem;

  ${({ width }) =>
    !_isNil(width) &&
    css`
      width: ${width};
    `}
`;
