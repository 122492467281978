import { ApolloError } from '@apollo/client';
import { Inline } from '@bedrock-layout/primitives';
import { atom, useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiPlus } from 'react-icons/bi';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Typography, toasts, useLayer } from 'ui';

import { siteConstantsAtom } from '../../atom';
import { CloneEntityModal } from '../../components/CloneEntityModal/CloneEntityModal';
import { ListingComponent } from '../../components/Listing';
import { RuleTemplatePopup } from '../../components/Listing/gettingStarted/components/rulesTemplate';
import { EntityLevelAccessModal } from '../../components/Modals/EntityLevelAccessModal/EntityLevelAccessModal';
import { ExportEntityModal } from '../../components/Modals/ExportEntityModal/ExportEntityModal';
import {
  PermissionType,
  permissionObj,
} from '../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../components/PermissionComponent/hooks/useCheckPermissions';
import { PagesBodyContainer } from '../../components/layouts/PagesBodyContainer';
import { customAttributesAtom } from '../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import { useGetSiteMeta } from '../../hooks/useGetSiteMeta';
import { useSendEventToGTM } from '../../hooks/useSendEventToGTM';
import {
  handleGetConstantData,
  handleSetCheckSumByEntityName,
  setDateAndTZinContext,
} from '../../utils/common';
import { ENTITY_ID, ENTITY_MAP } from '../../utils/constant';
import {
  CreateRuleSheet,
  RuleTypes,
  dataSetParamsAtom,
  firstCustomAttributeAtom,
} from './components/CreateRuleSheet/CreateRuleSheet';
import { DeleteRuleModal } from './components/RulesTable/DeleteRuleModal/DeleteRuleModal';
import { simpleRuleNodesAtom } from './components/SimpleRule';
import { dataset } from './fixtures/dataset';
import { ruleInitialState } from './fixtures/ruleInitialState';
import { useDeleteRule } from './hooks/graphql/useDeleteRule';
import { useGetTableData } from './hooks/graphql/useGetTableData';
import type { TableResponseModel } from './hooks/graphql/useGetTableData';
import { FieldsByID } from './types';

export const createRuleSheetAtom = atom<RuleTypes>('');
export const isRuleReadOnlyAtom = atom(false);
export const isRuleTestOnlyAtom = atom(false);
export const isRulePublishOnlyAtom = atom(false);
export const isLiveNodeSheetClosedAtom = atom(true);
export const simpleRuleNodeIdAtom = atom<string | undefined>(undefined);
export const selectedDataSetAtom = atom<string[]>([]);
export const dataSetFieldsByIdAtom = atom<FieldsByID>({});
export const ruleLimitsConfigAtom = atom<Record<string, any>>({});
export const ruleWarningsAtom = atom<string[]>([]);

type RulesProp = {
  subModules: Record<string, boolean>;
  permissions: Record<PermissionType, boolean>;
  limits: Record<string, any>;
};

export function Rules(props: RulesProp) {
  const navigate = useNavigate();

  const { subModules, limits, permissions } = props;

  const { openWithProps: openRuleTemplatePopup } = useLayer(
    <RuleTemplatePopup
      title="Create Rule"
      type="RULE_TEMPLATE"
      selectedTab={0}
      subModules={subModules}
    />
  );

  const urlParams = new URLSearchParams(window.location.search);
  const paramsWsid = urlParams.get('wsid');

  if (!_isNil(paramsWsid) && !_isEmpty(paramsWsid)) {
    window.sessionStorage.setItem('workspaceUUID', paramsWsid);
  }

  const entity = ENTITY_MAP[window.location.pathname.split('/')[1]];

  const [, setLimitConfig] = useAtom(ruleLimitsConfigAtom);

  const { sendEventToGTM } = useSendEventToGTM();

  const { siteMeta } = useGetSiteMeta();

  const [listData, setListData] = useState<TableResponseModel | undefined>();

  const [getTableData, { data, refetch }] = useGetTableData();
  const [deleteRule] = useDeleteRule();

  const { isHide: editDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.create],
    entityList: [ENTITY_ID.rules],
  });

  const [searchParams] = useSearchParams();
  const { ruleId } = useParams();

  const [type, setType] = useState('');
  const [modal, setModal] = useState('');
  const [cloneRuleName, setCloneRuleName] = useState('');
  const [refreshListData, setRefreshListData] = useState(false);

  const [ruleType, setRuleType] = useAtom(createRuleSheetAtom);
  const [, setIsRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [, setIsLiveNodeSheetClosed] = useAtom(isLiveNodeSheetClosedAtom);
  const [siteConstant, setSiteConstant] = useAtom(siteConstantsAtom);
  const [, setRuleWarning] = useAtom(ruleWarningsAtom);

  const [, setRuleList] = useAtom(simpleRuleNodesAtom);
  const [, setCustomAttributes] = useAtom(customAttributesAtom);
  const [, setSelectedDataSets] = useAtom(selectedDataSetAtom);
  const [, setDatasetParams] = useAtom(dataSetParamsAtom);
  const [, setFirstCustomAttribute] = useAtom(firstCustomAttributeAtom);

  const resetRulesData = () => {
    setRuleList(structuredClone(ruleInitialState));
    setCustomAttributes({});
    setSelectedDataSets([]);
    setDatasetParams(dataset);
    setFirstCustomAttribute('');
    setRuleWarning([]);
  };

  useEffect(() => {
    if (_isNil(ruleId) || _isEmpty(ruleId)) {
      setTimeout(() => {
        resetRulesData();
      }, 0);
    }
  }, [ruleId]);

  const { openWithProps: openRuleSheet } = useLayer(
    <CreateRuleSheet refetch={refetch} from={type} />
  );

  const { openWithProps: openDeleteConfirmationModal } = useLayer(
    <DeleteRuleModal />
  );

  const { openWithProps: openCloneModal } = useLayer(
    <CloneEntityModal entityType="rule" />
  );

  const { openWithProps: openExportModal } = useLayer(
    <ExportEntityModal entityType="rule" />
  );

  const { openWithProps: openEntityAccessModal } = useLayer(
    <EntityLevelAccessModal />
  );

  const updateState = () => {
    setType(searchParams.get('type') ?? '');
    setModal(searchParams.get('modal') ?? '');
    setRuleType((searchParams.get('ruleType') as RuleTypes) ?? '');
    setCloneRuleName(searchParams.get('ruleName') ?? '');

    setIsLiveNodeSheetClosed(true);
  };

  useEffect(() => {
    updateState();
  }, [searchParams]);

  useEffect(() => {
    if (!_isNil(data)) setListData(data);
  }, [data]);

  useEffect(() => {
    setLimitConfig(limits);
  }, [limits]);

  useEffect(() => {
    if (type === 'view' || editDisable) {
      setIsRuleReadOnly(true);
    } else if (!_isEmpty(type)) {
      setIsRuleReadOnly(false);
    }
  }, [type, editDisable]);

  useEffect(() => {
    if (!_isNil(type) && !_isNil(ruleType) && type === 'create') {
      openRuleSheet({
        ruleName: ruleType,
        from: type,
      });
    }
  }, [ruleType, type]);

  useEffect(() => {
    if (
      !_isNil(type) &&
      !_isNil(ruleId) &&
      !_isNil(ruleType) &&
      (type === 'edit' || type === 'view')
    ) {
      openRuleSheet({
        ruleId,
        ruleName: ruleType,
        from: type,
      });
    }

    if (
      !_isNil(type) &&
      !_isNil(ruleId) &&
      !_isNil(ruleType) &&
      !_isEmpty(cloneRuleName) &&
      type === 'clone'
    ) {
      openRuleSheet({
        ruleId,
        ruleName: ruleType,
        from: 'edit',
        isClone: true,
      });
    }
  }, [ruleType, ruleId, type, cloneRuleName]);

  useEffect(() => {
    void handleGetConstantData(
      'rules,integrations,datasets',
      setSiteConstant,
      siteConstant
    );
  }, []);

  const handleRowClick = (data: any) => {

    if(!_isNil(data.settings)){
      setDateAndTZinContext({
        timezone:
          typeof data.settings?.timezone === 'object'
            ? data.settings?.timezone.value
            : data.settings?.timezone,
        dateFormat: data.settings?.dateFormat,
      });
    }

    sendEventToGTM({
      event: 'rule',
      source: 'listing',
      type: data.type,
      element: '',
      action: 'edit_click',
    });

    const state = 'staging';

    if (!_isNil(window.location.search) && !_isEmpty(window.location.search)) {
      window.sessionStorage.setItem('rulesRedirection', window.location.search);
    }
    navigate(
      `/rules/${data.id as string}?type=edit&stage=${state}&ruleType=${
        data.type as string
      }&wsid=${sessionStorage.getItem('workspaceUUID') as string}`
    );
  };

  const handleCloneActionClick = (data: any) => {
    sendEventToGTM({
      event: 'rule',
      source: 'listing',
      type,
      element: '',
      action: 'clone_click',
    });

    setRuleType(type as RuleTypes);
    handleSetCheckSumByEntityName('rule', data.checksum);

    openCloneModal({
      id: data.id,
      subType: data.type,
    });
  };

  const handleDeleteRule = async (
    id: string,
    checksum: string,
    type: string
  ) => {
    handleSetCheckSumByEntityName('rule', checksum);

    sendEventToGTM({
      event: 'rule',
      source: 'listing',
      type,
      element: '',
      action: 'delete_click',
    });

    try {
      await deleteRule({
        variables: {
          id,
          checksum,
        },
      });

      toasts.success('Rule deleted successfully', 'deleted-success');
      const response = await refetch();
      setListData(response.data);
    } catch (error) {
      if (error instanceof ApolloError) {
        if (error?.graphQLErrors[0]?.extensions?.code === 'server_error') {
          toasts.error('Failed to delete rule', 'error');
        } else {
          toasts.error(error.message, 'error');
        }
      }
    }
  };

  const handleDeleteActionClick = async (data: any) => {
    await handleDeleteRule(data.id, data.checksum, data.ruleType);
  };

  const updateListingData = () => {
    setRefreshListData(true);
  };

  const handleActionColumnClick = useCallback((actionData: any) => {
    const { type, data } = actionData;

    if (type === 'clone') {
      handleCloneActionClick(data);
    } else if (type === 'delete') {
      openDeleteConfirmationModal({
        onDeleteClick: async () => await handleDeleteActionClick(data),
      });
    } else if (type === 'export') {
      openExportModal({
        entityId: data.id,
        entityName: 'rule',
        isLive: data.isLive,
        version: data.versionInfo.currentVersion,
      });
    } else if (type === 'edit_access') {
      const { id, name, type, status, accessRole } = data;
      openEntityAccessModal({
        entityInfo: {
          id,
          name,
          type: 'rule',
          status,
          subType: type,
          accessRole,
        },
        updateListingData,
      });
    }
  }, []);

  const handleCreateRule = () => {
    openRuleTemplatePopup({});
  };
  useEffect(() => {
    if (modal === 'create') {
      handleCreateRule();
    }
  }, [modal]);
  const HeaderComponent = useCallback(() => {
    return (
      <Inline gutter={25}>
        <Typography name="heading1" fontWeight={700}>
          Rules
        </Typography>
        <Button
          disabled={editDisable}
          onClick={handleCreateRule}
          leadingIcon={<BiPlus size={20} />}
        >
          Create Rule
        </Button>
      </Inline>
    );
  }, [editDisable]);

  return (
    <PagesBodyContainer>
      <Helmet>
        <title>
          {siteMeta[window.location.pathname.substring(1)]?.title ?? ''}
        </title>
        <meta
          name="description"
          content={
            siteMeta[window.location.pathname.substring(1)]?.description ?? ''
          }
        />
      </Helmet>
      <ListingComponent
        entity={entity}
        headerTitle="Rules"
        callOnRowClick={handleRowClick}
        callOnCellClick={() => {}}
        errorTitle="No Rules found"
        HeaderComponent={HeaderComponent as any}
        isGraphQL={true}
        getListDataQuery={getTableData}
        queryListData={listData}
        handleActionItemClick={handleActionColumnClick}
        permissions={permissions}
        subModules={subModules}
        isCreateDisable={editDisable}
        refreshListData={refreshListData}
        setRefreshListData={setRefreshListData}
      />
    </PagesBodyContainer>
  );
}
