import _isNil from 'lodash/isNil';
import { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { Dataset, NectedSuggestionModel } from 'ui';

import { ResultType } from '../../pages/Rules/components/SimpleRule/Results';
import type { FieldNodeTypes } from '../../pages/Rules/components/SimpleRule/models';
import { fieldByDataType } from './FieldComponents';

type FieldByDataTypeProps = UseControllerProps<any> & {
  dataType: string;
  showError?: boolean;
  index?: number;
  suggestions?: string[];
  suggestionObjs?: NectedSuggestionModel[];
  disabled?: boolean;
  section?: ResultType;
  isSmallInput?: boolean;
  isAdditionalData?: boolean;
  hideOptionalCustomAttributes?: boolean;
  setValue?: UseFormSetValue<any>;
  returnTypeName?: string;
  executedValueName?: string;
  additionalDataIndex?: number;
  dataSet?: Record<string, Dataset>;
  hidePopover: () => void;
};

function isOfDataType(dataType: string): dataType is FieldNodeTypes {
  return [
    'string',
    'numeric',
    'boolean',
    'date',
    'json',
    'jsFormula',
    'excelFormula',
    'list',
  ].includes(dataType);
}

export function FieldByDataType({
  control,
  dataType,
  name,
  rules,
  showError = false,
  index,
  suggestions,
  suggestionObjs = [],
  disabled = false,
  section,
  isSmallInput = false,
  isAdditionalData,
  hideOptionalCustomAttributes,
  setValue,
  returnTypeName,
  executedValueName,
  additionalDataIndex,
  dataSet,
  hidePopover
}: FieldByDataTypeProps) {
  const Component = isOfDataType(dataType) ? fieldByDataType[dataType] : null;

  if (_isNil(Component)) {
    return null;
  }

  return (
    <Component
      dataType={dataType}
      showError={showError}
      control={control}
      nodeName={name}
      rules={rules}
      index={index}
      suggestions={suggestions}
      disabled={disabled}
      section={section}
      isSmallInput={isSmallInput}
      isAdditionalData={isAdditionalData}
      hideOptionalCustomAttributes={hideOptionalCustomAttributes}
      suggestionObjs={suggestionObjs}
      setOriginalValue={setValue}
      returnTypeName={returnTypeName}
      executedValueName={executedValueName}
      additionalDataIndex={additionalDataIndex}
      dataSet={dataSet ?? {}}
      hidePopover={hidePopover}
    />
  );
}
