import * as Sentry from '@sentry/react';
import type { SeverityLevel } from '@sentry/types';

const isSentryInitialized = (): boolean => {
  try {
    return Sentry.getCurrentHub().getClient() !== undefined;
  } catch (e) {
    return false;
  }
};

type MetaPayload = {
  context?: string;
  extra?: Record<string, any>;
  level?: SeverityLevel;
};

export const reportToSentry = (
  error: Error | string,
  metaPayload: MetaPayload = {},
  captureContext: boolean = true
): string | null => {
  try {
    // Check if Sentry is initialized
    if (!isSentryInitialized()) {
      console.warn('Sentry is not initialized, error will not be reported');
      return null;
    }

    const errorObject = typeof error === 'string' ? new Error(error) : error;

    // Use an object with known Sentry properties instead of the CaptureContext type
    const sentryContext: {
      tags?: Record<string, string>;
      extra?: Record<string, any>;
      level?: SeverityLevel;
      user?: {
        [key: string]: any;
      };
      attachStacktrace?: boolean;
    } = {
      tags: {},
      extra: { ...metaPayload.extra },
      level: metaPayload.level || 'error',
    };

    try {
      const workspaceUUID = sessionStorage.getItem('workspaceUUID');
      const userUUID = localStorage.getItem('userUUID');
      const userType = localStorage.getItem('userType');
      if (userUUID) {
        sentryContext.user = {
          userUUID,
          workspaceUUID,
          userType,
        };
      }
    } catch (sessionError) {
      console.warn(
        'Could not retrieve user data from session storage:',
        sessionError
      );
    }

    sentryContext.attachStacktrace = true;

    if (captureContext && typeof window !== 'undefined') {
      const urlPath = window.location.pathname;
      sentryContext.tags!.urlPath = urlPath;

      // Create additional categorized tags based on URL structure
      const pathSegments = urlPath.split('/').filter(Boolean);

      if (pathSegments.length > 0) {
        sentryContext.tags!.section = pathSegments[0];

        if (pathSegments.length > 1) {
          sentryContext.tags!.subsection = pathSegments[1];
        }
      }

      // Tag for specific page types if they exist in the URL
      if (urlPath.includes('/rules')) {
        sentryContext.tags!.pageType = 'rules';
      } else if (urlPath.includes('/workflow')) {
        sentryContext.tags!.pageType = 'workflow';
      } else if (urlPath === '/home' || urlPath === '/') {
        sentryContext.tags!.pageType = 'home';
      } else if (urlPath.includes('/customapp')) {
        sentryContext.tags!.pageType = 'customapp';
      } else if (urlPath.includes('/auditlogs')) {
        sentryContext.tags!.pageType = 'auditlogs';
      } else if (urlPath.includes('/credentials')) {
        sentryContext.tags!.pageType = 'credentials';
      } else if (urlPath.includes('/integrations')) {
        sentryContext.tags!.pageType = 'integrations';
      } else if (urlPath.includes('/datasets')) {
        sentryContext.tags!.pageType = 'datasets';
      } else if (urlPath.includes('/remoteconfig')) {
        sentryContext.tags!.pageType = 'remoteconfig';
      } else if (urlPath.includes('/module')) {
        sentryContext.tags!.pageType = 'module';
      } else if (urlPath.includes('/workspace/settings')) {
        sentryContext.tags!.pageType = 'workspace-settings';
      } else if (urlPath.includes('/workspace/team')) {
        sentryContext.tags!.pageType = 'workspace-team';
      } else if (urlPath.includes('/workspace/embed')) {
        sentryContext.tags!.pageType = 'workspace-embed';
      } else if (urlPath.includes('/questionnaire')) {
        sentryContext.tags!.pageType = 'questionnaire';
      }

      sentryContext.tags!.browser = navigator.userAgent;
      sentryContext.tags!.viewport = `${window.innerWidth}x${window.innerHeight}`;

      if (window.performance) {
        const perfData = window.performance.timing;
        sentryContext.extra!.performance = {
          loadTime: perfData.loadEventEnd - perfData.navigationStart,
          domReady: perfData.domComplete - perfData.domLoading,
          networkLatency: perfData.responseEnd - perfData.requestStart,
        };
      }

      try {
        if (
          window.__REACT_DEVTOOLS_GLOBAL_HOOK__ &&
          window.__REACT_DEVTOOLS_GLOBAL_HOOK__.getCurrentFiber
        ) {
          const fiber = window.__REACT_DEVTOOLS_GLOBAL_HOOK__.getCurrentFiber();
          if (fiber) {
            sentryContext.extra!.componentStack = getComponentStack(fiber);
          }
        }
      } catch (devToolsError) {
        // Ignore errors
      }
    }

    if (metaPayload.context) {
      sentryContext.tags!.context = metaPayload.context;
    }

    if (!errorObject.stack && Error.captureStackTrace) {
      Error.captureStackTrace(errorObject, reportToSentry);
    }

    if (
      typeof window !== 'undefined' &&
      window.__REACT_ERROR_OVERLAY_GLOBAL_HOOK__
    ) {
      sentryContext.extra!.reactLifecycle =
        window.__REACT_ERROR_OVERLAY_GLOBAL_HOOK__.currentHookName || 'unknown';
    }

    if (navigator.connection) {
      sentryContext.extra!.network = {
        type: navigator.connection.effectiveType,
        downlink: navigator.connection.downlink,
        rtt: navigator.connection.rtt,
        saveData: navigator.connection.saveData,
      };
    }

    if (navigator.deviceMemory) {
      sentryContext.extra!.deviceMemory = `${navigator.deviceMemory}GB`;
    }

    const transaction = Sentry.startTransaction({
      name: `error_handling:${metaPayload.context || 'unknown'}`,
      op: 'error',
    });

    try {
      const eventId = Sentry.captureException(errorObject, sentryContext);
      transaction.finish();
      return eventId;
    } catch (sentryError) {
      console.error('Failed to report error to Sentry:', sentryError);
      console.error('Original error:', error);
      transaction.setStatus('internal_error');
      transaction.finish();
      return null;
    }
  } catch (e) {
    console.error('Error in reportToSentry function:', e);
    return null;
  }
};

function getComponentStack(fiber: any): string {
  const stack: string[] = [];

  while (fiber) {
    if (fiber.type && typeof fiber.type !== 'string') {
      const name = fiber.type.displayName || fiber.type.name || 'Unknown';
      stack.push(name);
    }
    fiber = fiber.return;
  }

  return stack.join(' > ');
}

// Make reportToSentry available as a window function
if (typeof window !== 'undefined') {
  (window as any).reportToSentry = reportToSentry;
}

// For type augmentation to accommodate React DevTools and our global function
declare global {
  interface Window {
    __REACT_DEVTOOLS_GLOBAL_HOOK__?: {
      getCurrentFiber?: () => any;
    };
    __REACT_ERROR_OVERLAY_GLOBAL_HOOK__?: {
      currentHookName?: string;
    };
    reportToSentry?: (
      error: Error | string,
      metaPayload?: MetaPayload,
      captureContext?: boolean
    ) => string | null;
  }

  interface Navigator {
    connection?: {
      effectiveType: string;
      downlink: number;
      rtt: number;
      saveData: boolean;
    };
    deviceMemory?: number;
  }
}

export default reportToSentry;