import { z } from 'zod';

import { workspaceSettingsObject } from './constant';

const {
  WORKSPACE_NAME,
  APPROVAL_FLOW_ENABLED,
  WORKSPACE_THEME,
  WORKSPACE_THEME_ENABLED,
  ENFORCE_MODULE_SCHEMA,
  TIMEZONE,
  DATE_FORMAT,
} = workspaceSettingsObject;

export const settingsFormSchema = z.object({
  [WORKSPACE_NAME]: z.string().min(1, 'Workspace name cannot be empty'),
  [APPROVAL_FLOW_ENABLED]: z.boolean().optional(),
  [ENFORCE_MODULE_SCHEMA]: z.boolean().optional(),
  [WORKSPACE_THEME]: z.any(),
  [WORKSPACE_THEME_ENABLED]: z.any(),
  [TIMEZONE]: z
    .object({
      label: z.string().optional(),
      value: z.string().optional(),
    })
    .optional(),
  [DATE_FORMAT]: z.string().optional(),
});
