import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const Container = styled(Inline)`
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border: 1px solid var(--color-primary2);
  background: var(--color-primary2);
`;
