import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import { useEffect, useState } from 'react';
import { UseFormSetValue, useForm } from 'react-hook-form';
import {
  Button,
  Dataset,
  EditorLanguages,
  NectedEditorField,
  NectedSuggestionModel,
  Sheet,
  SheetSize,
  Typography,
  toasts,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { siteConstantsAtom } from '../../../../../atom';
import { HowToLink } from '../../../../../components/HowToLink/HowToLink';
import { customAttributesAtom } from '../../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import { getTooltipText, isValidJson } from '../../../../../utils/common';
import { editorDomain } from '../../../../../utils/constant';
import { useGenerateDataset } from '../../../../Workflow/hooks/useGenerateDataset';
import { sanitizedStringV2 } from '../../../../Workflow/utils/common';
import { useUpdateExecutedValueRules } from '../../../hooks/useUpdateExecutedValueRules';
import { isRuleReadOnlyAtom, selectedDataSetAtom } from '../../../index';
import { jsNodeResultQuery } from '../../../schema';
import {
  filterDataSetSuggestionObjBySection,
  filterDataSetSuggestionsBySection,
  getFilteredDataSetObjWithoutOptional,
  getFilteredDataSetWithoutOptional,
  updateDataSetOnChange,
} from '../../../utils/common';
import {
  dataSetParamsAtom,
  sectionAtom,
} from '../../CreateRuleSheet/CreateRuleSheet';
import { SyntaxErrorContainer } from '../../RuleComponents/RuleComponents.styled';
import { suggestionsAtom } from './JsonNodePill';
import {
  JsonBlockContainer,
  JsonFieldContainer,
  SaveButtonContainer,
} from './JsonNodeSheet.styled';
import { OnCloseNodeModal } from './OnCloseNodeModal';
import type { ResultType } from './Results';
import { SaveNodeModal } from './SaveNodeModal';

export type JsonNodeSheetV2Props = {
  index: number;
  section: ResultType;
  hideOptionalCustomAttributes?: boolean;
  suggestionObjs?: NectedSuggestionModel[];
  setOriginalValue?: UseFormSetValue<any>;
  onChangeSpecial?: (value: any) => void;
  returnTypeName?: string;
  executedValueName?: string;
  hideSuggestions?: boolean;
  size?: SheetSize;
  isJsonInCustomInput?: boolean;
  onClose?: () => void;
  overrideValue?: any;
  dataSet?: Record<string, Dataset>;
  type: string;
  disabled?: boolean;
  nodeQuery?: string;
};

type JsonNodeSheetV2Result = {
  nodeQuery: string;
};

export function JsonNodeSheetV2({
  type,
  index,
  section,
  hideOptionalCustomAttributes = false,
  suggestionObjs = [],
  setOriginalValue,
  onChangeSpecial,
  returnTypeName,
  executedValueName,
  hideSuggestions = false,
  size = 'medium',
  isJsonInCustomInput = false,
  onClose,
  dataSet = {},
  overrideValue,
  disabled = false,
  nodeQuery,
}: JsonNodeSheetV2Props) {
  const [dataSetVariables] = useAtom(dataSetParamsAtom);
  const [suggestions] = useAtom(suggestionsAtom);
  const [isReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [currentSection] = useAtom(sectionAtom);
  const [customAttributes] = useAtom(customAttributesAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [dataSetSelected] = useAtom(selectedDataSetAtom);

  const { openWithProps: openSaveNodeModal } = useLayer(<SaveNodeModal />);
  const { close } = useCurrentLayer();

  const { open: onCloseModal } = useLayer(
    <OnCloseNodeModal onClose={() => close()} />
  );

  const [, setDataSetTokens] = useState<string[]>([]);
  const [datasetTokensObj, setDataSetTokensObjs] = useState<
    NectedSuggestionModel[]
  >([]);
  const [isQueryValidNected, setIsQueryValidNected] = useState(true);
  const [isReturnTypeRequired, setIsReturnTypeRequired] = useState(false);
  const [executedValue, setExecutedValue] = useState<any | null>(null);
  const [, setReturnType] = useState<string | null>(null);
  const [hasChanged, setHasChanged] = useState(false);

  const [updatedDataset, setUpdatedDataset] = useState<Record<string, Dataset>>(
    {}
  );

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const {
    control: localControl,
    handleSubmit,
    watch,
  } = useForm<JsonNodeSheetV2Result>({
    resolver: zodResolver(jsNodeResultQuery),
    defaultValues: {
      nodeQuery:
        !_isNil(overrideValue) && overrideValue !== ''
          ? typeof overrideValue === 'string'
            ? overrideValue
            : JSON.stringify(overrideValue)
          : _isEmpty(nodeQuery) || _isNil(nodeQuery)
          ? getTooltipText(
              siteConstants,
              'rules',
              isJsonInCustomInput ? `${type}InCustomInput` : `${type}InResult`,
              'otherText'
            )
          : typeof nodeQuery === 'string'
          ? nodeQuery
          : JSON.stringify(nodeQuery),
    },
    mode: 'onSubmit',
  });

  const localQuery = watch('nodeQuery');

  const comment = getTooltipText(
    siteConstants,
    'rules',
    isJsonInCustomInput ? `${type}InCustomInput` : `${type}InResult`,
    'otherText'
  );

  const handleSave = () => {
    if (typeof onClose === 'function') {
      onClose();
    }

    if (!isQueryValidNected) {
      toasts.error(
        'You still have some errors in the editor. Please resolve to proceed',
        'error'
      );

      return;
    }

    if (_isEmpty(localQuery)) {
      toasts.warning('Put your JSON comment here', 'warning');
    }

    if (_isNil(executedValueName)) {
      if (typeof onChangeSpecial === 'function') {
        onChangeSpecial(localQuery);
      }

      close();
    }

    setIsReturnTypeRequired(true);
  };

  const onSetReturnType = (type: any) => {
    if (type === 'undefined' || type === null) {
      setReturnType('undefined');

      toasts.error(
        'The type of the last statement cannot be null or undefined',
        'type-null-undefined'
      );
    }

    setReturnType(type);
    setIsReturnTypeRequired(false);
  };

  const { tokens: dataSetSuggestionsObj } = useGenerateDataset({
    updatedDataset,
  });

  const { executedValue: execValues, handleGetExecutionValues } =
    useUpdateExecutedValueRules({
      updatedDataset,
    });

  useEffect(() => {
    let filteredDataSetVariablesObj = filterDataSetSuggestionObjBySection(
      dataSetSuggestionsObj,
      section
    );

    if (hideOptionalCustomAttributes) {
      filteredDataSetVariablesObj = getFilteredDataSetObjWithoutOptional(
        filteredDataSetVariablesObj,
        customAttributes
      );
    }

    setDataSetTokensObjs([...filteredDataSetVariablesObj]);
  }, [JSON.stringify(dataSetSuggestionsObj)]);

  useEffect(() => {
    setUpdatedDataset((prev) => ({
      ...prev,
      ...dataSet,
    }));
  }, [JSON.stringify(dataSet)]);

  useEffect(() => {
    if (!_isNil(dataSetVariables)) {
      const dataSetSuggestions = _reduce(
        dataSetVariables,
        (result: string[], value, key) => {
          if (!_isNil(value.attributes)) {
            return [
              ...result,
              ..._map(value.attributes, (attributeValue, attributeKey) => {
                if (
                  ['string', 'dateTime', 'date'].includes(
                    attributeValue.dataType
                  )
                ) {
                  return `"<<${key}.${attributeKey}>>"`;
                }

                return `<<${key}.${attributeKey}>>`;
              }),
            ];
          }

          return result;
        },
        []
      );

      setUpdatedDataset((prev) => ({
        ...prev,
        ...updateDataSetOnChange(
          customAttributes,
          dataSetVariables,
          dataSetSelected,
          false,
          true
        ),
      }));

      let tokenSuggestions = suggestions.reduce<string[]>(
        (result, curr, currentIndex) => {
          if (index > currentIndex) {
            return [...result, curr];
          }

          return result;
        },
        []
      );

      let filteredDataSetVariables = filterDataSetSuggestionsBySection(
        dataSetSuggestions,
        section
      );

      if (hideOptionalCustomAttributes) {
        filteredDataSetVariables = getFilteredDataSetWithoutOptional(
          filteredDataSetVariables,
          customAttributes
        );

        tokenSuggestions = getFilteredDataSetWithoutOptional(
          tokenSuggestions,
          customAttributes
        );
      }

      setDataSetTokens([...filteredDataSetVariables, ...tokenSuggestions]);
    }
  }, [
    JSON.stringify(dataSetVariables),
    JSON.stringify(suggestions),
    JSON.stringify(suggestionObjs),
    index,
    currentSection,
    JSON.stringify(customAttributes),
  ]);

  useEffect(() => {
    if (
      !_isEmpty(localQuery) &&
      isMounted &&
      typeof setOriginalValue === 'function'
    ) {
      if (
        !_isNil(executedValueName) &&
        !_isEmpty(executedValueName) &&
        !_isNil(executedValue)
      ) {
        setOriginalValue(executedValueName, executedValue);
        close();
      }
    }
  }, [executedValue]);

  const onExecutionValueSave = (execValue: any) => {
    if (!_isUndefined(execValue)) {
      setExecutedValue(execValue);
    } else if (!_isNil(executedValueName) || !_isNil(returnTypeName)) {
      setExecutedValue(undefined);
      openSaveNodeModal({
        onSave: () => {
          if (!_isNil(setOriginalValue)) {
            if (!_isNil(returnTypeName) && !_isEmpty(returnTypeName)) {
              setOriginalValue(returnTypeName, undefined);
            }

            if (!_isNil(executedValueName) && !_isEmpty(executedValueName)) {
              setOriginalValue(executedValueName, undefined);
            }
          }

          close();
        },
      });
    }
  };

  const stringWithReplacedToken = !_isEmpty(localQuery)
    ? sanitizedStringV2(localQuery ?? '', updatedDataset)
    : localQuery;

  return (
    <Sheet
      onClose={() => {
        if (hasChanged) {
          onCloseModal();
        } else {
          close();
        }
      }}
      size={size}
    >
      <JsonBlockContainer as="form" onSubmit={handleSubmit(handleSave)}>
        <PadBox padding="1rem" style={{ blockSize: 'inherit' }}>
          <Stack gutter="2rem">
            <Inline align="center" stretch="start">
              <Typography name="heading1">
                {type === 'json' ? 'JSON' : 'List'} Editor
              </Typography>
              <HowToLink
                link={getTooltipText(
                  siteConstants,
                  'rules',
                  'jsonEditorHowTo',
                  'howToLinks'
                )}
              />
            </Inline>
            <JsonFieldContainer>
              <NectedEditorField
                name="nodeQuery"
                control={localControl}
                showError={false}
                customSuggestions={hideSuggestions ? [] : datasetTokensObj}
                defaultValue={comment}
                mode={type as EditorLanguages}
                setReturnType={onSetReturnType}
                onSetEditorValidity={setIsQueryValidNected}
                setExecutedValue={onExecutionValueSave}
                sendReturnType={isReturnTypeRequired}
                setHasEditorChanged={setHasChanged}
                domain={editorDomain}
                execValues={execValues}
                handleGetExecData={handleGetExecutionValues}
                readOnly={isReadOnly || disabled}
              />
            </JsonFieldContainer>

            {!_isEmpty(localQuery) &&
              !isValidJson(stringWithReplacedToken) &&
              localQuery !== comment && (
                <SyntaxErrorContainer padding="1rem">
                  <Typography>
                    The statement written here is syntactically incorrect
                  </Typography>
                </SyntaxErrorContainer>
              )}
          </Stack>
        </PadBox>
        <SaveButtonContainer padding={[4, 8]}>
          <Inline justify="end">
            <Button
              appearance="contained"
              type="submit"
              disabled={isReadOnly || disabled}
            >
              Save
            </Button>
          </Inline>
        </SaveButtonContainer>
      </JsonBlockContainer>
    </Sheet>
  );
}
