import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { UseControllerProps, UseFormSetValue, useWatch } from 'react-hook-form';
import { RiErrorWarningLine } from 'react-icons/ri';
import {
  Dataset,
  OnClickRuleArgs,
  PopoverMethods,
  PopoverPanel,
  RuleField,
  RulePopover,
  Typography,
} from 'ui';

import { isRuleReadOnlyAtom, selectedDataSetAtom } from '../..';
import { predefineTokenDatasetAtom } from '../../../../atom';
import {
  TokenSelectionPopover,
  TokenSelectionType,
} from '../../../../components/TokenComponents/TokenSelectionPopover/TokenSelectionPopover';
import { InfoComponentContainer } from '../../../../components/TokenComponents/TokenSelectionPopover/TokenSelectionPopover.styled';
import { getUsedSchemaAttributes } from '../../../../components/TokenComponents/utils/helperFunction';
import { customAttributesAtom } from '../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import {
  extractSourceAndAttributeFromValue,
  isFieldReadOnly,
} from '../../../../utils/common';
import type { sendEventToGTMType } from '../../types';
import {
  getDataSetByType,
  getDefaultValueByDataTypeV2,
  removeCustomFunction,
  updateDataSetOnChange,
} from '../../utils/common';
import { dataSetParamsAtom } from '../CreateRuleSheet/CreateRuleSheet';
import { getAllIdsExceptFirst } from '../DecisionTable/TableNodes/RhsNode';
import { RhsInputContainer } from './ResultRhs.styled';
import { RhsLauncher } from './RhsLauncher';

export type ResultConditionType = 'workflowDT' | '';

type RhsOptions = {
  updateDataType?: boolean;
};

type RhsParamsPopoverProps = Omit<UseControllerProps<any>, 'name'> & {
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
  dataType: string;
  componentType?: string;
  placeholder?: string;
  setValue?: UseFormSetValue<any>;
  getValues?: UseFormSetValue<any>;
  nodeName: string;
  keyName?: string;
  isSmall?: boolean;
  updatedDataSet?: Record<string, Dataset>;
  isFull?: boolean;
  type?: ResultConditionType;
  disabled?: boolean;
  allowList?: boolean;
  typesToAllow?: string[];
  onlyJson?: boolean;
  size?: string;
  onSelect?: () => void;
  showTooltip?: boolean;
  showExpandIconIfList?: boolean;
  sourceKey?: string;
  attributeKey?: string;
  nodeTypeName?: string;
  rightIcon?: any;
  otherKeys?: Record<string, any>;
  forceExpandListIcon?: boolean;
  readOnly?: boolean;
  header?: ReactNode;
  idsToExpand?: string[];
  idsToNotExpand?: string[];
  options?: RhsOptions;
  inputType?: 'input' | 'textarea';
  isRelativeDate?: boolean;
  useTokenSelectionPopover?: boolean;
  schemaId?: string;
  isSchemaMandatory?: boolean;
  popoverTabsToShow?: string[];
  handleOpenEditorSheet?: (type: string) => void;
};

export const ResultRhs = ({
  getValues,
  componentType = 'default',
  control,
  handleSendEventToGTM,
  dataType,
  setValue,
  nodeName,
  keyName,
  isSmall,
  isFull,
  updatedDataSet,
  type = '',
  disabled = false,
  allowList = false,
  typesToAllow,
  onlyJson = false,
  size = '46rem',
  showTooltip = false,
  onSelect,
  showExpandIconIfList = true,
  sourceKey = 'source',
  attributeKey = 'attribute',
  nodeTypeName,
  rightIcon,
  otherKeys,
  forceExpandListIcon = false,
  readOnly,
  header,
  idsToExpand = [],
  idsToNotExpand = [],
  options = {
    updateDataType: true,
  },
  inputType = 'input',
  isRelativeDate = false,
  useTokenSelectionPopover = false,
  schemaId,
  isSchemaMandatory = false,
  popoverTabsToShow = ['token', 'predefined', 'customFunction'],
  handleOpenEditorSheet,
  placeholder,
}: RhsParamsPopoverProps) => {
  const [dataset] = useAtom(dataSetParamsAtom);
  const [dataSetSelected] = useAtom(selectedDataSetAtom);
  const [customAttributes] = useAtom(customAttributesAtom);
  const [predefineTokenDataset] = useAtom(predefineTokenDatasetAtom);

  const [showInfoComponent, setShowInfoComponent] = useState(false);
  const [tokenSelectionTabsToShow, setTokenSelectionTabsToShow] =
    useState(popoverTabsToShow);

  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const [panelVisible, setPanelVisible] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filteredDataSet, setFilteredDataSet] = useState(dataset);

  useEffect(() => {
    if (
      _isNil(updatedDataSet) &&
      !_isUndefined(dataType) &&
      !_isEmpty(dataType)
    ) {
      const dataSetWithSameDataType = getDataSetByType(
        dataset,
        dataType,
        dataSetSelected
      );

      setFilteredDataSet(
        updateDataSetOnChange(
          customAttributes,
          dataSetWithSameDataType,
          dataSetSelected,
          true,
          true
        )
      );
    }
  }, [dataset, dataSetSelected]);

  useEffect(() => {
    const usedSchemaObj = getUsedSchemaAttributes(
      predefineTokenDataset,
      schemaId
    );

    if (!_isNil(usedSchemaObj) && !_isNil(usedSchemaObj?.usageType)) {
      setShowInfoComponent(usedSchemaObj.usageType === 'mandatory');
    }

    if (_isNil(usedSchemaObj) || _isEmpty(usedSchemaObj)) {
      setTokenSelectionTabsToShow(
        popoverTabsToShow.filter((val) => val !== 'predefined')
      );
    }
  }, [JSON.stringify(predefineTokenDataset), schemaId]);

  let source: string | null | undefined = useWatch({
    control,
    name: `${nodeName}.${sourceKey}`,
  });

  let attribute: string | null | undefined = useWatch({
    control,
    name: `${nodeName}.${attributeKey}`,
  });

  const value = useWatch({ control, name: `${nodeName}.value` });

  if (isRelativeDate) {
    const datasetToUse = _isNil(updatedDataSet) ? dataset : updatedDataSet;
    source = extractSourceAndAttributeFromValue(
      value?.value ?? '',
      datasetToUse
    ).source;
    attribute = extractSourceAndAttributeFromValue(
      value?.value ?? '',
      datasetToUse
    ).attribute;
  }

  const title = isRelativeDate
    ? value?.value ?? ''
    : !_isNil(source) &&
      !_isNil(attribute) &&
      !_isEmpty(source) &&
      !_isEmpty(attribute)
    ? `${source}.${attribute}`
    : value;

  const ref = useRef<PopoverMethods>(null);

  const onItemClick = ({
    value,
    key,
    dataType: dType,
    executedValue,
  }: OnClickRuleArgs) => {
    if (typeof handleSendEventToGTM === 'function') {
      handleSendEventToGTM({
        action: 'edit',
        element: 'rhs_value',
        actionName: dType,
      });
    }

    if (!isRelativeDate) {
      if (typeof setValue === 'function') {
        setValue(`${nodeName}.value`, null);
        if (componentType === 'resetVariableKey') {
          // For resetVariableKey, set keySource and keyAttribute
          setValue(`${nodeName}.keySource`, key);
          setValue(`${nodeName}.keyAttribute`, value);
          setValue(`${nodeName}.key`, nodeName);
          setValue(`${nodeName}.mapping`, true);
          setValue(`${nodeName}.keyName`, nodeName);

          // Don't touch source and attribute, as they're needed by resetVariableValue

          if (!_isNil(otherKeys)) {
            Object.keys(otherKeys).forEach((k) => {
              setValue(k, otherKeys[k]);
            });
          }

          if (typeof onSelect === 'function') {
            onSelect();
          }
        } else if (componentType === 'resetVariableValue') {
          // For resetVariableValue, set source and attribute
          setValue(`${nodeName}.source`, key);
          setValue(`${nodeName}.attribute`, value);
          setValue(`${nodeName}.mapping`, true);
          setValue(`${nodeName}.keyName`, nodeName);

          // Don't touch keySource and keyAttribute, as they're needed by resetVariableKey

          setValue(
            `${nodeName}.dataType`,
            !!options?.updateDataType ? dType : dataType
          );

          setValue(
            `${nodeName}.executedValue`,
            executedValue ?? getDefaultValueByDataTypeV2(dType)
          );

          if (!_isNil(schemaId) && !_isEmpty(schemaId)) {
            setValue(`${nodeName}.schemaId`, schemaId);
          }

          if (!_isNil(otherKeys)) {
            Object.keys(otherKeys).forEach((k) => {
              setValue(k, otherKeys[k]);
            });
          }

          if (!_isNil(nodeTypeName)) {
            setValue(`${nodeTypeName}`, 'params');
          }

          if (typeof onSelect === 'function') {
            onSelect();
          }
        } else {
          setValue(`${nodeName}.${sourceKey}`, key);
          setValue(`${nodeName}.${attributeKey}`, value);

          setValue(
            `${nodeName}.dataType`,
            !!options?.updateDataType ? dType : dataType
          );

          setValue(
            `${nodeName}.executedValue`,
            executedValue ?? getDefaultValueByDataTypeV2(dType)
          );

          if (!_isNil(keyName) && !nodeName.includes('additionalData')) {
            setValue(`${nodeName}.keyName`, keyName);
          } else {
            setValue(`${nodeName}.name`, keyName);
          }

          if (!_isNil(schemaId) && !_isEmpty(schemaId)) {
            setValue(`${nodeName}.schemaId`, schemaId);
          }

          if (!_isNil(otherKeys)) {
            Object.keys(otherKeys).forEach((k) => {
              setValue(k, otherKeys[k]);
            });
          }

          if (!_isNil(nodeTypeName)) {
            setValue(`${nodeTypeName}`, 'params');
          }

          if (typeof onSelect === 'function') {
            onSelect();
          }
        }
      }
    } else {
      if (typeof setValue === 'function') {
        setValue(`${nodeName}.value.value`, `{{.${key}.${value}}}`);

        setValue(
          `${nodeName}.dataType`,
          // eslint-disable-next-line
          !!options?.updateDataType ? dType : dataType
        );

        setValue(`${nodeName}.executedValue`, null);

        if (!_isNil(keyName) && !nodeName.includes('additionalData')) {
          setValue(`${nodeName}.keyName`, keyName);
        } else {
          setValue(`${nodeName}.name`, keyName);
        }

        if (!_isNil(otherKeys)) {
          Object.keys(otherKeys).forEach((k) => {
            setValue(k, otherKeys[k]);
          });
        }

        if (!_isNil(nodeTypeName)) {
          setValue(`${nodeTypeName}`, 'noCodeFunc');
        }

        if (typeof onSelect === 'function') {
          onSelect();
        }
      }
    }
    ref.current?.hide();
  };

  const handlePredefineValueSelection = (
    { value, dataType, executedValue }: OnClickRuleArgs,
    type?: TokenSelectionType
  ) => {
    if (type === 'predefined') {
      if (!isRelativeDate) {
        if (typeof setValue === 'function') {
          if (['json', 'list'].includes(dataType)) {
            setValue(
              `${nodeName}.value`,
              !_isNil(executedValue) ? JSON.stringify(executedValue) : null
            );
          } else {
            setValue(`${nodeName}.value`, value);
          }
          setValue(
            `${nodeName}.executedValue`,
            executedValue ?? getDefaultValueByDataTypeV2(dataType)
          );
          setValue(`${nodeName}.${sourceKey}`, null);
          setValue(`${nodeName}.${attributeKey}`, null);
          setValue(`${nodeName}.dataType`, dataType);
        }
      }
    } else if (type === 'customFunction') {
      if (typeof setValue === 'function') {
        setValue(`${nodeName}.value`, null);
        setValue(
          `${nodeName}.executedValue`,
          executedValue ?? getDefaultValueByDataTypeV2(dataType)
        );
        setValue(`${nodeName}.dataType`, dataType);
        setValue(`${nodeName}.${sourceKey}`, null);
        setValue(`${nodeName}.${attributeKey}`, null);
        setValue(`${nodeName}.returnType`, null);

        if (typeof handleOpenEditorSheet === 'function') {
          handleOpenEditorSheet(dataType);
        }
      }
    }

    ref?.current?.hide();
  };

  const dataSet = _isNil(updatedDataSet)
    ? removeCustomFunction(dataset)
    : removeCustomFunction(updatedDataSet);

  const updatedHeader = React.isValidElement(header)
    ? React.cloneElement(header, {
        ...header.props,
        popoverRef: ref,
      })
    : header;

  const popoverComponent = (
    <>
      {isRelativeDate ? (
        <RulePopover
          dataset={dataSet}
          version="v2"
          disabled={isRuleReadOnly || disabled}
          onClick={onItemClick}
          allowList={allowList}
          typesToAllow={typesToAllow}
          onlyJson={onlyJson}
          showTooltip={showTooltip}
          idsToExpand={idsToExpand}
          idsToNotExpand={
            idsToNotExpand.length === 0
              ? getAllIdsExceptFirst(dataSet)
              : idsToNotExpand
          }
          header={updatedHeader}
          infoComponent={
            showInfoComponent ? (
              <InfoComponentContainer>
                <RiErrorWarningLine size={16} />
                <Typography name="paragraphSmall">
                  Result must match predefined Value (mandatory)
                </Typography>
              </InfoComponentContainer>
            ) : null
          }
          footer={
            (_isNil(updatedDataSet) || _isEmpty(updatedDataSet)) &&
            type === 'workflowDT' ? (
              <Typography name="paragraphSmall">
                Please create workflow attribute in the first step
              </Typography>
            ) : undefined
          }
        />
      ) : (
        <RuleField
          name={nodeName}
          control={control}
          dataset={dataSet}
          version="v2"
          disabled={isRuleReadOnly || disabled}
          onClick={onItemClick}
          allowList={allowList}
          typesToAllow={typesToAllow}
          onlyJson={onlyJson}
          showTooltip={showTooltip}
          idsToExpand={idsToExpand}
          idsToNotExpand={
            idsToNotExpand.length === 0
              ? getAllIdsExceptFirst(dataSet)
              : idsToNotExpand
          }
          header={updatedHeader}
          footer={
            (_isNil(updatedDataSet) || _isEmpty(updatedDataSet)) &&
            type === 'workflowDT' ? (
              <Typography name="paragraphSmall">
                Please create workflow attribute in the first step
              </Typography>
            ) : undefined
          }
          infoComponent={
            showInfoComponent ? (
              <InfoComponentContainer>
                <RiErrorWarningLine size={16} />
                <Typography name="paragraphSmall">
                  Result must match predefined Value (mandatory)
                </Typography>
              </InfoComponentContainer>
            ) : null
          }
        />
      )}
    </>
  );

  return (
    <PopoverPanel
      trigger="click"
      placement="bottom-start"
      appendTo={document.body}
      launcher={
        <RhsLauncher
          placeholder={placeholder}
          from="common"
          panelVisible={panelVisible}
          text={title ?? ''}
          nodeName={nodeName}
          dataType={dataType ?? ''}
          handleSendEventToGTM={handleSendEventToGTM}
          setValue={setValue}
          onlyJson={onlyJson}
          showExpandIconIfList={showExpandIconIfList}
          forceExpandListIcon={forceExpandListIcon}
          isRelativeDate={isRelativeDate}
          nodeType={
            !_isNil(source) &&
            !_isNil(attribute) &&
            !_isEmpty(source) &&
            !_isEmpty(attribute)
              ? isRelativeDate
                ? 'noCodeFunc'
                : 'token'
              : 'constant'
          }
          control={control}
          isSmall={isSmall}
          isFull={isFull}
          type={type}
          disabled={disabled || isRuleReadOnly}
          showTooltip={showTooltip}
          dataset={_isNil(updatedDataSet) ? dataset : updatedDataSet}
          nodeTypeName={nodeTypeName}
          rightIcon={rightIcon}
          readOnly={
            (readOnly ?? isFieldReadOnly(dataType ?? '')) || isSchemaMandatory
          }
          inputType={inputType}
          sourceKey={sourceKey}
          attributeKey={attributeKey}
          hidePopover={ref.current?.hide}
        />
      }
      ref={ref}
      padding="8px"
      disabled={isRuleReadOnly || disabled}
    >
      <RhsInputContainer
        onMouseEnter={() => setPanelVisible(true)}
        onMouseLeave={() => setPanelVisible(false)}
        $size={size}
      >
        {useTokenSelectionPopover ? (
          <TokenSelectionPopover
            selectedSchemaId={schemaId}
            tokenDataset={predefineTokenDataset}
            customTokenComponent={popoverComponent}
            handleValueSelection={handlePredefineValueSelection}
            tabsToShow={tokenSelectionTabsToShow}
            typesToAllow={typesToAllow}
            size={size}
            setPanelVisible={setPanelVisible}
            showTooltip={['json', 'list'].includes(dataType)}
          />
        ) : (
          popoverComponent
        )}
      </RhsInputContainer>
    </PopoverPanel>
  );
};
