import { Column, Columns, Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import {
  Control,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
  useWatch,
} from 'react-hook-form';
import {
  Dataset,
  DropdownField,
  Image,
  NectedSuggestionModel,
  Typography,
  toasts,
} from 'ui';

import { axiosPrivate } from '../../../../../api/axios';
import { usedConnectorMappingAtom } from '../../../../../atom';
import { SyntaxErrorContainer } from '../../../../../components/ActionComponents/ActionSheet.styled';
import { IntegrationField } from '../../../../../components/IntegrationField/IntegrationField';
import { QueryEditor } from '../../../../../components/QueryEditor/QueryEditor';
import SchemaViewer from '../../../../../components/SchemaViewer';
import { DatabaseType } from '../../../../../components/SchemaViewer/types';
import { StackAsItem } from '../../../../../components/layouts/Stack.styled';
import { isValidImageURL } from '../../../../../utils/common';
import { useGetPublishedConnectors } from '../../../../DataSets/hooks/useGetPublishedConnectors';
import { DatabaseName, SchemasValue } from '../../../../DataSets/types';
import {
  convertResponseObjectToSchemas,
  getSuggestionsKeywordsNew,
} from '../../../../DataSets/utils';
import { PublishedConnectors } from '../../../../Rules/components/DecisionTable/types';
import { isWorkflowReadOnlyAtom } from '../../../atoms/atoms';
import { EditorContainer, PluginContainer } from './ConnectorMapping.styled';
import { queryOptions } from './constant';

type ConnectorMappingProps = {
  pluginName: string;
  category: string;
  tokens: NectedSuggestionModel[];
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  setError: UseFormSetError<any>;
  watch: UseFormWatch<any>;
  handleGetExecutionValues?: (value: Record<string, any>) => void;
  execValues?: Record<string, any>;
  connectorData?: any;
  updatedDataSet: Record<string, Dataset>;
};

export function ConnectorMapping({
  pluginName = '',
  tokens,
  control,
  setValue,
  watch,
  handleGetExecutionValues,
  execValues,
  connectorData,
  updatedDataSet,
  setError,
}: ConnectorMappingProps) {
  const { data, refetch } = useGetPublishedConnectors(true);
  const [connectorList, setConnectorList] = useState<any>();
  const [schema, setSchema] = useState<SchemasValue>({});
  const [customSuggestionObj, setCustomSuggestionObj] = useState(tokens);
  const [clearValue, setClearValue] = useState(false);
  const [defaultValue, setDefaultValue] = useState<string | null>('');
  const [lastCursorObj, setLastCursorObj] = useState({
    row: 0,
    col: 0,
    name: '',
  });
  const [publishConnectorbyId, setPublishConnectors] =
    useState<PublishedConnectors>({});

  const [, setIsQueryValidNected] = useState(true);
  const [isValidQuery] = useState(true);

  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [, setUsedConnectorMapping] = useAtom(usedConnectorMappingAtom);

  const selectedConnector = useWatch({
    name: 'integration',
    control,
  });

  const selectedAction = useWatch({
    name: 'input.action.value',
    control,
  });

  const plugin = useWatch({
    name: 'plugin',
    control,
  });

  const query = useWatch({
    name: 'input.query.value',
    control,
  });

  const editPlugin = () => {
    window.open(
      `${window.location.origin}/integrations/${
        selectedConnector?.value as string
      }?stage=staging&connector=${connectorData.plugin.name as string}&wsid=${
        sessionStorage.getItem('workspaceUUID') as string
      }`,
      window !== window.parent ? '_self' : '_blank'
    );
  };

  const refreshPlugins = async () => {
    await refetch();
  };

  const isReadOnly =
    _isNil(selectedAction) || selectedAction.value === queryOptions[0].value;

  useEffect(() => {
    setClearValue(true);
  }, []);

  useEffect(() => {
    if (!_isNil(selectedConnector)) {
      void getDataSetSchema();
    }
  }, [JSON.stringify(selectedConnector)]);

  useEffect(() => {
    if (!_isEmpty(publishConnectorbyId)) {
      const id = selectedConnector?.value;

      if (!_isNil(id) && !_isEmpty(id)) {
        const currConnectorConfig = publishConnectorbyId[id];

        const connectorStatus: boolean =
          ((currConnectorConfig?.staging?.isTested as boolean) ?? true) &&
          ((currConnectorConfig?.staging?.isPublish as boolean) ?? true);

        setUsedConnectorMapping((prev) => ({
          ...prev,
          [id]: {
            status: connectorStatus,
            source: [],
          },
        }));
      }
    }
  }, [JSON.stringify(selectedConnector), JSON.stringify(publishConnectorbyId)]);

  useEffect(() => {
    if (clearValue) setValue('input.query.value', '');
  }, [JSON.stringify(selectedAction)]);

  useEffect(() => {
    let newCustomSuggObj = [...tokens];

    if (!_isNil(schema) && !_isEmpty(schema)) {
      const schemaTokens = getSuggestionsKeywordsNew(
        schema,
        plugin,
        pluginName as DatabaseName
      );

      newCustomSuggObj = [...newCustomSuggObj, ...schemaTokens];
    }

    setCustomSuggestionObj(newCustomSuggObj);
  }, [tokens, schema]);

  const getDataSetSchema = async (refresh = false) => {
    try {
      const response = await axiosPrivate.post(
        '/integration/connector/execute',
        {
          id: selectedConnector.value,
          environment: 'staging',
          method: 'getSchema',
          params: {
            refresh,
          },
          timezone: window.sessionStorage.getItem('nected-tz'),
          dateFormat: window.sessionStorage.getItem('nected-df'),
        }
      );

      if (response?.data.code === 'success') {
        setSchema(convertResponseObjectToSchemas(response.data.data.result));
      }
    } catch (err) {
      if (err instanceof Error) {
        toasts.error(err.message, 'get-schema-error');
      }
    }
  };

  useEffect(() => {
    if (!_isNil(data)) {
      const publishedConnectors = data.getConnector.data
        .filter((connector) => connector.plugin.name === pluginName)
        .map((connector) => {
          return {
            label: connector.name,
            value: connector.id,
          };
        });

      setConnectorList(publishedConnectors);
    }
  }, [data, pluginName]);

  useEffect(() => {
    if (!_isNil(data)) {
      const publishedConnectors = data.getConnector.data ?? [];
      const publishConnectorIdAndPlugin: PublishedConnectors = {};

      if (publishedConnectors.length > 0) {
        publishedConnectors.forEach((connector) => {
          publishConnectorIdAndPlugin[connector.id] = connector;
        });
      }
      setPublishConnectors(publishConnectorIdAndPlugin);
    }
  }, [JSON.stringify(data)]);

  const handleQueryGenerated = (value: string, queryType: string) => {
    setValue('input.query.value', value);
    setValue(
      'input.action.value',
      queryType === 'insert' || queryType === 'update'
        ? queryOptions[1]
        : queryOptions[0]
    );
  };

  const handleColumnClick = (tableName: string, columnName: string) => {};

  return (
    <Stack gutter="1.5rem">
      <PluginContainer justify="start" align="center" gap="0.8rem">
        <Image
          src={isValidImageURL(plugin?.imageUrl)}
          alt="minus"
          size="small"
          round
        />
        <Typography> {plugin?.displayName ?? ''}</Typography>
      </PluginContainer>

      <EditorContainer databaseName={pluginName ?? ''}>
        <StackAsItem grow={1} as={Columns} columns={12}>
          <Column span={8}>
            <IntegrationField
              control={control}
              editPlugin={editPlugin}
              refreshPlugins={refreshPlugins}
              name="integration"
              connectorList={connectorList}
              pluginId={plugin?.id}
              disabled={isWorkflowReadOnly}
              connectorId={selectedConnector?.value}
              onSelect={(nValue, oValue) => {
                if (nValue?.value !== oValue?.value) {
                  setValue('input.query.value', '');
                  setDefaultValue('');
                } else {
                  setDefaultValue('');
                }
              }}
            />

            {!_isNil(selectedConnector?.value) &&
              !_isEmpty(selectedConnector?.value) && (
                <>
                  {pluginName !== 'mongodb' && (
                    <div>
                      <Inline gutter={8}>
                        <Typography fontWeight={700}>Action</Typography>
                      </Inline>
                      <DropdownField
                        control={control}
                        name="input.action.value"
                        options={queryOptions}
                        disabled={isWorkflowReadOnly}
                      />
                    </div>
                  )}

                  <QueryEditor
                    setError={setError}
                    from="workflow"
                    control={control}
                    name="input.query.value"
                    customSuggestionObj={customSuggestionObj}
                    databaseName={
                      pluginName === 'postgres'
                        ? 'pgsql'
                        : (pluginName as DatabaseName)
                    }
                    setValidity={setIsQueryValidNected}
                    methods={isReadOnly ? 'read' : 'write'}
                    disabled={isWorkflowReadOnly}
                    handleGetExecutionValues={handleGetExecutionValues}
                    execValues={execValues}
                    resetValue={defaultValue}
                    setValue={setValue}
                    onReset={() => {
                      setDefaultValue(null);
                    }}
                    lastCursorObj={lastCursorObj}
                    setLastCursorObj={setLastCursorObj}
                  />
                </>
              )}
          </Column>

          <Column span={4}>
            <SchemaViewer
              schema={schema}
              dbType={(pluginName as DatabaseType) ?? 'mysql'}
              onQueryGenerated={handleQueryGenerated}
              onColumnClick={handleColumnClick}
              getDataSetSchema={getDataSetSchema}
              method="all"
              schemaHeading={'Schema'}
              updatedDataSet={updatedDataSet}
              placeholder="Search tables and columns"
            />
          </Column>
        </StackAsItem>
      </EditorContainer>

      {!isValidQuery && !_isEmpty(query) && (
        <SyntaxErrorContainer padding="1rem">
          <Typography>
            The statement written here is syntactically incorrect
          </Typography>
        </SyntaxErrorContainer>
      )}
    </Stack>
  );
}
