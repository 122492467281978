import type { ObservableQuery } from '@apollo/client';
import { Inline } from '@bedrock-layout/inline';
import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useRef } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  Button,
  formatNectedDate,
  Image,
  PopoverMethods,
  TextButton,
  TextField,
  toasts,
} from 'ui';

import { PermissionWrapper } from '../../../components/PermissionComponent';
import { permissionObj } from '../../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../../components/PermissionComponent/hooks/useCheckPermissions';
import { showGraphQlErrorToast } from '../../../utils/common';
import { ENTITY_ID, envMap } from '../../../utils/constant';
import { KEY_REGEX } from '../../../utils/regex';
import { maxFiftyCharactersRule } from '../../../utils/validation';
import { dataTypes, fieldDefaultValue } from '../constant';
import { useSaveConfig } from '../hooks/useSaveRemoteConfig';
import type {
  GetRemoteConfigResult,
  RemoteConfigFieldsDataTypes,
  SaveConfigResult,
} from '../models';
import { AddConfigValueType } from '../models';
import { remoteLimitsConfigAtom } from './RemoteConfig';
import {
  FieldContainer,
  HorizontalLine,
  StackStyled,
} from './RemoteConfig.styled';
import { RemoteConfigDataType } from './RemoteConfigDataType';
import { RemoteConfigField } from './form/RemoteConfigComponents';

type AddConfigFieldArrayProps = {
  refetch: ObservableQuery<GetRemoteConfigResult>['refetch'];
  savedConfigList: GetRemoteConfigResult;
  setRemoteData: (data: GetRemoteConfigResult | undefined) => void;
};

type FormValues = {
  newRemoteConfig: Array<Record<string, AddConfigValueType>>;
};

export function AddConfigFieldArray({
  refetch,
  savedConfigList,
  setRemoteData,
}: AddConfigFieldArrayProps) {
  const { control, handleSubmit, reset } = useForm<FormValues>({
    mode: 'onChange',
  });
  const [saveConfig] = useSaveConfig();

  const [limitConfig] = useAtom(remoteLimitsConfigAtom);

  const { fields, append, remove } = useFieldArray({
    name: 'newRemoteConfig',
    control,
  });

  const entityList = [ENTITY_ID.remoteConfig];
  const { isHide: disabledRemoteConfigField } = useCheckPermissions({
    allowedPermission: [permissionObj.create, permissionObj.edit],
    entityList,
  });

  const handleAddConfig = (dataType: RemoteConfigFieldsDataTypes) => {
    if (
      !_isEmpty(limitConfig) &&
      fields.length + savedConfigList.savedConfig.data.length <
        limitConfig.maxGv.value
    ) {
      append({
        name: '',
        dataType,
        value: fieldDefaultValue[dataType] as AddConfigValueType,
      });
    } else if (!_isEmpty(limitConfig.maxGv.message)) {
      toasts.info(limitConfig.maxGv.message, 'info');
    }
  };

  const handleFormatSavableValue = (value: any, type: AddConfigValueType) => {
    if (type === dataTypes.json || type === dataTypes.list) {
      try {
        return JSON.parse(value as string);
      } catch (error) {
        return {};
      }
    } else if (type === dataTypes.numeric) {
      return parseFloat(value as string);
    } else if (type === dataTypes.date && !_isNil(value)) {
      return formatNectedDate(value as Date, 'date');
    } else if (type === dataTypes.dateTime && !_isNil(value)) {
      return formatNectedDate(value as Date, 'dateTime');
    }
    return value;
  };

  const onSubmit = async ({ newRemoteConfig }: FormValues) => {
    const requests = newRemoteConfig.map(async (config) => {
      const { name, dataType, value } = config;
      return await saveConfig({
        variables: {
          name: name as string,
          dataType: dataType as RemoteConfigFieldsDataTypes,
          value: handleFormatSavableValue(
            value,
            dataType as RemoteConfigFieldsDataTypes
          ),
        },
      });
    });

    try {
      const successRequests = (await Promise.allSettled(requests).then(
        (results) => results.filter((result) => result.status === 'fulfilled')
      )) as Array<PromiseFulfilledResult<SaveConfigResult>>;

      if (successRequests.length < requests.length) {
        const failedKeys = newRemoteConfig.filter(
          ({ name }) =>
            successRequests.filter(
              ({ value }) => value.data.saveConfig.name === name
            ).length === 0
        );

        failedKeys.forEach(({ name }) =>
          toasts.error(`Key ${name as string} is unable to save`, 'error')
        );
      } else {
        toasts.success('Saved successfully', 'saved-success');
      }

      setRemoteData(undefined);
      try {
        const data = await refetch();
        setRemoteData(data.data);
      } catch (error) {
        showGraphQlErrorToast(error);
      }

      reset({ newRemoteConfig: [] });
    } catch (error) {
      showGraphQlErrorToast(error);
    }
  };

  const dataTypeRef = useRef<PopoverMethods>(null);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gutter=".8rem">
        {fields.length > 0 && (
          <PadBox padding={['2.4rem', 0]}>
            <HorizontalLine />
          </PadBox>
        )}

        <StackStyled gutter="1.6rem">
          {fields.map(({ id, value, dataType }, index) => {
            return (
              <Inline as="li" key={id} align="start">
                <FieldContainer>
                  <TextField
                    control={control}
                    rules={{
                      required: 'Field is required',
                      pattern: {
                        value: KEY_REGEX,
                        message: 'Invalid key',
                      },
                      maxLength: maxFiftyCharactersRule,
                      validate: {
                        require: (value) => {
                          if (typeof value === 'string') {
                            return savedConfigList.savedConfig.data.filter(
                              ({ name }) => name === value
                            ).length > 0
                              ? `${value} already exists`
                              : undefined;
                          }

                          return undefined;
                        },
                      },
                    }}
                    name={`newRemoteConfig.${index}.name`}
                    placeholder="Key"
                    showErrorIcon={false}
                  />
                </FieldContainer>

                <FieldContainer widthRequired={dataType !== 'boolean'}>
                  <RemoteConfigField
                    type={dataType as RemoteConfigFieldsDataTypes}
                    control={control}
                    name={`newRemoteConfig.${index}.value`}
                    value={value}
                    disabled={disabledRemoteConfigField}
                  />
                </FieldContainer>

                <PermissionWrapper
                  allowedPermission={[permissionObj.delete]}
                  entityList={entityList}
                >
                  <Inline as={FieldContainer} align="center">
                    <TextButton onClick={() => remove(index)}>
                      <Image
                        src={`${envMap.VITE_ASSETS_URL}website/icons/trash.svg`}
                        alt="trash"
                        size="small"
                      />
                    </TextButton>
                  </Inline>
                </PermissionWrapper>
              </Inline>
            );
          })}
        </StackStyled>

        <PermissionWrapper
          allowedPermission={[permissionObj.create, permissionObj.edit]}
          entityList={entityList}
        >
          <Stack gutter="1.6rem">
            <Inline>
              <RemoteConfigDataType
                handleAddConfig={handleAddConfig}
                dataTypeRef={dataTypeRef}
              />
            </Inline>

            {fields.length > 0 && (
              <Inline>
                <Button type="submit" size="small">
                  Save
                </Button>
              </Inline>
            )}
          </Stack>
        </PermissionWrapper>
      </Stack>
    </form>
  );
}
