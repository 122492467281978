import { Inline, PadBox } from '@bedrock-layout/primitives';
import _has from 'lodash/has';
import _isNil from 'lodash/isNil';
import _reduce from 'lodash/reduce';
import { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs, Typography } from 'ui';

import { RuleExecutionOutput } from '../../../../../components/jsonOutputField';
import { PaddingAsPropType } from '../../../../../types';
import { StatusCode } from '../../../../../utils/response/statusCode';
import { RULE_TEST_RESPONSE_DEFAULT } from '../../../fixtures/rules';
import type { RuleTestResponse } from '../../../hooks/useServerResponse';
import type {
  ActionInfoObject,
  RuleSetActionInfoObject,
  RuleSetTestActionResponse,
} from '../../../types';
import { _isEmpty } from '../../../worker/DTTableTransform';
import { RuleExecutionAction } from '../../RuleExecutionAction/RuleExecutionAction';
import {
  ActionTabContainer,
  ExecutionTestStyled,
} from './ExecutionTest.styled';
import { GetActionTitle } from './GetActionTitle';

type RuleExecutionTestProps = {
  serverResponse: RuleTestResponse;
  isTesting: boolean;
  ruleActionResponse: RuleSetTestActionResponse;
  actionInfo: RuleSetActionInfoObject;
  handleExpandIconClick: (actionName: string, ruleId: string) => void;
  overrideOutput?: Record<string, any>;
  forceStatus?: boolean;
};

export function ExecutionTest({
  serverResponse = RULE_TEST_RESPONSE_DEFAULT,
  isTesting,
  ruleActionResponse,
  actionInfo,
  handleExpandIconClick,
  overrideOutput,
  forceStatus,
}: RuleExecutionTestProps) {
  const { executionTime, json } = serverResponse;

  const [activeRuleId, setActiveRuleId] = useState('');
  const [ruleIdList, setRuleIdList] = useState<string[]>([]);
  const [totalActionInfo, setTotalActionInfo] = useState<ActionInfoObject>({
    count: 0,
    status: '',
  });

  useEffect(() => {
    setActiveRuleId(ruleIdList[0]);
  }, []);

  useEffect(() => {
    setRuleIdList(Object.keys(ruleActionResponse));

    const count = _reduce(
      ruleActionResponse,
      (accum, currRule) => {
        return accum + currRule.actionResponse.length;
      },
      0
    );

    setTotalActionInfo((prevState) => ({
      status: prevState.status,
      count,
    }));
  }, [ruleActionResponse]);

  const getOverallActionStatus = () => {
    const actionInfoList = Object.values(actionInfo);

    const isProgress = actionInfoList.some(
      (currInfo) => currInfo.status === StatusCode.RUNNING
    );

    const isFailed = actionInfoList.some(
      (currInfo) => currInfo.status === StatusCode.FAILED
    );

    const isCompleted = actionInfoList.some(
      (currInfo) => currInfo.status === StatusCode.COMPLETED
    );

    if (isProgress) {
      return StatusCode.RUNNING;
    } else if (isFailed) {
      return StatusCode.FAILED;
    } else if (isCompleted) {
      return StatusCode.COMPLETED;
    } else {
      return '';
    }
  };

  useEffect(() => {
    const status = getOverallActionStatus();

    setTotalActionInfo((prevState) => ({
      count: prevState.count,
      status,
    }));
  }, [actionInfo]);

  const onTabChange = (activeIndex: number) => {
    setActiveRuleId(ruleIdList[activeIndex]);
  };

  const handleEditorExpand = (actionName: string) => {
    handleExpandIconClick(actionName, activeRuleId);
  };

  return (
    <ExecutionTestStyled gutter={0} grow={1}>
      <Tabs>
        <TabList>
          <Tab>Output</Tab>

          <Tab>
            <GetActionTitle
              name="Actions"
              actionInfo={totalActionInfo}
              statusPosition="end"
            />
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <RuleExecutionOutput
              executionTime={executionTime}
              isTesting={isTesting}
              responseJson={
                !_isNil(overrideOutput)
                  ? JSON.stringify(overrideOutput ?? {}, null, 2)
                  : json
              }
              tableOutput={
                !_isNil(overrideOutput)
                  ? overrideOutput?.output
                  : JSON.parse(!_isEmpty(json) ? json : '{}')?.data?.output
              }
              forceStatus={forceStatus}
            />
          </TabPanel>

          <TabPanel>
            <Inline as={ActionTabContainer} padding="0.9rem" gutter={5}>
              {ruleIdList.length === 0 ? (
                <PadBox padding={['1rem', '5rem']} as={Typography}>
                  All Rules failed
                </PadBox>
              ) : (
                <Tabs type="vertical" onTabChange={onTabChange}>
                  <TabList>
                    {ruleIdList.map((id) => {
                      return _has(ruleActionResponse, id) ? (
                        <Tab key={id} type="vertical">
                          <GetActionTitle
                            name={ruleActionResponse[id].ruleName}
                            actionInfo={actionInfo[id]}
                            statusPosition={'start'}
                          />
                        </Tab>
                      ) : (
                        <></>
                      );
                    })}
                  </TabList>

                  <TabPanels>
                    {ruleIdList.map((id) => (
                      <>
                        {_has(ruleActionResponse, id) ? (
                          <TabPanel key={id}>
                            <RuleExecutionAction
                              handleExpandIconClick={handleEditorExpand}
                              actionResponse={structuredClone(
                                ruleActionResponse[id].actionResponse
                              )}
                              padding={'0px' as PaddingAsPropType}
                              ruleType="RuleSet"
                              flexGrow={1}
                            />
                          </TabPanel>
                        ) : null}
                      </>
                    ))}
                  </TabPanels>
                </Tabs>
              )}
            </Inline>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </ExecutionTestStyled>
  );
}
