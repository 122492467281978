import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { toasts } from 'ui';

import { checksumWarningAtom } from '../../../../atom';
import { useAxiosPrivate } from '../../../../hooks';
import {
  checksumMessage,
  getResponseErrorMessage,
  handleGetCheckSumByEntityName,
  handleSetCheckSumByEntityName,
  showErrorToast,
} from '../../../../utils/common';
import type { RuleEnvironment } from '../../../../utils/constant';
import type { CustomAxiosError } from '../../../../utils/response/types';
import type { UpdateConnectorStatusFunction } from '../../types';

type TestConnectorRequest = {
  id: string;
  environment: RuleEnvironment;
  method: string;
  checksum: string;
  params: Record<string, any>;
};

export type TestConnectorFunction = () => void;

export type UseTestConnectorReturn = {
  loading: boolean;
  testConnector: TestConnectorFunction;
};

export function useTestConnector(
  updateConnectorStatus: UpdateConnectorStatusFunction,
  currentEnvironment: RuleEnvironment,
  connectorId: string,
  connectorName: string,
  pluginType: string
): UseTestConnectorReturn {
  const { axiosPrivate } = useAxiosPrivate();

  const [, setShowChecksumPopup] = useAtom(checksumWarningAtom);

  const { isLoading: loading, mutateAsync: testConnectorMutator } = useMutation(
    {
      mutationFn: async (request: TestConnectorRequest) => {
        return await axiosPrivate.post('/integration/connector/execute', {
          ...request,
          timezone: window.sessionStorage.getItem('nected-tz'),
          dateFormat: window.sessionStorage.getItem('nected-df'),
        });
      },
    }
  );

  const testConnector = async () => {
    try {
      const { data } = await testConnectorMutator({
        id: connectorId,
        environment: currentEnvironment,
        method: 'testConfig',
        checksum: handleGetCheckSumByEntityName('integrations') ?? '',
        params: {
          cr: true,
        },
      });

      if (!_isNil(data.checksum)) {
        handleSetCheckSumByEntityName('integrations', data.checksum);
      }

      toasts.success('Tested successfully', 'tested-success');
      updateConnectorStatus(currentEnvironment, true);
    } catch (error: unknown) {
      showErrorToast(error as AxiosError<CustomAxiosError>);

      if (error instanceof Error) {
        if (
          getResponseErrorMessage(
            error as AxiosError<CustomAxiosError>
          ).includes(checksumMessage)
        ) {
          setShowChecksumPopup({
            showPopup: true,
            metaData: {
              connectorId,
              connectorName,
              type: pluginType,
            },
          });
        }
      }

      if (error instanceof AxiosError) {
        const data = error?.response?.data;

        if (!_isNil(data.checksum)) {
          handleSetCheckSumByEntityName('integrations', data.checksum);
        }
      }
    }
  };

  return {
    testConnector,
    loading,
  };
}
