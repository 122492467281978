import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import { useEffect, useState } from 'react';
import { UseControllerProps, UseFormSetValue, useForm } from 'react-hook-form';
import {
  Button,
  Dataset,
  EditorLanguages,
  Modal,
  ModalContent,
  NectedEditorField,
  NectedSuggestionModel,
  Typography,
  toasts,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { siteConstantsAtom } from '../../../../../atom';
import { customAttributesAtom } from '../../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import {
  getTooltipText,
  isCorrectJsSyntaxV2,
} from '../../../../../utils/common';
import { editorDomain } from '../../../../../utils/constant';
import { useGenerateDataset } from '../../../../Workflow/hooks/useGenerateDataset';
import { useUpdateExecutedValueRules } from '../../../hooks/useUpdateExecutedValueRules';
import { isRuleReadOnlyAtom, selectedDataSetAtom } from '../../../index';
import { jsNodeResultQuery } from '../../../schema';
import { updateDataSetOnChange } from '../../../utils/common';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { SyntaxErrorContainer } from '../../RuleComponents/RuleComponents.styled';
import { OnCloseNodeModal } from '../../SimpleRule/Results/OnCloseNodeModal';
import { SaveNodeModal } from '../../SimpleRule/Results/SaveNodeModal';
import { decisionTableNodesAtom } from '../DecisionTable';
import {
  ExcelEditorContainer,
  FooterStyled,
} from '../ExcelNodeModal/ExcelNodeModal.styled';

export type JsonNodeSheetProps = UseControllerProps & {
  nodeId: string;
  hideOptionalCustomAttributes?: boolean;
  setValue?: UseFormSetValue<any>;
  executedValueName?: string;
  hideSuggestions?: boolean;
  isJsonInCustomInput?: boolean;
  onClose?: () => void;
  overrideValue?: any;
  type: string;
};

type JsonNodeSheetResult = {
  nodeQuery: string;
};

export function ExcelFormulaSheet({
  nodeId,
  type,
  name,
  setValue,
  executedValueName,
  isJsonInCustomInput = false,
  onClose,
}: JsonNodeSheetProps) {
  const [isReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [nodes, setNodes] = useAtom(decisionTableNodesAtom);

  const [dataSetVariables] = useAtom(dataSetParamsAtom);
  const [customAttributes] = useAtom(customAttributesAtom);
  const [dataSetSelected] = useAtom(selectedDataSetAtom);

  const [hasEditorChanged, setHasEditorChanged] = useState(false);

  const [isQueryValidNected, setIsQueryValidNected] = useState(true);

  const [isReturnTypeRequired, setIsReturnTypeRequired] = useState(false);
  const [returnType, setReturnType] = useState<string | null>(null);

  const [suggestionObjs, setSuggestionObjs] = useState<NectedSuggestionModel[]>(
    []
  );
  const [executedValue, setExecutedValue] = useState<any | null>(null);

  const [updatedDataset, setUpdatedDataset] = useState<Record<string, Dataset>>(
    {}
  );

  const { openWithProps: openSaveNodeModal } = useLayer(<SaveNodeModal />);
  const { close: closeEditorSheet } = useCurrentLayer();

  const { open: onCloseModal } = useLayer(
    <OnCloseNodeModal onClose={() => close()} />
  );

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const currentNodeValue = nodes?.[nodeId].query;

  const {
    control: localControl,
    handleSubmit,
    watch,
  } = useForm<JsonNodeSheetResult>({
    resolver: zodResolver(jsNodeResultQuery),
    defaultValues: {
      nodeQuery:
        typeof currentNodeValue === 'string'
          ? currentNodeValue
          : JSON.stringify(currentNodeValue),
    },
    mode: 'onSubmit',
  });

  const localQuery = watch('nodeQuery');

  const comment = getTooltipText(
    siteConstants,
    'rules',
    isJsonInCustomInput ? `${type}InCustomInput` : `${type}InResult`,
    'otherText'
  );

  const handleSave = () => {
    if (typeof onClose === 'function') {
      onClose();
    }

    if (!isQueryValidNected) {
      toasts.error(
        'You still have some errors in the editor. Please resolve to proceed',
        'error'
      );

      return;
    }

    if (_isEmpty(localQuery)) {
      toasts.warning('Put your JSON comment here', 'warning');
    }

    if (_isNil(executedValueName)) {
      if (typeof setValue === 'function') {
        setValue(name, localQuery);
      }

      close();
    }

    setIsReturnTypeRequired(true);
  };

  const onSetReturnType = (type: any) => {
    if (type === 'undefined' || type === null) {
      setReturnType('undefined');
    }

    setReturnType(type);
    setIsReturnTypeRequired(false);
  };

  const { tokens } = useGenerateDataset({
    updatedDataset,
  });

  const { executedValue: execValues, handleGetExecutionValues } =
    useUpdateExecutedValueRules({
      updatedDataset,
    });

  useEffect(() => {
    setSuggestionObjs(tokens);
  }, [JSON.stringify(tokens)]);

  useEffect(() => {
    if (!_isNil(dataSetVariables)) {
      setUpdatedDataset(
        updateDataSetOnChange(
          customAttributes,
          dataSetVariables,
          dataSetSelected
        )
      );
    }
  }, [dataSetVariables]);

  useEffect(() => {
    if (
      !_isNil(returnType) &&
      returnType !== 'undefined' &&
      !_isEmpty(returnType) &&
      isMounted
    ) {
      if (typeof setValue === 'function') {
        if (
          !_isNil(executedValueName) &&
          !_isEmpty(executedValueName) &&
          !_isNil(executedValue)
        ) {
          setValue(executedValueName, executedValue);
          setValue(name, localQuery);
          close();
        }
      }

      setNodes((prev) => ({
        ...prev,
        [nodeId]: {
          ...prev[nodeId],
          query: localQuery,
          value: executedValue,
          returnType: returnType ?? '',
          sourceType: undefined,
          attribute: undefined,
        },
      }));
      closeEditorSheet();
    }
  }, [returnType]);

  const onExecutionValueSave = (execValue: any) => {
    if (!_isUndefined(execValue)) {
      setExecutedValue(execValue);
    } else if (!_isNil(executedValueName)) {
      setExecutedValue(undefined);
      openSaveNodeModal({
        onSave: () => {
          if (!_isNil(setValue)) {
            if (!_isNil(executedValueName) && !_isEmpty(executedValueName)) {
              setValue(executedValueName, undefined);
            }

            setValue(name, watch('nodeQuery'));
          }

          setNodes((prev) => ({
            ...prev,
            [nodeId]: {
              ...prev[nodeId],
              query: localQuery,
              value: undefined,
              returnType: returnType ?? '',
              sourceType: undefined,
              attribute: undefined,
            },
          }));

          close();
        },
      });
    }
  };

  return (
    <Modal size="extraLargeXS" hideHeader hideCloseButton>
      <ModalContent>
        <form onSubmit={handleSubmit(handleSave)}>
          <Stack gutter="1rem">
            <PadBox padding="1rem">
              <Stack gutter={8}>
                <Typography name="heading1">Formula Editor</Typography>

                <ExcelEditorContainer $readOnly={isReadOnly}>
                  <NectedEditorField
                    name="nodeQuery"
                    control={localControl}
                    showError={false}
                    customSuggestions={suggestionObjs}
                    defaultValue={comment}
                    mode={type as EditorLanguages}
                    setReturnType={onSetReturnType}
                    onSetEditorValidity={setIsQueryValidNected}
                    setExecutedValue={onExecutionValueSave}
                    sendReturnType={isReturnTypeRequired}
                    setHasEditorChanged={setHasEditorChanged}
                    domain={editorDomain}
                    execValues={execValues}
                    handleGetExecData={handleGetExecutionValues}
                    readOnly={isReadOnly}
                  />

                  {!isCorrectJsSyntaxV2(localQuery, updatedDataset).status && (
                    <SyntaxErrorContainer padding="1rem">
                      <Typography>
                        {
                          isCorrectJsSyntaxV2(localQuery, updatedDataset)
                            .message
                        }
                      </Typography>
                    </SyntaxErrorContainer>
                  )}
                </ExcelEditorContainer>
              </Stack>
            </PadBox>

            {!isCorrectJsSyntaxV2(localQuery, updatedDataset).status && (
              <SyntaxErrorContainer padding="1rem">
                <Typography>
                  {isCorrectJsSyntaxV2(localQuery, updatedDataset).message}
                </Typography>
              </SyntaxErrorContainer>
            )}

            <FooterStyled padding={[4, 8]}>
              <Inline justify="end">
                <Button
                  type="button"
                  appearance="filled"
                  onClick={() => {
                    if (hasEditorChanged) {
                      onCloseModal();
                    } else {
                      closeEditorSheet();
                    }
                  }}
                >
                  Close
                </Button>
                <Button type="submit" disabled={isReadOnly}>
                  Save
                </Button>
              </Inline>
            </FooterStyled>
          </Stack>
        </form>
      </ModalContent>
    </Modal>
  );
}
