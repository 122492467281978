/* eslint-disable @typescript-eslint/strict-boolean-expressions */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Stack } from '@bedrock-layout/stack';
import { config } from 'ace-builds';
import { addCompleter } from 'ace-builds/src-noconflict/ext-language_tools';
import modeJSON from 'ace-builds/src-noconflict/mode-json';
import aceTheme from 'ace-builds/src-noconflict/theme-chrome';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import AceEditor from 'react-ace';
import ReactAce from 'react-ace/lib/ace';
import { UseFormSetError, useWatch } from 'react-hook-form';
import {
  DropdownInput,
  EditorLanguages,
  NectedEditorField,
  NectedSuggestionModel,
  TextInput,
  Typography,
} from 'ui';

import {
  SectionButton,
  SectionContainer,
} from '../../../components/TabSelectionApiHook/TabSelectionApiHook.styled';
import { queryOptions } from '../../Workflow/components/Sheets/ConnectorActionSheet/constant';
import {
  addQuotesToKeys,
  buildMongoQuery,
  getSelectOptions,
  getUniqueOptions,
  mongoQueryHelpers,
  parseMongoQuery,
  removeQuotesFromKeys,
} from '../mongoUtils';

type FieldType = 'text' | 'number' | 'textarea' | 'select';
type CommandType = string | { value: string; label: string };

type MongoNoCodeEditorProps = {
  control: any;
  name: string;
  mode: EditorLanguages | undefined;
  customSuggestions: NectedSuggestionModel[];
  readOnly: any;
  methods: any;
  onSetEditorValidity: any;
  domain: any;
  setValue: any;
  from: string;
  lastCursorObj: Record<string, any>;
  setLastCursorObj: any;
  setError: UseFormSetError<any>;
};
config.setModuleUrl('ace/mode/json', modeJSON);
config.setModuleUrl('ace/theme/github', aceTheme);

export const MongoNoCodeEditor = ({
  control,
  name,
  mode,
  customSuggestions,
  readOnly,
  methods,
  onSetEditorValidity,
  domain,
  setValue,
  from,
  lastCursorObj,
  setError,
  setLastCursorObj,
}: MongoNoCodeEditorProps) => {
  const defaultValue = useWatch({
    name,
    control,
  });

  const [tab, setTab] = useState(0);
  const [command, setCommand] = useState<CommandType>('');
  const [fields, setFields] = useState(mongoQueryHelpers.getSortedFields(''));
  const [formValues, setFormValues] = useState<Record<string, any>>({});
  const [isControlledUpdate, setIsControlledUpdate] = useState(false);
  const memoizedSuggestions = useMemo(
    () => customSuggestions,
    [customSuggestions.length, customSuggestions[0]]
  );

  const editorsRef = useRef<Map<string, MutableRefObject<ReactAce | null>>>(
    new Map()
  );

  const getEditorRef = (
    fieldName: string
  ): MutableRefObject<ReactAce | null> => {
    const existingRef = editorsRef.current.get(fieldName);

    if (existingRef) {
      return existingRef;
    }

    // If no ref exists, create a new one
    const newRef: MutableRefObject<ReactAce | null> = { current: null };
    editorsRef.current.set(fieldName, newRef);

    return newRef;
  };

  useEffect(() => {
    initializeMongoValues('115');
  }, [memoizedSuggestions]);

  const initializeMongoValues = (from = '') => {
    try {
      const parsedValues = parseMongoQuery(
        defaultValue ?? '',
        customSuggestions
      );

      // Only update state if values have actually changed
      setCommand((prevCommand) => {
        const newCommand = parsedValues.command ?? '';

        return prevCommand !== newCommand ? newCommand : prevCommand;
      });

      setFormValues((prevValues) => {
        // Deep comparison might be needed for complex objects
        const hasChanged =
          JSON.stringify(prevValues) !== JSON.stringify(parsedValues);

        return hasChanged ? parsedValues : prevValues;
      });

      onSetEditorValidity(true);
    } catch (error: any) {
      // eslint-disable-next-line
      console.error('Error Parsing', error);
      onSetEditorValidity(false);
    }
  };

  useEffect(() => {
    const updatedExtention = customSuggestions.map((i) => {
      if (
        i.value.includes('{{.') &&
        !i.value.includes('"{{.') &&
        ['string', 'date', 'dateTime'].includes(i.meta)
      ) {
        i.value = `"${i.value}"`;
      }

      return i;
    });

    const completers = {
      getCompletions: function (
        editor: any,
        session: any,
        pos: any,
        prefix: any,
        callback: (...args: any) => void
      ) {
        callback(null, updatedExtention);
      },
      getExactMatch: true,
    };

    addCompleter(completers);
  }, [JSON.stringify(customSuggestions)]);

  useEffect(() => {
    setFields(
      mongoQueryHelpers.getSortedFields(
        typeof command === 'string' ? command : command?.value
      )
    );
  }, [command]);

  useEffect(() => {
    if (_isEmpty(defaultValue) || _isNil(defaultValue)) {
      setFormValues({});
    }
  }, [JSON.stringify(defaultValue)]);

  const handleFieldChange = (fieldName: string, value: any) => {
    const newValues = { ...formValues };

    if (['query', 'projection', 'sort', 'options'].includes(fieldName)) {
      try {
        const parsed = JSON.parse(addQuotesToKeys(value, customSuggestions));
        newValues[fieldName] = removeQuotesFromKeys(
          JSON.stringify(parsed, null, 2),
          customSuggestions
        );
      } catch (e) {
        newValues[fieldName] = value;
      }
    } else {
      newValues[fieldName] = value;
    }

    if (fieldName === 'command' && from === 'workflow') {
      setValue(
        'input.action.value',
        newValues.command.value.includes('insert') ||
          newValues.command.value.includes('update')
          ? queryOptions[1]
          : queryOptions[0]
      );
    }

    if (!_isNil(newValues.command)) {
      if (
        typeof newValues.command?.value === 'string' &&
        newValues.command.value.toLowerCase().includes('insertmany')
      ) {
        let constructedValue = newValues.query;

        if (
          typeof constructedValue === 'string' &&
          constructedValue.length > 0 &&
          !constructedValue.startsWith('[') &&
          !constructedValue.endsWith(']')
        ) {
          // Ensure we're working with a string and provide a default empty string
          const safeValue = String(constructedValue || '');
          constructedValue = `[${safeValue}]`;
        }

        newValues.query = constructedValue;
      }

      setFormValues(newValues);
      setCommand(newValues.command.label);
      try {
        const queryString = buildMongoQuery({
          command: newValues.command,
          values: newValues,
          forStorage: true,
          customSuggestions,
        });

        if (queryString === 'QUERY_ERROR') {
          onSetEditorValidity(false);
        } else {
          setValue(name, queryString);
          onSetEditorValidity(true);
        }
      } catch (error) {
        // eslint-disable-next-line
        console.error('Error updating query:', error);
        onSetEditorValidity(false);
      }
    } else {
      onSetEditorValidity(false);
    }
  };

  const aceHandleFieldChange = useCallback(
    (fieldName: string, value: any) => {
      const newValues = { ...formValues };

      if (fieldName === 'command') {
        newValues.command = {
          label: value,
          value: mongoQueryHelpers.getOperationType(value),
        };
      } else if (
        [
          'query',
          'projection',
          'sort',
          'options',
          'pipeline',
          'update',
          'document',
          'documents',
          'arrayFilters',
          'filter',
          'operations',
          'index',
        ].includes(fieldName)
      ) {
        try {
          if (_isNil(value) || _isEmpty(value) || value.trim() === '') {
            newValues[fieldName] =
              fieldName === 'pipeline' || fieldName === 'documents'
                ? '[]'
                : '{}';
          } else {
            newValues[fieldName] = value;
          }
        } catch (e) {
          // eslint-disable-next-line
          console.error(`Error processing ${fieldName}:`, e);
          newValues[fieldName] = value;
        }
      } else {
        newValues[fieldName] = value;
      }

      setIsControlledUpdate(true);
      setFormValues(newValues);

      try {
        const queryString = buildMongoQuery({
          command: newValues.command,
          values: newValues,
          forStorage: true,
          customSuggestions,
        });

        if (queryString === 'QUERY_ERROR') {
          onSetEditorValidity(false);
        } else {
          setValue(name, queryString);
          onSetEditorValidity(true);
        }
      } catch (error) {
        // eslint-disable-next-line
        console.error('Error building query:', error);
        setError(name, {
          message: 'Error in the query string',
          type: 'validate',
        });
        onSetEditorValidity(false);
      }
    },
    [formValues, setValue, name, onSetEditorValidity]
  );

  useEffect(() => {
    if (tab === 1) {
      // Always initialize on tab change to raw editor
      void initializeMongoValues('343');
    } else if (!_isNil(defaultValue) && !isControlledUpdate) {
      // Initialize on defaultValue changes only if it's not from our controlled updates
      void initializeMongoValues('346');
    }
    setIsControlledUpdate(false); // Reset the flag after each update
  }, [JSON.stringify(defaultValue), tab]);

  // eslint-disable-next-line
  return (
    <>
      <SectionContainer gutter={0} style={{ margin: '15px 0px' }}>
        <SectionButton
          type="button"
          $active={tab === 0}
          onClick={(e) => {
            e.stopPropagation();
            setTab(0);
          }}
        >
          Low Code
        </SectionButton>
        <SectionButton
          type="button"
          $active={tab === 1}
          onClick={(e) => {
            e.stopPropagation();
            setTab(1);
          }}
        >
          Raw
        </SectionButton>
      </SectionContainer>
      {tab === 0 && (
        <Stack>
          {Object.entries(fields).map(([fieldName, fieldConfig], index) => (
            <div key={index}>
              <Typography
                className="label-style"
                fontWeight={700}
                name="heading4"
              >{`${fieldConfig.label}`}</Typography>

              {['text', 'number'].includes(fieldConfig.type as FieldType) && (
                <TextInput
                  type={fieldConfig.type}
                  name={fieldName}
                  placeholder={fieldConfig.placeholder}
                  value={formValues[fieldName] || ''}
                  onChange={(e) => handleFieldChange(fieldName, e.target.value)}
                />
              )}
              {['textarea'].includes(fieldConfig.type as FieldType) && (
                <AceEditor
                  className="queryEditorMongo"
                  ref={getEditorRef(fieldName ?? '')}
                  mode="json"
                  theme="chrome"
                  placeholder={fieldConfig.placeholder ?? ''}
                  width="100%"
                  fontSize={14}
                  maxLines={12}
                  showPrintMargin={false}
                  highlightActiveLine={true}
                  enableBasicAutocompletion={true}
                  enableLiveAutocompletion={true}
                  showGutter={true}
                  setOptions={{
                    useWorker: false,
                    showLineNumbers: true,
                    tabSize: 2,
                  }}
                  readOnly={readOnly}
                  value={formValues[fieldName] ?? ''}
                  onCursorChange={(selection) => {
                    setLastCursorObj({
                      row: (selection.cursor.row as unknown as number) + 1,
                      col: (selection.cursor.column as unknown as number) + 1,
                      name: fieldName,
                    });
                  }}
                  onChange={() => {
                    const editor = getEditorRef(fieldName).current?.editor;

                    if (editor) {
                      const value = editor.getValue();
                      aceHandleFieldChange(fieldName, value);
                    }
                  }}
                  name={name}
                />
              )}
              {['select'].includes(fieldConfig.type as FieldType) &&
                fieldConfig.name === 'collection' && (
                  <DropdownInput
                    placeholder={fieldConfig.placeholder}
                    name={fieldName}
                    value={formValues[fieldName] ?? ''}
                    options={getUniqueOptions(customSuggestions)}
                    onChange={(value) => handleFieldChange(fieldName, value)}
                  />
                )}
              {['select'].includes(fieldConfig.type as FieldType) &&
                fieldConfig.name !== 'collection' && (
                  <DropdownInput
                    placeholder={fieldConfig.placeholder}
                    name={fieldName}
                    value={formValues[fieldName] ?? ''}
                    options={getSelectOptions(fieldConfig, methods)}
                    onChange={(value) => {
                      if (fieldName === 'command') {
                        setCommand(value);
                      }
                      handleFieldChange(fieldName, value);
                    }}
                  />
                )}
            </div>
          ))}
        </Stack>
      )}
      {tab === 1 && (
        <>
          <Typography className="label-style" fontWeight={700} name="heading4">
            Add Query
          </Typography>
          <NectedEditorField
            height={`calc(100% - 20rem)`}
            heightFull={false}
            control={control}
            name={name}
            mode={mode}
            customSuggestions={customSuggestions}
            readOnly={readOnly}
            methods={methods}
            onSetEditorValidity={onSetEditorValidity}
            domain={domain}
            defaultValue={defaultValue}
          />
        </>
      )}
    </>
  );
};
