import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
  Button,
  Dataset,
  Modal,
  ModalContent,
  NectedEditorField,
  NectedSuggestionModel,
  Typography,
  toasts,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { isRuleReadOnlyAtom, selectedDataSetAtom } from '../../../..';
import { siteConstantsAtom } from '../../../../../../atom';
import { customAttributesAtom } from '../../../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import {
  getTooltipText,
  isCorrectJsSyntaxV2,
} from '../../../../../../utils/common';
import { editorDomain } from '../../../../../../utils/constant';
import { useGenerateDataset } from '../../../../../Workflow/hooks/useGenerateDataset';
import { useUpdateExecutedValueRules } from '../../../../hooks/useUpdateExecutedValueRules';
import { updateDataSetOnChange } from '../../../../utils/common';
import { dataSetParamsAtom } from '../../../CreateRuleSheet/CreateRuleSheet';
import { SyntaxErrorContainer } from '../../../RuleComponents/RuleComponents.styled';
import { ExcelEditorContainer } from '../../ExcelNodeModal/ExcelNodeModal.styled';
import { FooterStyled } from '../../JsNodeSheet/JsNodeSheet.styled';
import { OnCloseNodeModal } from '../../Results/OnCloseNodeModal';
import { SaveNodeModal } from '../../Results/SaveNodeModal';
import { simpleRuleNodesAtom } from '../../SimpleRule';
import { SqlNodeModel } from '../../models';
import { sqlNodeScheme } from '../../schema';

export type ExcelFormulaNodeSheetProps = {
  nodeId: string;
};

export function ExcelFormulaNodeSheet({ nodeId }: ExcelFormulaNodeSheetProps) {
  const [ruleList, setRuleList] = useAtom(simpleRuleNodesAtom);
  const { close: closeExcelModal } = useCurrentLayer();
  const [dataSetVariables] = useAtom(dataSetParamsAtom);
  const [, setDataSetTokens] = useState<string[]>([]);
  const [suggestionObjs, setSuggestionObjs] = useState<NectedSuggestionModel[]>(
    []
  );
  const [isReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [customAttributes] = useAtom(customAttributesAtom);
  const [dataSetSelected] = useAtom(selectedDataSetAtom);

  const [isQueryValidNected, setIsQueryValidNected] = useState(true);
  const [isReturnTypeRequired, setIsReturnTypeRequired] = useState(false);
  const [returnType, setReturnType] = useState<string | null>(null);
  const [executedValue, setExecutedValue] = useState<any | null>(null);
  const [hasChanged, setHasChanged] = useState(false);

  const [updatedDataset, setUpdatedDataset] = useState<Record<string, Dataset>>(
    {}
  );

  const { tokens } = useGenerateDataset({
    updatedDataset,
  });

  const { executedValue: execValues, handleGetExecutionValues } =
    useUpdateExecutedValueRules({
      updatedDataset,
    });

  const { open: onCloseModal } = useLayer(
    <OnCloseNodeModal onClose={() => closeExcelModal()} />
  );

  const { openWithProps: openSaveNodeModal } = useLayer(<SaveNodeModal />);

  const { control, handleSubmit, watch } = useForm<SqlNodeModel>({
    resolver: zodResolver(sqlNodeScheme),
    defaultValues: {
      sqlNodeName:
        _isEmpty(ruleList[nodeId].name) || _isNil(ruleList[nodeId].name)
          ? 'Untitled'
          : ruleList[nodeId].name,
      query: ruleList[nodeId].query,
    },
    mode: 'onSubmit',
  });

  const jsCode = useWatch({
    name: 'query',
    control,
  });

  const onSubmit = (data: SqlNodeModel) => {
    if (!isQueryValidNected) {
      toasts.error(
        'You still have some errors in the editor. Please resolve to proceed',
        'error'
      );

      return;
    }

    setIsReturnTypeRequired(true);
  };

  const onExecutionValueSave = (execValue: any) => {
    if (!_isUndefined(execValue)) {
      setExecutedValue(execValue);
    } else {
      setExecutedValue(undefined);
      openSaveNodeModal({
        onSave: () => {
          setRuleList((prev) => ({
            ...prev,
            [nodeId]: {
              ...prev[nodeId],
              query: watch('query'),
              value: undefined,
              returnType : returnType ?? '',
              sourceType: undefined,
              attribute: undefined,
            },
          }));

          close();
        },
      });
    }
  };

  const onSetReturnType = (type: any) => {
    if (type === 'undefined' || type === null) {
      setReturnType('undefined');
    }

    setReturnType(type);
    setIsReturnTypeRequired(false);
  };

  useEffect(() => {
    if (
      !_isNil(returnType) &&
      returnType !== 'undefined' &&
      !_isEmpty(returnType)
    ) {
      setRuleList((prev) => ({
        ...prev,
        [nodeId]: {
          ...prev[nodeId],
          query: watch('query'),
          value: executedValue,
          returnType,
          sourceType: undefined,
          attribute: undefined,
        },
      }));

      closeExcelModal();
    }
  }, [returnType]);

  useEffect(() => {
    setSuggestionObjs(tokens);
  }, [JSON.stringify(tokens)]);

  useEffect(() => {
    if (!_isNil(dataSetVariables)) {
      const dataSetSuggestions = _reduce(
        dataSetVariables,
        (result: string[], value, key) => {
          if (!_isNil(value.attributes)) {
            return [
              ...result,
              ..._map(value.attributes, (attributeValue, attributeKey) => {
                if (
                  ['string', 'dateTime', 'date'].includes(
                    attributeValue.dataType
                  )
                ) {
                  return `"<<${key}.${attributeKey}>>"`;
                }

                return `<<${key}.${attributeKey}>>`;
              }),
            ];
          }

          return result;
        },
        []
      );

      setUpdatedDataset(
        updateDataSetOnChange(
          customAttributes,
          dataSetVariables,
          dataSetSelected
        )
      );

      setDataSetTokens([...dataSetSuggestions]);
    }
  }, [dataSetVariables]);

  return (
    <Modal size="extraLargeXS" hideHeader hideCloseButton>
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gutter="1rem">
            <PadBox padding="1rem">
              <Stack gutter={8}>
                <Typography name="heading1">Formula Editor</Typography>

                <ExcelEditorContainer $readOnly={isReadOnly}>
                  <NectedEditorField
                    name="query"
                    control={control}
                    mode="js"
                    customSuggestions={suggestionObjs}
                    defaultValue={getTooltipText(
                      siteConstants,
                      'rules',
                      'formulaInResult',
                      'otherText'
                    )}
                    readOnly={isReadOnly}
                    onSetEditorValidity={setIsQueryValidNected}
                    setReturnType={onSetReturnType}
                    sendReturnType={isReturnTypeRequired}
                    domain={editorDomain}
                    execValues={execValues}
                    handleGetExecData={handleGetExecutionValues}
                    showError={false}
                    setExecutedValue={onExecutionValueSave}
                    setHasEditorChanged={setHasChanged}
                  />

                  {!isCorrectJsSyntaxV2(jsCode, updatedDataset).status && (
                    <SyntaxErrorContainer padding="1rem">
                      <Typography>
                        {isCorrectJsSyntaxV2(jsCode, updatedDataset).message}
                      </Typography>
                    </SyntaxErrorContainer>
                  )}
                </ExcelEditorContainer>
              </Stack>
            </PadBox>

            {!isCorrectJsSyntaxV2(jsCode, updatedDataset).status && (
              <SyntaxErrorContainer padding="1rem">
                <Typography>
                  {isCorrectJsSyntaxV2(jsCode, updatedDataset).message}
                </Typography>
              </SyntaxErrorContainer>
            )}

            {returnType === 'undefined' && (
              <SyntaxErrorContainer padding="1rem">
                <Typography>
                  The last statement must be a boolean expression
                </Typography>
              </SyntaxErrorContainer>
            )}

            <FooterStyled padding={[4, 8]}>
              <Inline justify="end">
                <Button
                  type="button"
                  appearance="filled"
                  onClick={() => {
                    if (hasChanged) {
                      onCloseModal();
                    } else {
                      closeExcelModal();
                    }
                  }}
                >
                  Close
                </Button>
                <Button type="submit" disabled={isReadOnly}>
                  Save
                </Button>
              </Inline>
            </FooterStyled>
          </Stack>
        </form>
      </ModalContent>
    </Modal>
  );
}
