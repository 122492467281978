import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import _some from 'lodash/some';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import type { Location } from 'react-router-dom';
import {
  Button,
  Expander,
  Image,
  List,
  ListItem,
  ListItemIcon,
  ListItemLink,
  TooltipReact,
  Typography,
} from 'ui';

import { subscriptionPlanAtom } from '../../atom/index';

import { currentWorkspaceDetailAtom } from '../../pages/Workspace/atom';
import type { RoleJsonType, SubscriptionPlanType } from '../../types/';
import { checkIfThemeEnabled } from '../../utils/common';
import { HelpMenu, envMap } from '../../utils/constant';
import { roleJsonAtom } from '../authentication/router/AuthProvider';
import { StackAsItem } from '../layouts/Stack.styled';
import { NectedLogo } from '../logo';
import {
  AddOnContainer,
  ExternalLink,
  ItemName,
  ProgressBarContainer,
  ProgressWidth,
  SidebarBeta,
  SidebarFooter,
  SidebarFooterContainer,
  SidebarMenuContainer,
  SidebarStyled,
  StatsDisplay,
  TrialPeriodComponent,
  TrialText,
  UpgradeLink,
  UsageBox,
  UsageContainer,
  UsageHelpText,
} from './Sidebar.styled';
import { WorkspaceSelection } from './WorkspaceSelection';
import { SidebarItemType, sidebarItems } from './data';

const deploymentType = envMap.VITE_DEPLOYMENT_TYPE;

type SidebarItem = { route: string; label: string };

function isHomeRoute(pathname: string, route: string) {
  return pathname === '/' && route === '/home';
}

function isActiveRoute(location: Location, item: SidebarItem) {
  return (
    isHomeRoute(location.pathname, item.route) ||
    location.pathname.includes(item.route)
  );
}

function isHideRoute(roleJson: RoleJsonType, id: string) {
  if (id === 'home') {
    return false;
  }

  let isHide =
    !_isNil(roleJson) && !_isNil(roleJson.flags) && id in roleJson.flags
      ? !roleJson.flags[id]
      : true;

  if (
    !isHide &&
    !_isNil(roleJson) &&
    !_isNil(roleJson.internals) &&
    !_isUndefined(roleJson.internals[id])
  ) {
    const internalObj = roleJson.internals;
    const isChildConfigEnable = _some(
      internalObj[id],
      (curr, key) => internalObj[id][key]
    );
    isHide = !isChildConfigEnable;
  }

  return isHide;
}

function doesChildListExist(items: SidebarItemType[], roleJson: RoleJsonType) {
  const hasChildItem: boolean[] = [];

  items.forEach((item) => {
    if (
      !_isNil(roleJson) &&
      !_isNil(roleJson.flags) &&
      item.id in roleJson.flags &&
      roleJson.flags[item.id]
    ) {
      hasChildItem.push(true);
    }
  });

  return hasChildItem.some((curr) => curr);
}

type UsageMapItem = {
  title: string;
  valueKey: string[];
  allowedKey: string;
};

const USAGE_MAP: UsageMapItem[] = [
  {
    title: 'Editors',
    valueKey: ['editor'],
    allowedKey: 'editorLimit',
  },
  {
    title: 'Rules',
    valueKey: ['rule'],
    allowedKey: 'ruleLimit',
  },
  {
    title: 'Workflow',
    valueKey: ['workflow'],
    allowedKey: 'workflowLimit',
  },
  {
    title: 'API/Invocations',
    valueKey: ['invocation'],
    allowedKey: 'invocationLimit',
  },
];

const consolidateUsage = (
  subscriptionPlan: SubscriptionPlanType,
  valueKey: string[]
) => {
  let totalUsage = 0;

  if (!_isNil(subscriptionPlan)) {
    valueKey.forEach((key) => {
      totalUsage = totalUsage + (subscriptionPlan.usage[key] as number);
    });
  }

  return totalUsage;
};

export const renderUsageProgress = (
  subscriptionPlan: SubscriptionPlanType,
  filteringEntity = ''
) => {
  const filteredUsageMap =
    !_isEmpty(filteringEntity) && !_isNil(filteringEntity)
      ? USAGE_MAP.filter(
          (item) => item.title.toLowerCase() === filteringEntity.toLowerCase()
        )
      : USAGE_MAP;

  const isWhiteLabel = checkIfThemeEnabled(
    undefined,
    envMap.VITE_FULL_WHITELABELLED === 'true'
  );

  return (
    <>
      {filteredUsageMap.map((item, usageMapIndex) => {
        const progress =
          (consolidateUsage(subscriptionPlan, item.valueKey) /
            subscriptionPlan.plan[item.allowedKey]) *
          100;
        const aboutToExceed = progress > 80;

        return (
          <Stack key={usageMapIndex} gutter={0}>
            <UsageContainer>
              <Typography>{item.title}</Typography>
              {subscriptionPlan.plan[item.allowedKey] === 0 && (
                <Typography>∞</Typography>
              )}
              {subscriptionPlan.plan[item.allowedKey] !== 0 && (
                <StatsDisplay
                  id={`stats-${item.title.toLocaleLowerCase()}`}
                  aboutToExceed={aboutToExceed}
                >
                  {`${consolidateUsage(subscriptionPlan, item.valueKey)}`} /{' '}
                  {`${subscriptionPlan.plan[item.allowedKey] as string}`}
                </StatsDisplay>
              )}
            </UsageContainer>
            {subscriptionPlan.plan[item.allowedKey] !== 0 && (
              <ProgressBarContainer>
                <ProgressWidth
                  progress={progress}
                  aboutToExceed={aboutToExceed}
                />
              </ProgressBarContainer>
            )}
            {aboutToExceed &&
              subscriptionPlan.plan[item.allowedKey] !== 0 &&
              !isWhiteLabel && (
                <AddOnContainer>
                  <UpgradeLink
                    href="#"
                    id={`add-ons-link-${item.title.toLocaleLowerCase()}`}
                  >
                    {' '}
                    + Add On
                  </UpgradeLink>
                </AddOnContainer>
              )}
          </Stack>
        );
      })}
    </>
  );
};

export function Sidebar() {
  const [subscriptionPlan, setSubscriptionPlan] = useAtom(subscriptionPlanAtom);
  const location = useLocation();
  const [roleJson] = useAtom(roleJsonAtom);
  const [currentWorkspace] = useAtom(currentWorkspaceDetailAtom);

  const [sideBarItems] = useState(sidebarItems);
  const usage = JSON.parse(window.sessionStorage.getItem('userUsage') ?? '{}');
  const userPlan = JSON.parse(
    window.sessionStorage.getItem('userPlan') ?? '{}'
  );

  const limits = userPlan?.limits;

  const planObj = userPlan?.plan ?? {};
  const daysLeftForTrial: number = usage?.daysLeft ?? -1;
  const showTrialComponent =
    daysLeftForTrial > 0 && subscriptionPlan?.plan?.isPaid === false;

  const threshold = usage?.threshold ?? 2;

  const btnTitle = usage?.btnTitle;
  const allotedDaysForTrial = usage?.allotedTrialDays;

  const showRequestTrialComponent =
    subscriptionPlan?.plan?.isPaid === false &&
    Object.keys(planObj).length > 0 &&
    (daysLeftForTrial < 0 || _isNil(daysLeftForTrial));

  // Please Do not remove this code, it might be needed in the future
  // const isPremiumSidebarItem = (id: string) => {
  //   if (!_isNil(premiumFeatPlan) && id in premiumFeatPlan && !_isNil(usage)) {
  //     return (
  //       (premiumFeatPlan[id].premium as boolean) && (usage.showBadge as boolean)
  //     );
  //   }

  //   return false;
  // };

  useEffect(() => {
    if (!_isNil(limits) && !_isNil(subscriptionPlan)) {
      setSubscriptionPlan({
        ...subscriptionPlan,
        plan: limits,
      });
    }
  }, [JSON.stringify(limits), JSON.stringify(subscriptionPlan)]);

  const checkUsageExceeds = (
    subscriptionPlan: SubscriptionPlanType | null,
    rules: UsageMapItem[]
  ) => {
    if (!_isNil(subscriptionPlan)) {
      for (const i of rules) {
        const progress =
          (consolidateUsage(subscriptionPlan, i.valueKey) /
            subscriptionPlan.plan[i.allowedKey]) *
          100;

        if (progress > 80 && progress !== Infinity) {
          return true;
        }
      }
    }

    return false;
  };

  const tooltipForIcon =
    'Your account is at risk we may delete your data please upgrade or limit your usage';

  const iconUrl = checkUsageExceeds(subscriptionPlan, USAGE_MAP)
    ? `${envMap.VITE_ASSETS_URL}website/icons/account_usage_warning.png`
    : '';

  const hasIconUrl = !_isNil(iconUrl) && !_isEmpty(iconUrl);
  const isWhiteLabel = checkIfThemeEnabled(
    currentWorkspace?.whiteLabelEnabled,
    envMap.VITE_FULL_WHITELABELLED === 'true'
  );

  const getUsageTitle = () => {
    return (
      <Stack gutter={2}>
        <Inline gutter={'1.6rem'} align="center">
          <Typography name="heading4" fontWeight={700}>
            Monthly Usage{' '}
          </Typography>
          {!_isNil(tooltipForIcon) && !_isEmpty(tooltipForIcon) ? (
            <TooltipReact
              id={tooltipForIcon}
              launcher={
                hasIconUrl ? (
                  <Image
                    src={iconUrl}
                    alt="usage exceeded alert"
                    size="small"
                  />
                ) : undefined
              }
            >
              <Typography>{tooltipForIcon}</Typography>
            </TooltipReact>
          ) : (
            <>
              {hasIconUrl ? (
                <Image src={iconUrl} alt="usage exceeded alert" size="small" />
              ) : (
                ''
              )}
            </>
          )}
        </Inline>

        {!_isNil(subscriptionPlan) && (
          <Typography
            name="secondarySmall"
            id="plan-details"
            data-planName={subscriptionPlan.planName}
            data-isPaid={subscriptionPlan.plan.isPaid}
          >
            {subscriptionPlan.plan.name ?? subscriptionPlan.planName} &nbsp;
            &nbsp;
            {deploymentType === 'onpremise' || isWhiteLabel ? null : (
              <UpgradeLink
                textDecoration={true}
                target="_blank"
                href={`https://${
                  window.location.href.includes('stage') ||
                  window.location.href.includes('localhost')
                    ? 'nected.webflow.io'
                    : 'nected.ai'
                }/pricing#pricing-container`}
                id="upgrade-plan-link"
              >
                Upgrade Plan
              </UpgradeLink>
            )}
          </Typography>
        )}
      </Stack>
    );
  };

  let updatedSideBarItems = sideBarItems;

  if(currentWorkspace?.appCount === 0){
    updatedSideBarItems = updatedSideBarItems.map(section => ({
      ...section,
      items: section.items.filter(item => item.id !== "customapp")
    }));
  }

  return (
    <Stack>
      <Stack as={SidebarStyled} padding={16}>
        <StackAsItem grow={1} gutter={20}>
          <div>
            <NectedLogo />
          </div>
          <WorkspaceSelection />
          <SidebarMenuContainer gutter={10}>
            {updatedSideBarItems.map((section, sectionIndex) => {
              const isChildExist = doesChildListExist(section.items, roleJson);
              let updatedSectionItems = section.items;

              if (deploymentType === 'onpremise' || isWhiteLabel) {
                updatedSectionItems = updatedSectionItems.filter(
                  (item) => item.id !== 'onpremise'
                );
              }

              return (
                <List
                  title={isChildExist ? section.label : ''}
                  key={sectionIndex}
                >
                  {isChildExist &&
                    updatedSectionItems.map((item, sectionItemIndex) => {
                      const isActive = isActiveRoute(location, item);
                      const isHide = isHideRoute(roleJson, item.id);
                      const icon = isActive ? item.activeIcon : item.icon;

                      return (
                        <ListItemLink
                          to={item.route}
                          active={isActive}
                          key={`${sectionIndex}_${sectionItemIndex}`}
                          isHide={isHide}
                        >
                          <ListItem
                            metaInfo={{
                              id: `${item.id}-sidebar`,
                              trigger: 'onLoad',
                            }}
                            active={isActive}
                          >
                            <ListItemIcon
                              icon={<img src={icon} alt={item.alt} />}
                              $active={isActive}
                              isImage
                            />

                            <span>{item.label}</span>

                            {!_isNil(item.isBeta) && item.isBeta ? (
                              <SidebarBeta
                                padding={['0.4rem', '1rem']}
                                $isActive={isActive}
                              >
                                BETA
                              </SidebarBeta>
                            ) : null}

                            {/* {isPremium && <PremiumPill />} */}
                          </ListItem>
                        </ListItemLink>
                      );
                    })}
                </List>
              );
            })}

            {!isWhiteLabel && (
              <List title={'Help'}>
                {HelpMenu.items.map((item, index) => {
                  return (
                    <ExternalLink
                      key={index}
                      to={item.route}
                      target={item.target}
                    >
                      <ListItem active={false}>
                        <ListItemIcon
                          icon={<img src={item.icon} alt={item.alt} />}
                          $active={false}
                          isImage
                        />
                        <ItemName>{item.name}</ItemName>
                      </ListItem>
                    </ExternalLink>
                  );
                })}
              </List>
            )}
          </SidebarMenuContainer>
        </StackAsItem>
      </Stack>
      <SidebarFooterContainer>
        <SidebarFooter style={{ padding: '1.6rem' }} gutter={10}>
          {showTrialComponent && (
            <TrialPeriodComponent
              bgColor={
                daysLeftForTrial >= threshold
                  ? 'var(--color-primary1)'
                  : 'var(--color-fireEngineRed)'
              }
            >
              <TrialText
                fontWeight={700}
              >{`Trial period ends in ${daysLeftForTrial} days`}</TrialText>
              <Button
                id="sidebar-upgrade-button"
                size="xs"
                appearance={
                  daysLeftForTrial >= threshold ? 'bordered' : 'bordered-danger'
                }
              >
                Upgrade Plan
              </Button>
            </TrialPeriodComponent>
          )}
          {showRequestTrialComponent && (
            <TrialPeriodComponent bgColor={'var(--color-primary1)'}>
              <TrialText fontWeight={700}>Explore Premium Features</TrialText>
              {_isNil(btnTitle) || _isEmpty(btnTitle) ? (
                <Button
                  id="sidebar-upgrade-button"
                  size="xs"
                  appearance={'bordered'}
                >
                  Upgrade Plan
                </Button>
              ) : (
                <Button
                  id="sidebar-extend-request-button"
                  size="xs"
                  appearance={'bordered'}
                >
                  {`${btnTitle as string} ${
                    allotedDaysForTrial as string
                  } days free trial`}
                </Button>
              )}
            </TrialPeriodComponent>
          )}
          {!_isNil(subscriptionPlan) && (
            <UsageBox>
              <Expander
                collapsed={true}
                title={getUsageTitle()}
                fontWeight={700}
              >
                <Stack gutter={'1.4rem'}>
                  {renderUsageProgress(subscriptionPlan)}
                </Stack>
                <UsageHelpText name="secondarySmall">
                  Usage measured from the 1st every month
                </UsageHelpText>
              </Expander>
            </UsageBox>
          )}
        </SidebarFooter>
      </SidebarFooterContainer>
    </Stack>
  );
}
