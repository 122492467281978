import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import _isNil from 'lodash/isNil';
import AceEditor from 'react-ace';
import { BsCheckCircleFill } from 'react-icons/bs';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Expander, Spinner, Typography } from 'ui';

import { PaddingAsPropType } from '../../../../types';
import { StatusCode } from '../../../../utils/response/statusCode';
import { ActionData, RuleTestActionObject, RuleType } from '../../types';
import {
  ErrorIcon,
  ErrorMessage,
  JsonContainer,
  NoActionContainer,
  StatusColumn,
} from './RuleExecutionAction.styled';
import { TableContainer } from './TableContainer';

type RuleExecutionActionProps = {
  actionResponse: RuleTestActionObject[];
  handleExpandIconClick: (actionName: string) => void;
  padding?: PaddingAsPropType;
  flexGrow?: number;
  ruleType?: RuleType;
};

type RulesTableData = {
  actionName: string;
  actionData: ActionData;
};

export function RuleExecutionAction({
  actionResponse,
  handleExpandIconClick,
  padding,
  flexGrow,
  ruleType = 'SimpleRule',
}: RuleExecutionActionProps) {
  const getStatusBasedTitleComponent = (actionData: ActionData) => {
    const { hasError, executionTime, json } = actionData;

    if (hasError) {
      return (
        <Inline align="center" gutter={5}>
          <ErrorIcon>
            <FaExclamationTriangle fill="var(--color-fireEngineRed)" />
          </ErrorIcon>
          <ErrorMessage name="error">Failed: {json}</ErrorMessage>
        </Inline>
      );
    } else {
      return (
        <Inline align="center" gutter={5}>
          <BsCheckCircleFill fill="green" />
          <Typography name="success">Success {executionTime}</Typography>
        </Inline>
      );
    }
  };

  const columns: Array<ColumnDef<RulesTableData>> = [
    {
      header: 'Action',
      accessorKey: 'actionName',
      cell: (props: CellContext<RulesTableData, any>) => (
        <Typography>{props.getValue()}</Typography>
      ),
    },
    {
      header: 'Status',
      accessorKey: 'actionData',
      cell: (props: CellContext<RulesTableData, any>) => {
        const actionData = props.getValue();
        const { status, isCollapsed, json } = actionData;
        const isProgress = status === StatusCode.RUNNING;

        return (
          <>
            {isProgress && (
              <PadBox as={Inline} padding={['0.5rem', '1rem']}>
                <Spinner size="extraSmall" />
              </PadBox>
            )}
            {!isProgress && (
              <Expander
                title={getStatusBasedTitleComponent(actionData)}
                collapsed={isCollapsed}
                onClick={() =>
                  handleExpandIconClick(props.row.original.actionName)
                }
                as={StatusColumn}
              >
                <Stack>
                  {ruleType === 'RuleSet' && (
                    <>
                      <Typography name="heading5" fontWeight={700}>
                        Input
                      </Typography>
                      <JsonContainer>
                        <AceEditor
                          className="json-result-readonly"
                          mode="json"
                          theme="chrome"
                          width="100%"
                          fontSize={12}
                          showPrintMargin={false}
                          highlightActiveLine={true}
                          showGutter={true}
                          setOptions={{
                            useWorker: false,
                            showLineNumbers: true,
                            tabSize: 2,
                          }}
                          readOnly
                          value={
                            !_isNil(actionData.ruleOutput)
                              ? actionData.ruleOutput
                              : ''
                          }
                        />
                      </JsonContainer>
                      <Typography name="heading5" fontWeight={700}>
                        Output
                      </Typography>
                    </>
                  )}
                  <JsonContainer>
                    <AceEditor
                      className="json-result-readonly"
                      mode="json"
                      theme="chrome"
                      width="100%"
                      fontSize={12}
                      showPrintMargin={false}
                      highlightActiveLine={true}
                      showGutter={true}
                      setOptions={{
                        useWorker: false,
                        showLineNumbers: true,
                        tabSize: 2,
                      }}
                      readOnly
                      value={json}
                    />
                  </JsonContainer>
                </Stack>
              </Expander>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      {actionResponse.length === 0 ? (
        <NoActionContainer>No actions provided</NoActionContainer>
      ) : (
        <TableContainer
          columns={columns}
          tableData={actionResponse}
          padding={padding as PaddingAsPropType}
          flexGrow={flexGrow}
        />
      )}
    </>
  );
}
