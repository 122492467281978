import { translations } from '../translations/en-US';
import { isValidDateorDateTime } from './common';

type MaxCharactersValidationRule = {
  value: number;
  message: string;
};

export const maxFiftyCharactersRule: MaxCharactersValidationRule = {
  value: 50,
  message: translations['validation.max_characters'],
};

export const isValidDate = (date: string, from = ''): boolean => {
  return isValidDateorDateTime(date, from).isValid;
};

export const isValidDateTime = (dateTime: string, from = ''): boolean => {
  return (
    isValidDateorDateTime(dateTime, from).isValid &&
    isValidDateorDateTime(dateTime, from).type === 'dateTime'
  );
};

export const isValidDateTimeSecondary = (
  dateTimeSecondary: string
): boolean => {
  return (
    isValidDateorDateTime(dateTimeSecondary).isValid &&
    isValidDateorDateTime(dateTimeSecondary).type === 'dateTime'
  );
};
