import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { FiPlusCircle } from 'react-icons/fi';
import { Button, TextButton, TextField, TooltipReact, Typography } from 'ui';

import { AddButton } from '../../../components/AddButton';
import { FormHeader } from '../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import {
  FormFooter,
  FormPanel,
  Table,
  TableData,
  TableHeader,
  TableRow,
} from '../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet.styled';
import { sendEventToGTM } from '../../../utils/common';
import { moduleSchemaDefaultValues } from '../models';
import { SchemaFormProps } from '../types';
import { PredefineValueSelection } from './PredefineValueSelection';
import { UsageSelection } from './UsageSelection';
import { schemaSavingErrorAtom } from './createModuleSchemaSheet';
import { SchemaDataTypeSelection } from './dataTypeSelection';

export function SchemaForm({
  fields,
  append,
  remove,
  control,
  watch,
  setValue,
  onSubmit,
  hasSchemaId = false,
  handleDeleteSchema,
}: SchemaFormProps) {
  const [, setSchemaSavingError] = useAtom(schemaSavingErrorAtom);
  const formHeaders: FormHeader[] = [
    {
      label: 'Name',
    },
    {
      label: 'Type',
    },
    {
      label: 'Predefined Value',
    },
    {
      label: 'Usage',
    },
    {
      label: '',
    },
  ];

  const formValues = watch('moduleSchema');

  useEffect(() => {
    if (!_isNil(formValues) && formValues.length > 0 && !hasSchemaId) {
      setSchemaSavingError('you have unsaved changes');
    }
  }, [formValues, setSchemaSavingError]);

  const handleSendGTMEvent = (action: string) => {
    sendEventToGTM({
      event: 'rule',
      type: 'moduleSchema',
      action,
      element: '',
      nec_source: '',
      action_name: '',
    });
  };

  const handleAddConfig = () => {
    handleSendGTMEvent('add');
    append({ ...moduleSchemaDefaultValues });
  };

  const handleDeleteRow = (index: number) => {
    handleSendGTMEvent('delete');
    remove(index);
  };

  const isReadOnly = false;

  return (
    <Stack as={FormPanel}>
      <Stack as={FormPanel} gutter={'2.4rem'} padding={['1.2rem', '2.4rem']}>
        <Stack gutter="1rem">
          <Table>
            <TableRow>
              {formHeaders.map((header) => (
                <TableHeader key={header.label}>
                  <PadBox padding="1.2rem">
                    <Inline align="center" gutter={8}>
                      <Typography fontWeight={700}>{header.label}</Typography>

                      {!_isNil(header.tooltipContent) && (
                        <TooltipReact id={header.label} placement="top-start">
                          {header.tooltipContent}
                        </TooltipReact>
                      )}
                    </Inline>
                  </PadBox>
                </TableHeader>
              ))}
            </TableRow>

            {fields.map((field, index) => {
              return (
                <TableRow key={field.id}>
                  <TableData>
                    <PadBox padding={'1.2rem'}>
                      <TextField
                        control={control}
                        rules={{ required: 'Required' }}
                        name={`moduleSchema.${index}.name`}
                        placeholder="Name"
                        showErrorIcon={false}
                        disabled={isReadOnly || hasSchemaId}
                      />
                    </PadBox>
                  </TableData>

                  <TableData>
                    <PadBox padding={'1.2rem'}>
                      <SchemaDataTypeSelection
                        index={index}
                        setValue={setValue}
                        control={control}
                        name={`moduleSchema.${index}.dataType`}
                        isDisabled={isReadOnly || hasSchemaId}
                      />
                    </PadBox>
                  </TableData>

                  <TableData>
                    <PadBox padding={'1.2rem'}>
                      <PredefineValueSelection
                        setValue={setValue}
                        control={control}
                        index={index}
                        name={`moduleSchema.${index}.value`}
                        disabled={isReadOnly || hasSchemaId}
                      />
                    </PadBox>
                  </TableData>

                  <TableData>
                    <PadBox padding={'1.2rem'}>
                      <UsageSelection
                        index={index}
                        setValue={setValue}
                        control={control}
                        name={`moduleSchema.${index}.usageType`}
                        isDisabled={isReadOnly}
                      />
                    </PadBox>
                  </TableData>
                  <TableData>
                    <PadBox padding="2.3rem">
                      <TextButton
                        onClick={() => {
                          if (
                            hasSchemaId &&
                            typeof handleDeleteSchema === 'function'
                          ) {
                            handleDeleteSchema();
                          } else if (!hasSchemaId) {
                            handleDeleteRow(index);
                          }
                        }}
                        disabled={isReadOnly}
                      >
                        Delete
                      </TextButton>
                    </PadBox>
                  </TableData>
                </TableRow>
              );
            })}
          </Table>

          {!hasSchemaId && (
            <Inline align="center">
              {isReadOnly ? (
                <FiPlusCircle color="var(--color-darkGray)" />
              ) : (
                <AddButton />
              )}

              <TextButton disabled={isReadOnly} onClick={handleAddConfig}>
                Add Attribute
              </TextButton>
            </Inline>
          )}
        </Stack>
      </Stack>
      {!isReadOnly && (
        <Inline as={FormFooter} padding="0.8rem" justify="end">
          <Button type="submit" onClick={onSubmit}>
            Save
          </Button>
        </Inline>
      )}
    </Stack>
  );
}
