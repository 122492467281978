import { PadBox } from '@bedrock-layout/padbox';
import { Center } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiArrowBack } from 'react-icons/bi';
import { IoIosApps } from 'react-icons/io';
import { LuArrowRightToLine } from 'react-icons/lu';
import { Button, Typography } from 'ui';

import { BannerBox } from '../../components/BannerBox';
import { NoDataContainer } from '../../components/Listing/listing.styled';
import { NameIconContainer } from '../../components/WorkspaceComponent/WorkspaceComponent.styled';
import { useAxiosPrivate } from '../../hooks';
import { useGetSiteMeta } from '../../hooks/useGetSiteMeta';
import { envMap } from '../../utils/constant';
import { IntegrationName } from '../Integrations/components/common/IntegrationSheet.styled';
import { ConnectorCardStyled } from '../Integrations/components/connector/ConnectorCard.styled';
import {
  AppContainer,
  CustomAppContainer,
  CustomAppEmptyContainer,
  GoBackButton,
  HideFooter,
} from './customApp.styled';

export function CustomApp() {
  const { axiosPrivate } = useAxiosPrivate();
  const { siteMeta } = useGetSiteMeta();
  const [availableApps, setAvailableApps] = useState([]);
  const [selectedEntityId, setSelectedEntityId] = useState('');
  const [customApp, setCustomApp] = useState<Record<string, any> | null>(null);

  const setCookies = (customApp: Record<string, any>): void => {
    if (!Array.isArray(customApp?.cookies)) {
      // eslint-disable-next-line
      console.warn('Invalid cookies array');

      return;
    }

    const isDev = envMap.VITE_ENVIRONMENT === 'development';
    const domain = isDev ? '.stage.nected.io' : '.nected.ai';

    customApp.cookies.forEach((cookie: string) => {
      try {
        if (typeof cookie !== 'string') {
          // eslint-disable-next-line
          console.warn('Invalid cookie format: not a string');

          return;
        }

        // Replace the domain in the cookie string
        const modifiedCookie = cookie.replace(
          /domain=[^;]+/,
          `domain=${domain}`
        );

        document.cookie = modifiedCookie;
        // eslint-disable-next-line
      } catch (error) {
        // eslint-disable-next-line
        console.error(
          'Error setting cookie:',
          error instanceof Error ? error.message : 'Unknown error'
        );
      }
    });
  };

  useEffect(() => {
    void fetchAllCustomApps();

    return () => {
      setSelectedEntityId('');
      setCustomApp(null);
      setAvailableApps([]);
    };
  }, []);

  const fetchAllCustomApps = async () => {
    const customAppURL = '/module/external/list';
    const response = await axiosPrivate.get(customAppURL);

    if (!_isNil(response.data.data)) {
      setAvailableApps(response.data.data);
    }
  };

  const onClickSingleApp = (entityId: string) => {
    setSelectedEntityId(entityId);
  };

  useEffect(() => {
    if (!_isNil(selectedEntityId) && !_isEmpty(selectedEntityId)) {
      // call detail api
      void fetchCustomAppResponse(selectedEntityId);
    }
  }, [selectedEntityId]);

  const fetchCustomAppResponse = async (selectedEntityId: string) => {
    const customAppURL = `/module/external/${selectedEntityId}`;
    const response = await axiosPrivate.get(customAppURL);

    if (!_isNil(response.data.data)) {
      setCustomApp(response.data.data);
      void setCookies(response.data.data);
    }
  };

  const handleGoBack = () => {
    setSelectedEntityId('');
    setCustomApp(null);
  };

  return (
    <CustomAppContainer>
      <Helmet>
        <title>
          {siteMeta[window.location.pathname.substring(1)]?.title ?? ''}
        </title>
        <meta
          name="description"
          content={
            siteMeta[window.location.pathname.substring(1)]?.description ?? ''
          }
        />
      </Helmet>
      {availableApps.length === 0 &&
        _isEmpty(selectedEntityId) &&
        _isNil(customApp) && (
          <CustomAppEmptyContainer>
            <NoDataContainer>
              <BannerBox
                title={`No apps available`}
                subTitle="Want to create no-code custom apps ?"
                bannerImage="no-code-custom-app.png"
                buttonProps={{
                  buttonText: 'Signup For Early Access',
                }}
              />
            </NoDataContainer>
          </CustomAppEmptyContainer>
        )}
      {availableApps.length > 0 &&
        _isEmpty(selectedEntityId) &&
        _isNil(customApp) && (
          <PadBox padding={['4rem']}>
            <Typography name="heading1" fontWeight={700}>
              Available Apps
            </Typography>
            <AppContainer>
              {availableApps.map((item: Record<string, any>, index: number) => {
                return (
                  <ConnectorCardStyled key={index}>
                    <PadBox padding={['4.9rem', '0.8rem', '0.8rem']}>
                      <Stack gutter="1.6rem">
                        <Stack gutter="2.8rem">
                          <Center centerChildren centerText>
                            <NameIconContainer align="center" justify="center">
                              {_isEmpty(item.name) ? (
                                <IoIosApps />
                              ) : (
                                <Typography>
                                  {item.name[0].toUpperCase()}
                                </Typography>
                              )}
                            </NameIconContainer>
                            <br />
                            <IntegrationName>{item.name}</IntegrationName>
                          </Center>
                        </Stack>

                        <Button
                          trailingIcon={<LuArrowRightToLine />}
                          onClick={() => onClickSingleApp(item.id)}
                        >
                          View
                        </Button>
                      </Stack>
                    </PadBox>
                  </ConnectorCardStyled>
                );
              })}
            </AppContainer>
          </PadBox>
        )}
      {!_isEmpty(selectedEntityId) && !_isNil(customApp) && (
        <>
          <GoBackButton onClick={handleGoBack}>
            {' '}
            <BiArrowBack /> Back
          </GoBackButton>
          <HideFooter />
          <iframe
            style={{
              width: '100%',
              height: 'calc(100vh)',
              border: '0px',
              padding: '0px',
              display: 'block',
              backgroundColor: '#ffffff',
            }}
            id="custom-app-iframe"
            name="custom-app-frame"
            src={customApp?.appUrl}
            title="Nected Application"
            loading="eager"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-downloads"
            referrerPolicy="no-referrer-when-downgrade"
            className="custom-app-iframe"
            onLoad={(e) => {
              try {
                const iframe = document.getElementById(
                  'custom-app-iframe'
                ) as HTMLIFrameElement;

                if (iframe?.contentDocument == null) {
                  // eslint-disable-next-line
                  // eslint-disable-next-line
                  // eslint-disable-next-line
                  console.warn(
                    'Cannot access iframe content - possible cross-origin restriction'
                  );

                  return;
                }

                // Check for the specific text in the iframe content
                const iframeContent = iframe.contentDocument.body.innerText;

                if (
                  iframeContent.includes(
                    'This Budibase app is not publicly accessible'
                  )
                ) {
                  // You can use react-router-dom's navigate or window.location
                  window.location.href = '/'; // or any other path you want to redirect to
                }
              } catch (error) {
                // eslint-disable-next-line
                console.error('Error checking iframe content:', error);
              }
            }}
            onError={(e) => {
              // eslint-disable-next-line
              console.error('iframe failed to load');
              // Error handling if needed
            }}
          />
        </>
      )}
    </CustomAppContainer>
  );
}
