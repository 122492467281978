import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { Attributes, Dataset } from 'ui';

export const getTokenModuleName = (
  tokenDataset: Record<string, Dataset>,
  schemaId: string
) => {
  let moduleObj: any;

  Object.keys(tokenDataset ?? {}).forEach((moduleKey) => {
    Object.keys(tokenDataset[moduleKey].attributes ?? {}).forEach((attrKey) => {
      if (tokenDataset[moduleKey].attributes[attrKey].id === schemaId) {
        moduleObj = tokenDataset[moduleKey];
      }
    });
  });

  return moduleObj?.name ?? '';
};

export const getUsedSchemaAttributes = (
  tokenDataset: Record<string, Dataset>,
  schemaId?: string | null
) => {
  let attrObj: Attributes | undefined;

  if (_isNil(schemaId) || _isEmpty(schemaId)) {
    return undefined;
  }

  Object.keys(tokenDataset ?? {}).forEach((moduleKey) => {
    Object.keys(tokenDataset[moduleKey].attributes ?? {}).forEach((attrKey) => {
      if (tokenDataset[moduleKey].attributes[attrKey].id === schemaId) {
        attrObj = tokenDataset[moduleKey].attributes[attrKey];
      }
    });
  });

  return attrObj;
};

export const isValidSchemaId = (
  tokenDataset: Record<string, Dataset>,
  schemaId?: string
) => {
  const attrObj = getUsedSchemaAttributes(tokenDataset, schemaId);

  return !_isNil(attrObj) && !_isEmpty(attrObj);
};
