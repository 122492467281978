import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';
import { TextField } from 'ui';

export const WorkSpaceFormFieldContainer = styled(Stack)`
  max-inline-size: 32rem;
`;
export const ButtonContainer = styled(Stack)`
  max-inline-size: 10rem;
`;

export const WSTextField = styled(TextField)`
  padding: 0.8rem;
`;

export const WSColorWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
`;


export const DateTimeLauncherStyled = styled(Inline)`
  justify-content: space-between;
  gap: 1rem;
  border: 1px solid var(--color-solitude);
  padding: 1rem;
  border-radius: 0.5rem;
`;

export const DateTimePopoverStyledContainer = styled(Stack)`
  max-block-size: 50rem;
  max-inline-size: 42rem;
  overflow-y: auto;
`;

export const SupportedFormatItem = styled(Inline)`
  gap: 1rem;
  align-items: center;
  border-bottom: 1px solid var(--color-solitude);
  padding: 1rem 0rem;
  &:last-child {
    border-bottom: none !important;
  }
`;