import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { LuBox } from 'react-icons/lu';
import { PiSigmaLight } from 'react-icons/pi';
import { RiErrorWarningLine } from 'react-icons/ri';
import {
  Attributes,
  Dataset,
  Image,
  OnClickRuleArgs,
  PopoverMethods,
  RulePopover,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Typography,
} from 'ui';

import { generateUid } from '../../../utils/common';
import { envMap } from '../../../utils/constant';
import { EmptyPredefineValueComponent } from '../EmptyPredefineValueComponent/EmptyPredefineValueComponent';
import {
  InfoComponentContainer,
  TokenSelectionContainer,
} from './TokenSelectionPopover.styled';

export type TokenSelectionType = 'token' | 'predefined' | 'customFunction';

type TokenSelectionPopoverProps = {
  customTokenComponent?: ReactElement;
  disabled?: boolean;
  selectedSchemaId?: string | null;
  tokenDataset: Record<string, Dataset>;
  handleValueSelection?: (
    data: OnClickRuleArgs,
    type?: TokenSelectionType
  ) => void;
  tabsToShow?: string[];
  size?: string;
  typesToAllow?: string[];
  showTooltip?: boolean;
  setPanelVisible?: any;
};

export const defaultTokenTabsToShow = ['token', 'predefined', 'customFunction'];

export const TokenSelectionPopover = ({
  disabled = false,
  customTokenComponent,
  selectedSchemaId,
  tokenDataset,
  handleValueSelection,
  setPanelVisible,
  tabsToShow = defaultTokenTabsToShow,
  size = '46rem',
  typesToAllow,
  showTooltip,
}: TokenSelectionPopoverProps) => {
  const ref = useRef<PopoverMethods>(null);

  const [dataset, setDataset] = useState<Record<string, Dataset>>({});
  const [showInfo, setShowInfo] = useState(false);
  const [customFuncDataset, setCustomFuncDataset] = useState<
    Record<string, Dataset>
  >({});

  useEffect(() => {
    const finalAttributes: Record<string, Attributes> = {
      jsFormula: {
        name: 'JS Code',
        dataType: 'jsFormula',
      },
      excelFormula: {
        name: 'Formula',
        dataType: 'excelFormula',
      },
    };

    const finalDataset: Record<string, Dataset> = {
      customFunction: {
        name: 'Custom Functions',
        id: generateUid('customFunction_'),
        attributes: finalAttributes,
      },
    };

    setCustomFuncDataset(finalDataset);
  }, []);

  useEffect(() => {
    if (!_isNil(tokenDataset) && !_isEmpty(tokenDataset)) {
      const tokenKeys = Object.keys(tokenDataset);
      for (let i = 0; i < tokenKeys.length; i++) {
        let isFound = false;

        const currentAttributes = tokenDataset[tokenKeys[i]].attributes;
        const attrKeys = Object.keys(currentAttributes);

        for (let j = 0; j < attrKeys.length; j++) {
          const attributeObj = currentAttributes[attrKeys[j]];

          if (attributeObj.id === selectedSchemaId) {
            const finalAttributes =
              attributeObj?.options?.reduce(
                (finalObj: Record<string, Attributes>, currOption, index) => {
                  const key = ['json', 'list'].includes(attributeObj.dataType)
                    ? index
                    : currOption.value;

                  return {
                    ...finalObj,
                    [key]: {
                      name: currOption.value,
                      dataType: attributeObj.dataType,
                      executedValue: currOption.value,
                    },
                  };
                },
                {}
              ) ?? {};

            const finalDataset: Record<string, Dataset> = {
              [attributeObj.name]: {
                name: attributeObj.name,
                id: attributeObj.id ?? '',
                attributes: finalAttributes,
                emptyMessage: EmptyPredefineValueComponent({
                  moduleId: tokenKeys[i],
                  attributeId: attributeObj.id ?? '',
                }),
              },
            };

            setDataset(finalDataset);

            setShowInfo(attributeObj?.usageType === 'mandatory' || false);

            isFound = true;
            break;
          }
        }

        if (isFound) {
          break;
        }
      }
    }
  }, [JSON.stringify(tokenDataset), selectedSchemaId]);

  const tabList: ReactElement[] = [];
  const tabPanelList: ReactElement[] = [];

  if (tabsToShow.includes('token')) {
    tabList.push(
      <Tab
        styleClassName="tabStyled"
        childClassName="tabStyled"
        key="token"
        size="extraSmall"
        disabled={disabled}
      >
        <Inline align="center" gutter={'1rem'}>
          <LuBox />
          Token
        </Inline>
      </Tab>
    );

    tabPanelList.push(
      <TabPanel>
        <PadBox padding="0.8rem">{customTokenComponent}</PadBox>
      </TabPanel>
    );
  }

  if (tabsToShow.includes('predefined')) {
    tabList.push(
      <Tab
        styleClassName="tabStyled"
        childClassName="tabStyled"
        key="predefinedValues"
        size="extraSmall"
        disabled={disabled}
      >
        <Inline align="center" gutter={'1rem'}>
          <Image
            src={`${envMap.VITE_ASSETS_URL}website/icons/module.svg`}
            alt="attribute-icon"
          />
          Predefined
        </Inline>
      </Tab>
    );

    tabPanelList.push(
      <TabPanel>
        <PadBox padding="0.8rem">
          <RulePopover
            dataset={dataset}
            allowList={true}
            placeholder={'Search in values'}
            typesToAllow={typesToAllow}
            version="v2"
            showTooltip={showTooltip}
            restrictNestedNodeSelection={true}
            infoComponent={
              showInfo ? (
                <InfoComponentContainer>
                  <RiErrorWarningLine size={16} />
                  <Typography name="paragraphSmall">
                    Result must match predefined Value (mandatory)
                  </Typography>
                </InfoComponentContainer>
              ) : null
            }
            footer={
              Object.keys(dataset).length === 0 ? (
                <>
                  <Typography>No Value found</Typography>
                </>
              ) : (
                false
              )
            }
            onClick={({ value, key, dataType, executedValue }) => {
              if (typeof handleValueSelection === 'function') {
                handleValueSelection(
                  {
                    value,
                    key,
                    dataType,
                    executedValue,
                  },
                  'predefined'
                );
              }
              if(typeof setPanelVisible === 'function'){
                setPanelVisible(false)
              }
              ref.current?.hide();
            }}
          />
        </PadBox>
      </TabPanel>
    );
  }

  if (tabsToShow.includes('customFunction')) {
    tabList.push(
      <Tab
        styleClassName="tabStyled"
        childClassName="tabStyled"
        key="customFunction"
        size="extraSmall"
        disabled={disabled}
      >
        <Inline align="center" gutter={'1rem'}>
          <PiSigmaLight />
          Custom function
        </Inline>
      </Tab>
    );

    tabPanelList.push(
      <TabPanel>
        <PadBox padding="0.8rem">
          <RulePopover
            dataset={customFuncDataset}
            allowList={true}
            placeholder={'Search in values'}
            version="v2"
            onClick={({ value, key, dataType, executedValue }) => {
              if (typeof handleValueSelection === 'function') {
                handleValueSelection(
                  {
                    value,
                    key,
                    dataType,
                    executedValue,
                  },
                  'customFunction'
                );
              }
              if(typeof setPanelVisible === 'function'){
                setPanelVisible(false)
              }
              ref.current?.hide();
            }}
          />
        </PadBox>
      </TabPanel>
    );
  }

  return (
    <TokenSelectionContainer size={size}>
      <Tabs>
        <TabList styleClassName="tabStyled">{tabList}</TabList>
        <TabPanels>{tabPanelList}</TabPanels>
      </Tabs>
    </TokenSelectionContainer>
  );
};
