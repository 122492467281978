import { getTimezoneOffset } from 'date-fns-tz';
import dateFormat from 'dateformat';
import _isNil from 'lodash/isNil';

import { DateFormatMap } from './constant';

export const getDateInTimezone = () => {
  const timezone = window.sessionStorage.getItem('nected-tz') ?? '';
  // Get the current date
  const date = new Date();

  // Get current time string in the desired timezone
  const timeString = date.toLocaleString('en-US', { timeZone: timezone });

  // Get current time string in the local timezone
  const localTimeString = date.toLocaleString('en-US');

  // Parse both times
  const targetTime = new Date(timeString);
  const localTime = new Date(localTimeString);

  // Calculate the time difference
  const timeDiff = targetTime.getTime() - localTime.getTime();

  // Adjust the original date by adding the difference
  return new Date(date.getTime() + timeDiff);
};

export const formatNectedDate = (
  value: string | Date,
  type: string,
  updateTZ = false
) => {
  const hasTimezoneInInput =
    typeof value === 'string' &&
    (value.match(/[+-]\d{2}:\d{2}$/) || value.match(/Z$/));

  // Return early if value is falsy
  if (!value) return value;

  // Handle special keywords without digit check
  if (typeof value === 'string') {
    // Check for special keywords first
    if (value.toUpperCase() === 'NOW') {
      // Continue processing - these will be handled in the date creation section
    }
    // Only perform digit check for non-special keywords
    else {
      // Check if the string has any numbers - if not, it's definitely not a date
      if (!/\d/.test(value)) {
        return value;
      }

      // Check against common date formats
      const hasDateFormat =
        value.match(/^\d{4}-\d{2}-\d{2}/) || // YYYY-MM-DD
        value.match(/^\d{1,2}[/-]\d{1,2}[/-]\d{4}/) || // MM/DD/YYYY or DD/MM/YYYY
        value.match(/^\d{4}[/-]\d{1,2}[/-]\d{1,2}/) || // YYYY/MM/DD
        value.includes('T'); // ISO format

      if (!hasDateFormat) {
        return value;
      }
    }
  }

  // Special case for ISO8601 strings with timezone offset
  if (
    typeof value === 'string' &&
    value.includes('T') &&
    value.match(/[+-]\d{2}:\d{2}$/)
  ) {
    // Extract date and time without the timezone
    const baseParts = value.split('T');
    if (baseParts.length === 2) {
      const datePart = baseParts[0];

      // If type is 'date', just return the date part
      if (type === 'date') {
        return datePart;
      }

      // Only update timezone if updateTZ is true
      if (updateTZ) {
        // Otherwise, process datetime as before
        const timeMatch = baseParts[1].match(/^(\d{2}:\d{2}:\d{2})/);
        if (timeMatch && timeMatch[1]) {
          const timePart = timeMatch[1];

          // Get user's timezone
          const timezone =
            window.sessionStorage.getItem('nected-tz') ??
            Intl.DateTimeFormat().resolvedOptions().timeZone;

          // Apply new timezone to the same time
          const newOffset = getFormattedTimezoneOffset(timezone);
          const result = `${datePart}T${timePart}${newOffset}`;
          return result;
        }
      } else {
        // If updateTZ is false, just return the original value
        return value;
      }
    }
  }

  let updatedValue: Date;
  const timezone =
    window.sessionStorage.getItem('nected-tz') ??
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  try {
    if (typeof value === 'string' && value.toUpperCase() === 'NOW') {
      updatedValue = getCurrentDateInTimezone(timezone);
    } else {
      // For string values, we'll check if the parsed date corresponds to current date
      // If it does, and input doesn't look like "now", it's probably an invalid date
      // that was defaulted to current date by customParseDate
      if (typeof value === 'string') {
        const parsedDate = customParseDate(removeOffsetFromDateString(value));
        const now = getCurrentDateInTimezone(timezone);

        // If parsed date is today's date and input doesn't indicate "now"
        if (
          parsedDate.toDateString() === now.toDateString() &&
          value.toLowerCase() !== 'now' &&
          value.toLowerCase() !== 'today' &&
          !value.match(/^\d{4}-\d{2}-\d{2}$/) && // Not already a proper date string
          !value.match(/today|current|present/i)
        ) {
          // Not a "now" indicator
          return value; // Return original value for invalid dates
        }

        updatedValue = parsedDate;
      } else {
        updatedValue = value;
      }
    }

    // Check if the parsed date is valid before formatting
    if (!(updatedValue instanceof Date) || isNaN(updatedValue.getTime())) {
      return value; // Return original value if date is invalid
    }
  } catch (error) {
    return value; // Return original value on any error
  }

  const globalDateSettings = window.sessionStorage.getItem('nected-df') ?? 'in';
  const dateMask = DateFormatMap[globalDateSettings as unknown as number].date;
  const dateTimeMask =
    DateFormatMap[globalDateSettings as unknown as number].dateTime;
  const formatString = type === 'date' ? dateMask : dateTimeMask;

  try {
    const formattedDate = dateFormat(updatedValue, formatString);

    if (type !== 'date' && (updateTZ || (!updateTZ && !hasTimezoneInInput))) {
      const offset = getFormattedTimezoneOffset(timezone);
      return `${removeOffsetFromDateString(formattedDate)}${offset}`;
    }
    return formattedDate;
  } catch (error) {
    return value;
  }
};

export const isInvalidDate = (dateString: string | null) => {
  if (_isNil(dateString)) {
    return false;
  }
  const date = customParseDate(dateString);
  return date instanceof Date && !isNaN(date as unknown as number);
};

export const customParseDate = (input: string | Date): Date => {
  // For all date formats, use the regular parsing logic
  let parsedDate: Date;

  // Get user preference for date format (1 = US format with mm/dd, 0 = Indian format with dd/mm)
  const globalDateSettings = window.sessionStorage.getItem('nected-df') ?? 'in';
  const isUSFormat = globalDateSettings === 'us';

  if (typeof input === 'string' && input.length > 0) {
    // Regular expressions for different date formats
    const slashFormat = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/; // mm/dd/yyyy or dd/mm/yyyy
    const dashFormat = /^(\d{1,2})-(\d{1,2})-(\d{4})$/; // mm-dd-yyyy or dd-mm-yyyy
    const yearFirstSlashFormat = /^(\d{4})\/(\d{1,2})\/(\d{1,2})$/; // yyyy/mm/dd
    const yearFirstDashFormat = /^(\d{4})-(\d{1,2})-(\d{1,2})$/; // yyyy-mm-dd

    // Date and time formats
    const dateTimeSlashFormat =
      /^(\d{1,2})\/(\d{1,2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})$/;
    const dateTimeDashFormat =
      /^(\d{1,2})-(\d{1,2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})$/;
    const yearFirstDateTimeSlashFormat =
      /^(\d{4})\/(\d{1,2})\/(\d{1,2}) (\d{2}):(\d{2}):(\d{2})$/;
    const yearFirstDateTimeDashFormat =
      /^(\d{4})-(\d{1,2})-(\d{1,2}) (\d{2}):(\d{2}):(\d{2})$/;

    // ISO formats with improved nanosecond support
    const isoFormat =
      /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,9}))?(?:Z|[+-]\d{2}:\d{2})?$/;
    const dateTimeTZFormat =
      /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/; // yyyy-MM-ddTHH:mm:ssZ
    const rfc3339Format =
      /^(\d{4})-(\d{2})-(\d{2})(?:T|\s)(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,9}))?(?:Z)?$/; // RFC 3339 with Z timezone and nanoseconds

    // New formats: MM-DD-YYYYTHH:MM:SS+TZ or DD-MM-YYYYTHH:MM:SS+TZ
    const formattedDashTimeWithTZOffset =
      /^(\d{1,2})-(\d{1,2})-(\d{4})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,9}))?([+-]\d{2}:\d{2})?$/;
    // Also support slash format: MM/DD/YYYYTHH:MM:SS+TZ or DD/MM/YYYYTHH:MM:SS+TZ
    const formattedSlashTimeWithTZOffset =
      /^(\d{1,2})\/(\d{1,2})\/(\d{4})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,9}))?([+-]\d{2}:\d{2})?$/;

    let match;

    // Parse date with format: 12/31/2023 or 31/12/2023
    if ((match = input.match(slashFormat)) !== null) {
      const first = parseInt(match[1], 10);
      const second = parseInt(match[2], 10);
      const year = parseInt(match[3], 10);

      if (isUSFormat) {
        // US format: mm/dd/yyyy
        parsedDate = new Date(year, first - 1, second);
      } else {
        // Indian format: dd/mm/yyyy
        parsedDate = new Date(year, second - 1, first);
      }
    }
    // Parse date with format: 12-31-2023 or 31-12-2023
    else if ((match = input.match(dashFormat)) !== null) {
      const first = parseInt(match[1], 10);
      const second = parseInt(match[2], 10);
      const year = parseInt(match[3], 10);

      if (isUSFormat) {
        // US format: mm-dd-yyyy
        parsedDate = new Date(year, first - 1, second);
      } else {
        // Indian format: dd-mm-yyyy
        parsedDate = new Date(year, second - 1, first);
      }
    }
    // Parse date with format: 2023/12/31
    else if ((match = input.match(yearFirstSlashFormat)) !== null) {
      parsedDate = new Date(
        parseInt(match[1], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[3], 10)
      );
    }
    // Parse date with format: 2023-12-31
    else if ((match = input.match(yearFirstDashFormat)) !== null) {
      parsedDate = new Date(
        parseInt(match[1], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[3], 10)
      );
    }
    // Parse datetime with format: 12/31/2023 14:30:00 or 31/12/2023 14:30:00
    else if ((match = input.match(dateTimeSlashFormat)) !== null) {
      const first = parseInt(match[1], 10);
      const second = parseInt(match[2], 10);
      const year = parseInt(match[3], 10);
      const hours = parseInt(match[4], 10);
      const minutes = parseInt(match[5], 10);
      const seconds = parseInt(match[6], 10);

      if (isUSFormat) {
        // US format: mm/dd/yyyy HH:mm:ss
        parsedDate = new Date(year, first - 1, second, hours, minutes, seconds);
      } else {
        // Indian format: dd/mm/yyyy HH:mm:ss
        parsedDate = new Date(year, second - 1, first, hours, minutes, seconds);
      }
    }
    // Parse datetime with format: 12-31-2023 14:30:00 or 31-12-2023 14:30:00
    else if ((match = input.match(dateTimeDashFormat)) !== null) {
      const first = parseInt(match[1], 10);
      const second = parseInt(match[2], 10);
      const year = parseInt(match[3], 10);
      const hours = parseInt(match[4], 10);
      const minutes = parseInt(match[5], 10);
      const seconds = parseInt(match[6], 10);

      if (isUSFormat) {
        // US format: mm-dd-yyyy HH:mm:ss
        parsedDate = new Date(year, first - 1, second, hours, minutes, seconds);
      } else {
        // Indian format: dd-mm-yyyy HH:mm:ss
        parsedDate = new Date(year, second - 1, first, hours, minutes, seconds);
      }
    }
    // Parse datetime with format: 2023/12/31 14:30:00
    else if ((match = input.match(yearFirstDateTimeSlashFormat)) !== null) {
      parsedDate = new Date(
        parseInt(match[1], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[3], 10),
        parseInt(match[4], 10),
        parseInt(match[5], 10),
        parseInt(match[6], 10)
      );
    }
    // Parse datetime with format: 2023-12-31 14:30:00
    else if ((match = input.match(yearFirstDateTimeDashFormat)) !== null) {
      parsedDate = new Date(
        parseInt(match[1], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[3], 10),
        parseInt(match[4], 10),
        parseInt(match[5], 10),
        parseInt(match[6], 10)
      );
    }
    // Parse ISO datetime format: 2023-12-31T14:30:00Z or 2023-12-31T14:30:00+05:30 with nanoseconds
    else if ((match = input.match(isoFormat)) !== null) {
      const year = parseInt(match[1], 10);
      const month = parseInt(match[2], 10) - 1;
      const day = parseInt(match[3], 10);
      const hours = parseInt(match[4], 10);
      const minutes = parseInt(match[5], 10);
      const seconds = parseInt(match[6], 10);
      
      // Handle nanoseconds - convert to milliseconds (JavaScript Date only supports milliseconds)
      let milliseconds = 0;
      if (match[7]) {
        // Pad to 3 digits to convert to milliseconds (JavaScript limit)
        const nanoStr = match[7].padEnd(9, '0');
        milliseconds = parseInt(nanoStr.substring(0, 3), 10);
      }
      
      // For direct ISO format, we can let the Date constructor handle timezone offsets
      if (input.includes('Z') || input.includes('+') || input.includes('-')) {
        parsedDate = new Date(input);
      } else {
        parsedDate = new Date(year, month, day, hours, minutes, seconds, milliseconds);
      }
    }
    // Parse yyyy-MM-ddTHH:mm:ssZ format (UTC)
    else if ((match = input.match(dateTimeTZFormat)) !== null) {
      parsedDate = new Date(input);
    }
    // Parse RFC 3339 format with Z timezone and nanosecond precision
    else if ((match = input.match(rfc3339Format)) !== null) {
      const year = parseInt(match[1], 10);
      const month = parseInt(match[2], 10) - 1;
      const day = parseInt(match[3], 10);
      const hours = parseInt(match[4], 10);
      const minutes = parseInt(match[5], 10);
      const seconds = parseInt(match[6], 10);
      
      // Handle nanoseconds - convert to milliseconds (JavaScript Date only supports milliseconds)
      let milliseconds = 0;
      if (match[7]) {
        // Pad to 3 digits to convert to milliseconds (JavaScript limit)
        const nanoStr = match[7].padEnd(9, '0');
        milliseconds = parseInt(nanoStr.substring(0, 3), 10);
      }
      
      if (input.includes('Z')) {
        // UTC time
        parsedDate = new Date(Date.UTC(year, month, day, hours, minutes, seconds, milliseconds));
      } else {
        // Local time
        parsedDate = new Date(year, month, day, hours, minutes, seconds, milliseconds);
      }
    }
    // Parse formatted date with timezone offset and nanoseconds
    else if (
      (match = input.match(formattedDashTimeWithTZOffset)) !== null ||
      (match = input.match(formattedSlashTimeWithTZOffset)) !== null
    ) {
      const first = parseInt(match[1], 10);
      const second = parseInt(match[2], 10);
      const year = parseInt(match[3], 10);
      const hours = parseInt(match[4], 10);
      const minutes = parseInt(match[5], 10);
      const seconds = parseInt(match[6], 10);
      
      // Handle nanoseconds
      let milliseconds = 0;
      if (match[7]) {
        const nanoStr = match[7].padEnd(9, '0');
        milliseconds = parseInt(nanoStr.substring(0, 3), 10);
      }
      
      const tzOffset = match[8] || ''; // Format: +HH:MM or -HH:MM or empty

      // Create a proper ISO string that Date constructor can handle
      let isoString;
      if (isUSFormat) {
        // US format: MM-DD-YYYY to YYYY-MM-DD
        isoString = `${year}-${first.toString().padStart(2, '0')}-${second
          .toString()
          .padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minutes
          .toString()
          .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      } else {
        // Indian format: DD-MM-YYYY to YYYY-MM-DD
        isoString = `${year}-${second.toString().padStart(2, '0')}-${first
          .toString()
          .padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minutes
          .toString()
          .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      }
      
      // Add milliseconds if present
      if (match[7]) {
        isoString += `.${milliseconds.toString().padStart(3, '0')}`;
      }
      
      // Add timezone if present
      if (tzOffset) {
        isoString += tzOffset;
      }

      parsedDate = new Date(isoString);
    } else {
      // Try direct parsing as a last resort
      const directParsed = new Date(input);
      if (!isNaN(directParsed.getTime())) {
        parsedDate = directParsed;
      } else {
        // Fallback for other formats or invalid input
        console.warn('Could not parse date format:', input);
        parsedDate = new Date(); // Return current date for invalid input
      }
    }
  } else if (input instanceof Date) {
    parsedDate = new Date(input); // Create a copy of the Date object
  } else {
    parsedDate = new Date(); // Return current date for invalid input
  }

  // Get the user's timezone from localStorage and adjust if needed
  try {
    const userTimezone = localStorage.getItem('nected-tz');

    if (userTimezone) {
      // For dates without timezone info, adjust to the user's timezone
      const options: Intl.DateTimeFormatOptions = {
        timeZone: userTimezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      };

      const formatter = new Intl.DateTimeFormat('en-US', options);
      const parts = formatter.formatToParts(parsedDate);

      // Extract date/time components from the formatted parts
      const dateParts = parts.reduce((acc, part) => {
        if (part.type !== 'literal') {
          acc[part.type] = parseInt(part.value, 10);
        }
        return acc;
      }, {} as Record<string, number>);

      // Create a new date in the user's timezone
      const adjustedDate = new Date(
        dateParts.year,
        dateParts.month - 1,
        dateParts.day,
        dateParts.hour,
        dateParts.minute,
        dateParts.second
      );

      return adjustedDate;
    }
  } catch (error) {
    console.error('Error applying timezone:', error);
  }
  // Return the parsed date without timezone adjustment
  return parsedDate;
};

const formatTimezoneOffset = (offsetMinutes: number): string => {
  const sign = offsetMinutes < 0 ? '-' : '+';
  const absOffset = Math.abs(offsetMinutes);
  const hours = String(Math.floor(absOffset / 60)).padStart(2, '0');
  const minutes = String(absOffset % 60).padStart(2, '0');
  return `${sign}${hours}:${minutes}`;
};

export const getFormattedTimezoneOffset = (
  timeZone: string,
  date: Date = new Date()
): string => {
  const offsetMilliseconds = getTimezoneOffset(timeZone, date);
  // Convert milliseconds to minutes
  const offsetMinutes = offsetMilliseconds / 60000;
  return formatTimezoneOffset(offsetMinutes);
};

export const removeOffsetFromDateString = (dateString: string): string => {
  if (!dateString) return dateString;
  return dateString.replace(/([+-]\d{2}:\d{2}|Z)$/, '');
};

/**
 * Gets the current date adjusted for the specified timezone
 * @param timezone The timezone to use (e.g. 'America/New_York')
 * @returns A Date object representing the current time in the specified timezone
 */
export const getCurrentDateInTimezone = (timezone: string): Date => {
  // Get current date
  const date = new Date();

  // Format the date to ISO string with the specified timezone
  const dateOptions: Intl.DateTimeFormatOptions = {
    timeZone: timezone,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  };

  const formatter = new Intl.DateTimeFormat('en-US', dateOptions);
  const parts = formatter.formatToParts(date);

  // Build date string from parts
  const getPartValue = (type: string): string => {
    const part = parts.find((p) => p.type === type);
    return part ? part.value : '';
  };

  const year = getPartValue('year');
  const month = getPartValue('month').padStart(2, '0');
  const day = getPartValue('day').padStart(2, '0');
  const hour = getPartValue('hour').padStart(2, '0');
  const minute = getPartValue('minute').padStart(2, '0');
  const second = getPartValue('second').padStart(2, '0');

  // Create a new date using the timezone-adjusted values
  const tzAdjustedDateStr = `${year}-${month}-${day}T${hour}:${minute}:${second}`;
  return new Date(tzAdjustedDateStr);
};
